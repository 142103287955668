import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import * as constants from "../constants";

export default function LikeButton({
  isFavourite,
  onClick,
  className,
  isMobile,
}) {
  const style = {
    right:
      className === "first"
        ? (isMobile ? 0 : 4) + constants.gutterFor({ isMobile })
        : undefined,
  };

  return (
    <button
      onClick={onClick}
      style={style}
      className={clsx(
        "shop-card__like-button",
        isFavourite && "favourite",
        className,
      )}
    >
      {isFavourite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
    </button>
  );
}

LikeButton.propTypes = {
  isMobile: PropTypes.bool,
  isFavourite: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
