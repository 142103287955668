import * as React from "react";
import PropTypes from "prop-types";
import { withStyles, styled } from "@material-ui/core/styles";
import { Switch, FormControlLabel, FormGroup } from "@material-ui/core";

const ToggleSwitch = React.memo(function ToggleSwitch({
  label,
  disabled = false,
  checked = false,
  onToggle,
}) {
  return (
    <FormGroup>
      <StyledFormControlLabel
        control={
          <StyledSwitch
            checked={checked}
            disabled={disabled}
            onChange={onToggle}
            name="unpersonalized"
          />
        }
        label={label}
        labelPlacement="start"
      />
    </FormGroup>
  );
});

ToggleSwitch.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  textAlign: "right",
  boxSizing: "border-box",
  margin: 0,
  display: "flex",
  flexGap: theme.spacing(2),
  gap: theme.spacing(2),
  "& span.MuiTypography-root": {
    fontSize: theme.typography.subtitle2.fontSize,
    fontFamily: `${theme.typography.interFontStack} !important`,
  },
}));

const StyledSwitch = withStyles((theme) => ({
  root: {
    width: theme.spacing(4) + 2,
    height: theme.spacing(2) + 2,
    padding: 0,
    zIndex: 5,
    overflow: "initial",
    boxSizing: "border-box",
  },
  switchBase: {
    boxSizing: "border-box",
    padding: 1,
    "&$checked": {
      transform: `translateX(${theme.spacing(2)}px)`,
      color: theme.palette.common.white,
      "& + $track": {
        background:
          "linear-gradient(86.12deg, #385A6D 12.48%, #34B6FF 73.95%), #C4C4C4",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "3px solid #fff",
    },
  },
  thumb: {
    boxSizing: "border-box",
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  track: {
    boxSizing: "border-box",
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default ToggleSwitch;
