import * as React from "react";
import useSWR from "swr";
import { Influencer, withIncludes } from "../domain";
import fetcher from "./fetcher";

const oneMinute = 1000 * 60 * 1; // 1 minute
const baseEndpoint = "/api/influencer";
const endpoint = (forShopBuilder) =>
  forShopBuilder ? "byRouteForShopBuilder" : "byRoute";

export default function useInfluencer({ route, influencerId, forShopBuilder }) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/${
      route ? `${endpoint(forShopBuilder)}/${route}` : `byId/${influencerId}`
    }`,
    fetcher,
    { focusThrottleInterval: oneMinute },
  );

  const influencer = React.useMemo(() => {
    if (!data) {
      return;
    }

    return withIncludes(Influencer.from(data));
  }, [data]);

  return {
    influencer,
    isLoading: !influencer && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useRecomendedShops({ route }) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/recommendedShops/${route}`,
    fetcher,
  );

  const influencers = React.useMemo(() => {
    if (!data) {
      return;
    }
    return data;
  }, [data]);

  return {
    influencers,
    isLoading: !influencers && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useYouMightLike({ route }) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/youMightLike/${route}`,
    fetcher,
  );

  const influencers = React.useMemo(() => {
    if (!data) {
      return;
    }
    return data;
  }, [data]);

  return {
    influencers,
    isLoading: !influencers && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
