import Autocomplete from "@material-ui/lab/Autocomplete";
import { styled } from "@material-ui/core/styles";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
    { padding: "5.5px 4px" },

  "& .MuiAutocomplete-popupIndicator::before": {
    content: "''",
    height: 24,
    width: 1,
    left: -1,
    position: "absolute",
    background: theme.palette.grey[300],
    borderRadius: 0,
  },

  "& .MuiAutocomplete-popupIndicator.MuiAutocomplete-popupIndicatorOpen::before":
    {
      content: "''",
      height: 24,
      width: 1,
      left: "100%",
      position: "absolute",
      background: theme.palette.grey[300],
      borderRadius: 0,
    },

  "& .MuiAutocomplete-popupIndicator": {
    position: "relative",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "& .MuiAutocomplete-clearIndicator": {
    marginRight: 2,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export default StyledAutocomplete;
