import * as React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useInfluencerEvents, useNotification } from "../../../hooks";
import { StyledTextButton, GradientText } from "../../../theme";
import { Influencer } from "../../../domain";
import { noop } from "../../../util";

const EventDialog = React.lazy(() =>
  import("../../TalentDashboard/EventDialog"),
);

const createLink = ({ influencerId }) =>
  `/Dashboard/Manage-Events?activeInfluencerId=${influencerId}`;

const AddEventButton = React.memo(function AddEventButton({ influencer }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const { refresh } = useInfluencerEvents(influencer.influencerId);
  const notify = useNotification();

  const notificationAction = React.useMemo(() => {
    if (!influencer) {
      return null;
    }

    return (
      <Button
        variant="text"
        size="small"
        onClick={() => {
          history.push(createLink(influencer));
        }}
      >
        <GradientText variant="body2">Manage My Events</GradientText>
      </Button>
    );
  }, [influencer, history]);

  const onClose = React.useCallback(
    (message) => {
      if (message) {
        notify({
          message,
          action: notificationAction,
          duration: 5000,
        });
        refresh();
      }
    },
    [refresh, notify, notificationAction],
  );

  return (
    <>
      <StyledTextButton
        color="secondary"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        Add Event
      </StyledTextButton>

      {open && (
        <React.Suspense fallback={null}>
          <EventDialog
            open={open}
            setOpen={setOpen}
            onClose={onClose}
            setAddedItemToList={noop}
          />
        </React.Suspense>
      )}
    </>
  );
});

AddEventButton.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
};

export default AddEventButton;
