import * as React from "react";
import PropTypes from "prop-types";
import { AutoSizer, List } from "react-virtualized";

export default function VirtualizedList({
  AutoSizerProps = {},
  className = "VirtualizedList",
  defaultHeight = 1100,
  defaultWidth = 1100,
  parentRef,
  rowHeight,
  rowRenderer,
  rows,
  ...args
}) {
  return (
    <AutoSizer
      defaultHeight={defaultHeight}
      defaultWidth={defaultWidth}
      {...AutoSizerProps}
    >
      {({ height, width }) => (
        <List
          className={className}
          height={height}
          ref={parentRef}
          rowCount={rows.length}
          rowHeight={rowHeight}
          rowRenderer={rowRenderer}
          width={width}
          {...args}
        />
      )}
    </AutoSizer>
  );
}

VirtualizedList.propTypes = {
  AutoSizerProps: PropTypes.object,
  className: PropTypes.string,
  defaultHeight: PropTypes.number,
  defaultWidth: PropTypes.number,
  parentRef: PropTypes.object,
  rowHeight: PropTypes.number.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
};
