import * as React from "react";

export default function ReferralIcon({ width = 17, height = 17 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6105 12.8608V11.4972C11.6105 10.7739 11.3232 10.0802 10.8117 9.5687C10.3003 9.05724 9.60656 8.7699 8.88324 8.7699H3.42869C2.70537 8.7699 2.01168 9.05724 1.50022 9.5687C0.988753 10.0802 0.701416 10.7739 0.701416 11.4972V12.8608"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.15599 6.04262C7.66222 6.04262 8.88326 4.82158 8.88326 3.31535C8.88326 1.80912 7.66222 0.588074 6.15599 0.588074C4.64975 0.588074 3.42871 1.80912 3.42871 3.31535C3.42871 4.82158 4.64975 6.04262 6.15599 6.04262Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7015 12.8608V11.4972C15.701 10.8929 15.4999 10.3059 15.1297 9.82828C14.7594 9.3507 14.2411 9.00959 13.656 8.85852"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9287 0.676697C11.5154 0.826902 12.0353 1.16808 12.4066 1.64645C12.778 2.12483 12.9795 2.71317 12.9795 3.31874C12.9795 3.92432 12.778 4.51266 12.4066 4.99103C12.0353 5.4694 11.5154 5.81059 10.9287 5.96079"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
