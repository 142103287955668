import * as React from "react";
import { D } from "@mobily/ts-belt";
import { useAtom } from "jotai";
import SignTypeBadge from "../SignTypeBadge";
import { SignType } from "../../domain";
import { CloseRounded } from "@material-ui/icons";

export default function SignedTypes({ filterAtom }) {
  const [filter, setFilter] = useAtom(filterAtom);

  const signedTypes = filter.signedType || {};

  return Object.keys(signedTypes).map((signedType) => (
    <SignTypeBadge
      key={signedType}
      label={SignType.toLabel(signedType)}
      signedType={signedType}
      onDelete={() => {
        setFilter(D.update("signedType", D.deleteKey(signedType)));
      }}
      DeleteIcon={CloseRounded}
    />
  ));
}
