import * as React from "react";
import PropTypes from "prop-types";
import {
  Radio as MUIRadio,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Radio from "./Radio";

export default function Option({
  label,
  onClick,
  checked = true,
  type,
  endIcon,
  color = "primary",
}) {
  return (
    <StyledGrid container justifyContent="space-between" alignItems="center">
      <StyledFormControlLabel
        label={label}
        labelPlacement="end"
        control={controlForType({ type, onClick, checked, color })}
      />
      {endIcon && <Grid item>{endIcon}</Grid>}
    </StyledGrid>
  );
}

Option.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  type: PropTypes.oneOf(["radio", "checkbox"]),
  endIcon: PropTypes.node,
  color: PropTypes.string,
};

function controlForType({ type, onClick, checked, color }) {
  if (type === "checkbox") {
    return <Checkbox onClick={onClick} checked={checked} color={color} />;
  }

  if (type === "radio" && (color === "primary" || color === "secondary")) {
    return <MUIRadio onClick={onClick} checked={checked} color={color} />;
  }

  return <Radio onClick={onClick} checked={checked} color={color} />;
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  flexGap: theme.spacing(1),
  gap: theme.spacing(1),
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  display: "flex",
  flexGap: theme.spacing(1),
  gap: theme.spacing(1),
  flexGrow: 1,
  "& .MuiFormControlLabel-label": {
    fontSize: theme.typography.pxToRem(15),
  },
}));
