import * as React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";

const baseEndpoint = "/api/autographPurchase/user/";

export default function useOrderTracking(autograph_purchase_id) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}${autograph_purchase_id}/track`,
    fetcher,
  );

  const trackingNumbers = React.useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data;
  }, [data]);

  return {
    trackingNumbers,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
