import PropTypes from "prop-types";
import { D } from "@mobily/ts-belt";
import { useAtom } from "jotai";
import * as React from "react";
import { Form } from "../../theme";
import { useFilters } from "./utils";

const getName = D.get("name");
const getId = D.get("id");

export default function Order({ filterAtom }) {
  const [filter, setFilter] = useAtom(filterAtom);
  const { filters } = useFilters();

  return (
    <Form.SimpleSelect
      formControlProps={{ fullWidth: true }}
      value={filter.order}
      options={filters.order}
      getOptionLabel={getName}
      getOptionValue={getId}
      onChange={(evt) => {
        setFilter(D.merge({ rank: 0, order: evt.target.value }));
      }}
    />
  );
}

Order.propTypes = {
  filterAtom: PropTypes.object.isRequired,
};
