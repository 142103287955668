import { styled } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const SoftMediumCard = styled(Paper)(({ theme }) => ({
  boxShadow: theme.boxShadow.softCard,
  padding: theme.spacing(3),
  borderRadius: 10,

  "&.disabled .fade-on-disable": {
    opacity: 0.5,
  },
}));

export default SoftMediumCard;
