import * as React from "react";
import PropTypes from "prop-types";
import GradientText from "../../theme/GradientText";

export default function NewIcon({ width = 19, height = 16 }) {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.80147 6.45411C9.80158 6.32719 9.73188 6.21028 9.62025 6.14992C9.61261 6.14577 8.84323 5.72634 7.94166 4.9311C7.11833 4.20502 6.01125 3.00631 5.41217 1.41868C5.36147 1.28423 5.23275 1.19531 5.08907 1.19531C4.94541 1.19531 4.81669 1.28435 4.76598 1.41868C4.16692 3.00622 3.05984 4.2049 2.23649 4.9311C1.3348 5.72634 0.565554 6.14575 0.558129 6.14981C0.446276 6.21007 0.376465 6.32686 0.376465 6.45388C0.376465 6.58091 0.446276 6.69772 0.558129 6.75796C0.565661 6.76201 1.32234 7.173 2.22256 7.96429C3.05084 8.69242 4.16445 9.89493 4.76598 11.4891C4.81668 11.6235 4.94539 11.7125 5.08907 11.7125C5.23274 11.7125 5.36146 11.6234 5.41217 11.4891C6.01122 9.90155 7.1183 8.70287 7.94166 7.97667C8.84335 7.18143 9.61259 6.76202 9.62002 6.75796C9.73176 6.6977 9.80147 6.58104 9.80147 6.45411Z"
          fill="black"
        />
        <path
          d="M9.80147 6.45411C9.80158 6.32719 9.73188 6.21028 9.62025 6.14992C9.61261 6.14577 8.84323 5.72634 7.94166 4.9311C7.11833 4.20502 6.01125 3.00631 5.41217 1.41868C5.36147 1.28423 5.23275 1.19531 5.08907 1.19531C4.94541 1.19531 4.81669 1.28435 4.76598 1.41868C4.16692 3.00622 3.05984 4.2049 2.23649 4.9311C1.3348 5.72634 0.565554 6.14575 0.558129 6.14981C0.446276 6.21007 0.376465 6.32686 0.376465 6.45388C0.376465 6.58091 0.446276 6.69772 0.558129 6.75796C0.565661 6.76201 1.32234 7.173 2.22256 7.96429C3.05084 8.69242 4.16445 9.89493 4.76598 11.4891C4.81668 11.6235 4.94539 11.7125 5.08907 11.7125C5.23274 11.7125 5.36146 11.6234 5.41217 11.4891C6.01122 9.90155 7.1183 8.70287 7.94166 7.97667C8.84335 7.18143 9.61259 6.76202 9.62002 6.75796C9.73176 6.6977 9.80147 6.58104 9.80147 6.45411Z"
          fill="#E15499"
        />
        <path
          d="M12.6003 12.9328C12.5871 12.9256 11.2777 12.2024 10.7829 10.8915C10.7323 10.7571 10.6035 10.6682 10.4599 10.6682C10.3162 10.6682 10.1875 10.7572 10.1368 10.8915C9.64211 12.2024 8.33269 12.9257 8.32 12.9325C8.20837 12.9927 8.1389 13.1093 8.13867 13.2362C8.13845 13.363 8.20803 13.4799 8.31944 13.5404C8.33259 13.5476 9.64201 14.2708 10.1367 15.5816C10.1874 15.7161 10.3162 15.805 10.4598 15.805C10.6035 15.805 10.7322 15.716 10.7829 15.5816C11.2776 14.2708 12.587 13.5475 12.5997 13.5406C12.7113 13.4805 12.7808 13.3639 12.781 13.237C12.7812 13.1102 12.7117 12.9934 12.6003 12.9328Z"
          fill="black"
        />
        <path
          d="M12.6003 12.9328C12.5871 12.9256 11.2777 12.2024 10.7829 10.8915C10.7323 10.7571 10.6035 10.6682 10.4599 10.6682C10.3162 10.6682 10.1875 10.7572 10.1368 10.8915C9.64211 12.2024 8.33269 12.9257 8.32 12.9325C8.20837 12.9927 8.1389 13.1093 8.13867 13.2362C8.13845 13.363 8.20803 13.4799 8.31944 13.5404C8.33259 13.5476 9.64201 14.2708 10.1367 15.5816C10.1874 15.7161 10.3162 15.805 10.4598 15.805C10.6035 15.805 10.7322 15.716 10.7829 15.5816C11.2776 14.2708 12.587 13.5475 12.5997 13.5406C12.7113 13.4805 12.7808 13.3639 12.781 13.237C12.7812 13.1102 12.7117 12.9934 12.6003 12.9328Z"
          fill="#E15499"
        />
        <path
          d="M18.8185 3.9251C18.8128 3.92206 18.2407 3.60965 17.5707 3.01811C16.9612 2.47998 16.1416 1.59199 15.6988 0.418378C15.6481 0.283928 15.5194 0.195007 15.3757 0.195007C15.232 0.195007 15.1033 0.284043 15.0526 0.418378C14.6096 1.59211 13.7901 2.47998 13.1806 3.01811C12.5106 3.60977 11.9384 3.92204 11.9331 3.92498C11.8213 3.98524 11.7515 4.10203 11.7515 4.22905C11.7515 4.35607 11.8213 4.47289 11.9331 4.53313C11.9556 4.54527 14.1973 5.77319 15.0527 8.03979C15.1034 8.17424 15.2321 8.26316 15.3758 8.26316C15.5195 8.26316 15.6482 8.17412 15.6989 8.03979C16.1418 6.86606 16.9613 5.97818 17.5709 5.44005C18.2409 4.8484 18.8131 4.53612 18.8184 4.53319C18.9301 4.47304 18.9997 4.35636 18.9998 4.22943C18.9998 4.1024 18.9301 3.9856 18.8186 3.92512L18.8185 3.9251Z"
          fill="black"
        />
        <path
          d="M18.8185 3.9251C18.8128 3.92206 18.2407 3.60965 17.5707 3.01811C16.9612 2.47998 16.1416 1.59199 15.6988 0.418378C15.6481 0.283928 15.5194 0.195007 15.3757 0.195007C15.232 0.195007 15.1033 0.284043 15.0526 0.418378C14.6096 1.59211 13.7901 2.47998 13.1806 3.01811C12.5106 3.60977 11.9384 3.92204 11.9331 3.92498C11.8213 3.98524 11.7515 4.10203 11.7515 4.22905C11.7515 4.35607 11.8213 4.47289 11.9331 4.53313C11.9556 4.54527 14.1973 5.77319 15.0527 8.03979C15.1034 8.17424 15.2321 8.26316 15.3758 8.26316C15.5195 8.26316 15.6482 8.17412 15.6989 8.03979C16.1418 6.86606 16.9613 5.97818 17.5709 5.44005C18.2409 4.8484 18.8131 4.53612 18.8184 4.53319C18.9301 4.47304 18.9997 4.35636 18.9998 4.22943C18.9998 4.1024 18.9301 3.9856 18.8186 3.92512L18.8185 3.9251Z"
          fill="#E15499"
        />
      </svg>

      <GradientText style={{ fontWeight: 600 }}>NEW</GradientText>
    </>
  );
}

NewIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
