export default class Address {
  #rawAddress;

  static DEFAULT_ADDRESS = Object.seal(
    Object.freeze({
      country: "US",
      zipCode: "91324",
      state: "CA",
      city: "Northridge",
      address: "19849 Nordhoff St",
      address2: null,
      email: null,
      phoneNumber: null,
      firstName: null,
      lastName: null,
    }),
  );

  static isDefaultAddress(address = {}) {
    return (
      address.country === Address.DEFAULT_ADDRESS.country &&
      address.zipCode === Address.DEFAULT_ADDRESS.zipCode &&
      address.state === Address.DEFAULT_ADDRESS.state &&
      address.city === Address.DEFAULT_ADDRESS.city &&
      address.address === Address.DEFAULT_ADDRESS.address
    );
  }

  static isDomestic(address = {}) {
    return address.country === "US" || address.country === "United States";
  }

  /*
   * from creates a new Address from a rawAddress
   *
   * @param {object} rawAddress
   * @returns {Address}
   */
  static from(rawAddress) {
    return new Address(rawAddress);
  }

  /*
   * creates a new Address from a rawAddress
   *
   * @param {object} rawAddress
   * @returns {Address}
   */
  constructor(rawAddress) {
    const rawAddressCopy = { ...rawAddress };
    this.#rawAddress = rawAddressCopy;
  }

  get firstName() {
    return this.#rawAddress.firstName;
  }

  get lastName() {
    return this.#rawAddress.lastName;
  }

  get address() {
    return this.#rawAddress.address;
  }

  get address2() {
    return this.#rawAddress.address2;
  }

  get city() {
    return this.#rawAddress.city;
  }

  get state() {
    return this.#rawAddress.state;
  }

  get country() {
    return this.#rawAddress.country;
  }

  get zipCode() {
    return this.#rawAddress.zipCode;
  }

  get email() {
    return this.#rawAddress.email;
  }

  get phoneNumber() {
    return this.#rawAddress.phoneNumber;
  }

  get taxRate() {
    return this.#rawAddress.taxRate;
  }

  get isFreightTaxable() {
    return this.#rawAddress.freightTaxable;
  }

  /*
   * mutate returns a new Address with key set to value
   *
   * @param {string} key
   * @param {any} value
   *
   * @returns {Address}
   */
  mutate(key, value) {
    const updatedRawAddress = { ...this.#rawAddress, [key]: value };

    return new Address(updatedRawAddress);
  }

  toJSON() {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      address: this.address,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      zipCode: this.zipCode,
      email: this.email,
      phoneNumber: this.phoneNumber,
      taxRate: this.taxRate,
      isFreightTaxable: this.isFreightTaxable,
    };
  }
}
