import * as React from "react";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { GoldText } from "../../theme";

const GoldBadge = React.forwardRef(function GoldBadge(
  {
    label,
    top,
    left,
    right,
    bottom,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    style = {},
  },
  ref,
) {
  const isAbsolute = Boolean(top || left || right || bottom);

  const finalStyles = {
    top,
    left,
    right,
    bottom,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    position: isAbsolute ? "absolute" : undefined,
    ...style,
  };

  return (
    <StyledBox style={finalStyles} ref={ref}>
      <GoldText>{label}</GoldText>
    </StyledBox>
  );
});

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.gradient.grey,
  width: "fit-content",
  height: "fit-content",
  padding: `4px ${theme.spacing(1)}px`,
}));

export default GoldBadge;
