import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";

const StyledPage = styled(Box)(({ theme }) => ({
  background: "#F7F9FC",
  boxSizing: "border-box",
  fill: 1,
  fontFamily: theme.typography.interFontStack,
  margin: "0",
  padding: "0",
  position: "relative",
  width: "100%",
  flex: 1,
}));

export default function DashboardPage({ children, ...args }) {
  return (
    <StyledPage id="DashboardPage" {...args}>
      {children}
    </StyledPage>
  );
}

DashboardPage.propTypes = {
  children: PropTypes.node,
};
