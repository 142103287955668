import * as React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import SignType from "../../domain/signType";
import { Product } from "../../domain";

function makeSignedTypeList(products) {
  const uniqueSignedTypes = Array.from(
    new Set(products.map((product) => product.signedType)),
  );

  return uniqueSignedTypes
    .reduce(
      (list, signedType) => {
        list.push(SignType.toLabel(signedType));
        return list;
      },
      ["All Signing Types"],
    )
    .sort();
}

const useStyles = makeStyles(() => ({
  root: {
    minWidth: "6em",
    fontSize: "1.5em",
    fontWeight: "bold",
  },
}));

const SignTypeLabel = React.memo(function SignTypeLabel({
  signType,
  products,
  onChange,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { route } = useParams();
  const [signedTypeLabel, setSignedTypeLabel] = React.useState(
    SignType.toLabel(signType),
  );
  const [signedTypeList, setSignedTypeList] = React.useState([]);

  React.useEffect(() => {
    if (!products) return;
    if (signedTypeList.length) return;

    setSignedTypeList(makeSignedTypeList(products));
  }, [products, signedTypeList]);

  const changeSignedType = React.useCallback(
    (val) => {
      setSignedTypeLabel(val);
      const param = SignType.labelToParam(val);
      history.replace(`/${route}/${param}`);

      if (typeof onChange === "function") onChange(val);
    },
    [onChange, history, route],
  );

  return (
    <TextField
      id="select-signtype"
      label="Signed Type"
      select
      size="medium"
      variant="standard"
      value={signedTypeLabel}
      margin="none"
      onChange={(event) => {
        changeSignedType(event.target.value);
      }}
      InputProps={{ classes, disableUnderline: true }}
    >
      {signedTypeList.map((option, idx) => (
        <MenuItem key={`option${idx}`} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
});

SignTypeLabel.propTypes = {
  signType: PropTypes.object,
  products: PropTypes.arrayOf(PropTypes.instanceOf(Product)),
  onChange: PropTypes.func,
};

export default SignTypeLabel;
