import Money from "./money";
import Influencer from "./influencer";

export default class Fulfiller extends Influencer {
  #rawFulfiller;

  /*
   * from creates a new Fulfiller from a rawFulfiller
   *
   * @param {object} rawFulfiller
   * @returns {Fulfiller}
   */
  static from(rawFulfiller) {
    return new Fulfiller(rawFulfiller);
  }

  /*
   * creates a new Fulfiller from a rawFulfiller
   *
   * @param {object} rawFulfiller
   * @returns {Fulfiller}
   */
  constructor(rawFulfiller) {
    super(rawFulfiller);
    const rawFulfillerCopy = { ...rawFulfiller };
    this.#rawFulfiller = rawFulfillerCopy;
  }

  get hasNoFulfillment() {
    return Boolean(this.#rawFulfiller.no_fulfillment);
  }

  get splitPercentage() {
    return this.#rawFulfiller.split_percentage;
  }

  /*
   * splitAmount returns the money for a fulfiller's split amount
   *
   * @returns {Money}
   */
  get splitAmount() {
    return Money.for({
      value: this.#rawFulfiller.split_amount / 100,
      currency: "usd",
    });
  }

  get position() {
    return this.#rawFulfiller.position;
  }

  get influencerPosition() {
    return super.position;
  }

  get isActive() {
    return Boolean(this.#rawFulfiller.active);
  }

  get isActiveInfluencer() {
    return super.isActive;
  }

  get created() {
    if (this.#rawFulfiller.created) {
      return new Date(this.#rawFulfiller.created);
    }

    return void 0;
  }

  get influencerCreated() {
    return super.created;
  }

  get updated() {
    if (this.#rawFulfiller.updated) {
      return new Date(this.#rawFulfiller.updated);
    }

    return void 0;
  }

  get influencerUpdated() {
    return super.updated;
  }

  /*
   * mutate returns a new Fulfiller with key set to value
   *
   * @param {string} key
   * @param {any} value
   *
   * @returns {Fulfiller}
   */
  mutate(key, value) {
    const updatedRawFulfiller = { ...this.#rawFulfiller, [key]: value };
    return new Fulfiller(updatedRawFulfiller);
  }

  toJSON() {
    return {
      ...super.toJSON(),
      hasNoFulfillment: this.hasNoFulfillment,
    };
  }
}
