import confettiProvider from "./confettiProvider";

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

export function simpleConfetti() {
  return confettiProvider({
    particleCount: 100,
    spread: 70,
    origin: { y: 0.6 },
  });
}

export function realisticConfetti() {
  const count = 200;
  const defaults = { origin: { y: 0.7 } };

  const realisticOpts = [
    [0.25, { spread: 26, startVelocity: 55 }],
    [0.2, { spread: 60 }],
    [0.35, { spread: 100, decay: 0.91, scalar: 0.8 }],
    [0.1, { spread: 100, startVelocity: 25, decay: 0.92, scalar: 1.2 }],
    [0.1, { spread: 120, startVelocity: 45 }],
  ];

  return realisticOpts.forEach(([particleRatio, opts]) => {
    confettiProvider({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
    });
  });
}

export function annoyingFireworks() {
  const duration = 15 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

  const interval = setInterval(() => {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 50 * (timeLeft / duration);

    confettiProvider({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
    });
    confettiProvider({
      ...defaults,
      particleCount,
      origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
    });
  }, 250);
}
