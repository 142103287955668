import * as React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@material-ui/core";
import { Form, SoftMediumCard, StyledTextButton } from "../../../theme";
import ImageAvatar from "../../ImageAvatar";
import { Influencer } from "../../../domain";
import { useInfluencer } from "../../../hooks";
import Description from "./Description";
import ShopStatus from "./ShopStatus";
import AddEventButton from "./AddEventButton";
import UpcomingSignings, { loadingUI } from "../../UpcomingSignings";
import SocialHandles from "../../SocialHandles";
import VideoAvatarEditor from "../../VideoAvatarEditor";
import { Skeleton } from "@material-ui/lab";
import SocialHandleModal from "../SocialHandleModal";

const ShopBuilderHeader = React.memo(function ShopBuilderHeader({
  route,
  setEditingAvatar,
  setEditingSettings,
}) {
  const { influencer, isLoading, isError, refresh } = useInfluencer({
    route,
    forShopBuilder: true,
  });

  const [completionDeadline, setCompletionDeadline] = React.useState(
    influencer?.completion_deadline ?? 7,
  );

  const handleChange = async (evt) => {
    const newValue = Number.parseInt(evt.target.value, 10);

    setCompletionDeadline(newValue);

    const updates = { completion_deadline: newValue };

    Influencer.update({ influencer, updates }).then(() => refresh());
  };

  React.useEffect(() => {
    setCompletionDeadline(influencer?.completionDeadline ?? 7);
  }, [influencer?.completionDeadline]);

  return (
    <SoftMediumCard>
      <Grid container spacing={3}>
        <Grid item xs={6} sm="auto">
          <ImageAvatar
            disableShadow
            influencer={influencer}
            height={192}
            width={144}
            onClick={() => setEditingAvatar(true)}
          />

          <Box pt={1}>
            <StyledTextButton
              size="small"
              color="secondary"
              onClick={() => setEditingAvatar(true)}
            >
              Edit Account Photo
            </StyledTextButton>
          </Box>
        </Grid>

        <Grid item xs={6} sm="auto">
          {!influencer && <Skeleton width={143} height={192} variant="rect" />}

          {Boolean(influencer) && (
            <VideoAvatarEditor influencerId={influencer.influencerId} />
          )}
        </Grid>

        <Grid item container xs={12} sm direction="column" spacing={1}>
          <Description
            influencer={influencer}
            isLoading={isLoading}
            isError={isError}
            refresh={refresh}
          />

          {Boolean(influencer?.productTagString) && (
            <Grid item>
              <Typography variant="h6">Shop Hashtag</Typography>
              <Typography gutterBottom variant="subtitle1" component="p">
                #{influencer.productTagString}
              </Typography>
            </Grid>
          )}

          <Grid item xs container spacing={2} alignItems="center">
            <Grid item>
              <SocialHandles handles={influencer?.handles?.list ?? []} />
            </Grid>

            <Grid item>
              <SocialHandleModal route={route} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="column" xs={12} md={4}>
          <Grid item container justifyContent="flex-end">
            <Box pb={3}>
              <ShopStatus route={route} />
            </Box>
          </Grid>

          <Grid item>
            {!influencer && loadingUI}

            {Boolean(influencer) && (
              <UpcomingSignings
                influencerId={influencer.influencerId}
                alignItems="flex-end"
                allowEdit={!influencer.isGroupShop}
              />
            )}
          </Grid>

          <Grid item container justifyContent="flex-end">
            {Boolean(influencer) && (
              <Box pt={0.5} pb={2}>
                <AddEventButton influencer={influencer} refresh={refresh} />
              </Box>
            )}
          </Grid>

          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            direction="column"
          >
            <Typography variant="caption" color="textSecondary">
              Shop Signing Deadline
            </Typography>

            <Form.FancySelect
              aria-label="Set Completion Deadline"
              id="completionDeadlineSelect"
              size="small"
              variant="outlined"
              value={completionDeadline}
              onChange={handleChange}
              options={Array.from({ length: 13 }, (_, i) => i + 2)}
              getOptionLabel={(n) => `${n} Days from Purchase`}
            />
          </Grid>

          <Grid
            item
            container
            alignItems="flex-end"
            justifyContent="flex-end"
            style={{ flexGrow: 1 }}
          >
            <Box mt={1}>
              <StyledTextButton
                size="small"
                onClick={() => setEditingSettings(true)}
              >
                Advanced Settings
              </StyledTextButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </SoftMediumCard>
  );
});

ShopBuilderHeader.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
  route: PropTypes.string,
  setEditingAvatar: PropTypes.func,
  setEditingSettings: PropTypes.func,
};

export default ShopBuilderHeader;
