import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { A, B, D, G, O, pipe } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer } from "../../../domain";
import { useInfluencerRoute, useMatchesXsDown } from "../../../hooks";
import ShopTag from "./ShopTag";

const StyledHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.grey[800],
  fontWeight: theme.typography.fontWeightBold,
  [theme.breakpoints.up("xs")]: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const topDivider = <Divider style={{ marginBottom: 8, opacity: 0.5 }} />;
const bottomDivider = <Divider style={{ marginTop: 8, opacity: 0.5 }} />;
const defaultTagsToShow = 6;
const maxTagsToShow = 12;

export default function ShopTags() {
  const { influencer } = useInfluencerRoute();
  const matchesXsDown = useMatchesXsDown();
  const [showMore, setShowMore] = React.useState(false);

  const { productTags } = influencer;

  if (!G.isArray(productTags) || A.isEmpty(productTags)) {
    return null;
  }

  const showAll =
    A.length(productTags) <= defaultTagsToShow || showMore || matchesXsDown;

  const tags = pipe(
    D.get(influencer, "productTags"),
    O.getWithDefault([]),
    A.filter((tag) => tag.shopRoute !== influencer.route),
    showAll ? A.slice(0, maxTagsToShow) : A.slice(0, defaultTagsToShow),
    A.map((tag) => (
      <Grid item key={tag.tagString}>
        <ShopTag tag={tag} />
      </Grid>
    )),
  );

  if (matchesXsDown) {
    return (
      <>
        {topDivider}

        <Grid container alignItems="center">
          <Grid item xs={12}>
            <StyledHeading variant="subtitle2">
              Related Collections
            </StyledHeading>
          </Grid>

          <Grid item xs={12}>
            <Box
              width="100%"
              py={1}
              display="flex"
              gridGap="8px"
              style={{ overflowX: "scroll" }}
            >
              {tags}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <>
      {topDivider}

      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <StyledHeading variant="subtitle2">Related Collections</StyledHeading>
        </Grid>

        {tags}

        {A.length(productTags) > defaultTagsToShow && (
          <Grid item>
            <Button
              size="small"
              onClick={() => {
                setShowMore(B.not);
              }}
            >
              {showMore ? "Show Less" : "Show More"}
            </Button>
          </Grid>
        )}
      </Grid>

      {bottomDivider}
    </>
  );
}

ShopTags.propTypes = {
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  influencer: PropTypes.instanceOf(Influencer),
};
