import { Box, Button, Typography } from "@material-ui/core";
import { G } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import Salespeople from "./Salespeople";
import SalespersonSearch from "./SalespersonSearch";
import {
  MAX_SALESPEOPLE,
  StyledDialog,
  updateSalespeople,
  useSalespersons,
} from "./utils";

export default function ShopSalesperson({ influencerId }) {
  const {
    isError,
    isLoading: isLoadingSalespersons,
    mutate,
    salespersons,
  } = useSalespersons(influencerId);

  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const setSalespersons = React.useCallback(
    async (workingSalespersons) => {
      let optimisticData;

      if (G.isFunction(workingSalespersons)) {
        optimisticData = workingSalespersons(salespersons);
      } else {
        optimisticData = workingSalespersons;
      }

      setLoading(true);

      try {
        await mutate(
          updateSalespeople({ influencerId, salespersons: optimisticData }),
          {
            optimisticData,
            rollbackOnError(err) {
              console.error(err);
              return err.name !== "AbortError";
            },
          },
        );

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    },
    [salespersons, influencerId, mutate],
  );

  const isLoading = isLoadingSalespersons || loading;

  const onClose = () => {
    if (isLoading) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box mb={2} display="flex" justifyContent="center">
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Edit Salespersons
      </Button>

      <StyledDialog open={open} onClose={onClose}>
        <Typography variant="h5" gutterBottom>
          Edit Salespersons
        </Typography>

        <Box py={2} mb={2}>
          <Salespeople
            isError={isError}
            salespersons={salespersons}
            setSalespersons={setSalespersons}
          />
        </Box>

        {salespersons.length < MAX_SALESPEOPLE && (
          <SalespersonSearch
            isError={isError}
            isLoading={isLoading}
            salespersons={salespersons}
            setSalespersons={setSalespersons}
            setUser={setUser}
            user={user}
          />
        )}

        <Box
          display="flex"
          justifyContent="flex-end"
          mt={salespersons.length < MAX_SALESPEOPLE ? 4 : 0}
        >
          <Button
            disabled={!salespersons.length}
            color="secondary"
            onClick={onClose}
            style={{ minWidth: "12rem" }}
            variant="contained"
          >
            Done
          </Button>
        </Box>
      </StyledDialog>
    </Box>
  );
}

ShopSalesperson.propTypes = {
  influencerId: PropTypes.number.isRequired,
};
