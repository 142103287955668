import { styled } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const StreamilyButtonSmall = styled(Button)(({ theme }) => ({
  textTransform: "none",
  background: theme.gradient.primary,
  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  "&:disabled": {
    background: theme.palette.grey[200],
  },
  transition: "all 0.1s ease-out",
  "&:hover": {
    filter: "brightness(1.05)",
    background: "linear-gradient(93.21deg, #93CFF1 1.22%, #2A6FC0 88.2%)",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  },
  color: "white",
  width: "100%",
  padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  "&.offline": {
    background: theme.palette.grey[500],
  },
}));

export default StreamilyButtonSmall;
