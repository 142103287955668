import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Link } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StreamilyBlueLink = React.memo(function StreamilyBlueLink({
  children,
  component,
  ...props
}) {
  if (props.to) {
    // eslint-disable-next-line no-param-reassign
    component = RouterLink;
  } else if (props.onClick) {
    // eslint-disable-next-line no-param-reassign
    component = "button";
  }

  return (
    <StyledLink {...props} component={component}>
      {children}
    </StyledLink>
  );
});

StreamilyBlueLink.propTypes = {
  component: PropTypes.func,
  onClick: PropTypes.func,
  onHover: PropTypes.string,
};

const StyledLink = styled(Link)(({ theme }) => ({
  border: "none",
  background: "none",
  padding: 0,
  fontFamily: theme.typography.interFontStack,
  lineHeight: theme.typography.body1.lineHeight,
  letterSpacing: theme.typography.body1.letterSpacing,
  textAlign: "unset",
  fontSize: theme.typography.body1.fontSize,
  color: theme.palette.blue.main,
  cursor: "pointer",
  transition: "color 0.2s ease-out, text-shadow 0.2s ease-out",
  textDecoration: "none",
  "&:hover": {
    color: theme.palette.blue.dark,
    textDecoration: "none",
    textShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
  },
  "&:focus": {
    color: theme.palette.blue.dark,
    textDecoration: "none",
    textShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
    outlineColor: theme.palette.blue.dark,
    outline: "2px solid",
    borderRadius: theme.spacing(0.5),
  },
}));

export default StreamilyBlueLink;
