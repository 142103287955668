import { Snackbar } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  minHeight: 55,
  "& .MuiSnackbarContent-message": {
    ...theme.typography.body2,
    color: theme.palette.getContrastText(theme.palette.grey.main),
    fontSize: "1rem",
    fontWeight: 600,
    margin: "0.25em",
  },
  "& .MuiSnackbarContent-root": {
    background: theme.palette.grey.dark,
    minWidth: "auto",
  },
}));

export default StyledSnackbar;
