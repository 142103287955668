import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import FileDetails from "./FileDetails";

function FilePreview({ file, url }) {
  if (file.type.startsWith("video")) {
    return <FilePreview.VideoPreview file={file} url={url} />;
  }

  if (file.type.startsWith("image")) {
    return <FilePreview.ImagePreview file={file} url={url} />;
  }

  return (
    <Grid container>
      <FileDetails file={file} />
    </Grid>
  );
}

FilePreview.propTypes = {
  file: PropTypes.instanceOf(File),
  url: PropTypes.string.isRequired,
};

FilePreview.VideoPreview = function VideoPreview({ file, url }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md="auto">
        <StyledVideo height={300} width={220} controls src={url} />
      </Grid>

      <FileDetails file={file} />
    </Grid>
  );
};

const StyledVideo = styled("video")(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  boxShadow: "0px 2px 5px 0px #00000040",
  height: 300,
  width: 220,
  objectFit: "cover",
}));

FilePreview.ImagePreview = function ImagePreview({ file, url }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md="auto">
        <StyledImage src={url} height={300} width={220} />
      </Grid>

      <FileDetails file={file} />
    </Grid>
  );
};

const StyledImage = styled("img")(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  boxShadow: "0px 2px 5px 0px #00000040",
  height: 300,
  width: 220,
  objectFit: "cover",
}));

export default React.memo(FilePreview);
