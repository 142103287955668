import * as React from "react";
import useSWR from "swr";
import { User } from "../domain";
import fetcher from "./fetcher";

const baseEndpoint = "/api/user";

export default function useUsers(query) {
  const searchParams = new URLSearchParams();

  Object.entries(query).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  const searchString = searchParams.toString();

  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/search${searchString.length ? `?${searchString}` : ""}`,
    fetcher,
  );

  const users = React.useMemo(() => {
    if (!data || !Array.isArray(data)) {
      return;
    }

    return data.map(User.from);
  }, [data]);

  return {
    users,
    isLoading: !users && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useUsersByEmail(email = "") {
  const endpoint = email
    ? `${baseEndpoint}/search/?email=${encodeURIComponent(email)}`
    : null;

  const { data, error, mutate } = useSWR(endpoint, fetcher, {
    keepPreviousData: true,
  });

  const users = React.useMemo(() => {
    if (Array.isArray(data)) {
      return data.map(User.from);
    }

    return [];
  }, [data]);

  return {
    users,
    isLoading: !data && email && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
