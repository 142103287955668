import * as React from "react";
import axios from "axios";
import useSWR from "swr";
import fetcher from "./fetcher";
import { logger } from "../util";

const baseEndpoint = "/api/user";

export function useTeam(userId) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/${userId}/team`,
    fetcher,
  );

  const team = React.useMemo(() => {
    if (!data?.team) {
      return null;
    }
    const [team] = data.team;

    return team;
  }, [data]);

  return {
    team,
    isTeamLoading: !data && !error,
    isTeamError: Boolean(error),
    teamEerror: error,
    teamRefresh: mutate,
  };
}

export async function updateTeam({ userId, teamId, userTeamId }) {
  try {
    const { status } = await axios.post(`${baseEndpoint}/team`, {
      userId,
      teamId,
      userTeamId,
    });
    return status === 200;
  } catch (err) {
    logger.error(err);
    return false;
  }
}

export function useTeamList() {
  const { data, error, mutate } = useSWR(`${baseEndpoint}/teamlist`, fetcher);

  const teamList = React.useMemo(() => {
    if (!Array.isArray(data?.teamList)) {
      return [];
    }
    return data?.teamList;
  }, [data]);

  return {
    teamList,
    isTeamListLoading: !data && !error,
    isTeamListError: Boolean(error),
    teamListError: error,
    teamListRefresh: mutate,
  };
}
