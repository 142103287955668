import * as socialHandles from "./socialHandle";

const isJSON = (response) =>
  response.headers.has("Content-Type") &&
  response.headers.get("Content-Type").startsWith("application/json");

const toBody = (response) =>
  isJSON(response) ? response.json() : response.text();

/*
 * SocialHandles represents an Influencer's social handles
 */
export default class SocialHandles {
  #rawInfluencer;

  static async update({ socialHandles, updates = {} }) {
    const init = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        influencer_id: socialHandles.influencerId,
        ...updates,
      }),
    };

    const response = await window.fetch(
      `/api/socialHandle/${socialHandles.id}`,
      init,
    );

    if (!response.ok) {
      const body = await toBody(response);
      const error = new TypeError("Response was not ok");
      error.body = body;
      throw error;
    }

    if (!isJSON(response)) {
      const body = await toBody(response);
      const error = new TypeError("Response was not json");
      error.body = body;
      throw error;
    }

    return toBody(response);
  }

  /*
   * for creates a new SocialHandles from a rawInfluencer
   *
   * @param {object} rawInfluencer
   * @returns {SocialHandles}
   */
  static for(rawInfluencer) {
    return new SocialHandles(rawInfluencer);
  }

  /*
   * creates a new SocialHandles for a rawInfluencer
   *
   * @param {object} rawInfluencer
   * @returns {SocialHandles}
   */
  constructor(rawInfluencer) {
    this.#rawInfluencer = rawInfluencer;
  }

  get influencerId() {
    return this.#rawInfluencer.influencer_id;
  }

  get id() {
    return this.#rawInfluencer.social_handle_id;
  }

  get instagram() {
    if (this.#rawInfluencer.instagram_handle) {
      return new socialHandles.InstagramHandle({
        handle: this.#rawInfluencer.instagram_handle,
      });
    }

    return void 0;
  }

  get twitch() {
    if (this.#rawInfluencer.twitch_handle) {
      return new socialHandles.TwitchHandle({
        handle: this.#rawInfluencer.twitch_handle,
      });
    }

    return void 0;
  }

  get youtube() {
    if (this.#rawInfluencer.youtube_handle) {
      return new socialHandles.YoutubeHandle({
        handle: this.#rawInfluencer.youtube_handle,
        channel_id: this.#rawInfluencer.youtube_channel_id,
      });
    }

    return void 0;
  }

  get tiktok() {
    if (this.#rawInfluencer.tiktok_handle) {
      return new socialHandles.TikTokHandle({
        handle: this.#rawInfluencer.tiktok_handle,
      });
    }

    return void 0;
  }

  get twitter() {
    if (this.#rawInfluencer.twitter_handle) {
      return new socialHandles.TwitterHandle({
        handle: this.#rawInfluencer.twitter_handle,
      });
    }

    return void 0;
  }

  get facebook() {
    if (this.#rawInfluencer.facebook_handle) {
      return new socialHandles.FacebookHandle({
        handle: this.#rawInfluencer.facebook_handle,
      });
    }

    return void 0;
  }

  get list() {
    return [
      this.instagram,
      this.twitch,
      this.youtube,
      this.tiktok,
      this.twitter,
      this.facebook,
    ].filter(Boolean);
  }

  toJSON() {
    return {
      instagram: this.instagram?.toJSON(),
      twitch: this.twitch?.toJSON(),
      youtube: this.youtube?.toJSON(),
      tiktok: this.tiktok?.toJSON(),
      twitter: this.twitter?.toJSON(),
      facebook: this.facebook?.toJSON(),
      list: this.list.map((handle) => handle.toJSON()),
    };
  }
}
