import PropTypes from "prop-types";
import * as React from "react";
import {
  useProductForShopBuilder,
  useProductTypesForUser,
  useSignedTypes,
} from "../../hooks";
import Modal from "./Modal";

export default function ShopBuilderProductModal({
  productId,
  onClose,
  livestreamWarning,
}) {
  const { product, isError, isLoading } = useProductForShopBuilder(
    productId ?? null,
  );

  const {
    productTypes,
    isError: isErrorPT,
    isLoading: isLoadingPT,
  } = useProductTypesForUser();

  const {
    signedTypes,
    isError: isErrorST,
    isLoading: isLoadingST,
  } = useSignedTypes();

  if (
    isLoading ||
    isError ||
    isErrorPT ||
    isLoadingPT ||
    isErrorST ||
    isLoadingST
  ) {
    return null;
  }

  return (
    <Modal
      hasLivestreamWarning={Boolean(livestreamWarning)}
      onClose={onClose}
      product={product}
      productTypes={productTypes}
      signedTypes={signedTypes}
    />
  );
}

ShopBuilderProductModal.propTypes = {
  livestreamWarning: PropTypes.any,
  onClose: PropTypes.func,
  productId: PropTypes.number,
};
