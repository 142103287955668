import * as React from "react";
import PropTypes from "prop-types";
import {
  InputBase,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledInput = styled(InputBase)(({ theme }) => ({
  position: "relative",
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  borderRadius: 10,
  zIndex: 1,

  "&::after": {
    content: '""',
    background: "white",
    inset: 1,
    zIndex: -1,
    position: "absolute",
    borderRadius: 9,
  },

  "&::before": {
    zIndex: -1,
    content: '""',
    inset: 0,
    position: "absolute",
    borderRadius: 10,
    background: theme.palette.grey[300],
    transition: "background 0.2s ease-in-out",
  },

  "&:hover::after, &:focus-within::after": {
    inset: 2,
    borderRadius: 8,
  },

  "&:hover::before, &:focus-within::before": {
    background:
      "linear-gradient(268.66deg, #358FAB -7.68%, #A8DCEC 99.38%), linear-gradient(90deg, #F98E8E 0%, #B450A1 29.17%, #A865C5 66.67%, #374BCF 100%), linear-gradient(0deg, #4FBADC, #4FBADC)",
  },

  "&.Mui-disabled::before, &.Mui-disabled:hover::before, &.Mui-disabled:focus-within::before":
    {
      background: theme.palette.grey[300],
    },

  "&.Mui-disabled::after, &.Mui-disabled:hover::after, &.Mui-disabled:focus-within::after":
    {
      inset: 1,
      borderRadius: 9,
      background: theme.palette.grey[100],
    },

  "&.MuiInputBase-root.MuiInputBase-formControl .MuiInputBase-input::placeholder":
    {
      color: theme.palette.grey.lighter,
      opacity: "0.75 !important",
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(15),
    },
}));

const StyledLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiInputLabel-formControl": {
    display: "flex",
    position: "relative",
    transform: "none",
    ...theme.typography.body2,
    color: "#5B5B5B",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(0.5),
  },
}));

export default function LabelInput({
  disabled = true,
  error = false,
  helperText = "",
  label = "",
  onChange,
  onClick,
  placeholder = "",
  type = "text",
  value,
  fullWidth = false,
  ...props
}) {
  return (
    <StyledFormControl error={error} fullWidth={fullWidth} disabled={disabled}>
      <StyledLabel disableAnimation>{label}</StyledLabel>
      <StyledInput
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
        type={type}
        value={value}
        {...props}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </StyledFormControl>
  );
}

LabelInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};
