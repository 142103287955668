import { curry } from "./fp";

export function limitLength(str = "", maxLength = 50) {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.substring(0, maxLength - 3)}...`;
}

export function capitalizeWord(inputStr = "") {
  if (!inputStr || typeof inputStr !== "string") {
    return "";
  }

  const first = inputStr.substring(0, 1);
  const rest = inputStr.substring(1);

  return `${first.toLocaleUpperCase()}${rest.toLocaleLowerCase()}`;
}

export function titleCase(sentence = "") {
  if (!sentence || typeof sentence !== "string") {
    return "";
  }

  return sentence.split(/\s+/).map(capitalizeWord).join(" ");
}

// Join english words with commas and "and" in the last one
export function joinEnglish(...words) {
  if (!words || !words.length) {
    return "";
  }

  let [first] = words;

  if (Array.isArray(first)) {
    // eslint-disable-next-line no-param-reassign
    words = first;
    [first] = words;
  }

  if (typeof first !== "string") {
    return "";
  }

  if (words.length === 1) {
    return first;
  }

  const last = words.pop();
  return `${words.join(", ")} and ${last}`;
}

// Pluralize english words
export function pluralizeEnglish(word = "", count = 1) {
  if (!word || typeof word !== "string") {
    return "";
  }

  if (count === 1) {
    return word;
  }

  if (word.endsWith("s")) {
    return `${word}es`;
  }

  if (word.endsWith("y")) {
    return `${word.slice(0, -1)}ies`;
  }

  return `${word}s`;
}

export function joinEnglishReducer(currentPhrase, currentWord, index, array) {
  if (index === 0) {
    return currentWord;
  }

  if (index === array.length - 1) {
    return `${currentPhrase} and ${currentWord}`;
  }

  return `${currentPhrase}, ${currentWord}`;
}

/*
 * joinEnglishPick
 *
 * @param {function} picker - A function that takes an element and returns a string
 * @param {array} array - An array of elements
 * @returns {string} - A string of the elements joined with commas and "and" in the last one
 */
export const joinEnglishPick = curry((picker, array) => {
  return array.reduce((currentPhrase, currentElement, index, array) => {
    const currentWord = picker(currentElement);

    if (index === 0) {
      return currentWord;
    }

    if (index === array.length - 1) {
      return `${currentPhrase} and ${currentWord}`;
    }

    return `${currentPhrase}, ${currentWord}`;
  }, "");
});

export const validateEmail = (email) => {
  if (
    email &&
    email.match(
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
    )
  ) {
    return true;
  }
  return false;
};
