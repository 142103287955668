import { Box, Collapse, Divider, Grid, Hidden } from "@material-ui/core";
import { styled as muiStyled } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import FacebookIcon from "../assets/facebook.svg";
import InstagramIcon from "../assets/instagram.svg";
import TiktokIcon from "../assets/tik_tok.svg";
import TwitchIcon from "../assets/twitchFooter.svg";
import TwitterIcon from "../assets/twitter.svg";
import YoutubeIcon from "../assets/youtube.svg";
import NewsletterEmailEntry from "../components/Home/NewsletterEmailEntry";
import { useMatchesXsDown } from "../hooks";

const socialIcons = [
  <a
    key="instagram"
    href="https://instagram.com/streamily.live"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img width={28} height={28} src={InstagramIcon} alt="Instagram" />
  </a>,
  <a
    key="twitter"
    href="https://twitter.com/StreamilyLive"
    target="_blank"
    rel="noopener noreferrer"
  >
    <span
      style={{
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        padding: 6,
        background: "black",
        width: 28,
        height: 28,
      }}
    >
      <img width={16} height={16} src={TwitterIcon} alt="X" />
    </span>
  </a>,
  <a
    key="youtube"
    href="https://youtube.com/streamily"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img width={28} height={28} src={YoutubeIcon} alt="YouTube" />
  </a>,
  <a
    key="facebook"
    href="https://www.facebook.com/Streamily"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img width={28} height={28} src={FacebookIcon} alt="Facebook" />
  </a>,
  <a
    key="tiktok"
    href="https://www.tiktok.com/@streamilylive"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img width={28} height={28} src={TiktokIcon} alt="TikTok" />
  </a>,
  <a
    key="twitch"
    href="https://www.twitch.tv/streamilylive"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img width={28} height={28} src={TwitchIcon} alt="Twitch" />
  </a>,
];

const pagesWithoutFooter = [
  "/dashboard",
  "/signing-assistant",
  "/talent-dash/get-paid",
  "/account-dashboard",
  "/pendingdashboard",
  "/quickship",
];

const showGiftCards = false;

const shouldHide = ({ pathname }) =>
  pagesWithoutFooter.some((page) => pathname.toLowerCase().includes(page));

const Footer = ({ isHomePage }) => {
  const location = useLocation();
  const [showSupport, setShowSupport] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false);
  const [showJoin, setShowJoin] = React.useState(false);
  const isMobile = useMatchesXsDown();

  if (shouldHide(location)) {
    return null;
  }

  const className = isHomePage ? "dark" : "light";

  return (
    <Container id="streamilyFooterMain" isHomePage={isHomePage}>
      <Hidden xsDown>
        <Divider
          style={{
            marginBottom: "2rem",
            background: isHomePage ? "#575D63" : "#dadada",
          }}
        />
      </Hidden>

      <Hidden smUp>
        <NewsletterEmailEntry isHomePage={isHomePage} />
      </Hidden>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <Box
            mt={isMobile ? 4 : 0}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {!isMobile && (
              <ColumnTitle isHomePage={isHomePage}>Support</ColumnTitle>
            )}

            {isMobile && (
              <ToggleButton
                isHomePage={isHomePage}
                isOpen={showSupport}
                onClick={() => setShowSupport((s) => !s)}
                label={
                  <ColumnTitle isHomePage={isHomePage}>Support</ColumnTitle>
                }
              />
            )}
          </Box>

          <Collapse in={!isMobile || showSupport}>
            <Box
              mt={2}
              mb={2}
              gridGap={12}
              display="flex"
              flexDirection="column"
            >
              <AnchorLink isHomePage={isHomePage} href="/faq">
                FAQ
              </AnchorLink>

              <AnchorLink isHomePage={isHomePage} href="/return-policy">
                Return Policy
              </AnchorLink>

              <ReactLink isHomePage={isHomePage} to="/Contact">
                Contact Us
              </ReactLink>
            </Box>
          </Collapse>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {!isMobile && (
              <ColumnTitle isHomePage={isHomePage}>Info</ColumnTitle>
            )}

            {isMobile && (
              <ToggleButton
                isHomePage={isHomePage}
                isOpen={showInfo}
                onClick={() => setShowInfo((s) => !s)}
                label={<ColumnTitle isHomePage={isHomePage}>Info</ColumnTitle>}
              />
            )}
          </Box>

          <Collapse in={!isMobile || showInfo}>
            <Box
              mt={2}
              mb={2}
              gridGap={12}
              display="flex"
              flexDirection="column"
            >
              {showGiftCards && (
                <AnchorLink
                  isHomePage={isHomePage}
                  href="https://giftup.app/place-order/c2c871d0-ff24-4390-9eca-35251fe179b5?platform=hosted"
                >
                  Gift Cards
                </AnchorLink>
              )}
              <AnchorLink
                isHomePage={isHomePage}
                href="https://blog.streamily.com"
              >
                Blog
              </AnchorLink>

              <AnchorLink isHomePage={isHomePage} href="/sitemap.html">
                Sitemap
              </AnchorLink>
            </Box>
          </Collapse>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {!isMobile && (
              <ColumnTitle isHomePage={isHomePage}>Join Us</ColumnTitle>
            )}

            {isMobile && (
              <ToggleButton
                isHomePage={isHomePage}
                isOpen={showJoin}
                onClick={() => setShowJoin((s) => !s)}
                label={
                  <ColumnTitle isHomePage={isHomePage}>Join Us</ColumnTitle>
                }
              />
            )}
          </Box>

          <Collapse in={!isMobile || showJoin}>
            <Box
              mt={2}
              mb={2}
              gridGap={12}
              display="flex"
              flexDirection="column"
            >
              <ReactLink isHomePage={isHomePage} to="/talent/signup">
                <strong>Talent Sign Up</strong>
              </ReactLink>

              <AnchorLink
                isHomePage={isHomePage}
                href="https://landing.streamily.com/affiliate"
              >
                <strong>Become an Affiliate</strong>
              </AnchorLink>

              <AnchorLink
                isHomePage={isHomePage}
                href="https://www.indeed.com/cmp/Smashnet/jobs"
              >
                We Are Hiring
              </AnchorLink>
            </Box>
          </Collapse>
        </Grid>

        <Hidden xsDown>
          <Grid item xs={12} sm={6}>
            <NewsletterEmailEntry isHomePage={isHomePage} />

            <Box
              mt={isMobile ? 2 : 4}
              mb={isMobile ? 2 : 0}
              display="flex"
              gridGap={16}
              justifyContent={isMobile ? "center" : "flex-end"}
            >
              {socialIcons}
            </Box>
          </Grid>
        </Hidden>
      </Grid>

      <Hidden xsDown>
        <Divider
          style={{
            margin: "1rem 0 1rem 0",
            background: isHomePage ? "#575D63" : "#dadada",
          }}
        />
      </Hidden>

      <FooterDiv>
        <Copyright className={className}>
          Copyright © {new Date().getFullYear()} SmashNet
        </Copyright>

        <StyledBox>
          <PolicyLink className={className} href="/terms-and-conditions">
            Terms and Conditions
          </PolicyLink>

          <PolicyLink className={className} href="/privacy-policy">
            Privacy Policy
          </PolicyLink>
        </StyledBox>
      </FooterDiv>

      <Hidden smUp>
        <Box mt={2} display="flex" gridGap={16} justifyContent="center">
          {socialIcons}
        </Box>
      </Hidden>
    </Container>
  );
};

Footer.propTypes = {
  isHomePage: PropTypes.bool,
};

export default Footer;

const StyledBox = muiStyled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  minWidth: 300,
  justifyContent: "flex-end",
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const StyledButton = muiStyled("button")(({ theme }) => ({
  background: "none",
  font: "inherit",
  border: "none",
  width: "100%",
  color: theme.palette.text.primary,
  padding: 0,
  margin: 0,
  cursor: "pointer",
  "&.dark": {
    color: theme.palette.common.white,
    "&:focus": {
      border: "none",
    },
  },
}));

function ToggleButton({ isOpen, onClick, isHomePage, label, ...props }) {
  return (
    <StyledButton
      {...props}
      onClick={onClick}
      className={clsx(isHomePage && "dark")}
    >
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        {label}

        {isOpen ? <RemoveIcon /> : <AddIcon />}
      </Box>
    </StyledButton>
  );
}

ToggleButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  isHomePage: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

// the display property of Container is altered by ../pages/Dashboard/Sales.jsx - be very careful if you want this to be anything but "flex"
const Container = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  font-size: 16px;
  margin-top: 10px;
  flex-shrink: 0;
  padding: 10px 32px 30px 32px;

  @media only screen and (min-width: 801px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 959px) {
    padding: 10px 16px 30px 16px;
  }

  ${(props) => (props.isHomePage ? "color: #ffffff" : "color: #000000")};
`;

const ColumnTitle = styled.h4`
  font-family: "Inter", Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #222;
  margin: 0;
  @media only screen and (max-width: 800px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 599px) {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  ${(props) => (props.isHomePage ? "color: #fff" : "color: #222")};
`;

const AnchorLink = styled.a`
  text-decoration: none;
  font-family: "Inter", Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2b2b2b;
  &:hover {
    text-decoration: underline;
  }

  ${(props) => (props.isHomePage ? "color: #fff" : "color: #2b2b2b")};
`;

// eslint-disable-next-line no-unused-vars
const ReactLink = styled(({ isHomePage, ...props }) => <Link {...props} />)`
  text-decoration: none;
  font-family: "Inter", Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #2b2b2b;
  &:hover {
    text-decoration: underline;
  }

  ${(props) => (props.isHomePage ? "color: #fff" : "color: #2b2b2b")};
`;

const FooterDiv = styled.div`
  text-align: center;
  display: flex;
  justify-content: left;
  @media only screen and (max-width: 599px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const PolicyLink = muiStyled("a")(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.primary,
  textDecoration: "none",
  "&.dark": {
    color: theme.palette.common.white,
  },
}));

const Copyright = muiStyled("span")(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.text.primary,
  display: "flex",
  justifyContent: "left",
  textAlign: "center",
  width: "100%",
  "&.dark": {
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down("xs")]: {
    justifyContent: "center",
  },
}));
