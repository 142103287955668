import React from "react";
import styled, { keyframes } from "styled-components";

import { useStateValue } from "../globalState";

const Alerts = () => {
  const [{ alerts }] = useStateValue();

  return (
    <AlertBoxContainer>
      {alerts.map((alert, index) => (
        <AlertBox key={`alert-${index}`} error={alert.type === "error"}>
          {alert.message}
        </AlertBox>
      ))}
    </AlertBoxContainer>
  );
};

export default Alerts;

const AlertBoxContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: none;
`;

const AlertBox = styled.div`
  margin: 10px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  background: rgb(80, 180, 150);
  color: white;
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  z-index: 20;
  opacity: 0;
  border: 1px solid rgb(80, 180, 150);
  ${(props) =>
    props.error &&
    `
        background: rgb(230,100,100);
        border: 1px solid rgb(230,100,100);
    `}
  animation: ${keyframes`
        0%, 90% { 
            opacity: 1;
        }
    `} 3s linear,
    ${keyframes`
    0% { 
        background: white;
        color: rgb(80,180,150);
    }
    `} 0.5s linear;
`;
