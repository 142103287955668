import * as React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StreamilyListLink = React.forwardRef(function StreamilyListLink(
  { to, onClick, exact, startIcon, label, endIcon, className, href },
  ref,
) {
  return (
    <StyledListItem
      href={href}
      className={className}
      component={to ? StyledNavLink : href ? StyledLink : null}
      onClick={onClick}
      exact={exact}
      to={to ? to : null}
      ref={ref}
    >
      {startIcon && <StyledListIcon>{startIcon}</StyledListIcon>}
      <StyledListItemText>{label}</StyledListItemText>
      {endIcon && <Box ml={1}>{endIcon}</Box>}
    </StyledListItem>
  );
});

StreamilyListLink.propTypes = {
  to: PropTypes.string,
  exact: PropTypes.bool,
  onClick: PropTypes.func,
  startIcon: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endIcon: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
};

const StyledListItem = styled(ListItem)(({ theme }) => ({
  background: "inherit",
  border: "none",
  boxSizing: "border-box",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  textAlign: "inherit",
  padding: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  "&:hover": {
    background: theme.palette.grey[200],
    cursor: "pointer",
    "&.active": {
      background: theme.palette.grey[200],
    },
  },
  "&.active": {
    "& .MuiTypography-root": {
      fontWeight: 600,
    },
    background: theme.palette.grey[100],
  },
  [theme.breakpoints.down("sm")]: {
    flexBasis: "fit-content",
  },
}));

const StyledListIcon = styled(ListItemIcon)(({ theme }) => ({
  color: "inherit",
  padding: 0,
  minWidth: theme.spacing(4),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  "& .MuiTypography-root": {
    fontSize: 13,
    fontFamily: theme.typography.interFontStack,
    lineHeight: "16px",
  },
}));

const StyledNavLink = styled(NavLink)(() => ({
  textDecoration: "none",
  color: "inherit",
  fontSize: 13,
}));

const StyledLink = styled(Link)(() => ({
  textDecoration: "none",
  color: "inherit",
  fontSize: 13,
  "&:hover": {
    textDecoration: "none",
  },
}));

export default StreamilyListLink;
