import * as React from "react";

export default function useMediaQuery(queryString) {
  const matchMedia = React.useMemo(
    () => window.matchMedia(queryString),
    [queryString],
  );

  const [matches, setMatches] = React.useState(() => matchMedia.matches);

  React.useEffect(() => {
    setMatches(matchMedia.matches);
    const listener = (evt) => {
      setMatches(evt.matches);
    };

    if (matchMedia.addEventListener) {
      matchMedia.addEventListener("change", listener);
      return () => matchMedia.removeEventListener("change", listener);
    }

    matchMedia.addListener(listener);
    return () => matchMedia.removeListener(listener);
  }, [matchMedia]);

  return matches;
}
