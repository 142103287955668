import * as React from "react";
import PropTypes from "prop-types";
import { StyledSnackbar } from "../theme";
import { generateId } from "../util";

const notificationContext = React.createContext({});
const defaultDuration = 5000;

export function useNotification() {
  return React.useContext(notificationContext);
}

const NotificationProvider = React.memo(function NotificationProvider({
  children,
}) {
  const [notifications, setNotifications] = React.useState([]);

  const notify = React.useCallback(
    ({ message, duration = defaultDuration, action, ...props } = {}) => {
      setNotifications((notifications) => {
        const newId = generateId();

        const onClose = () => {
          setNotifications((notifications) =>
            notifications.filter(({ id }) => id !== newId),
          );
        };

        return notifications.concat({
          id: newId,
          component: (
            <StyledSnackbar
              key={newId}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={true}
              autoHideDuration={duration}
              onClose={onClose}
              message={message}
              action={action}
              {...props}
            />
          ),
        });
      });
    },
    [],
  );

  return (
    <notificationContext.Provider value={notify}>
      {notifications.map(({ component }) => component)}

      {children}
    </notificationContext.Provider>
  );
});

NotificationProvider.propTypes = {
  children: PropTypes.node,
};

export default NotificationProvider;
