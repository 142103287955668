import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import explodeEmoji from "../../../../assets/explode-emoji.png";
import loveEmoji from "../../../../assets/love-emoji.png";
import starEyesEmoji from "../../../../assets/stareyes-emoji.png";

const fanReviews = [
  {
    emoji: starEyesEmoji,
    text: "“My print from Maggie just arrived today, thank you so much because you make my Friday much better”",
  },
  {
    emoji: explodeEmoji,
    text: "“I wish I knew about your community sooner. This is so fantastic. I love that this exists even for charity. Best of all worlds.”",
  },
  {
    emoji: loveEmoji,
    text: "“My granddaughter received her beautiful picture she was waiting for she was very happy”",
  },
];

export default function FanReviews({ isCurrentTab }) {
  return (
    <Slide
      timeout={{ enter: 500, exit: 250 }}
      in={isCurrentTab}
      direction="right"
      unmountOnExit
    >
      <div className="home-page__sections__promo-tabs__cards">
        {fanReviews.map(({ emoji, text }) => (
          <div className="home-page__sections__promo-tabs__card" key={emoji}>
            <img height={44} width={44} alt="" src={emoji} />
            <p className="home-page__sections__promo-tabs_-text">{text}</p>
          </div>
        ))}
      </div>
    </Slide>
  );
}

FanReviews.propTypes = {
  isCurrentTab: PropTypes.bool,
  isMobile: PropTypes.bool,
};
