import * as React from "react";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import UpcomingSignings from "../../UpcomingSignings";
import SocialHandles from "../../SocialHandles";
import { ErrorUI } from "../../../theme";
import {
  useInfluencerRoute,
  useMatchesMdDown,
  useMatchesXsDown,
} from "../../../hooks";

const loadingUI = (
  <Grid container>
    <Grid item>
      <Skeleton variant="rect" width={300} height={64} />
    </Grid>
  </Grid>
);

const ShopSideDetails = React.memo(function ShopSideDetails() {
  const { influencer, isLoading, isError } = useInfluencerRoute();
  const matchesMd = useMatchesMdDown();
  const matchesXs = useMatchesXsDown();

  if (isLoading) {
    return loadingUI;
  }

  if (isError) {
    return <ErrorUI message="Unable to load shop data" />;
  }
  const { influencerId, handles } = influencer;
  const boxPadding = matchesMd ? 0 : 5;

  return (
    <Box pt={boxPadding} pb={matchesXs ? 0 : 1}>
      <Grid
        container
        spacing={2}
        direction={matchesXs ? "column-reverse" : "column"}
      >
        {influencer.showEvents && (
          <Grid item xs={12}>
            <UpcomingSignings
              influencerId={influencerId}
              isGroupShop={influencer.isGroupShop}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <SocialHandles handles={handles?.list ?? []} />
        </Grid>
      </Grid>
    </Box>
  );
});

export default ShopSideDetails;
