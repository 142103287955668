import { Avatar, Box, Typography } from "@material-ui/core";
import { G, D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { useDebounce, useShopsSearchDashboard } from "../../../hooks";
import { Form } from "../../../theme";

function createSigner({ avatar_url, created, influencer_id, name, route }) {
  return {
    active: true,
    avatarUrl: avatar_url,
    influencerCreated: created,
    influencerId: influencer_id,
    name,
    noFulfillment: false,
    route,
    splitAmount: 0,
  };
}

const renderOption = (shop) => (
  <Box display="flex" alignItems="center" gridGap="8px">
    <Avatar
      style={{ height: 24, width: 24 }}
      src={shop.avatar_url}
      alt={shop.name}
    />
    <Typography variant="body2">{shop.name}</Typography>
  </Box>
);

export default function SignerSearch({ onAddSigner, influencerIds }) {
  const [query, setQuery] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const inputRef = React.useRef(null);

  const { shops } = useShopsSearchDashboard(query, {
    excludeCollections: true,
    excludedIds: influencerIds,
  });

  useDebounce(
    () => {
      setQuery(searchValue.trim());
    },
    250,
    [searchValue],
  );

  const onChange = (_, newValue) => {
    if (G.isObject(newValue) && D.isNotEmpty(newValue)) {
      onAddSigner(createSigner(newValue));
      window.requestAnimationFrame(() => {
        inputRef.current?.blur?.();
      });
    }
  };

  const renderInput = React.useCallback(
    (params) => (
      <Form.Input
        {...params}
        inputRef={inputRef}
        fullWidth
        label="Add a new Signer"
        size="small"
        onChange={(evt) => {
          setSearchValue(evt.target.value);
        }}
      />
    ),
    [],
  );

  return (
    <Box mt={2} maxWidth={300}>
      <Form.Autocomplete
        autoComplete
        clearOnBlur
        handleHomeEndKeys
        includeInputInList
        options={shops}
        value={null} // This is required to clear the input
        selectOnFocus
        size="small"
        onChange={onChange}
        getOptionLabel={D.getUnsafe("name")}
        renderInput={renderInput}
        renderOption={renderOption}
      />
    </Box>
  );
}

SignerSearch.propTypes = {
  onAddSigner: PropTypes.func.isRequired,
  influencerIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};
