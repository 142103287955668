import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const BlueGradientText = styled(Typography)(({ theme }) => ({
  background: theme.gradient.blue,
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textFillColor: "transparent",
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(13),
  fontWeight: theme.typography.semiBold,
}));

export default BlueGradientText;
