import * as React from "react";
import PropTypes from "prop-types";
import { MessageDialog } from "../../theme";

export default function InternationalWarning({ onClose, onCancel, onConfirm }) {
  return (
    <MessageDialog
      title="Oops!"
      message="It looks like you're located outside of the U.S. Would you like to switch this product to a standard A4 print?"
      onClose={onClose}
      buttonList={[
        {
          label: "No",
          onClick: onCancel,
        },
        {
          label: "Yes",
          default: true,
          onClick: onConfirm,
        },
      ]}
    />
  );
}

InternationalWarning.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
