import * as React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";
import { Product } from "../domain";

const baseEndpoint = "/api/product/presignedApproval";

export default function usePresignedProducts() {
  const { data, error, mutate } = useSWR(baseEndpoint, fetcher);

  const products = React.useMemo(() => {
    if (!data) {
      return [];
    }

    const { products } = data;

    if (!Array.isArray(products)) {
      return [];
    }

    return products.map(Product.from);
  }, [data]);

  return {
    products,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
