import { A, pipe } from "@mobily/ts-belt";

/*
 * signerTextFor returns a string of the product signers
 *
 * @param {object} product
 * @returns {string} signer string
 */
export function signerTextFor({ fulfillers, influencerName } = {}) {
  if (!fulfillers.length) {
    return `Signed by: ${influencerName}`;
  }

  return `Signed by: ${pipe(
    fulfillers,
    A.filter(
      (fulfiller) => !fulfiller.hasNoFulfillment && !fulfiller.no_fulfillment,
    ),
    A.map(({ name }) => name),
    A.join(", "),
  )}`;
}

export function signerLinksFor({
  fulfillers,
  influencerName,
  influencerRoute,
} = {}) {
  if (!fulfillers.length) {
    return [
      {
        name: influencerName,
        href: `${window.location.origin}/${influencerRoute}`,
      },
    ];
  }

  return pipe(
    fulfillers,
    A.filter(
      (fulfiller) => !fulfiller.hasNoFulfillment && !fulfiller.no_fulfillment,
    ),
    A.map((fulfiller) => ({
      name: fulfiller.name,
      href: `${window.location.origin}/${fulfiller.route}`,
    })),
  );
}
