import * as React from "react";
import useSWR from "swr";
import { Product } from "../domain";
import fetcher from "./fetcher";

const baseEndpoint = "/api/product";

export default function useProduct(productId) {
  const { data, error, mutate } = useSWR(
    productId === null ? null : `${baseEndpoint}/${productId}`,
    fetcher,
  );

  const product = React.useMemo(() => {
    if (!data?.product) {
      return null;
    }

    return Product.from(data.product);
  }, [data]);

  return {
    product,
    isLoading: !product && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useProductForShopBuilder(productId) {
  const { data, error, mutate } = useSWR(
    productId === null ? null : `${baseEndpoint}/shopBuilder/${productId}`,
    fetcher,
  );

  return {
    product: data?.product ?? null,
    isLoading: !data?.product && !error && productId !== null,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
