import * as React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";
import axios from "axios";

const baseEndpoint = "/api/productTag";

export function useProductTags(productId) {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    if (!productId) return { editProductTags: [] };
    axios
      .get(`${baseEndpoint}/${productId}`)
      .then((res) => {
        setData(res.data?.productTags);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [productId]);

  return { editProductTags: data };
}

export function useProductTagsSearch(
  query,
  opts = { isCharacter: false, isFranchise: false },
) {
  let endpoint = null;

  if (query) {
    const params = new URLSearchParams({
      q: query,
      is_character: Boolean(opts?.isCharacter),
      is_franchise: Boolean(opts?.isFranchise),
    });

    endpoint = `${baseEndpoint}?${params}`;
  }

  const { data, error } = useSWR(endpoint, fetcher, { keepPreviousData: true });

  return {
    tags: data?.tags ?? [],
    error,
    isLoading: !error && !data && !!query,
    isError: Boolean(error),
  };
}

export function useProductTagTypes(productId) {
  const { data, error, isLoading } = useSWR(
    productId ? `${baseEndpoint}/types/${productId}` : null,
    fetcher,
    {},
  );

  // if no tags assigned to product then {} returned
  const editProductTagTypes =
    !productId ||
    !data ||
    (!data.character_tag_type && !data.franchise_tag_type)
      ? {
          character_tag_type: "",
          franchise_tag_type: "",
        }
      : data;

  return {
    editProductTagTypes,
    isLoading,
    isError: Boolean(error),
    error,
  };
}

export function useProductTag() {
  const { data, error, mutate } = useSWR(baseEndpoint, fetcher);

  const productTags = React.useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data;
  }, [data]);

  return {
    allProductTags: productTags,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
