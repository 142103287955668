import { Box, Button, Typography } from "@material-ui/core";
import { A, D, G, pipe } from "@mobily/ts-belt";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useChanges } from "./service";
import { StyledDialog, StyledSmallTitle } from "./styles";

const formatDate = (str) => {
  try {
    return format(new Date(str), "MM/dd/yyyy");
  } catch {
    return "N/A";
  }
};

const parseDiff = (diff) => {
  if (!G.isObject(diff) || D.isEmpty(diff)) return "";

  return pipe(
    diff,
    D.selectKeys(["code", "percentage"]),
    D.toPairs,
    A.map(([key, data]) => {
      if (data.new && data.old) {
        return `${key} to ${data.new} from ${data.old}`;
      }

      return null;
    }),
    A.filter(Boolean),
    A.join(", "),
  );
};

export default function AffiliateLinksChangeLog({ influencerId }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Change Log</Button>

      <Modal
        influencerId={influencerId}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}

AffiliateLinksChangeLog.propTypes = { influencerId: PropTypes.number };

function Modal({ influencerId, open, onClose }) {
  const { changes } = useChanges(influencerId);

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledSmallTitle>Change Log</StyledSmallTitle>

      <Box
        display="flex"
        flexDirection="column"
        maxHeight={400}
        style={{ overflowY: "auto" }}
      >
        {changes.map((change) => (
          <Box
            key={change.changeId}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gridGap="16px"
            minWidth={380}
          >
            <Typography variant="body2">
              {`User: ${change.userId ?? "N/A"} `}
            </Typography>

            <Typography variant="body1">
              {change.description} {parseDiff(change.diff)}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {formatDate(change.created)}
            </Typography>
          </Box>
        ))}
      </Box>
    </StyledDialog>
  );
}

Modal.propTypes = {
  influencerId: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
