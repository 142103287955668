import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import MuteIcon from "@material-ui/icons/VolumeOff";

const Video = () => {
  const VideoRef = useRef(null);

  const [videoUrl, setVideoUrl] = useState("");
  const [hideUnmuteButton, setHideUnmuteButton] = useState(false);

  useEffect(() => {
    const getParameterByName = (name) => {
      const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };
    const paramVideoUrl = getParameterByName("vu");
    if (paramVideoUrl) setVideoUrl(paramVideoUrl);
  }, []);

  const UnmuteButtonOnClick = () => {
    if (VideoRef.current) VideoRef.current.muted = false;
    setHideUnmuteButton(true);
  };

  useEffect(() => {
    const onVolumeChange = (e) => {
      if (!e.target.muted) setHideUnmuteButton(true);
    };
    requestAnimationFrame(() => {
      if (VideoRef.current) {
        VideoRef.current.addEventListener("volumechange", onVolumeChange);
      }
    });
    return () => {
      if (VideoRef.current) {
        VideoRef.current.removeEventListener("volumechange", onVolumeChange);
      }
    };
  }, []);

  return (
    <Container>
      <Title>Your Streamily Video</Title>
      {videoUrl ? (
        <VideoContainer>
          <VideoElem
            ref={VideoRef}
            src={videoUrl}
            controls
            autoPlay
            muted
            playsInline
          ></VideoElem>
          {!hideUnmuteButton && (
            <UnmuteButton onClick={UnmuteButtonOnClick}>
              <MuteIcon />
            </UnmuteButton>
          )}
        </VideoContainer>
      ) : (
        <NoVideoText>No Video To Display</NoVideoText>
      )}
    </Container>
  );
};

export default Video;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 80vh;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin: 10px;
  text-align: center;
  color: rgb(60, 60, 60);
  margin-top: 20px;
`;

const VideoContainer = styled.div`
  position: relative;
`;

const VideoElem = styled.video`
  width: 90vw;
  height: auto;
  max-width: 600px;
  max-height: 70vh;
  margin: 5px;
`;

const UnmuteButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  background: rgba(0, 0, 0, 0.4);
  color: rgb(255, 255, 255);
  border-radius: 50%;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.6);
  }
  &:active {
    background: rgba(0, 0, 0, 0.8);
  }
`;

const NoVideoText = styled.div`
  font-size: 20px;
  color: rgb(100, 100, 100);
  font-weight: bold;
  text-align: center;
`;
