import { Typography } from "@material-ui/core";
import { G } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { useDebounce, useUsersByEmail } from "../hooks";
import { Form } from "../theme";

export default function UserSearch({
  disabled,
  error,
  helperText,
  label,
  onAddUser,
  onClick,
  onRemoveUser,
  onSearchChange,
  user = null,
}) {
  const inputRef = React.useRef(null);
  const [query, setQuery] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const { users } = useUsersByEmail(query);

  useDebounce(
    () => {
      setQuery(searchValue.trim());
    },
    250,
    [searchValue],
  );

  const onChange = (_, newValue) => {
    if (newValue) {
      onAddUser(newValue);
    } else {
      onRemoveUser();
    }

    window.requestAnimationFrame(() => {
      inputRef.current?.blur?.();
    });
  };

  const renderInput = (params) => (
    <Form.Input
      {...params}
      inputRef={inputRef}
      inputProps={{ maxLength: 45, ...params.inputProps }}
      fullWidth
      label={label ?? "Search for a user"}
      size="small"
      onChange={(evt) => {
        setSearchValue(evt.target.value);
      }}
      disabled={disabled}
      helperText={helperText}
      error={error}
    />
  );

  React.useEffect(() => {
    onSearchChange?.(searchValue);
  }, [onSearchChange, searchValue]);

  return (
    <Form.Autocomplete
      autoComplete
      clearOnBlur={!user?.email}
      freeSolo
      handleHomeEndKeys
      includeInputInList
      options={users}
      value={user?.email ?? ""} // This is required to clear the input
      selectOnFocus
      size="small"
      onChange={onChange}
      getOptionLabel={(user) => {
        if (G.isString(user)) {
          return user;
        }
        return user.email;
      }}
      renderInput={renderInput}
      renderOption={(user) => (
        <Typography variant="body2">{user.email}</Typography>
      )}
      disabled={disabled}
      onClick={onClick}
    />
  );
}

UserSearch.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onAddUser: PropTypes.func,
  onClick: PropTypes.func,
  onRemoveUser: PropTypes.func,
  onSearchChange: PropTypes.func,
  user: PropTypes.shape({ email: PropTypes.string.isRequired }),
};
