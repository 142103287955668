import * as React from "react";
import PropTypes from "prop-types";
import Section from "./Section";
import * as constants from "../constants";

function SectionWrapper({ isMobile, section, updateFavouriteStatus }) {
  return (
    <Section
      isMobile={isMobile}
      section={section}
      updateFavouriteStatus={
        section.updateFavouriteStatus ? updateFavouriteStatus : null
      }
    />
  );
}

SectionWrapper.propTypes = {
  influencers: PropTypes.object,
  section: PropTypes.shape({
    type: PropTypes.oneOf([constants.PRODUCT_TYPE, constants.INFLUENCER_TYPE]),
    look: PropTypes.oneOf([
      constants.LOOK_TALL,
      constants.LOOK_WIDE,
      constants.LOOK_PRODUCT,
    ]),
    title: PropTypes.string,
    updateFavouriteStatus: PropTypes.bool,
    name: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  updateFavouriteStatus: PropTypes.func,
};

export default React.memo(SectionWrapper);
