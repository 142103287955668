import PropTypes from "prop-types";

export const affiliateLink = PropTypes.shape({
  affiliateLinkId: PropTypes.number.isRequired,
  influencerId: PropTypes.number.isRequired,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  code: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
});
