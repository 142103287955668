const UPDATE_NOTES = "update_notes";
const UPDATE_PERSONALIZATION_TO = "update_personalization_to";

export function createInitialState() {
  return {
    notes: "",
    personalizationTo: "",
  };
}

export function productModalReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_NOTES:
      return { ...state, notes: payload };
    case UPDATE_PERSONALIZATION_TO:
      return { ...state, personalizationTo: payload };

    default:
      return state;
  }
}

export function updateNotes(payload) {
  return { type: UPDATE_NOTES, payload };
}

export function updatePersonalizationTo(payload) {
  return { type: UPDATE_PERSONALIZATION_TO, payload };
}
