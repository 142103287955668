import {
  Avatar,
  Box,
  Collapse,
  Divider,
  Grid,
  List,
  Typography,
} from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import { DashboardRounded } from "@material-ui/icons";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PublishIcon from "@material-ui/icons/Publish";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import FormatListNumberedRtlIcon from "@material-ui/icons/FormatListNumberedRtl";
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import MoreOutlinedIcon from "@material-ui/icons/MoreOutlined";
import ReceiptIcon from "@material-ui/icons/ReceiptRounded";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import ShopOutlinedIcon from "@material-ui/icons/ShopOutlined";
import StyleIcon from "@material-ui/icons/Style";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";
import ViewCompactOutlinedIcon from "@material-ui/icons/ViewCompactOutlined";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import * as React from "react";
import styledComponent from "styled-components";
import CheckmarkIcon from "../../assets/checkmark-icon.png";
import shareYourShopIcon from "../../assets/share-your-shop-icon.svg";
import { Influencer } from "../../domain";
import { useUser } from "../../globalState";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Icons, RedNumberBadge } from "../../theme";
import SideBarItem from "../../theme/StreamilyListLink";
import { featureFlags } from "../../util";

const avatarStyles = { height: 36, width: 36 };
const avatarName = { fontWeight: 500 };

const StyledBox = styled(Box)(() => ({
  display: "flex",
  flexShrink: 0,
  flexDirection: "column",
  height: "100%",
  minWidth: "15rem",
  margin: 0,
  padding: 0,
}));

const CopiedIndicator = styledComponent.div`
  opacity: 0;
  top: 25%;
  left: 35%;
  background: #fff;
  border: 1px outset silver;
  border-radius: 5px;
  box-shadow: 2px 2px 5px silver;
  color: rgb(71 145 34);
  padding: 0 10px;
  pointer-events: none;
  position: absolute;
  transition: opacity 0.5s;
  &.show {
    opacity: 1;
  }
`;

const useStyles = makeStyles(() => ({
  sideBarItem: {
    "& .MuiTypography-root": {
      fontSize: "0.95rem",
      lineHeight: "1.8rem",
    },
    "& .MuiListItemIcon-root": {
      width: "34px",
    },
  },
  sideBarItemBold: {
    "& .MuiTypography-root": {
      fontSize: "0.95rem",
      fontWeight: 600,
      lineHeight: "1.7rem",
    },
    "& .MuiListItemIcon-root": {
      width: "34px",
    },
  },
  sideBarTopPadding: {
    "& .MuiTypography-root": {
      fontSize: "0.95rem",
      lineHeight: "1.8rem",
      paddingTop: "16px",
    },
    "& .MuiListItemIcon-root": {
      paddingTop: "16px",
      width: "34px",
    },
  },
}));

// eslint-disable-next-line complexity
export default function SideBarNav({
  activeInfluencer,
  UnsignedCountRef,
  unsignedCount,
  linkClick,
}) {
  const classes = useStyles();

  const user = useUser();
  const [adminOpen, setAdminOpen] = useLocalStorage(
    "ACCOUNT_ADMIN_OPEN",
    false,
  );
  const adminClick = React.useCallback(() => {
    setAdminOpen(!adminOpen);
  }, [adminOpen, setAdminOpen]);

  const [shopLinkCopiedCount, setShopLinkCopiedCount] = React.useState(0);
  const CopiedIndicatorRef = React.useRef(null);
  const copiedIndicatorTimeout = React.useRef(null);

  const CopyToClipboard = React.useCallback(() => {
    if (!activeInfluencer) return;

    navigator.clipboard
      .writeText(`https://streamily.com/${activeInfluencer.route}`)
      .then(
        function () {
          setShopLinkCopiedCount((prevState) => prevState + 1);
        },
        function (err) {
          console.error("Clipboard error:", err);
        },
      );
  }, [activeInfluencer]);

  React.useEffect(() => {
    if (shopLinkCopiedCount > 0) {
      CopiedIndicatorRef.current.classList.add("show");
      clearInterval(copiedIndicatorTimeout.current);
      copiedIndicatorTimeout.current = setTimeout(() => {
        CopiedIndicatorRef.current.classList.remove("show");
      }, 1000);
    }
  }, [shopLinkCopiedCount]);

  const itemClick = React.useCallback(() => {
    if (linkClick) linkClick();
  }, [linkClick]);

  return (
    <StyledBox py={4} px={2}>
      {/* User avatar and email */}
      <Box component="header" pt={2} pb={1}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Avatar style={avatarStyles} src={activeInfluencer?.avatarUrl} />
          </Grid>
          <Grid item>
            {activeInfluencer && (
              <Typography style={avatarName} variant="body2">
                {activeInfluencer.name}
              </Typography>
            )}

            {Boolean(activeInfluencer?.ownerEmail) && (
              <Typography component="h2" variant="caption">
                {activeInfluencer.ownerEmail}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      <Divider />

      <Box py={1}></Box>

      <List>
        {((activeInfluencer &&
          !activeInfluencer.hasNoShopEditor &&
          !activeInfluencer.isTcShop) ||
          ((user.isAdmin || user.isSuperadmin) &&
            !activeInfluencer.isTcShop)) && (
          <SideBarItem
            to={
              activeInfluencer
                ? `/Dashboard/MyDashboard/${activeInfluencer.route}`
                : `/Dashboard/`
            }
            startIcon={<Icons.MyDashboardIcon />}
            label="My Dashboard"
            className={classes.sideBarItem}
            onClick={itemClick}
          />
        )}
        {((activeInfluencer &&
          !activeInfluencer.hasNoShopEditor &&
          !activeInfluencer.isAgentShop &&
          !activeInfluencer.isTcShop) ||
          (user.isAdmin &&
            !activeInfluencer.isAgentShop &&
            !activeInfluencer.isTcShop) ||
          (user.isSuperadmin &&
            !activeInfluencer.isAgentShop &&
            !activeInfluencer.isTcShop)) && (
          <SideBarItem
            to={
              activeInfluencer
                ? `/Dashboard/s/${activeInfluencer.route}`
                : `/Dashboard/`
            }
            startIcon={<Icons.ShopBuilderIcon />}
            label="Shop Builder"
            className={classes.sideBarItem}
            onClick={itemClick}
          />
        )}
        {activeInfluencer && activeInfluencer.isTcShop && (
          <>
            <SideBarItem
              to={`/Dashboard/TalentCoordinatorPortal`}
              startIcon={<ViewCompactOutlinedIcon />}
              label="TC Portal"
              className={classes.sideBarItem}
              onClick={itemClick}
            />
            {featureFlags.AGENT_REPORTING_ENABLED && (
              <SideBarItem
                to={`/Dashboard/TalentCoordinatorReporting`}
                startIcon={<Icons.ReportingIcon />}
                label="Reporting"
                className={classes.sideBarItem}
                onClick={itemClick}
              />
            )}
          </>
        )}
        {activeInfluencer && activeInfluencer.isAgentShop && (
          <>
            <SideBarItem
              to={`/Dashboard/AgentOverview`}
              startIcon={<ViewCompactOutlinedIcon />}
              label="Agent Overview"
              className={classes.sideBarItem}
              onClick={itemClick}
            />
            {featureFlags.AGENT_REPORTING_ENABLED && (
              <SideBarItem
                to={`/Dashboard/AgentReporting`}
                startIcon={<Icons.ReportingIcon />}
                label="Reporting"
                className={classes.sideBarItem}
                onClick={itemClick}
              />
            )}
          </>
        )}
        {activeInfluencer &&
          !activeInfluencer.isGroupShop &&
          !activeInfluencer.isAgentShop &&
          !activeInfluencer.isTcShop && (
            <SideBarItem
              to={`/Dashboard/SalesOverview/${activeInfluencer.route}`}
              startIcon={<Icons.SalesIcon />}
              label="Sales Overview"
              className={classes.sideBarItem}
              onClick={itemClick}
              endIcon={
                unsignedCount > 0 ? (
                  <RedNumberBadge
                    ref={UnsignedCountRef}
                    value={unsignedCount}
                  />
                ) : undefined
              }
            />
          )}
        {activeInfluencer &&
          !activeInfluencer.isAgentShop &&
          !activeInfluencer.isTcShop && (
            <SideBarItem
              to={`/Dashboard/Manage-Events`}
              startIcon={<Icons.ManageEventsIcon />}
              label="Manage Events"
              className={classes.sideBarItem}
              onClick={itemClick}
            />
          )}
        {activeInfluencer &&
          !activeInfluencer.isAgentShop &&
          !activeInfluencer.isTcShop && (
            <SideBarItem
              to={"/Dashboard/ReturnLabel"}
              startIcon={<Icons.ReturnKitsIcon />}
              label="Return Label"
              className={classes.sideBarItem}
              onClick={itemClick}
            />
          )}
        {activeInfluencer && !activeInfluencer.isTcShop && (
          <SideBarItem
            to={`/Dashboard/Payout`}
            startIcon={<Icons.GetPaidIcon />}
            label="Get Paid"
            className={classes.sideBarItem}
            onClick={itemClick}
          />
        )}
        {activeInfluencer &&
          !activeInfluencer.isAgentShop &&
          !activeInfluencer.isTcShop && (
            <SideBarItem
              to={`/Dashboard/Referral`}
              startIcon={<Icons.ReferralIcon />}
              size="small"
              label="Make More $"
              className={classes.sideBarItem}
              onClick={itemClick}
            />
          )}
        {activeInfluencer && !activeInfluencer.isTcShop && (
          <SideBarItem
            to={`/Dashboard/Account-Settings`}
            startIcon={<Icons.AccountSettingsIcon />}
            size="small"
            label="Account Settings"
            className={classes.sideBarItem}
            onClick={itemClick}
          />
        )}

        {activeInfluencer &&
          !activeInfluencer.isAgentShop &&
          !activeInfluencer.isTcShop && (
            <SideBarItem
              startIcon={
                <img src={shareYourShopIcon} alt="shareYourShopIcon" />
              }
              size="small"
              label={
                <div>
                  Share Your Shop Link
                  <div style={{ fontSize: "0.8em", marginTop: "-0.7em" }}>
                    https://streamily.com/{activeInfluencer.route}
                  </div>
                  <CopiedIndicator ref={CopiedIndicatorRef}>
                    Copied
                  </CopiedIndicator>
                </div>
              }
              className={classes.sideBarItem}
              onClick={CopyToClipboard}
            />
          )}

        {(user.isAdmin || user.isSuperadmin) && (
          <>
            <SideBarItem
              className={
                adminOpen ? classes.sideBarItemBold : classes.sideBarItem
              }
              label="Admin Tools"
              endIcon={
                adminOpen ? (
                  <ExpandLess fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )
              }
              onClick={adminClick}
            />
            <Collapse in={adminOpen} timeout="auto" unmountOnExit>
              <SideBarItem
                to="/account-dashboard/accessLinks"
                label="Access Links"
                startIcon={<LinkOutlinedIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              <SideBarItem
                to="/account-dashboard/accountApproval"
                label="Account Approval"
                startIcon={<AccountBoxIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              <SideBarItem
                to="/account-dashboard/admin-query"
                label="Admin Query"
                startIcon={<ViewComfyIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              {user.isSuperadmin && (
                <SideBarItem
                  to="/account-dashboard/autopay-list"
                  label="Autopay Shops"
                  startIcon={<ShopOutlinedIcon fontSize="small" />}
                  className={classes.sideBarItem}
                  onClick={itemClick}
                />
              )}

              <SideBarItem
                to={`/account-dashboard/event-calendar/month/${moment(
                  new Date(),
                )
                  .tz("UTC")
                  .format("YYYY-MM-DD")}
                `}
                label="Event Calendar"
                startIcon={<CalendarIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              {user.isSuperadmin && (
                <SideBarItem
                  to="/account-dashboard/payouts"
                  label="Payouts"
                  startIcon={
                    <AccountBalanceWalletOutlinedIcon fontSize="small" />
                  }
                  className={classes.sideBarItem}
                  onClick={itemClick}
                />
              )}

              <SideBarItem
                to="/account-dashboard/print-boxes"
                label="Print Boxes"
                startIcon={<LocalShippingIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              <SideBarItem
                to="/account-dashboard/productApproval"
                label="Product Approval"
                startIcon={<AssignmentTurnedInOutlinedIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              <SideBarItem
                to="/account-dashboard/products"
                label="Product Search"
                startIcon={<FindInPageOutlinedIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              <SideBarItem
                to="/account-dashboard/productTypes"
                label="Product Type Assignment"
                startIcon={<AssignmentTurnedInOutlinedIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              <SideBarItem
                to="/account-dashboard/promoCodeManager"
                label="Promo Code Manager"
                startIcon={<ReceiptIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              {featureFlags.REPORTS_ENABLED && (
                <SideBarItem
                  to="/account-dashboard/reports"
                  label="Reports"
                  startIcon={<ListOutlinedIcon fontSize="small" />}
                  className={classes.sideBarItem}
                  onClick={itemClick}
                />
              )}

              <SideBarItem
                to="/account-dashboard/admin"
                label="Search Orders"
                startIcon={<SearchOutlinedIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              <SideBarItem
                to="/account-dashboard/shippingAssistant"
                label="Shipping Assistant"
                startIcon={<CustomIcon src={CheckmarkIcon} />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              <SideBarItem
                to="/account-dashboard/tag-approval"
                label="Tag Approval"
                startIcon={<MoreOutlinedIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              {user.isSuperadmin && (
                <SideBarItem
                  to="/account-dashboard/tag-manager"
                  label="Tag Manager"
                  startIcon={<MoreOutlinedIcon fontSize="small" />}
                  className={classes.sideBarItem}
                  onClick={itemClick}
                />
              )}

              <SideBarItem
                to="/account-dashboard/talent-coordinators"
                label="Talent Coordinators"
                startIcon={<AssignmentIndOutlinedIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              <SideBarItem
                to="/account-dashboard/userActions"
                label="User Actions"
                startIcon={<FormatListNumberedRtlIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              <SideBarItem
                to="/account-dashboard/users"
                label="Users Search"
                startIcon={<EmojiPeopleIcon fontSize="small" />}
                className={classes.sideBarItem}
                onClick={itemClick}
              />

              {user.isSuperadmin && (
                <SideBarItem
                  to="/account-dashboard/verticals"
                  label="Verticals"
                  startIcon={<StyleIcon fontSize="small" />}
                  className={classes.sideBarItem}
                  onClick={itemClick}
                />
              )}

              {user.isSuperadmin && (
                <SideBarItem
                  to="/account-dashboard/upload-orders"
                  label="Upload Orders"
                  startIcon={<PublishIcon fontSize="small" />}
                  className={classes.sideBarItem}
                  onClick={itemClick}
                />
              )}
            </Collapse>
          </>
        )}

        <Divider />
        {user.isAffiliate && (
          <SideBarItem
            to={`/account-dashboard/affiliate`}
            startIcon={<DashboardRounded />}
            size="small"
            label="Affiliate Portal"
            className={classes.sideBarItem}
            onClick={itemClick}
          />
        )}

        {user?.roles?.isCustomer && (
          <SideBarItem
            to={`/account-dashboard/orders`}
            startIcon={<ListOutlinedIcon />}
            size="small"
            label="My Orders"
            className={classes.sideBarItem}
            onClick={itemClick}
          />
        )}
        <SideBarItem
          to={`/account-dashboard/my-favorites`}
          startIcon={<FavoriteBorderIcon />}
          size="small"
          label="My Favorites"
          className={classes.sideBarItem}
          onClick={itemClick}
        />
        <SideBarItem
          to={`/account-dashboard/change-password`}
          startIcon={<LockOpenOutlinedIcon />}
          size="small"
          label="Change password"
          className={classes.sideBarItem}
          onClick={itemClick}
        />
        <SideBarItem
          to={`/logout`}
          startIcon={<ExitToAppOutlinedIcon />}
          size="small"
          label="Logout"
          className={classes.sideBarItem}
          onClick={itemClick}
        />
      </List>
    </StyledBox>
  );
}

SideBarNav.propTypes = {
  activeInfluencer: PropTypes.instanceOf(Influencer),
  UnsignedCountRef: PropTypes.any,
  unsignedCount: PropTypes.number,
  linkClick: PropTypes.func,
};

const CustomIcon = styled("img")(() => ({
  width: 20,
  height: 20,
}));
