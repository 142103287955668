import { Box, Button, Container, Typography } from "@material-ui/core";
import { B, D } from "@mobily/ts-belt";
import { atom } from "jotai";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as components from "../components/BrowseProducts";
import { defaultFilter } from "../components/BrowseProducts/utils";
import { SignType } from "../domain";
import { useMatchesSmDown } from "../hooks";

const styleForBox = (isSmDown) => {
  if (isSmDown) {
    return { display: "flex", flexDirection: "column", gridGap: "16px" };
  }

  return { display: "grid", gridTemplateColumns: "16rem 1fr", gridGap: "48px" };
};

export default function BrowseProducts() {
  const matchesSmDown = useMatchesSmDown();
  const history = useHistory();
  const padding = matchesSmDown ? 2 : 4;
  const [showFilters, setShowFilters] = React.useState(!matchesSmDown);

  const filterAtom = React.useMemo(() => {
    const searchParams = new URLSearchParams(history.location.search);

    if (!searchParams.has("types")) {
      return atom(defaultFilter);
    }

    const param = searchParams.get("types");
    const types = param
      .split(",")
      .filter((type) => SignType.list().includes(type))
      .reduce((acc, type) => {
        acc[type] = true;
        return acc;
      }, {});

    history.replace({ search: "" });

    return atom(D.merge(defaultFilter, { signedType: types }));
  }, [history]);

  React.useEffect(() => {
    setShowFilters(!matchesSmDown);
  }, [matchesSmDown]);

  return (
    <Container maxWidth="lg">
      <Box p={padding}>
        <Typography variant="h4">Browse Products</Typography>
      </Box>

      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection={matchesSmDown ? "column" : "row"}
        gridGap={matchesSmDown ? "8px" : "48px"}
        justifyContent="space-between"
        px={padding}
      >
        <components.Search
          filterAtom={filterAtom}
          matchesSmDown={matchesSmDown}
        />

        <Box
          display="flex"
          flex="1"
          flexWrap="wrap"
          gridGap="4px"
          justifyContent="flex-start"
        >
          <components.SignedTypes filterAtom={filterAtom} />
        </Box>

        <Box
          display="flex"
          gridGap="8px"
          alignItems="center"
          justifyContent="space-between"
          minWidth="12rem"
        >
          <components.Order
            filterAtom={filterAtom}
            matchesSmDown={matchesSmDown}
          />

          {matchesSmDown && (
            <Box minWidth="8rem">
              <Button
                fullWidth
                onClick={() => {
                  setShowFilters(B.not);
                }}
              >
                {showFilters ? "Hide Filters" : "Show Filters"}
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Box p={padding} {...styleForBox(matchesSmDown)}>
        <components.Filters
          filterAtom={filterAtom}
          matchesSmDown={matchesSmDown}
          showFilters={showFilters}
        />

        <components.Products
          filterAtom={filterAtom}
          matchesSmDown={matchesSmDown}
        />
      </Box>
    </Container>
  );
}
