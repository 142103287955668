import * as React from "react";

export default function SpeedyBoxIcon({ width = 50, height = 50 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.1311 9.75886L44.1101 14.3494C44.1101 14.6428 43.8795 14.8734 43.5861 14.8734C43.2926 14.8734 43.062 14.6428 43.062 14.3494L43.083 10.2829L38.4715 12.5886V28.7916L50.0002 22.4404V6.82429L44.1311 9.75886Z"
        fill="currentColor"
      />
      <path
        d="M25.8948 22.4404L37.4235 28.7916V12.5886L25.8948 6.82429V22.4404Z"
        fill="currentColor"
      />
      <path
        d="M32.3717 3.05127L26.5445 5.96488L37.9474 11.6663L42.5379 9.38155L32.3717 3.05127Z"
        fill="currentColor"
      />
      <path
        d="M49.3504 5.96488L37.9475 0.263428L33.4618 2.50628L43.628 8.83656L49.3504 5.96488Z"
        fill="currentColor"
      />
      <path
        d="M23.0077 13.4457H0.793371C0.35543 13.4457 0 13.9304 0 14.5275C0 15.1247 0.35543 15.6094 0.793371 15.6094H23.0077C23.4457 15.6094 23.8011 15.1247 23.8011 14.5275C23.8011 13.9304 23.4457 13.4457 23.0077 13.4457Z"
        fill="currentColor"
      />
      <path
        d="M23.0077 13.4457H0.793371C0.35543 13.4457 0 13.9304 0 14.5275C0 15.1247 0.35543 15.6094 0.793371 15.6094H23.0077C23.4457 15.6094 23.8011 15.1247 23.8011 14.5275C23.8011 13.9304 23.4457 13.4457 23.0077 13.4457Z"
        fill="currentColor"
      />
      <path
        d="M22.9899 9.11819H5.13907C4.69118 9.11819 4.32767 9.60287 4.32767 10.2001C4.32767 10.7973 4.69118 11.2819 5.13907 11.2819H22.9899C23.4378 11.2819 23.8013 10.7973 23.8013 10.2001C23.8013 9.60287 23.4378 9.11819 22.9899 9.11819Z"
        fill="currentColor"
      />
      <path
        d="M22.9899 9.11819H5.13907C4.69118 9.11819 4.32767 9.60287 4.32767 10.2001C4.32767 10.7973 4.69118 11.2819 5.13907 11.2819H22.9899C23.4378 11.2819 23.8013 10.7973 23.8013 10.2001C23.8013 9.60287 23.4378 9.11819 22.9899 9.11819Z"
        fill="currentColor"
      />
      <path
        d="M22.9898 4.79071H11.6302C11.1823 4.79071 10.8188 5.27539 10.8188 5.87258C10.8188 6.46977 11.1823 6.95445 11.6302 6.95445H22.9898C23.4377 6.95445 23.8012 6.46977 23.8012 5.87258C23.8012 5.27539 23.4377 4.79071 22.9898 4.79071Z"
        fill="currentColor"
      />
      <path
        d="M22.9898 4.79071H11.6302C11.1823 4.79071 10.8188 5.27539 10.8188 5.87258C10.8188 6.46977 11.1823 6.95445 11.6302 6.95445H22.9898C23.4377 6.95445 23.8012 6.46977 23.8012 5.87258C23.8012 5.27539 23.4377 4.79071 22.9898 4.79071Z"
        fill="currentColor"
      />
      <path
        d="M22.9899 17.7731H5.13907C4.69118 17.7731 4.32767 18.2578 4.32767 18.855C4.32767 19.4522 4.69118 19.9369 5.13907 19.9369H22.9899C23.4378 19.9369 23.8013 19.4522 23.8013 18.855C23.8013 18.2578 23.4378 17.7731 22.9899 17.7731Z"
        fill="currentColor"
      />
      <path
        d="M22.9899 17.7731H5.13907C4.69118 17.7731 4.32767 18.2578 4.32767 18.855C4.32767 19.4522 4.69118 19.9369 5.13907 19.9369H22.9899C23.4378 19.9369 23.8013 19.4522 23.8013 18.855C23.8013 18.2578 23.4378 17.7731 22.9899 17.7731Z"
        fill="currentColor"
      />
      <path
        d="M22.9898 22.1006H11.6302C11.1823 22.1006 10.8188 22.5853 10.8188 23.1825C10.8188 23.7797 11.1823 24.2644 11.6302 24.2644H22.9898C23.4377 24.2644 23.8012 23.7797 23.8012 23.1825C23.8012 22.5853 23.4377 22.1006 22.9898 22.1006Z"
        fill="currentColor"
      />
      <path
        d="M22.9898 22.1006H11.6302C11.1823 22.1006 10.8188 22.5853 10.8188 23.1825C10.8188 23.7797 11.1823 24.2644 11.6302 24.2644H22.9898C23.4377 24.2644 23.8012 23.7797 23.8012 23.1825C23.8012 22.5853 23.4377 22.1006 22.9898 22.1006Z"
        fill="currentColor"
      />
    </svg>
  );
}
