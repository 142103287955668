import { Avatar } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(4),
  [theme.breakpoints.down("xs")]: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
}));

export default SmallAvatar;
