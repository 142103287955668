import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@material-ui/core";
import { useInfluencer, useNotification } from "../../../hooks";
import { Form } from "../../../theme";
import Fields from "./Fields";
import SocialHandles from "../../../domain/socialHandles";

const SocialHandleDialog = React.memo(function SocialHandleDialog({
  route,
  open,
  onClose,
}) {
  const { influencer, isLoading, isError, refresh } = useInfluencer({
    route,
    forShopBuilder: true,
  });

  const notify = useNotification();

  const youtubeFieldRef = React.useRef(null);
  const [hasFieldError, setHasFieldError] = React.useState(false);
  const [state, setState] = React.useState(createInitialState(influencer));
  const [errorText, setErrorText] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const createOnChange = React.useCallback(
    (key) => (evt) => {
      const { value } = evt.target;
      setState((state) => ({ ...state, [key]: value }));
    },
    [],
  );

  const onSave = () => {
    setErrorText(null);
    setIsSaving(true);

    SocialHandles.update({ socialHandles: influencer.handles, updates: state })
      .then(() => refresh())
      .then(() => setIsSaving(false))
      .then(() => notify({ message: "Social handles saved!", duration: 3000 }))
      .then(onClose)
      .catch(showError);
  };

  const showError = (err) => {
    setErrorText(err.message);
    setIsSaving(false);
  };

  React.useEffect(() => {
    if (!influencer) {
      return;
    }

    setState(createInitialState(influencer));
  }, [influencer]);

  React.useEffect(() => {
    if (!youtubeFieldRef.current) {
      return;
    }

    const isValid = youtubeFieldRef.current.checkValidity();

    if (!isValid) {
      setHasFieldError(true);
    } else {
      setHasFieldError(false);
    }
  }, [state?.youtube]);

  if (isLoading || isError) {
    return null;
  }

  const shouldDisable = isLoading || isError || isSaving;
  const handleClose = shouldDisable || hasFieldError ? undefined : onClose;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Social Handles</DialogTitle>

      <DialogContent>
        <Form.StyledGrid>
          <Form.StyledDarkBackground>
            <Box minWidth={400} p={2}>
              <Fields
                shouldDisable={shouldDisable}
                state={state}
                createOnChange={createOnChange}
                errorText={errorText}
                hasFieldError={hasFieldError}
                youtubeFieldRef={youtubeFieldRef}
              />
            </Box>
          </Form.StyledDarkBackground>
        </Form.StyledGrid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} disabled={shouldDisable}>
          Cancel
        </Button>

        <Button
          color="secondary"
          onClick={onSave}
          disabled={shouldDisable || hasFieldError}
          variant="contained"
          size="small"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

SocialHandleDialog.propTypes = {
  route: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

function createInitialState(influencer = {}) {
  const { handles } = influencer;

  return {
    instagram: handles?.instagram?.value ?? "",
    twitch: handles?.twitch?.value ?? "",
    youtube: handles?.youtube?.value ?? "",
    tiktok: handles?.tiktok?.value ?? "",
    twitter: handles?.twitter?.value ?? "",
    facebook: handles?.facebook?.value ?? "",
  };
}

export default SocialHandleDialog;
