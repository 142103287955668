import React, { useEffect } from "react";

const StoreLandingPageInLocalStorage = () => {
  const setWithExpiry = (key, value, ttl) => {
    const now = new Date();
    const item = {
      value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };
  const getWithExpiry = (key) => {
    const itemStringyfied = localStorage.getItem(key);

    if (!itemStringyfied) {
      return null;
    }

    const item = JSON.parse(itemStringyfied);
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    var itemValue = item.value;
    return itemValue;
  };

  const getParameterByName = (name) => {
    const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  };

  useEffect(() => {
    // store landing page for 7 days in localStorage
    if (!getWithExpiry("lp")) {
      setWithExpiry("lp", window.location.pathname, 1000 * 60 * 60 * 24 * 7);
    }

    // store tracking query parameters for 7 days in localStorage
    if (!getWithExpiry("utm_campaign") && getParameterByName("utm_campaign")) {
      setWithExpiry(
        "utm_campaign",
        getParameterByName("utm_campaign"),
        1000 * 60 * 60 * 24 * 7,
      );
    }
    if (!getWithExpiry("utm_medium") && getParameterByName("utm_medium")) {
      setWithExpiry(
        "utm_medium",
        getParameterByName("utm_medium"),
        1000 * 60 * 60 * 24 * 7,
      );
    }
    if (!getWithExpiry("utm_source") && getParameterByName("utm_source")) {
      setWithExpiry(
        "utm_source",
        getParameterByName("utm_source"),
        1000 * 60 * 60 * 24 * 7,
      );
    }
    if (
      !getWithExpiry("stly_meta_ad_id") &&
      getParameterByName("stly_meta_ad_id")
    ) {
      setWithExpiry(
        "stly_meta_ad_id",
        getParameterByName("stly_meta_ad_id"),
        1000 * 60 * 60 * 24 * 7,
      );
    }
    if (
      !getWithExpiry("stly_meta_campaign_name") &&
      getParameterByName("stly_meta_campaign_name")
    ) {
      setWithExpiry(
        "stly_meta_campaign_name",
        getParameterByName("stly_meta_campaign_name"),
        1000 * 60 * 60 * 24 * 7,
      );
    }
    if (
      !getWithExpiry("stly_meta_campaign_src") &&
      getParameterByName("stly_meta_campaign_src")
    ) {
      setWithExpiry(
        "stly_meta_campaign_src",
        getParameterByName("stly_meta_campaign_src"),
        1000 * 60 * 60 * 24 * 7,
      );
    }
  });
  return null;
};

export default StoreLandingPageInLocalStorage;
