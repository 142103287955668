import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SearchResultsWrapper from "./SearchResultWrapper";
import { Influencer, InfluencerEvent } from "../../../domain";

const getEvent = (item) => {
  if (!item.next_event_time) {
    return null;
  }

  return InfluencerEvent.from({
    date_type: item.date_type,
    event_end_date: item.event_end_date,
    event_start_date: item.event_start_date,
    event_type: item.event_type,
    next_event_time: item.next_event_time,
    time_zone_abbr: item.time_zone_abbr,
    time_zone: item.time_zone,
    time_zone_offset: item.time_zone_offset,
  });
};

function SearchResult({ item, onClick }) {
  const influencer = Influencer.from(item);
  const event = getEvent(item);

  return (
    <SearchResultsWrapper influencer={influencer} onClick={onClick}>
      <SearchResultIconContainer>
        <SearchResultIcon alt="avatar" src={item.avatar_url} />
      </SearchResultIconContainer>

      <SearchResultContent>
        <Row>
          <OpenIndicator open={influencer.isOpen} />
          <SearchResultTitle>{influencer.name}</SearchResultTitle>
          {influencer.isLive && <LiveIndicator>LIVE</LiveIndicator>}
        </Row>

        {event && <DateTime>{event.searchFormattedValue}</DateTime>}

        <SearchResultCredits>
          {influencer.famousFor}
          {influencer.famousFor && influencer.famousCharacter ? " | " : ""}
          {influencer.famousCharacter}
        </SearchResultCredits>
      </SearchResultContent>
    </SearchResultsWrapper>
  );
}

SearchResult.propTypes = {
  item: PropTypes.shape({
    next_event_time: PropTypes.string,
    avatar_url: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default React.memo(SearchResult);

const DateTime = styled.div`
  font-size: 14px;
  margin: 1px 0px;
  color: rgb(70, 70, 70);
  @media only screen and (max-width: 500px) {
    font-size: 12px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SearchResultIconContainer = styled.div`
  background: linear-gradient(125deg, rgb(200, 43, 102), rgb(110, 150, 240));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

const SearchResultIcon = styled.img`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
  padding: 2px;
`;

const SearchResultContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const SearchResultTitle = styled.div`
  font-weight: bold;
  color: rgb(60, 60, 60);
`;

const OpenIndicator = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid rgb(150, 150, 150);
  background: rgb(200, 43, 102);
  margin-right: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  ${(props) =>
    props.open &&
    `
        background: rgb(100,140,190);
    `}
`;

const SearchResultCredits = styled.div`
  font-size: 13px;
  color: rgb(70, 70, 70);
`;

const LiveIndicator = styled.div`
  background: rgb(255, 0, 80);
  padding: 2px 5px;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  font-size: 12px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  margin-left: 5px;
`;
