const conventionTags = new Set(["sdccpickup"]);

export default class Tag {
  #rawTag;

  /*
   * from creates a new Tag from a rawTag
   *
   * @param {object} rawTag
   * @returns {Tag}
   */
  static from(rawTag) {
    return new Tag(rawTag);
  }

  static isConventionTag(tag) {
    return tag && conventionTags.has(tag.value);
  }

  /*
   * creates a new Tag from a rawTag
   *
   * @param {object} rawTag
   * @returns {Tag}
   */
  constructor(rawTag) {
    const rawTagCopy = { ...rawTag };
    this.#rawTag = rawTagCopy;
  }

  get productTagId() {
    return this.#rawTag.product_tag_id;
  }

  get productId() {
    return this.#rawTag.product_id;
  }

  get value() {
    return this.#rawTag.tag_string;
  }

  get positionOverride() {
    return this.#rawTag.position_override;
  }

  get deactivatedAt() {
    if (this.#rawTag.deactivated_at) {
      return new Date(this.#rawTag.deactivated_at);
    }

    return void 0;
  }

  get isActive() {
    return Boolean(this.#rawTag.active);
  }

  get created() {
    if (this.#rawTag.created) {
      return new Date(this.#rawTag.created);
    }

    return void 0;
  }

  get updated() {
    if (this.#rawTag.updated) {
      return new Date(this.#rawTag.updated);
    }

    return void 0;
  }

  get raw() {
    return this.#rawTag;
  }

  /*
   * mutate returns a new Tag with key set to value
   *
   * @param {string} key
   * @param {any} value
   *
   * @returns {Tag}
   */
  mutate(key, value) {
    const updatedRawTag = { ...this.#rawTag, [key]: value };
    return new Tag(updatedRawTag);
  }

  toJSON() {
    return {
      productTagId: this.productTagId,
      productId: this.productId,
      value: this.value,
      positionOverride: this.positionOverride,
      deactivatedAt: this.deactivatedAt,
      isActive: this.isActive,
      created: this.created,
      updated: this.updated,
    };
  }
}
