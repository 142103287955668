import * as React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";
import axios from "axios";

const baseEndpoint = "/api/influencer?dashboard=true";

async function fetchActiveInfluencer(route) {
  try {
    const { data } = await axios.get(`${baseEndpoint}&route=${route}`);

    return data;
  } catch (err) {
    console.error(err);

    return null;
  }
}

export default function useUserInfluencers(route) {
  const { data, error, mutate } = useSWR(baseEndpoint, fetcher);

  const [userInfluencer, setUserInfluencer] = React.useState();
  const [activeInfluencer, setActiveInfluencer] = React.useState();

  const userInfluencers = React.useMemo(() => {
    if (!data) {
      return;
    }

    if (!Array.isArray(data) || !data.length) {
      return;
    }

    return data;
  }, [data]);

  React.useEffect(() => {
    if (!Array.isArray(userInfluencers) || !userInfluencers.length) {
      return;
    }

    if (route) {
      setUserInfluencer(
        userInfluencers.find((influencer) => influencer.route === route),
      );
    } else {
      const [first] = userInfluencers;

      setUserInfluencer(first);
    }
  }, [route, userInfluencers]);

  React.useEffect(() => {
    if (userInfluencer) {
      const { route } = userInfluencer;

      fetchActiveInfluencer(route).then(setActiveInfluencer);
    }
  }, [userInfluencer, setActiveInfluencer]);

  return {
    userInfluencers,
    activeInfluencer,
    isLoading: !userInfluencers && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
