/* eslint-disable no-unreachable */
import {
  Box,
  Button as MuiButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { A, D, G, S } from "@mobily/ts-belt";
import { useAtom, useAtomValue } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { useActiveInfluencer, useCurrentUser } from "../../hooks";
import { MessageDialog } from "../../theme";
import InternationalWarning from "./InternationalWarning";
import { Button } from "./styles";
import { createOrUpdateProduct, nextTextFor, STEPS } from "./utils";

function shouldDisable({ state, product, signers }) {
  const hasValidStock =
    (G.isNumber(product.stock) && product.stock >= 0) || product.stock === null;

  const isProductComplete =
    G.isString(product.name) &&
    S.isNotEmpty(product.name) &&
    G.isString(product.imageUrl) &&
    S.isNotEmpty(product.imageUrl) &&
    G.isArray(signers) &&
    A.isNotEmpty(signers) &&
    hasValidStock;

  return (
    state.isLoading ||
    state.hasUnsavedChanges ||
    (state.currentStep === STEPS.PRODUCT_SUMMARY && !isProductComplete)
  );
}

export default function ShopBuilderProductModalActions({
  defaultInternationalProductType,
  onClose,
  productAtom,
  productCharactersAtom,
  productSignersAtom,
  productTagsAtom,
  stateAtom,
}) {
  const activeInfluencer = useActiveInfluencer();
  const currentUser = useCurrentUser();
  const productTags = useAtomValue(productTagsAtom);
  const signers = useAtomValue(productSignersAtom);
  const characters = useAtomValue(productCharactersAtom);
  const [product, setProduct] = useAtom(productAtom);
  const [state, setState] = useAtom(stateAtom);

  const handleCreateOrUpdateProduct = async (newProduct) => {
    try {
      setState(D.merge({ isLoading: true }));

      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });

      await createOrUpdateProduct({
        product: newProduct,
        productTags,
        characters,
        signers,
        state,
      });

      onClose?.({ shouldRefreshInfluencer: true });
    } catch (err) {
      setState(
        D.merge({
          isLoading: false,
          hasError: true,
          errorMessage: err.message,
        }),
      );
    }
  };

  const handleDelete = async () => {
    return handleCreateOrUpdateProduct(D.merge(product, { archived: true }));
  };

  const handleReview = async () => {
    // If we're creating a product we want to verify if the product type is
    // correct, so for international products we'll show a warning and ask if
    // they want to change the product type to the default international if
    // it's not already set to that.
    if (
      state.currentStep === STEPS.PRODUCT_SUMMARY &&
      !state.isEditing &&
      !activeInfluencer.isDomestic &&
      !state.didConfirmTypeWarning &&
      G.isString(product.productType) &&
      (product.productType.includes('8"x10"') ||
        product.productType.includes('11"x17"'))
    ) {
      setState(
        D.merge({ showTypeWarning: true, didConfirmTypeWarning: false }),
      );
      return;
    }

    if (state.currentStep === STEPS.PRODUCT_SUMMARY) {
      return handleCreateOrUpdateProduct(product);
    }

    setState(
      D.merge({
        currentStep: state.isEditing
          ? STEPS.PRODUCT_SUMMARY
          : Math.min(STEPS.PRODUCT_SUMMARY, state.currentStep + 1),
        hasError: false,
        errorMessage: "",
      }),
    );
  };

  const handleBack = () => {
    setState(
      D.merge({
        currentStep: Math.max(STEPS.CREATE_PRODUCT, state.currentStep - 1),
        isEditing: false,
        hasError: false,
        errorMessage: "",
      }),
    );
  };

  const handleInternationalConfirm = async () => {
    setState(D.merge({ showTypeWarning: false, didConfirmTypeWarning: true }));

    const newProduct = D.merge(product, {
      productType: defaultInternationalProductType.name,
      productTypeId: defaultInternationalProductType.productTypeId,
    });

    setProduct(newProduct);

    return handleCreateOrUpdateProduct(newProduct);
  };

  const handleInternationalClose = async () => {
    setState(D.merge({ showTypeWarning: false, didConfirmTypeWarning: true }));

    return handleCreateOrUpdateProduct(product);
  };

  const isDisabled = shouldDisable({ state, product, signers });

  return (
    <Box
      alignItems="center"
      display="flex"
      gridGap="8px"
      justifyContent="flex-end"
    >
      {state.currentStep > STEPS.CREATE_PRODUCT && !state.isEditing && (
        <MuiButton onClick={handleBack}>Back</MuiButton>
      )}

      {state.currentStep === STEPS.PRODUCT_SUMMARY &&
        currentUser.isAdmin &&
        state.isEditing && (
          <MuiButton
            disabled={isDisabled}
            onClick={() => {
              setState(D.merge({ showDeleteWarning: true }));
            }}
            type="button"
          >
            <Typography variant="button" color="error">
              Delete
            </Typography>
          </MuiButton>
        )}

      {state.showDeleteWarning && (
        <MessageDialog
          buttonList={[
            { default: true, label: "Delete", onClick: handleDelete },
          ]}
          message="Are you sure you want to delete this product?"
          onClose={() => {
            setState(D.merge({ showDeleteWarning: false }));
          }}
          title="Confirm Delete"
        />
      )}

      <Button
        disabled={isDisabled}
        onClick={handleReview}
        style={{ minWidth: 152 }}
        type="button"
      >
        {!state.isLoading && nextTextFor(state)}

        {state.isLoading && <CircularProgress size={24} />}
      </Button>

      {state.showTypeWarning && (
        <InternationalWarning
          onCancel={handleInternationalClose}
          onClose={() => {
            setState(D.merge({ showTypeWarning: false }));
          }}
          onConfirm={handleInternationalConfirm}
        />
      )}
    </Box>
  );
}

ShopBuilderProductModalActions.propTypes = {
  defaultDomesticProductType: PropTypes.shape({
    name: PropTypes.string.isRequired,
    productTypeId: PropTypes.number.isRequired,
  }),
  defaultInternationalProductType: PropTypes.shape({
    name: PropTypes.string.isRequired,
    productTypeId: PropTypes.number.isRequired,
  }),
  onClose: PropTypes.func,
  productAtom: PropTypes.object.isRequired,
  productSignersAtom: PropTypes.object.isRequired,
  productCharactersAtom: PropTypes.object.isRequired,
  productTagsAtom: PropTypes.object.isRequired,
  stateAtom: PropTypes.object.isRequired,
};
