export const inputValidation = {
  firstName: {
    autoComplete: "given-name",
    name: "firstName",
    minLength: 1,
    maxLength: 40,
  },
  lastName: {
    autoComplete: "family-name",
    name: "lastName",
    minLength: 1,
    maxLength: 40,
  },
  instagramHandle: { maxLength: 25 },
  email: { autoComplete: "email", name: "email", minLength: 4, maxLength: 80 },
  phoneNumber: {
    autoComplete: "tel",
    name: "phoneNumber",
    minLength: 4,
    maxLength: 25,
  },
  address: {
    autoComplete: "address-line1",
    name: "address",
    minLength: 2,
    maxLength: 80,
  },
  address2: { autoComplete: "address-line2", name: "address2", maxLength: 40 },
  city: {
    autoComplete: "address-level2",
    name: "city",
    minLength: 2,
    maxLength: 80,
  },
  state: {
    autoComplete: "address-level1",
    name: "state",
    minLength: 2,
    maxLength: 80,
  },
  zipCode: {
    autoComplete: "postal-code",
    name: "zipCode",
    minLength: 2,
    maxLength: 40,
  },
  personalizationTo: { maxLength: 50 },
  notes: { maxLength: 150 },
  personalizationAddonQuote: { maxLength: 40 },
  personalizationAddonCharacterName: { maxLength: 20 },
  password: {
    autoComplete: "current-password",
    name: "password",
    minLength: 6,
    maxLength: 100,
  },
  country: { autoComplete: "country", name: "country" },
  caption: { maxLength: 255 },
};

export const validations = {
  phoneNumber: {
    pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    validityMessage:
      "Phone numbers may only contain numbers, dash (-) and space ( )",
  },
  password: {
    validityMessage: "Must be at least 6 characters in length",
  },
};

export default inputValidation;
