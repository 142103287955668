import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";

function BadgeIcon({ icon, badgeNumber }) {
  return (
    <>
      <StyledBadge>{badgeNumber}</StyledBadge>
      {icon}
    </>
  );
}

BadgeIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  badgeNumber: PropTypes.number.isRequired,
};

const StyledBadge = styled("span")(({ theme }) => ({
  position: "absolute",
  top: -theme.spacing(1),
  left: -theme.spacing(1),
  background: theme.palette.blue.main,
  borderRadius: "50%",
  height: 26,
  width: 26,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.palette.light.main,
  fontFamily: theme.typography.interFontStack,
  fontWeight: theme.typography.semiBold,
}));

export default React.memo(BadgeIcon);
