import { Box, IconButton, Typography } from "@material-ui/core";
import {
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  SaveRounded,
} from "@material-ui/icons";
import { D, flow, pipe, S } from "@mobily/ts-belt";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { fetcher } from "../../../../hooks";
import { copyToClipboard } from "../../../../util";
import { affiliateLink } from "./propTypes";
import { StyledInput, StyledRow } from "./styles";
import { isValidPercentage, parsePercentage } from "./utils";

const copiedUI = (
  <Typography
    variant="caption"
    style={{ position: "absolute", top: "-25px", left: 0 }}
    color="textSecondary"
  >
    Copied!
  </Typography>
);

const createDraftLink = (affiliateLink) =>
  D.set(
    affiliateLink,
    "percentage",
    `${Math.round(affiliateLink.percentage * 10000) / 100}%`,
  );

export default function AffiliateLink({
  affiliateLink = {},
  influencerRoute,
  refresh,
  setError,
}) {
  const [isEditing, setIsEditing] = React.useState(false);
  const [didCopy, setDidCopy] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [draftLink, setDraftLink] = React.useState(
    createDraftLink(affiliateLink),
  );

  const link = React.useMemo(() => {
    const params = new URLSearchParams({
      source: "aff",
      medium: draftLink.code,
    });

    return `streamily.com/${influencerRoute}?${params}`;
  }, [influencerRoute, draftLink.code]);

  const handleEdit = () => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }

    const updates = pipe(
      draftLink,
      D.selectKeys(["code", "percentage", "influencerId", "userId"]),
      D.update("percentage", parsePercentage),
      D.update("code", flow(S.toLowerCase, S.trim)),
    );

    if (
      updates.code === affiliateLink.code &&
      updates.percentage === affiliateLink.percentage
    ) {
      setIsEditing(false);
      setDraftLink(createDraftLink(affiliateLink));
      return;
    }

    if (!isValidPercentage(updates.percentage)) {
      setError("Invalid percentage");
      return;
    }

    if (S.isEmpty(updates.code)) {
      setError("Code cannot be empty");
      return;
    }

    if (updates.code.length > 100) {
      setError("Code cannot be longer than 100 characters");
      return;
    }

    setIsLoading(true);

    return fetcher(`/api/affiliateLink/${affiliateLink.affiliateLinkId}`, {
      method: "PUT",
      body: JSON.stringify(updates),
    })
      .then(({ affiliateLink }) => {
        setDraftLink(createDraftLink(affiliateLink));
        setIsEditing(false);
      })
      .catch((err) => {
        setError(err?.json?.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
        return refresh();
      });
  };

  const handleCopy = async () => {
    await copyToClipboard(link);

    setDidCopy(true);

    setTimeout(() => {
      setDidCopy(false);
    }, 2000);
  };

  const handleDelete = () => {
    setIsLoading(true);

    window
      .fetch(`/api/affiliateLink/${affiliateLink.affiliateLinkId}`, {
        method: "DELETE",
      })
      .then((res) => {
        if (!res.ok) throw new Error("Something went wrong");
        return refresh();
      })
      .catch((err) => {
        setError(err?.json?.errors[0]?.message ?? "Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isInactive = !affiliateLink.active;

  return (
    <StyledRow
      className={clsx(isEditing && "editing", isInactive && "inactive")}
    >
      <span>{affiliateLink.affiliateLinkId}</span>

      <StyledInput
        disabled={isInactive || isLoading}
        readOnly={!isEditing}
        value={draftLink.code}
        inputProps={{ minLength: 1, maxLength: 100 }}
        onChange={(evt) => {
          setError("");
          const code = pipe(evt.target.value, S.toLowerCase, S.trim);
          setDraftLink(D.set("code", code));
        }}
      />

      <StyledInput
        disabled={isInactive || isLoading}
        inputMode="numeric"
        readOnly={!isEditing}
        value={draftLink.percentage}
        onChange={(evt) => {
          setError("");
          const percentage = pipe(evt.target.value, S.trim);
          setDraftLink(D.set("percentage", percentage));
        }}
      />

      <span>{link}</span>

      <span>{affiliateLink.user?.email ?? "N/A"}</span>

      <Box display="flex" gridGap="8px" alignItems="center">
        <IconButton
          disabled={isInactive || isLoading}
          onClick={handleEdit}
          color={isEditing ? "secondary" : "default"}
        >
          {isEditing ? <SaveRounded /> : <EditOutlined />}
        </IconButton>

        <Box position="relative">
          <IconButton onClick={handleCopy} disabled={isInactive || isEditing}>
            <LinkOutlined />
          </IconButton>

          {didCopy && copiedUI}
        </Box>

        <IconButton onClick={handleDelete} disabled={isInactive || isEditing}>
          <DeleteOutlined />
        </IconButton>
      </Box>
    </StyledRow>
  );
}

AffiliateLink.propTypes = {
  affiliateLink,
  influencerRoute: PropTypes.string,
  refresh: PropTypes.func,
  setError: PropTypes.func,
};
