import { Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import * as constants from "../../constants";

const steps = [
  {
    title: "Order Items",
    text: "Choose any product to get signed by your favorite actors, characters, and influencers.",
  },
  {
    title: "Watch It Personalized",
    text: "Watch your item get personally signed by your favorite Talent on camera.",
  },
  {
    title: "Leave It To Streamily",
    text: "The Talent will send your orders to Streamily and we’ll make sure it’s processed and safely sent to you!",
  },
  {
    title: "Yours Forever",
    text: "Have a one-of-a-kind personalized autograph from your favorite Talent to hold onto forever.",
  },
];

export default function HowStreamilyWorks({ isCurrentTab, isMobile }) {
  return (
    <Slide
      timeout={{
        enter: 500,
        exit: 250,
      }}
      in={isCurrentTab}
      direction="left"
      unmountOnExit
      style={{ paddingRight: isMobile ? constants.M_GUTTER : void 0 }}
    >
      <div className="home-page__sections__promo-tabs__cards large">
        {steps.map(({ title, text }, i) => (
          <div
            className="home-page__sections__promo-tabs__card large"
            key={title}
          >
            <h2 className="home-page__sections__promo-tabs__card-title">
              {i + 1}. {title}
            </h2>
            <p className="home-page__sections__promo-tabs__text">{text}</p>
          </div>
        ))}
      </div>
    </Slide>
  );
}

HowStreamilyWorks.propTypes = {
  isCurrentTab: PropTypes.bool,
  isMobile: PropTypes.bool,
};
