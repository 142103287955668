import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Typography,
  DialogTitle as MUIDialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles, styled } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    color: theme.palette.grey[500],
  },
  closeButtonLeft: {
    position: "absolute",
    left: theme.spacing(1.5),
    top: theme.spacing(1.5),
    color: theme.palette.grey[500],
  },
  typography: {
    fontWeight: 600,
    maxWidth: "75%",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      margin: 0,
    },
  },
  left: {
    textAlign: "left",
    margin: 0,
  },
  center: {
    textAlign: "center",
    margin: "0 auto",
    "& *": {
      margin: "0 auto",
    },
  },
});

const DialogTitle = withStyles(styles)(function DialogTitle(props) {
  const {
    children,
    classes,
    onClose,
    closePosition = "right",
    align,
    subtitle,
    disabled = false,
    gutterBottom = 2,
    buttonProps = {},
    TypographyProps = {},
    ...other
  } = props;

  return (
    <Box width="100%" pb={gutterBottom}>
      <MUIDialogTitle
        disableTypography
        className={`${classes.root} ${classes[align ?? "center"]}`}
        {...other}
      >
        <Typography
          {...TypographyProps}
          className={classes.typography}
          variant="h5"
        >
          {children}
        </Typography>

        <IconButton
          aria-label="close"
          className={
            closePosition === "right"
              ? classes.closeButton
              : classes.closeButtonLeft
          }
          onClick={onClose}
          disabled={disabled}
          {...buttonProps}
        >
          <CloseIcon />
        </IconButton>
      </MUIDialogTitle>
      {subtitle && (
        <StyledTypography className={align ?? "center"}>
          {subtitle}
        </StyledTypography>
      )}
    </Box>
  );
});

DialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  align: PropTypes.oneOf(["left", "center"]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  gutterBottom: PropTypes.number,
  buttonProps: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DialogTitle;

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.secondary,
  margin: `0 ${theme.spacing(3)}px`,
  "&.left": {
    textAlign: "left",
  },
  "&.center": {
    textAlign: "center",
    maxWidth: "75%",
    margin: "0 auto",
  },
}));
