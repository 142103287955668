import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const LoadingIndicator = (props) => {
  const LoadingIndicatorRef = useRef(null);

  const CreateSpinner = (color) => {
    const canvas = LoadingIndicatorRef.current,
      d = (canvas.width = canvas.height = 50),
      ctx = canvas.getContext("2d");

    let opacity;

    ctx.translate(d / 2, d / 2);
    ctx.rotate((Math.PI * 360) / 360);
    ctx.lineWidth = Math.ceil(d / 50);
    ctx.lineCap = "square";

    for (let i = 0; i <= 360; i++) {
      ctx.save();

      ctx.rotate((Math.PI * i) / 180);

      ctx.beginPath();
      ctx.moveTo(0, 0);
      opacity = (360 - i * 0.95) / 360;
      ctx.strokeStyle = `rgba(255,255,255,${opacity.toFixed(2)})`;
      ctx.lineTo(0, d + 30);
      ctx.stroke();
      ctx.closePath();

      ctx.restore();
    }

    ctx.globalCompositeOperation = "source-out";
    ctx.beginPath();
    ctx.arc(0, 0, d / 2, 2 * Math.PI, false);
    ctx.fillStyle = color || "white";
    ctx.fill();

    ctx.globalCompositeOperation = "destination-out";
    ctx.beginPath();
    ctx.arc(0, 0, (d / 2) * 0.9, 2 * Math.PI, false);
    ctx.fill();
  };

  useEffect(() => {
    CreateSpinner(props.color || "rgb(102, 170, 216)");
  }, []);

  return (
    <Container show={props.show || true} style={props.style || {}}>
      <Canvas ref={LoadingIndicatorRef}></Canvas>
    </Container>
  );
};

export default LoadingIndicator;

const Container = styled.div`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${(props) =>
    !props.show &&
    `
        display: none;
    `}
`;

const Canvas = styled.canvas`
  margin: 10px;
  height: 100%;
  width: auto;
  animation: spin 1s infinite cubic-bezier(0.7, 0.4, 0.5, 0.7);
`;
