import * as React from "react";
import useSWR from "swr";
import { formatWithLast4 } from "../util/formatWith";
import fetcher from "./fetcher";

const baseEndpoint = "/api/autographPurchase/user";

export default function useOrders(page = 0) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}?page=${page}`,
    fetcher,
  );

  const orders = React.useMemo(() => {
    if (Array.isArray(data)) {
      return data.map(formatWithLast4("gift_card_code"));
    }
  }, [data]);

  return {
    orders,
    isLoading: !orders && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
