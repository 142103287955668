import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

const FranchiseHomepage = () => {
  const unmounted = useRef(false);

  const [franchises, setFranchises] = useState([]);

  const getData = async () => {
    try {
      const data = await axios.get(`/api/franchise`);
      if (unmounted.current) return;
      setFranchises(data.data.franchises);
    } catch (error) {
      if (unmounted.current) return;
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <Container>
      <FranchisesContainer>
        {franchises.map((franchise) => (
          <Franchise key={`franchise_${franchise.franchise_id}`}>
            <FranchiseTitle>
              {!!franchise.franchise_shop_avatar_url && (
                <FranchiseAvatar src={franchise.franchise_shop_avatar_url} />
              )}
              <FranchiseName>{franchise.name}</FranchiseName>
            </FranchiseTitle>
            <CharactersContainer>
              {franchise.characters.map((character) => (
                <Character key={`character_${character.product_id}`}>
                  <CharacterImage src={character.product_image_url} />
                  <CharacterDetails>
                    <CharacterName>{character.name}</CharacterName>
                    {!!character.signer_name && (
                      <SignerContainer>
                        <SignedByText>Signed By</SignedByText>
                        <Signer>
                          <SignerAvatar src={character.signer_avatar_url} />
                          <div>{character.signer_name}</div>
                        </Signer>
                      </SignerContainer>
                    )}
                  </CharacterDetails>
                </Character>
              ))}
            </CharactersContainer>
          </Franchise>
        ))}
      </FranchisesContainer>
    </Container>
  );
};

export default FranchiseHomepage;

const Container = styled.div`
  background-color: rgb(60, 60, 60);
  padding-bottom: 50px;
  padding-top: 20px;
`;

const FranchisesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Franchise = styled.a`
  margin: 10px 0;
  color: white;
`;

const FranchiseTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const FranchiseAvatar = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 5px;
  border: 1px solid rgb(125, 125, 125);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
`;

const FranchiseName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const CharactersContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  overflow-x: scroll;
  padding-bottom: 10px;
`;

const Character = styled.div`
  margin: 0 10px;
  cursor: pointer;
`;

const CharacterImage = styled.img`
  width: 170px;
  height: 200px;
  border-radius: 15px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  object-fit: cover;
  margin-top: 5px;
  &:hover {
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
  }
`;

const CharacterDetails = styled.div`
  padding: 5px;
`;

const CharacterName = styled.div`
  font-size: 16px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const SignerContainer = styled.div`
  margin-top: 5px;
`;

const SignedByText = styled.div`
  font-size: 12px;
  color: rgb(150, 150, 150);
`;

const Signer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2px;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const SignerAvatar = styled.img`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  object-fit: cover;
  margin-right: 5px;
  border: 1px solid rgb(125, 125, 125);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
`;
