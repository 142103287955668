import * as React from "react";
import {
  useStripe as useStripeNative,
  useElements,
} from "@stripe/react-stripe-js";

export default function useStripe() {
  const stripe = useStripeNative();
  const elements = useElements();

  return React.useMemo(() => {
    if (!stripe || !elements) return {};
    return { stripe, elements };
  }, [stripe, elements]);
}
