import axios from "axios";

export async function setServiceBump({ influencer_id, service_fee_bump }) {
  try {
    const { status } = await axios.put(`/api/influencer/${influencer_id}`, {
      service_fee_bump,
    });
    return status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
}
