import { Chip } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { F, G, S } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { PreloadLink } from "../../../theme";

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  textDecoration: "none",
  "& .MuiChip-label": {
    textDecoration: "none",
  },
}));

export default function ShopTag({ tag }) {
  if (
    tag.shopSoldOut ||
    !G.isString(tag.shopRoute) ||
    S.isEmpty(tag.shopRoute)
  ) {
    return <StyledChip label={tag.tagString} />;
  }

  return (
    <PreloadLink
      to={`/${tag.shopRoute}`}
      endpoints={[`/api/influencer/byRoute/${tag.shopRoute}`]}
    >
      <StyledChip label={tag.shopName} onClick={F.identity} />
    </PreloadLink>
  );
}

ShopTag.propTypes = {
  tag: PropTypes.shape({
    tagString: PropTypes.string.isRequired,
    sales: PropTypes.number.isRequired,
    shopRoute: PropTypes.string,
    shopSoldOut: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    shopName: PropTypes.string,
    shopAvatarUrl: PropTypes.string,
  }),
};
