import { Fade, Grid, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer } from "../../../../domain";

const VideoAvatar = React.memo(function VideoAvatar({ influencer }) {
  const intervalRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const [isMuted, setIsMuted] = React.useState(true);
  const [isHovering, setIsHovering] = React.useState(false);

  const handleClick = () => {
    if (!videoRef.current) {
      return;
    }

    if (isMuted) {
      videoRef.current.muted = false;
      setIsMuted(false);
    } else {
      videoRef.current.muted = true;
      setIsMuted(true);
    }
  };

  React.useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      if (videoRef.current?.muted) {
        setIsMuted(true);
      } else {
        setIsMuted(false);
      }
    }, 100);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <StyledGrid
      item
      xs
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Fade in={isMuted || isHovering}>
        <StyledIconButton
          title={isMuted ? "Unmute" : "Mute"}
          onClick={handleClick}
        >
          {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </StyledIconButton>
      </Fade>

      <StyledVideo
        ref={videoRef}
        height={300}
        width={220}
        autoPlay
        loop
        muted
        playsInline
        controls
        src={influencer.videoAvatarUrl}
      />
    </StyledGrid>
  );
});

VideoAvatar.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
};

const StyledGrid = styled(Grid)(() => ({
  position: "relative",
  margin: "0 auto",
  width: "fit-content",
}));

const StyledIconButton = styled(IconButton)(() => ({
  position: "absolute",
  top: 0,
  right: 0,
  color: "white",
  zIndex: 1,
}));

const StyledVideo = styled("video")(({ theme }) => ({
  objectFit: "cover",
  borderRadius: theme.spacing(0.5),
  boxShadow: "0px 2px 5px 0px #00000040",
}));

export default VideoAvatar;
