import { Icons } from "../../theme";

const registry = new Set();
const attributes = {};
/*
 * a SocialHandle represents an influencer's social presence on a social media
 * platform.
 */
export default class SocialHandle {
  #type;
  #value;

  static register(Handle) {
    registry.add(Handle);

    attributes[Handle.TYPE] = Object.seal(
      Object.freeze({
        type: Handle.TYPE,
        Icon: Handle.ICON,
        color: Handle.COLOR,
      }),
    );

    return Handle;
  }

  /*
   * attributesFor returns social media attributes for a handle
   *
   * @param {SocialHandle}
   * @returns {object} attributes
   */
  static attributesFor(handle) {
    if (!handle.type) {
      return void 0;
    }

    return attributes[handle.type];
  }

  /*
   * for returns a new SocialHandle for a rawHandle of a certain type
   *
   * @param {object} rawHandle { type, handle, channel_id? }
   * @returns {SocialHandle}
   */
  static for(rawHandle) {
    for (const Handle of registry) {
      if (Handle.canHandle(rawHandle)) {
        return new Handle(rawHandle);
      }
    }

    throw new TypeError(`No SocialHandle for type ${rawHandle?.type}`);
  }

  static canHandle() {
    throw new TypeError("Subclass must implement canHandle");
  }

  /*
   * creates a new SocialHandle from a type and value
   *
   * @param {object} rawHandle
   * @returns {SocialHandle}
   */
  constructor({ type, handle }) {
    this.#type = type;
    this.#value = handle;
  }

  get type() {
    return this.#type;
  }

  get value() {
    return this.#value;
  }

  toJSON() {
    return {
      type: this.#type,
      value: this.#value,
    };
  }
}

export const InstagramHandle = SocialHandle.register(
  class InstagramHandle extends SocialHandle {
    static TYPE = "instagram";
    static ICON = Icons.InstagramIcon;
    static COLOR = "#F00073";

    static canHandle({ type } = {}) {
      return type === InstagramHandle.TYPE;
    }

    get link() {
      return `https://instagram.com/${this.value}`;
    }

    constructor({ type = InstagramHandle.TYPE, handle }) {
      super({ type, handle });
    }
  },
);

export const TwitchHandle = SocialHandle.register(
  class TwitchHandle extends SocialHandle {
    static TYPE = "twitch";
    static ICON = Icons.TwitchIcon;
    static COLOR = "#9146FF";

    static canHandle({ type } = {}) {
      return type === TwitchHandle.TYPE;
    }

    get link() {
      return `https://twitch.tv/${this.value}`;
    }

    constructor({ type = TwitchHandle.TYPE, handle }) {
      super({ type, handle });
    }
  },
);

export const YoutubeHandle = SocialHandle.register(
  class YoutubeHandle extends SocialHandle {
    #channelId;

    static TYPE = "youtube";
    static ICON = Icons.YouTubeIcon;
    static COLOR = "#FF0000";

    static canHandle({ type } = {}) {
      return type === YoutubeHandle.TYPE;
    }

    constructor({ type = YoutubeHandle.TYPE, handle }) {
      super({ type, handle });
    }

    get link() {
      try {
        new URL(this.value); // Verify the URL is valid
        return this.value;
      } catch {
        // If not valid URL, try something basic
        return `https://youtube.com/${this.value}`;
      }
    }

    toJSON() {
      return {
        ...super.toJSON(),
        channelId: this.#channelId,
      };
    }
  },
);

export const TikTokHandle = SocialHandle.register(
  class TikTokHandle extends SocialHandle {
    static TYPE = "tiktok";
    static ICON = Icons.TikTokIcon;
    static COLOR = "#000";

    static canHandle({ type } = {}) {
      return type === TikTokHandle.TYPE;
    }

    get link() {
      return `https://tiktok.com/@${this.value}`;
    }

    constructor({ type = TikTokHandle.TYPE, handle }) {
      super({ type, handle });
    }
  },
);

export const TwitterHandle = SocialHandle.register(
  class TwitterHandle extends SocialHandle {
    static TYPE = "twitter";
    static ICON = Icons.TwitterIcon;
    static COLOR = "#000000";

    static canHandle({ type } = {}) {
      return type === TwitterHandle.TYPE;
    }

    get link() {
      return `https://twitter.com/${this.value}`;
    }

    constructor({ type = TwitterHandle.TYPE, handle }) {
      super({ type, handle });
    }
  },
);

export const FacebookHandle = SocialHandle.register(
  class FacebookHandle extends SocialHandle {
    static TYPE = "facebook";
    static ICON = Icons.FacebookIcon;
    static COLOR = "#1877f2";

    static canHandle({ type } = {}) {
      return type === FacebookHandle.TYPE;
    }

    get link() {
      return `https://facebook.com/${this.value}`;
    }

    constructor({ type = FacebookHandle.TYPE, handle }) {
      super({ type, handle });
    }
  },
);
