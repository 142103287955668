import { IconButton, InputBase, Popover } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  CloseRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  SearchRounded,
} from "@material-ui/icons";
import { A, D, F, S } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import SmallCheckbox from "./SmallCheckbox";

function IconComponent({ expanded }) {
  if (expanded) {
    return <ExpandLessRounded />;
  }

  return <ExpandMoreRounded />;
}

IconComponent.propTypes = { expanded: PropTypes.bool };

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  ...theme.typography.subtitle2,
  background: "#fff",
  display: "flex",
  fontWeight: 500,
  gap: 4,
  maxWidth: 250,
  padding: theme.spacing(1, 2),

  "& .MuiSvgIcon-root": {
    color: "#5B5B5B",
    left: -1,
    position: "relative",
  },
}));

const ScrollContainer = styled("div")(() => ({
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: 1,
}));

function ClearButton({ onClick }) {
  return (
    <IconButton size="small" onClick={onClick}>
      <CloseRounded fontSize="small" />
    </IconButton>
  );
}

ClearButton.propTypes = { onClick: PropTypes.func };

function StyledSearch({ value, onChange, ...props }) {
  return (
    <StyledInputBase
      {...props}
      startAdornment={<SearchRounded />}
      type="search"
      value={value}
      onChange={onChange}
      endAdornment={
        S.isEmpty(value) ? undefined : (
          <ClearButton
            onClick={() => {
              onChange({ target: { value: "" } });
            }}
          />
        )
      }
    />
  );
}

StyledSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const StyledButton = styled("button")(({ theme }) => ({
  alignItems: "center",
  background: "#fff",
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.spacing(1),
  color: "#5B5B5B",
  cursor: "pointer",
  display: "flex",
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
  gap: 16,
  height: 33,
  justifyContent: "space-between",
  lineHeight: "17px",
  margin: 0,
  minWidth: 165,
  textAlign: "left",
  transition: "box-shadow 0.125s ease-in-out",
  "&:hover": {
    boxShadow: "0px 2px 8px rgba(53, 143, 171, 0.1)",
  },

  "&:focus": {
    outline: `2px solid ${theme.palette.secondary.main}`,
  },

  "& svg": {
    color: "rgba(0, 0, 0, 0.54)",
    position: "relative",
    right: 4,
    top: 1,
  },

  "& span": {
    left: 12,
    position: "relative",
  },
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    background: "#ebebeb",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 0px 13.3775px rgba(0, 0, 0, 0.15)",
    display: "flex",
    flexDirection: "column",
    gap: 1,
    marginTop: 3,
    maxHeight: "50vh",
  },
}));

const StyledItem = styled("div")(({ theme }) => ({
  background: "#fbfbfb",
  padding: theme.spacing(0.5, 2),
}));

const selectAll = ({ options, getOptionKey }) =>
  options.reduce((result, option) => {
    result[getOptionKey(option)] = option;
    return result;
  }, {});

const selectNoneFn = () => ({});

const SearchSelect = React.memo(function SearchSelect({
  disableSorting = false,
  disabled = false,
  filterCompare = F.always(true),
  getOptionKey = F.identity,
  getOptionValue = F.identity,
  getOptionLabel = getOptionValue,
  label = "Select",
  getSelectLabel = F.always(label),
  onChange = F.identity,
  options = [],
  selectNone = false,
  initialSelected,
  ...props
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [selected, setSelected] = React.useState(
    initialSelected
      ? initialSelected
      : selectNone
      ? selectNoneFn()
      : selectAll({ options, getOptionKey }),
  );

  const handleOpen = (evt) => {
    setAnchorEl(evt.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Filter the options based on the search value and selected items
  const filter = (option) => {
    if (S.length(searchValue) === 0) {
      return true;
    }

    return (
      filterCompare(option, searchValue) ||
      Boolean(selected[getOptionKey(option)])
    );
  };

  // Sort the selected items to the top
  const sorter = (a, b) => {
    const keyA = getOptionKey(a);
    const keyB = getOptionKey(b);

    if (selected[keyA] && !selected[keyB]) {
      return -1;
    }

    if (selected[keyB] && !selected[keyA]) {
      return 1;
    }

    return 0;
  };

  React.useEffect(() => {
    onChange(selected);
  }, [selected, onChange]);

  React.useEffect(() => {
    setSelected(
      initialSelected
        ? initialSelected
        : selectNone
        ? selectNoneFn()
        : selectAll({ options, getOptionKey }),
    );
  }, [initialSelected, options, selectNone, setSelected, getOptionKey]);

  React.useEffect(() => {
    function handleScroll() {
      if (anchorEl) {
        handleClose();
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [anchorEl]);

  const open = Boolean(anchorEl) && !disabled;
  const keys = D.keys(selected);
  const hasSelectedAll = A.length(keys) === A.length(options);
  const hasSelectedNone = A.length(keys) === 0;

  return (
    <>
      <StyledButton {...props} onClick={handleOpen} disabled={disabled}>
        <span>
          {getSelectLabel({ hasSelectedAll, hasSelectedNone, selected, label })}
        </span>

        <IconComponent expanded={open} />
      </StyledButton>

      <StyledPopover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        disablePortal={true}
      >
        <StyledSearch
          placeholder="Search"
          value={searchValue}
          onChange={(evt) => {
            setSearchValue(evt.target.value);
          }}
        />

        <ScrollContainer>
          <StyledItem>
            <SmallCheckbox
              variant="subtitle2"
              label="Select All"
              checked={hasSelectedAll}
              onChange={(_, newValue) => {
                setSelected(
                  newValue ? selectAll({ options, getOptionKey }) : {},
                );
              }}
            />
          </StyledItem>

          {disableSorting &&
            options.filter(filter).map((option) => (
              <StyledItem key={getOptionKey(option)}>
                <SmallCheckbox
                  variant="subtitle2"
                  label={getOptionLabel(option)}
                  checked={Boolean(selected[getOptionKey(option)])}
                  onChange={() => {
                    const key = getOptionKey(option);
                    const isSelected = Boolean(selected[key]);

                    setSelected(
                      isSelected ? D.deleteKey(key) : D.set(key, option),
                    );
                  }}
                />
              </StyledItem>
            ))}

          {!disableSorting &&
            options
              .filter(filter)
              .sort(sorter)
              .map((option) => (
                <StyledItem key={getOptionKey(option)}>
                  <SmallCheckbox
                    variant="subtitle2"
                    label={getOptionLabel(option)}
                    checked={Boolean(selected[getOptionKey(option)])}
                    onChange={() => {
                      const key = getOptionKey(option);
                      const isSelected = Boolean(selected[key]);

                      setSelected(
                        isSelected ? D.deleteKey(key) : D.set(key, option),
                      );
                    }}
                  />
                </StyledItem>
              ))}
        </ScrollContainer>
      </StyledPopover>
    </>
  );
});

SearchSelect.propTypes = {
  disableSorting: PropTypes.bool,
  disabled: PropTypes.bool,
  filterCompare: PropTypes.func,
  getOptionKey: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  getSelectLabel: PropTypes.func,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any),
  selectNone: PropTypes.bool,
  initialSelected: PropTypes.objectOf(PropTypes.any),
};

export default SearchSelect;
