import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ErrorRoundedIcon from "@material-ui/icons/ErrorRounded";
import * as React from "react";

const StyledErrorText = styled("p")(({ theme }) => ({
  margin: 0,
  padding: 0,
  ...theme.typography.caption,
  fontWeight: 600,
  lineHeight: "14px",
  color: theme.palette.error.main,
  "&.span": { fontWeight: 800 },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export default function LivestreamWarningText() {
  return (
    <StyledBox
      display="flex"
      gridGap="8px"
      px={3}
      mb={1}
      mt={-2}
      alignItems="center"
    >
      <ErrorRoundedIcon
        fontSize="small"
        title="Create an event to have cusutomers see this product"
      />

      <StyledErrorText>
        <span>Important</span>: This product will not be displayed to your
        customers until a livestream event date has been set
      </StyledErrorText>
    </StyledBox>
  );
}
