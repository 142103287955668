import Influencer from "./influencer";

const registry = new Set();

export default class Endpoints {
  static for(classInstance) {
    for (const subclass of registry.values()) {
      if (subclass.canHandle(classInstance)) {
        return new subclass(classInstance);
      }
    }
  }

  static register(subclass) {
    registry.add(subclass);
    return subclass;
  }

  constructor(classInstance) {
    this.classInstance = classInstance;
  }
}

export const InfluencerEndpoints = Endpoints.register(
  class InfluencerEndpoints extends Endpoints {
    static canHandle(classInstance) {
      return classInstance instanceof Influencer;
    }

    get influencerByRoute() {
      return `/api/influencer/byRoute/${this.classInstance.route}`;
    }

    get influencerEvents() {
      return `/api/influencerEvents/${this.classInstance.influencerId}`;
    }

    get influencer() {
      return `/api/influencer/${this.classInstance.influencerId}`;
    }
  },
);
