import * as React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import clsx from "clsx";

const StyledButton = withStyles((theme) => ({
  root: {
    background:
      "linear-gradient(268.66deg, #1096E6 -7.68%, #E45599 99.38%), linear-gradient(268.66deg, #358FAB -7.68%, #A8DCEC 99.38%), #FFFFFF;",
    textTransform: "none",
    width: "100%",
    height: theme.spacing(6),
    padding: `0 ${theme.spacing(3)}px`,
    borderRadius: theme.spacing(1),
    fontSize: theme.typography.body2.fontSize,
    boxShadow: theme.boxShadow.buttonDefault,
    maxWidth: 305,
    color: "white",
    "&.default": {
      background: theme.palette.grey.main,
    },
    "&.default:hover": {
      background: theme.palette.grey.dark,
    },
    "&:hover": {
      boxShadow: theme.boxShadow.buttonHover,
    },
    "&:active, &:focus": {
      boxShadow: "none",
    },
    "&:disabled": {
      background: theme.palette.grey[400],
      color: theme.palette.grey[600],
      animation: "unset",
    },
    "&.small": {
      height: theme.spacing(4),
      fontSize: theme.typography.caption.fontSize,
      borderRadius: 5,
    },
    "&.medium": {
      height: theme.spacing(5),
      fontSize: theme.typography.caption.fontSize,
      borderRadius: 5,
    },
  },
  label: {
    fontFamily: theme.typography.interFontStack,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
      lineHeight: 1.2,
    },
  },
}))(Button);

export default function SexierButton({
  isLoading,
  children,
  className,
  size,
  color,
  ...props
}) {
  return (
    <StyledButton
      {...props}
      className={clsx(className, size, color)}
      disabled={Boolean(isLoading) || Boolean(props.disabled)}
    >
      {isLoading ? <CircularProgress size="2rem" /> : children}
    </StyledButton>
  );
}

SexierButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  color: PropTypes.oneOf(["default"]),
};
