import * as React from "react";

const logos = [
  { imageUrl: "netflix.png", title: "Netflix", width: 74, height: 20 },
  { imageUrl: "fox.png", title: "Fox", width: 46, height: 20 },
  { imageUrl: "cw.png", title: "The CW", width: 49, height: 20 },
  { imageUrl: "showtime.png", title: "Showtime", width: 55, height: 20 },
  { imageUrl: "disney.png", title: "Disney+", width: 57, height: 32 },
  { imageUrl: "paramount.png", title: "Paramount+", width: 51, height: 32 },
].map(({ imageUrl, title, width, height }) => (
  <img
    key={imageUrl}
    width={width}
    height={height}
    src={`https://d3qdxs9s8vjwda.cloudfront.net/misc/${imageUrl}`}
    alt={title}
  />
));

export default function Logos() {
  return (
    <div className="hidden-sm-down">
      <p className="home-page__hero__logos-text">Featuring Talent Seen On</p>
      <div className="home-page__hero__logos-list">{logos}</div>
    </div>
  );
}
