import { Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useHistory, useLocation, withRouter } from "react-router-dom";
import styled from "styled-components";
import * as CONFIG from "../config";
import getLoginRoute from "../domain/loginRoute";
import { useStateValue } from "../globalState";
import { useShrinkBuffer } from "../hooks";
import AccountDashboardMenu from "./AccountDashboardMenu";
import AuthModal from "./AuthModal";
import Search from "./Browse/Search";
import MobileSidebarModal from "./MobileSidebarModal";
import SiteNotice from "./SiteNotice";

const Navbar = React.memo(function Navbar() {
  const history = useHistory();
  const location = useLocation();
  const [{ user, triggerAuthModal }] = useStateValue();
  const NavbarAuthButtonsRef = useRef(null);

  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  // mobile sidebar vars
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const { shrinkBuffer, shouldShrink } = useShrinkBuffer();

  const CloseAuthModal = (user) => {
    setShowAuthModal(false);
    setShowMobileSidebar(false);

    if (!user || !Array.isArray(user?.roles)) {
      return;
    }

    const route = getLoginRoute(user);
    if (route) history.push(route);
    else window.location.reload(); // is this necessary anymore? TODO
  };

  const Signup = () => {
    setShowAuthModal(true);
    setIsLoggingIn(false);
  };

  const Login = () => {
    setShowAuthModal(true);
    setIsLoggingIn(true);
  };

  useEffect(() => {
    if (triggerAuthModal) {
      if (triggerAuthModal.type.toLowerCase() === "signup") {
        setIsLoggingIn(false);
      } else {
        setIsLoggingIn(true);
      }
      setShowAuthModal(true);
    }
  }, [triggerAuthModal]);

  return (
    <React.Fragment>
      <Container searchHidden={shouldShrink}>
        <HeaderBlock>
          {user.organization && user.organization.logo_url ? (
            <OrganizationLogoContainer to="/Dashboard">
              <OrganizationLogoImg
                alt="logo"
                src={user.organization.logo_url}
              />
            </OrganizationLogoContainer>
          ) : (
            <React.Fragment>
              <LogoContainer to="/">
                <LogoImg
                  width={144}
                  height={42}
                  alt="logo"
                  src={CONFIG.SITE_LOGO}
                />
              </LogoContainer>

              <SearchContainer className="search-block">
                <Search />
              </SearchContainer>
            </React.Fragment>
          )}
          <NavbarRight>
            {!shrinkBuffer &&
              !user.is_approval_pending &&
              user.show_dashboard_link && (
                <Button
                  style={{ height: 50, fontWeight: 500 }}
                  component={NavLink}
                  title="My Dashboard"
                  to="/Dashboard/MyDashboard"
                >
                  My Dashboard
                </Button>
              )}
            {!shrinkBuffer && !user.is_approval_pending && user.user_id ? (
              <AccountDashboardMenu user={user} />
            ) : shrinkBuffer && !user.is_approval_pending && user.user_id ? (
              <StyledMenuIcon
                fontSize="large"
                onClick={() => setShowMobileSidebar(!showMobileSidebar)}
              />
            ) : user.is_approval_pending ? (
              <>
                <Button
                  style={{ height: 50, fontWeight: 500 }}
                  component={NavLink}
                  title="My Pending Dashboard"
                  to="/talent/pending"
                >
                  My Dashboard
                </Button>
                <AccountDashboardMenu user={user} />
              </>
            ) : (
              <AuthButtons ref={NavbarAuthButtonsRef}>
                <LoginButton onClick={Signup}>{`Sign Up`}</LoginButton>
                <AuthButtonsBuffer>|</AuthButtonsBuffer>
                <LoginButton onClick={Login}>{`Log In`}</LoginButton>
              </AuthButtons>
            )}
          </NavbarRight>
        </HeaderBlock>

        {!shouldShrink && (
          <HeaderBlock>
            <SearchContainer className="search-block-mobile-view">
              <Search />
            </SearchContainer>
          </HeaderBlock>
        )}
      </Container>
      {showAuthModal && (
        <AuthModal onClose={CloseAuthModal} isLoggingIn={isLoggingIn} />
      )}
      {showMobileSidebar && <MobileSidebarModal onClose={CloseAuthModal} />}
      <Buffer searchHidden={shouldShrink} />

      <SiteNotice
        text="Streamily supports our Talent and is in compliance with the latest SAG-AFTRA guidelines"
        location={location}
      />
    </React.Fragment>
  );
});

export default withRouter(Navbar);

const StyledMenuIcon = styled(MenuIcon)`
  margin: 0.3em 0.7em 0 0;
`;

const Buffer = styled.div`
  height: 52px;
  @media (max-width: 480px) {
    height: ${(props) => (props.searchHidden ? 52 : 102)}px;
  }
`;

const SearchContainer = styled.div`
  box-sizing: border-box;
  flex-grow: 1;
  margin: auto 20px;
  z-index: 1;
  @media only screen and (max-width: 580px) {
    margin: 0;
  }
  @media only screen and (max-width: 480px) {
    width: 90%;
    margin: auto 20px;
  }
`;

const HeaderBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  z-index: 10;
`;

const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 25;
  box-shadow: 0px 2px 10px 0px #0000000d;
  background: white;
  height: 52px;

  @media only screen and (max-width: 480px) {
    height: ${(props) => (props.searchHidden ? 52 : 102)}px;
  }
`;

const LogoContainer = styled(NavLink)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  padding-left: 2px;
  height: 52px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const LogoImg = styled.img`
  object-fit: contain;
  width: 144px;
  cursor: pointer;
  margin: 5px;
  &:active {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;

const OrganizationLogoContainer = styled(NavLink)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  height: 52px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const OrganizationLogoImg = styled.img`
  height: 40px;
  width: auto;
  padding: 5px;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  &:active {
    background-color: rgb(230, 230, 230);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;

const NavbarRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const AuthButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 7px 0;
  margin-right: 20px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media only screen and (max-width: 300px) {
    margin-right: 10px;
  }
`;

const LoginButton = styled.button`
  background: none;
  border: none;
  font: 0.8rem / 1.5 "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  cursor: pointer;
  height: 36px;
  padding: 0 5px;
  font-weight: 500;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: rgb(100, 100, 100);
  &:hover {
    filter: brightness(60%);
  }
  &:active {
    filter: brightness(30%);
  }
`;

const AuthButtonsBuffer = styled.div`
  height: 36px;
  line-height: 36px;
  padding: 0 5px;
`;
