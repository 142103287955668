import * as React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";

function codeURLFor(userId) {
  const url = "/api/affiliate/codes";
  if (userId) {
    return `${url}${userId}`;
  }
  return url;
}

export function useAffiliateCodes(userId) {
  const { data, error, mutate } = useSWR(codeURLFor(userId), fetcher);

  const codes = React.useMemo(() => {
    if (Array.isArray(data?.codes)) {
      return data.codes;
    }

    return [];
  }, [data]);

  return {
    codes,
    error,
    isError: error,
    isLoading: !error && !data,
    refresh: mutate,
  };
}
