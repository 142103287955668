import { Box, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import * as React from "react";
import { StatusMessage } from "../../../../theme";
import AffiliateLink from "./AffiliateLink";
import { useAffiliateLinks } from "./service";
import { StyledHeader } from "./styles";

const loadingUI = (
  <Skeleton variant="rect" width="100%" height={118} animation="wave" />
);

const errorUI = (
  <StatusMessage
    message="There was an error loading affiliate links"
    type="error"
  />
);

export default function AffiliateLinksList({ influencerId, influencerRoute }) {
  const [error, setError] = React.useState("");
  const { affiliateLinks, isLoading, isError, refresh } =
    useAffiliateLinks(influencerId);

  return (
    <>
      <StyledHeader>
        <span>ID</span>
        <span>Code</span>
        <span>Percent</span>
        <span>URL</span>
        <span>Affiliate Email</span>
      </StyledHeader>

      {isLoading && loadingUI}

      {isError && errorUI}

      {!isLoading && affiliateLinks.length === 0 && (
        <Box display="flex" justifyContent="center" color="textSecondary">
          <Typography variant="body2">No affiliate links.</Typography>
        </Box>
      )}

      {!isLoading && (
        <Box display="flex" flexDirection="column" gridGap="8px">
          {affiliateLinks.map((affiliateLink) => (
            <AffiliateLink
              affiliateLink={affiliateLink}
              influencerRoute={influencerRoute}
              key={affiliateLink.affiliateLinkId}
              refresh={refresh}
              setError={setError}
            />
          ))}
        </Box>
      )}

      {Boolean(error) && <StatusMessage type="error" message={error} />}
    </>
  );
}

AffiliateLinksList.propTypes = {
  influencerId: PropTypes.number.isRequired,
  influencerRoute: PropTypes.string.isRequired,
};
