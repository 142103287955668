import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";

export default function ErrorUI({ message }) {
  return (
    <Grid container justifyContent="center" alignItems="center">
      {Boolean(message) && (
        <Grid item>
          <Typography color="error">{message}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

ErrorUI.propTypes = {
  message: PropTypes.string.isRequired,
};
