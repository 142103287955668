import * as React from "react";

export default function DollaBillsIcons({ width = 50, height = 50 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.01 18.0331H21.5236C17.6669 18.1599 14.5714 21.33 14.5714 25.2179C14.5714 29.1059 17.6669 32.276 21.5236 32.4028H22.01C25.8668 32.276 28.9623 29.1059 28.9623 25.2179C28.9623 21.33 25.8668 18.1599 22.01 18.0331ZM22.8136 29.402V30.1734C22.8136 31.569 20.6999 31.569 20.6999 30.1734V29.6135C17.8058 28.7182 19.8027 26.1696 21.0171 27.4152C21.0906 27.4887 21.2286 27.5844 21.4189 27.6055C22.2839 27.7586 22.4964 26.3689 21.5982 26.2632C21.5871 26.2531 21.577 26.2531 21.5659 26.2531C18.8289 25.8101 18.3698 22.1879 20.6999 21.0228V20.2515C20.6999 18.8558 22.8136 18.8558 22.8136 20.2515V20.8224C25.6916 21.7348 23.6806 24.2452 22.4753 23.0096C21.8751 22.4084 20.9325 23.2483 21.4501 23.9079C21.569 24.074 21.7975 24.1828 21.9254 24.1828C24.595 24.5745 25.1679 28.1342 22.8136 29.402Z"
        fill="currentColor"
      />
      <path
        d="M36.1273 18.0331H27.6847C29.756 19.745 31.0762 22.3228 31.0762 25.2179C31.0762 28.103 29.755 30.6909 27.6847 32.4028H36.1273C36.5291 30.6174 37.9449 29.2016 39.7303 28.7998V21.6361C37.9449 21.2343 36.5291 19.8185 36.1273 18.0331Z"
        fill="currentColor"
      />
      <path
        d="M12.4583 25.2179C12.4583 22.3228 13.7795 19.745 15.8499 18.0331H7.41731C7.00545 19.8185 5.5997 21.2343 3.80322 21.6361V28.7998C5.5997 29.2016 7.00445 30.6174 7.41731 32.4028H15.8499C13.7785 30.6909 12.4583 28.103 12.4583 25.2179Z"
        fill="currentColor"
      />
      <path
        d="M9.80585 12.116H14.9304L37.194 4.32893C37.4578 4.23427 37.7539 4.25541 38.0076 4.37122C38.2503 4.4981 38.4517 4.70957 38.5464 4.9734C38.779 5.6501 39.2543 6.18884 39.8988 6.49497C40.5432 6.80109 41.2723 6.84338 41.9379 6.61077C42.4978 6.42045 43.0899 6.70543 43.2903 7.25524L45.0234 12.2106C45.3929 12.3798 45.6467 12.7393 45.6467 13.1723V22.8616L49.9999 21.34L42.7405 0.598022L9.80585 12.116Z"
        fill="currentColor"
      />
      <path
        d="M43.5342 14.2287H0V36.2073H43.5342V14.2287ZM41.8435 29.7303C41.8435 30.3224 41.3682 30.7866 40.7872 30.7866C39.318 30.7866 38.1136 31.991 38.1136 33.4602C38.1136 34.0412 37.6484 34.5165 37.0573 34.5165C31.9569 34.5165 34.279 34.5165 6.47798 34.5165C5.88586 34.5165 5.42164 34.0412 5.42164 33.4602C5.42164 31.991 4.21728 30.7866 2.74808 30.7866C2.16705 30.7866 1.69175 30.3214 1.69175 29.7303V20.6956C1.69175 20.1145 2.16705 19.6392 2.74808 19.6392C4.21728 19.6392 5.42164 18.4449 5.42164 16.9767C5.42164 16.3957 5.88687 15.9204 6.47798 15.9204C11.5784 15.9204 9.25626 15.9204 37.0573 15.9204C37.6494 15.9204 38.1136 16.3957 38.1136 16.9767C38.1136 18.4459 39.318 19.6392 40.7872 19.6392C41.3682 19.6392 41.8435 20.1145 41.8435 20.6956V29.7303Z"
        fill="currentColor"
      />
      <path
        d="M38.98 8.39618C38.1562 8.00546 37.4795 7.39221 37.0042 6.63193L21.3344 12.116H42.7521L41.6001 8.84026C40.713 8.94599 39.8147 8.79796 38.98 8.39618Z"
        fill="currentColor"
      />
    </svg>
  );
}
