import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Fade, Button, Typography } from "@material-ui/core";
import PlayIcon from "@material-ui/icons/PlayArrowOutlined";
import { styled, withStyles } from "@material-ui/core/styles";
import { Influencer } from "../../domain";

const VideoAvatar = React.memo(function VideoAvatar({
  influencer,
  height,
  width,
}) {
  const unmounted = React.useRef();
  const videoRef = React.useRef(null);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const handleClick = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  React.useEffect(() => {
    unmounted.current = false;

    function handlePlay() {
      if (!unmounted.current) {
        setIsPlaying(true);
      }
    }

    function handlePause() {
      if (!unmounted.current) {
        setIsPlaying(false);
      }
    }

    const el = videoRef.current;

    el?.addEventListener("play", handlePlay);
    el?.addEventListener("pause", handlePause);

    return () => {
      el?.removeEventListener("play", handlePlay);
      el?.removeEventListener("pause", handlePause);
    };
  }, []);

  return (
    <StyledGrid item xs>
      {!isPlaying && <StyledOverlay />}

      {!isPlaying && (
        <Fade in>
          <StyledContainer
            container
            justifyContent="center"
            alignItems="center"
          >
            <StyledButton title="Preview Video" onClick={handleClick}>
              <PlayIcon fontSize="large" />
              <StyledTypography variant="body2">Preview Video</StyledTypography>
            </StyledButton>
          </StyledContainer>
        </Fade>
      )}

      <StyledVideo
        ref={videoRef}
        height={height}
        width={width}
        loop
        controls={isPlaying}
        src={influencer.videoAvatarUrl}
      ></StyledVideo>
    </StyledGrid>
  );
});

VideoAvatar.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  position: "relative",
  margin: "0 auto",
  width: "fit-content",
}));

const StyledContainer = styled(Grid)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  color: "white",
  zIndex: 1,
}));

const StyledOverlay = styled("div")(({ theme }) => ({
  background: theme.palette.grey.dark,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  position: "absolute",
  opacity: 0.5,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "white",
  margin: 0,
}));

const StyledButton = withStyles((theme) => ({
  root: {
    color: "white",
    margin: 0,
    padding: 0,
    background: "none",
    backgroundColor: "none",
    "&:hover": {
      background: "none",
      backgroundColor: "none",
    },
  },

  label: {
    display: "flex",
    flexFlow: "column nowrap",
  },
}))(Button);

const StyledVideo = styled("video")(({ theme }) => ({
  objectFit: "cover",
  borderRadius: theme.spacing(0.5),
  boxShadow: "0px 2px 5px 0px #00000040",
  verticalAlign: "middle",
}));

export default VideoAvatar;
