import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import SalespersonRow from "./SalespersonRow";

const noSalespeopleUI = (
  <Typography variant="body2" color="textSecondary">
    No salespeople.
  </Typography>
);

export default function Salespeople({
  salespersons,
  setSalespersons,
  isError,
}) {
  return salespersons.length
    ? salespersons.map((salesperson) => (
        <SalespersonRow
          isError={isError}
          key={salesperson.userId}
          salesperson={salesperson}
          setSalespersons={setSalespersons}
        />
      ))
    : noSalespeopleUI;
}

Salespeople.propTypes = {
  isError: PropTypes.bool,
  salespersons: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number,
      percentage: PropTypes.number,
    }),
  ),
  setSalespersons: PropTypes.func.isRequired,
};
