import React from "react";
import PropTypes from "prop-types";
import { Button, Box, Grid, Typography } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import StatusMessage from "../../theme/StatusMessage";
import { Money } from "../../domain";
import NewIcon from "./NewIcon";

const cancelled = <Button disabled>Not Available</Button>;

export function withCancelled(isCancelled, fn) {
  if (isCancelled) {
    return cancelled;
  }
  return fn();
}

const useStyles = makeStyles((theme) => ({
  badge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    boxSizing: "border-box",
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      borderRadius: theme.spacing(1),
    },
    boxShadow: `0 1px 4px 2px rgba(0, 0, 0, 0.05)`,
    background: "white",
    lineHeight: 1,
    transition: "all 0.1s ease-out",
    "&:hover": {
      boxShadow: `0 2px 6px 2px rgba(0, 0, 0, 0.075)`,
    },
  },
}));

const AddonBadge = React.memo(function AddonBadge({
  action,
  additionalDetails,
  description,
  icon,
  isNew,
  price,
  title,
}) {
  const styles = useStyles();
  return (
    <Box py={1} px={2} className={styles.badge}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={1} container justifyContent="center" alignItems="center">
          {icon}
        </Grid>

        <Grid item container xs>
          <Grid item container spacing={1} alignItems="center">
            <Grid item xs="auto">
              <Box display="flex" alignItems="center" gridGap="4px">
                {isNew && <NewIcon />}
                <StyledTitle>{title}</StyledTitle>
              </Box>
            </Grid>

            {Boolean(price) && (
              <Grid item xs>
                <Typography variant="body2">{price.formattedPrice}</Typography>
              </Grid>
            )}
          </Grid>

          <Grid item>
            <StyledDescription variant="caption">
              {description}
            </StyledDescription>
          </Grid>

          {Boolean(additionalDetails) && (
            <Grid item xs={12}>
              {additionalDetails}
            </Grid>
          )}
        </Grid>

        <Grid item xs={3}>
          {action || <StatusMessage message="Unavailable" type="grey" />}
        </Grid>
      </Grid>
    </Box>
  );
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.primary,
  fontWeight: 600,
}));

const StyledDescription = styled(Typography)(() => ({
  lineHeight: 1.25,
}));

AddonBadge.propTypes = {
  action: PropTypes.node,
  additionalDetails: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  description: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  price: PropTypes.instanceOf(Money),
  title: PropTypes.string.isRequired,
};

export default AddonBadge;
