import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";

const ConfirmModal = (props) => {
  return (
    <BackgroundOverlay onMouseDown={props.onClose}>
      <Container onMouseDown={(e) => e.stopPropagation()}>
        <Text data-test="confirm-modal-text">
          {props.overrideEntireDescription
            ? props.description
            : `Are you sure you want to ${props.description}?`}
        </Text>
        <ButtonsContainer>
          <Button
            data-test="confirm-modal-button-yes"
            title="YES"
            onClick={() => {
              props.action();
              props.onClose();
            }}
            variant="contained"
            color="secondary"
            style={{ margin: 10, width: 100 }}
          >
            Yes
          </Button>
          <Button
            data-test="confirm-modal-button-no"
            title="NO"
            onClick={props.onClose}
            variant="contained"
            color="inherit"
            style={{ margin: 10, width: 100 }}
          >
            No
          </Button>
        </ButtonsContainer>
      </Container>
    </BackgroundOverlay>
  );
};

ConfirmModal.propTypes = {
  description: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmModal;

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  background: white;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  max-width: 420px;
  box-sizing: border-box;
  max-height: 90%;
  overflow: hidden;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  margin: 5px;
`;

const Text = styled.div`
  color: rgb(80, 80, 80);
  flex: 1;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
