import * as React from "react";

const Cursor = React.memo(function CustomCursor({ defaultSrc, hoverSrc }) {
  React.useEffect(() => {
    const style = document.createElement("style");
    style.setAttribute("type", "text/css");
    style.innerHTML = `
*, *::before, *::after { cursor: url('${defaultSrc}'), auto }
a:hover, a[class]:hover, button:hover, button[class]:hover, a:hover *, a[class]:hover *, button:hover *, button[class]:hover * { cursor: url('${hoverSrc}'), auto !important }
`;

    document.body.appendChild(style);

    return () => {
      style.remove();
    };
  }, [defaultSrc, hoverSrc]);

  return null;
});

export default Cursor;
