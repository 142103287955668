import Addon from "./addon";
import Address from "./address";
import Cart from "./cart";
import CartTotals from "./cartTotals";
import CartProduct from "./cartProduct";
import EventEmitter from "./eventEmitter";
import Fulfiller from "./fulfiller";
import { InfluencerEndpoints } from "./endpoints";
import InfluencerEvent from "./influencerEvent";
import Influencer from "./influencer";
import Money from "./money";
import PaymentMethod from "./paymentMethod";
import Product from "./product";
import Roles from "./roles";
import Salesperson from "./salesperson";
import Sendin from "./sendin";
import SignType from "./signType";
import SocialHandle from "./socialHandles/socialHandle";
import Tag from "./tag";
import User from "./user";

export {
  Addon,
  Address,
  Cart,
  CartTotals,
  CartProduct,
  EventEmitter,
  Fulfiller,
  Influencer,
  InfluencerEndpoints,
  InfluencerEvent,
  Money,
  PaymentMethod,
  Product,
  Roles,
  Salesperson,
  Sendin,
  SignType,
  SocialHandle,
  Tag,
  User,
};

/*
 * withIncludes enriches a class with relevant includes
 *
 * @param {class}
 * @returns {class}
 */
export function withIncludes(classInstance) {
  if (classInstance instanceof User) {
    return withIncludesUser(classInstance);
  }

  if (classInstance instanceof Influencer) {
    return withIncludesInfluencer(classInstance);
  }

  if (classInstance instanceof Product) {
    return withIncludesProduct(classInstance);
  }

  return classInstance;
}

/*
 * withIncludesUser enriches a user with relevant includes, such as initializing its
 * list of salespeople.
 *
 * @param {User}
 * @returns {User}
 */
export function withIncludesUser(user) {
  if (user.roles.isTalent) {
    user.setSalespeople(Salesperson);
  }

  return user;
}

/*
 * withIncludesInfluencer enriches an influencer with relevant includes, such as initializing its
 * list of products.
 *
 * @param {Influencer}
 * @returns {Influencer}
 */
export function withIncludesInfluencer(influencer) {
  if (influencer.hasProducts) {
    influencer.setProducts(Product, withIncludesProduct);
  }

  return influencer;
}

/*
 * withIncludesProduct enriches a product with relevant includes, such as
 * initializing its tags and fulfillers
 *
 * @param {Product}
 * @returns {Product}
 */
export function withIncludesProduct(product) {
  if (product.hasFulfillers) {
    product.setFulfillers(Fulfiller);
  }

  if (product.hasTags) {
    product.setTags(Tag);
  }

  return product;
}
