import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";

const Header = React.memo(function Header({ title, subtitle, action = null }) {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs>
        <Typography variant="h5">{title}</Typography>

        {Boolean(subtitle) && (
          <Typography variant="subtitle2">{subtitle}</Typography>
        )}
      </Grid>

      {action}
    </Grid>
  );
});

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: PropTypes.node,
};

export default Header;
