import * as React from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Typography, InputAdornment } from "@material-ui/core";
import { Icons, StatusMessage } from "../../../theme";

const Fields = React.memo(function Fields({
  state,
  createOnChange,
  shouldDisable,
  errorText,
  hasFieldError,
  youtubeFieldRef,
}) {
  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <TextField
          InputProps={withAdornment(<Icons.InstagramIcon />)}
          label="Instagram"
          value={state.instagram}
          onChange={createOnChange("instagram")}
          variant="outlined"
          color="secondary"
          size="small"
          disabled={shouldDisable}
          fullWidth
        />
      </Grid>

      <Grid item>
        <TextField
          InputProps={withAdornment(<Icons.TwitchIcon fill="black" />)}
          label="Twitch"
          value={state.twitch}
          onChange={createOnChange("twitch")}
          color="secondary"
          variant="outlined"
          size="small"
          disabled={shouldDisable}
          fullWidth
        />
      </Grid>

      <Grid item>
        <TextField
          InputProps={withAdornment(<Icons.TikTokIcon fill="black" />)}
          label="TikTok"
          value={state.tiktok}
          onChange={createOnChange("tiktok")}
          variant="outlined"
          color="secondary"
          size="small"
          disabled={shouldDisable}
          fullWidth
        />
      </Grid>

      <Grid item>
        <TextField
          InputProps={withAdornment(<Icons.TwitterIcon />)}
          label="X"
          value={state.twitter}
          onChange={createOnChange("twitter")}
          variant="outlined"
          color="secondary"
          size="small"
          disabled={shouldDisable}
          fullWidth
        />
      </Grid>

      <Grid item>
        <TextField
          InputProps={withAdornment(<Icons.FacebookIcon />)}
          label="Facebook"
          value={state.facebook}
          onChange={createOnChange("facebook")}
          variant="outlined"
          color="secondary"
          size="small"
          disabled={shouldDisable}
          fullWidth
        />
      </Grid>

      <Grid item>
        <TextField
          inputRef={youtubeFieldRef}
          InputProps={withAdornment(<Icons.YouTubeIcon />)}
          label="YouTube Channel URL"
          placeholder="https://www.youtube.com/Streamily"
          value={state.youtube}
          onChange={createOnChange("youtube")}
          variant="outlined"
          color="secondary"
          type="url"
          size="small"
          fullWidth
          disabled={shouldDisable}
          error={hasFieldError}
          helperText={
            <Typography
              variant="caption"
              color={hasFieldError ? "error" : "textSecondary"}
            >
              {hasFieldError
                ? "Please enter a valid link. (eg. https://youtube.com/streamily)"
                : "The full link to your YouTube channel."}
            </Typography>
          }
        />
      </Grid>

      <Grid item>
        {errorText && <StatusMessage type="error" message={errorText} />}
      </Grid>
    </Grid>
  );
});

function withAdornment(icon) {
  return {
    startAdornment: <InputAdornment>{icon}</InputAdornment>,
  };
}

Fields.propTypes = {
  state: PropTypes.object,
  createOnChange: PropTypes.func,
  shouldDisable: PropTypes.bool,
  errorText: PropTypes.string,
  hasFieldError: PropTypes.bool,
  youtubeFieldRef: PropTypes.object,
};

export default Fields;
