import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import clsx from "clsx";
import { Influencer } from "../../domain";
import { Skeleton } from "@material-ui/lab";

const ImageAvatar = React.memo(function ImageAvatar({
  influencer,
  height = 300,
  width = 220,
  disableShadow,
  onClick,
}) {
  return (
    <Grid item xs container justifyContent="center">
      {!influencer && <Skeleton height={height} width={width} />}

      {Boolean(influencer) && influencer.avatarUrl && (
        <StyledImage
          onClick={onClick}
          height={height}
          width={width}
          src={influencer.avatarUrl}
          alt={influencer.name}
          className={clsx(
            disableShadow && "noShadow",
            Boolean(onClick) && "pointer",
          )}
        />
      )}

      {Boolean(influencer) && !influencer.avatarUrl && (
        <StyledBox
          width={width}
          height={height}
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={1}
          style={{ cursor: "pointer" }}
          onClick={onClick}
        >
          <Typography align="center" color="textSecondary" variant="caption">
            Add a Shop Image
          </Typography>
        </StyledBox>
      )}
    </Grid>
  );
});

ImageAvatar.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
  height: PropTypes.number,
  width: PropTypes.number,
  disableShadow: PropTypes.bool,
};

const StyledImage = styled("img")(({ theme }) => ({
  objectFit: "cover",
  borderRadius: theme.spacing(0.5),
  boxShadow: "0px 2px 5px 0px #00000040",
  "&.noShadow": {
    boxShadow: "none",
  },
  "&.pointer": {
    cursor: "pointer",
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  background: theme.palette.grey[100],
  borderRadius: theme.spacing(0.5),
}));

export default ImageAvatar;
