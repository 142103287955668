import { Grid } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, minmax(100px, 1fr))",
  gridTemplateRows: "auto",
  gap: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
  "@media (min-width: 600px)": {
    gap: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
  },
  "@media (min-width: 980px)": {
    gridTemplateColumns: "repeat(3, minmax(200px, 1fr))",
    gap: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
  },
  "@media (min-width: 1000px)": {
    gridTemplateColumns: "repeat(4, minmax(200px, 1fr))",
  },
  "@media (min-width: 1280px)": {
    gridTemplateColumns: "repeat(5, minmax(200px, 1fr))",
  },
}));

export default StyledGrid;
