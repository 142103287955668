import * as React from "react";

export default function ProcessInjection() {
  /*
   * Because we lose the window.process variable during React hot reload, to
   * avoid annoying errors we are copying it upon first load.
   */
  React.useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return null;
}
