// format seconds to "xx:xx"

export function formatSecToMinSec(seconds) {
  const min = Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0");
  const sec = (seconds % 60).toString().padStart(2, "0");

  return `${min}:${sec}`;
}
