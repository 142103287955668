import * as React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import clsx from "clsx";
import { InfluencerEvent } from "../../domain";
import { Hint, MessageDialog } from "../../theme";
import moment from "moment";
import { useMatchesSmDown } from "../../hooks";
import { limitLength } from "../../util";

const InfluencerEventElement = React.memo(function InfluencerEvent({
  influencerEvent,
  align = "left",
  onClick,
}) {
  const isMobile = useMatchesSmDown();

  const [hasEventData] = React.useState(
    influencerEvent.eventType && influencerEvent.eventTitle,
  );
  const [showEventDetails, setShowEventDetails] = React.useState(false);

  const makeDateDetails = React.useCallback(() => {
    if (influencerEvent.startDate) {
      return (
        <>
          <Box style={{ marginBottom: "0.3rem" }}>
            <Typography>
              <StyledSpan>From: </StyledSpan>
              {moment(influencerEvent.startDate).format("MMMM Do, YYYY")}
            </Typography>
            <Typography></Typography>
          </Box>
          <Box style={{ marginBottom: "0.3rem" }}>
            <Typography>
              <StyledSpan>To: </StyledSpan>
              {moment(influencerEvent.endDate).format("MMMM Do, YYYY")}
            </Typography>
          </Box>
        </>
      );
    }
    return (
      <>
        <Box style={{ marginBottom: "0.3rem" }}>
          <Typography>
            <StyledSpan>Date: </StyledSpan>
            {moment(influencerEvent.value).format("MMMM Do, YYYY")}
          </Typography>
        </Box>
        {influencerEvent.hasTime && (
          <Box style={{ marginBottom: "0.3rem" }}>
            <Typography>
              <StyledSpan>Time: </StyledSpan>
              {moment(influencerEvent.value).format("h:mm A ")}{" "}
              {influencerEvent.browserTimezone}
            </Typography>
          </Box>
        )}
      </>
    );
  }, [influencerEvent]);

  const makeLocation = React.useCallback(() => {
    if (influencerEvent.url) {
      return (
        <StyledLink
          href={influencerEvent.url}
          target="_blank"
          rel="noopener noreferrer"
          title={influencerEvent.eventUrl}
        >
          {influencerEvent.platform}
        </StyledLink>
      );
    }
    return influencerEvent.platform;
  }, [influencerEvent]);

  const handleEventType = () => {
    if (showEventDetails) return;
    setShowEventDetails(true);
  };

  // The onClick function is only passed in when the event is on the shop
  // builder page, and the user is allowed to edit the event. In this case, it
  // will open the event dialog to edit the event.
  if (onClick) {
    return (
      <StyledButton
        className={clsx(align)}
        variant="text"
        color="default"
        haseventtype={"false"}
        onClick={onClick}
      >
        {influencerEvent.formattedValue}
        {influencerEvent?.platform ? ` on ${influencerEvent?.platform}` : ""}
        {influencerEvent.hasInfluencer &&
          ` with ${influencerEvent.influencerName}`}
      </StyledButton>
    );
  }

  // show popup of event details: title, date, platform, description
  if (hasEventData) {
    return (
      <>
        <StyledButton
          className={clsx(align)}
          variant="text"
          color="default"
          haseventtype={String(!!influencerEvent.eventType)}
          onClick={handleEventType}
        >
          {`${limitLength(influencerEvent.eventTitle, 30)} - `}
          {influencerEvent.formattedTitleWithDateValue}
          {influencerEvent.hasInfluencer &&
            ` with ${influencerEvent.influencerName}`}
        </StyledButton>

        {showEventDetails && (
          <MessageDialog
            title={influencerEvent.eventTitle}
            message={
              <Box style={isMobile ? {} : { minWidth: "30rem" }}>
                {makeDateDetails()}
                {influencerEvent.platform && (
                  <Box style={{ marginBottom: "0.3rem" }}>
                    <Typography>
                      {" "}
                      <StyledSpan>Location: </StyledSpan>
                      {makeLocation()}
                    </Typography>
                  </Box>
                )}

                {influencerEvent.eventDescription && (
                  <Typography>
                    <StyledSpan>Event Description: </StyledSpan>
                    {influencerEvent.eventDescription}
                  </Typography>
                )}

                {influencerEvent.printBoxDisclaimer && (
                  <Hint>{influencerEvent.printBoxDisclaimer}</Hint>
                )}
              </Box>
            }
            onClose={() => {
              setShowEventDetails(false);
            }}
          />
        )}
      </>
    );
  }

  return (
    <StyledText className={align}>
      {influencerEvent.formattedValue}
      {influencerEvent?.platform ? ` on ${influencerEvent?.platform}` : ""}
      {influencerEvent.hasInfluencer &&
        ` with ${influencerEvent.influencerName}`}
    </StyledText>
  );
});

InfluencerEventElement.propTypes = {
  influencerEvent: PropTypes.instanceOf(InfluencerEvent),
  align: PropTypes.oneOf(["left", "right"]),
  onClick: PropTypes.func,
};

const StyledButton = styled("button")(({ theme }) => ({
  "&.left": {
    textAlign: "left",
  },
  "&.right": {
    textAlign: "right",
  },
  ...theme.typography.body2,
  fontWeight: theme.typography.semiBold,
  fontSize: theme.typography.pxToRem(14),
  padding: 0,
  border: 0,
  cursor: "pointer",
  transition: "all 0.1s ease-out",
  background: "none",
  lineHeight: 1.75,
  "&:hover": {
    textDecoration: "underline",
    background: "none",
  },
}));

const StyledSpan = styled("span")(() => ({
  color: "#2b2b2b",
  fontWeight: "bold",
}));

const StyledLink = styled("a")(({ theme }) => ({
  "&.left": {
    textAlign: "left",
  },
  "&.right": {
    textAlign: "right",
  },
  fontWeight: theme.typography.bold,
  padding: 0,
  margin: 0,
  border: 0,
  color: "#000",
  cursor: "pointer",
  transition: "all 0.1s ease-out",
  background: "none",
  textDecoration: "underline",
  "&:hover": {
    textDecoration: "underline",
    background: "none",
  },
}));

const StyledText = styled("span")(({ theme }) => ({
  "&.left": {
    textAlign: "left",
  },
  "&.right": {
    textAlign: "right",
  },
  ...theme.typography.body2,
  fontWeight: theme.typography.semiBold,
  fontSize: theme.typography.pxToRem(14),
  padding: 0,
  margin: 0,
  border: 0,
  lineHeight: 1.75,
}));

export default InfluencerEventElement;
