import * as React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";

const baseEndpoint = "/api/influencer/getAgentTalents/";

export default function useAgentTalents(userId) {
  const { data, error, mutate } = useSWR(`${baseEndpoint}${userId}`, fetcher);

  const talents = React.useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data;
  }, [data]);

  return {
    error,
    talents,
    isError: Boolean(error),
    isLoading: !data && !error,
    refreshAgentTalents: mutate,
  };
}
