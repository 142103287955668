import * as React from "react";
import { useHistory } from "react-router-dom";
import deleteCookie from "../util/deleteCookie";
import axios from "axios";

async function logout() {
  try {
    const { status } = await axios.post("/api/auth/logout");
    return status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
}

export default function Logout() {
  const history = useHistory();

  React.useEffect(() => {
    localStorage.removeItem("uo");
    localStorage.removeItem("STREAMILY::ACTIVE::INFLUENCER");
    sessionStorage.removeItem("STREAMILY::ACTIVE::INFLUENCER");
    deleteCookie("ut");
    history.push("/");
    logout().then(() => {
      window.location.reload();
    });
  }, [history]);

  return null;
}
