import useSWR from "swr";
import fetcher from "./fetcher";

const baseEndpoint = "/api/socialHandle";

export function useSocialHandle(socialHandleId) {
  const { data, error, mutate } = useSWR(
    socialHandleId ? `${baseEndpoint}/${socialHandleId}` : null,
    fetcher,
  );
  return {
    socialHandle: data?.socialHandle,
    isSocialHandleLoading: !data && !error,
    isSocialHandleError: Boolean(error),
    error,
    refresh: mutate,
  };
}
