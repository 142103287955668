import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";

const style = {
  padding: "0.25rem 0",
  width: "fit-content",
  minWidth: "fit-content",
};

const Header = React.memo(function Header({ isEditing, setIsEditing }) {
  return (
    <HeaderElement>
      <Title data-testid="service-bump-title">Service Fee Bump</Title>
      <Button
        data-testid="service-bump-button"
        size="large"
        color={isEditing ? "secondary" : "primary"}
        style={style}
        onClick={() => setIsEditing((s) => !s)}
      >
        {isEditing ? "SAVE" : "EDIT"}
      </Button>
    </HeaderElement>
  );
});

const HeaderElement = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

const Title = styled.h4`
  margin: 0;
  opacity: 0.75;
  font-size: 0.9rem;
`;

export default Header;
