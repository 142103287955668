import * as React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Collapse,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { SmallAvatar, SmallAvatarGroup } from "../../theme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Influencer } from "../../domain";
import { useMatchesXsDown } from "../../hooks";

const InfluencerLinks = React.memo(function InfluencerLinks({
  influencers = [],
}) {
  const matchesXs = useMatchesXsDown();
  const [open, setOpen] = React.useState(false);

  const onClick = () => {
    setOpen((open) => !open);
  };

  if (!influencers.length) {
    return null;
  }

  if (influencers.length === 1) {
    const [influencer] = influencers;

    return (
      <StyledGrid container>
        <StyledButton size="small" fullWidth href={influencer.fullRoute}>
          <StyledInnerGrid container alignItems="center">
            <SmallAvatar alt={influencer.name} src={influencer.avatarUrl} />

            <StyledTypography variant={matchesXs ? "caption" : "body2"}>
              Visit {influencer.name}&apos;s shop
            </StyledTypography>
          </StyledInnerGrid>
        </StyledButton>
      </StyledGrid>
    );
  }

  return (
    <StyledGrid container>
      <StyledButton
        size="small"
        fullWidth
        onClick={onClick}
        endIcon={open ? openIcon : notOpenIcon}
      >
        <StyledInnerGrid container alignItems="center">
          <SmallAvatarGroup max={3}>
            {influencers.map((influencer) => (
              <SmallAvatar
                key={influencer.avatarUrl}
                alt={influencer.name}
                src={influencer.avatarUrl}
              />
            ))}
          </SmallAvatarGroup>

          <Typography variant={matchesXs ? "caption" : "body2"}>
            {matchesXs ? "Talent Shops" : "View Talent Shops"}
          </Typography>
        </StyledInnerGrid>
      </StyledButton>

      <StyledList>
        <Collapse in={open}>
          {influencers.map((influencer) => (
            <ListItem
              key={influencer.route}
              component="a"
              href={influencer.fullRoute}
              alt={`Visit ${influencer.name}'s shop`}
              button
              dense
            >
              <ListItemAvatar>
                <SmallAvatar alt={influencer.name} src={influencer.avatarUrl} />
              </ListItemAvatar>

              <StyledListItemText>
                Visit {influencer.name}&apos;s shop
              </StyledListItemText>
            </ListItem>
          ))}
        </Collapse>
      </StyledList>
    </StyledGrid>
  );
});

InfluencerLinks.propTypes = {
  influencers: PropTypes.arrayOf(PropTypes.instanceOf(Influencer)),
};

const StyledButton = styled(Button)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexGap: theme.spacing(1),
  justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const StyledInnerGrid = styled(Grid)(({ theme }) => ({
  gap: 2,
  flexGap: 2,
  flexWrap: "nowrap",
  [theme.breakpoints.up("sm")]: {
    gap: theme.spacing(1),
    flexGap: theme.spacing(1),
  },
}));

const StyledList = styled(List)(() => ({
  width: "100%",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

const notOpenIcon = <ExpandMoreIcon />;
const openIcon = <ExpandLessIcon />;

export default InfluencerLinks;
