import moment from "moment";
import momentTimezone from "moment-timezone";

export function formatDateYearStr(dateObj) {
  return moment(dateObj).format("YYYY-MM-DD");
}

export function formatDateYearUTCStr(dateObj) {
  return moment(dateObj).utc().format("YYYY-MM-DD");
}

export function formatDateYearReverseStr(dateObj) {
  return momentTimezone(dateObj)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format("YYYY-MM-DD");
}

export function formatDateTimeStr(dateObj, timeObj) {
  return formatDateTimeUTCStr(`${dateObj} ${timeObj}`);
}

export function formatDateToDateTimeUTCStr(dateObj) {
  return momentTimezone(dateObj)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .utc()
    .format("YYYY-MM-DD HH:mm:ss");
}

export function formatDateReverseTZ(dateObj) {
  return moment
    .utc(dateObj)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
}

export function formatDateMomentYear(dateObj) {
  return moment(dateObj).format("YYYY-MM-DD");
}

export function formatDateStrTimeReverseTZ(dateObj) {
  return moment
    .utc(dateObj)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format("MMM D");
}

export function formatDateToDateTimeReverseTZ(dateObj) {
  return momentTimezone(dateObj)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format("lll");
}

export function formatDateTimeUTCStr(dateObj) {
  return moment(dateObj).utc().format("YYYY-MM-DD HH:mm:ss");
}

export function formatDateStrYearTZ(dateObj, timezone) {
  return momentTimezone(dateObj).tz(timezone).format("yyyy-MM-DD");
}

export function formatDateStrOnlyTimeReverseTZ(dateObj) {
  return moment
    .utc(dateObj)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format("HH:mm");
}

export function formatDateTZ(dateObj, timezone) {
  return momentTimezone(dateObj).tz(timezone);
}
