import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Link } from "react-router-dom";
import { mutate } from "swr";
import fetcher from "../../hooks/fetcher";

const StyledLink = styled("a")(() => ({
  color: "inherit",
  textDecoration: "none",
}));

const StyledRouterLink = styled(Link)(() => ({
  color: "inherit",
  textDecoration: "none",
}));

const PreloadLink = React.memo(function PreloadLink({
  children,
  to,
  endpoints,
  isReactLink = true,
  ...linkProps
}) {
  if (!isReactLink) {
    return (
      <StyledLink {...linkProps} href={to}>
        {children}
      </StyledLink>
    );
  }

  const prefetch = () => {
    if (typeof endpoints === "string") {
      mutate(endpoints, fetcher(endpoints));
    }

    if (Array.isArray(endpoints)) {
      Promise.all(
        endpoints
          .filter(Boolean)
          .map((endpoint) => mutate(endpoint, fetcher(endpoint))),
      );
    }
  };

  return (
    <StyledRouterLink {...linkProps} to={to} onMouseEnter={prefetch}>
      {children}
    </StyledRouterLink>
  );
});

PreloadLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  endpoints: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  isReactLink: PropTypes.bool,
};

export default PreloadLink;
