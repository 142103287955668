import * as React from "react";
import PropTypes from "prop-types";
import {
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Switch,
  FormControl,
} from "@material-ui/core";

export default function ToggleSwitch({ checked, onChange, error, helperText }) {
  return (
    <FormGroup row>
      <FormControl error={error}>
        <FormControlLabel
          label="Group Shop"
          style={{ gap: 8, margin: 0 }}
          control={
            <Switch checked={checked} color="secondary" onChange={onChange} />
          }
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </FormGroup>
  );
}

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};
