import * as React from "react";
import useSWR from "swr";
import { formatWithLast4 } from "../util/formatWith";
import fetcher from "./fetcher";

const baseEndpoint = "/api/autographPurchase/user";

export default function useOrder(autographPurchaseId) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/${autographPurchaseId}`,
    fetcher,
  );

  const order = React.useMemo(() => {
    if (!data) {
      return {};
    }
    return formatWithLast4("gift_card_code")(data);
  }, [data]);

  return {
    order,
    isLoading: !order && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
