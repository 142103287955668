import Axios from "axios";

// eslint-disable-next-line max-params
export default async function apiCall(
  label,
  url,
  params,
  callback,
  method = "get",
) {
  try {
    const config = { url, method };

    if (method.toLowerCase() === "get") config.params = params;
    else {
      //PUT, POST, DELETE, and PATCH
      config.data = params;
    }

    const response = await Axios(config);
    if (response.status !== 200) callback(false, response);
    else callback(true, response);
  } catch (error) {
    console.error(`API call failed for: ${label}`);
    if (error.response) {
      console.error(`  response error encounter:`, error.response);
      callback(false, error.response, label);
    } else if (error.request) {
      console.error(`  request error encounter:`, error);
      // console.log('toJSON:',error.toJSON())
      callback(false, `Request error encounter.`, label);
    } else {
      console.error(`  exception caught:`, error);
      callback(false, error.toString(), label);
    }
  }
}
