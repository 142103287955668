import * as React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

const SearchBox = React.memo(function SearchBox({
  label,
  query,
  setQuery,
  debounceTime = 500,
  fullWidth = true,
  clear,
  color,
}) {
  const timeout = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState(query);

  const onChange = (evt) => {
    const newValue = evt.target.value;

    setSearchQuery(newValue);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      setQuery(newValue);
    }, debounceTime);
  };

  React.useEffect(() => {
    if (!clear) return;
    const { isRegistered, register } = clear;

    if (!isRegistered) {
      register(() => setSearchQuery(""));
    }
  }, [clear]);

  return (
    <TextField
      variant="outlined"
      color={color ?? "primary"}
      size="small"
      fullWidth={fullWidth}
      name="query"
      type="search"
      onChange={onChange}
      value={searchQuery}
      label={label}
    />
  );
});

SearchBox.propTypes = {
  label: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
  debounceTime: PropTypes.number,
  color: PropTypes.string,
  clear: PropTypes.shape({
    isRegistered: PropTypes.bool,
    register: PropTypes.func,
  }),
  fullWidth: PropTypes.bool,
};

export default SearchBox;
