import * as React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { PageSubheading } from "..";

const StyledPageContent = styled(Box)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.xl,
  margin: "0",
  padding: "1rem 1rem 1rem 2rem",
  position: "relative",
  width: "100%",
}));

export default function DashboardPageContent({
  contentTitle,
  children,
  ...args
}) {
  return (
    <StyledPageContent {...args}>
      {contentTitle && <PageSubheading>{contentTitle}</PageSubheading>}
      {children}
    </StyledPageContent>
  );
}

DashboardPageContent.propTypes = {
  contentTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};
