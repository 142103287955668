import * as React from "react";
import PropTypes from "prop-types";
import { Grid, CircularProgress, Typography } from "@material-ui/core";

export default function LoadingUI({
  message,
  size = "3rem",
  disableShrink = false,
}) {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <CircularProgress disableShrink={disableShrink} size={size} />
      </Grid>

      {Boolean(message) && (
        <Grid item>
          <Typography>{message}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

LoadingUI.propTypes = {
  message: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableShrink: PropTypes.bool,
};
