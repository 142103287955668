import {
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ExpandMoreRounded } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { identity } from "../../util";

function IconComponent({ className, ...props }) {
  return <ExpandMoreRounded {...props} className={clsx(className)} />;
}

IconComponent.propTypes = {
  className: PropTypes.string,
};

const SmallInput = withStyles((theme) => ({
  root: {
    background: "#fff",
    borderRadius: theme.spacing(1),
    minWidth: 165,
  },
  input: {
    fontFamily: theme.typography.interFontStack,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    padding: theme.spacing(1, 2),
    color: "#5B5B5B",
    lineHeight: "17px",
  },
}))(InputBase);

const StyledSelect = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1),
    "&:focus": {
      borderRadius: theme.spacing(1),
      background: "#fff",
      outline: `2px solid ${theme.palette.secondary.main}`,
    },
    transition: "box-shadow 0.125s ease-in-out",
    "&:hover": {
      boxShadow: "0px 2px 8px rgba(53, 143, 171, 0.1)",
    },
  },
  icon: {
    right: theme.spacing(1),
  },
}))(Select);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    background: "#fff",
    color: "#2B2B2B",
    fontFamily: theme.typography.interFontStack,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    lineHeight: "18px",
    padding: theme.spacing(1, 2),
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 3,
    borderRadius: 6.7,
    background: "#fbfbfb",
    boxShadow: "0px 2px 13.3775px rgba(0, 0, 0, 0.15)",
  },
  list: {
    display: "flex",
    flexFlow: "column nowrap",
    background: "#ebebeb",
    gap: 1,
    minWidth: 140,
    padding: 0,
    "& .Mui-selected": {
      background: theme.palette.grey[100],
    },
    "& .MuiListItem-root:hover": {
      background: theme.palette.grey[100],
    },
  },
}));

const SimpleSelect = React.memo(function SimpleSelect({
  disabled,
  error,
  formControlProps,
  getOptionLabel = identity,
  getOptionValue = identity,
  helperText,
  inputProps,
  InputProps = {},
  onChange,
  options = [],
  value,
  ...props
}) {
  const classes = useStyles();

  return (
    <FormControl error={Boolean(error)} {...formControlProps}>
      <StyledSelect
        {...props}
        input={<SmallInput {...InputProps} />}
        inputProps={inputProps}
        value={value}
        onChange={onChange}
        disabled={disabled}
        IconComponent={IconComponent}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          classes,
        }}
      >
        {options.map((option) => (
          <StyledMenuItem
            key={getOptionValue(option)}
            value={getOptionValue(option)}
          >
            {getOptionLabel(option)}
          </StyledMenuItem>
        ))}
      </StyledSelect>

      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});

SimpleSelect.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  formControlProps: PropTypes.object,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  helperText: PropTypes.string,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  menuClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default SimpleSelect;
