import * as React from "react";
import useSWR from "swr";
import { Cart } from "../domain";
import fetcher from "./fetcher";

const baseEndpoint = "/api/cart";

export default function useCart() {
  const { data, error, mutate } = useSWR(baseEndpoint, fetcher);

  const cart = React.useMemo(() => {
    if (!data) {
      return;
    }

    return Cart.from(data);
  }, [data]);

  return {
    cart,
    isLoading: !cart && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useCartProduct(productId) {
  const { cart, isLoading, isError, error, refresh } = useCart();

  const cartProduct = React.useMemo(() => {
    if (isLoading || isError) {
      return null;
    }

    return cart.cartProducts.find(
      (cartProduct) => cartProduct.productId === productId,
    );
  }, [productId, cart, isLoading, isError]);

  return {
    cartProduct,
    isLoading: !cartProduct && !isError,
    isError,
    error,
    refresh,
  };
}

export function useCartProducts() {
  const { cart, isLoading, isError, error, refresh } = useCart();

  const cartProducts = React.useMemo(() => {
    if ((!cart && isLoading) || isError) {
      return [];
    }

    return cart.cartProducts;
  }, [cart, isLoading, isError]);

  return {
    cartProducts,
    isLoading,
    isError,
    error,
    refresh,
  };
}

export function useCartInfluencer() {
  const { cart, isLoading, isError, error, refresh } = useCart();

  const cartInfluencer = React.useMemo(() => {
    if (isLoading || isError) {
      return null;
    }

    return cart.influencer;
  }, [cart, isLoading, isError]);

  return {
    cartInfluencer,
    isLoading: !cartInfluencer && !isError,
    isError,
    error,
    refresh,
  };
}

const defaultCartAttributes = {
  influencerId: null,
  trackingCode: null,
  purchaseSubscription: false,
  savePaymentInformation: true,
  pickupAtConvention: false,
  canContact: true,
};

export function useCartAttributes() {
  const { cart, isLoading, isError, error, refresh } = useCart();
  const [cartAttributes, setCartAttributes] = React.useState(
    defaultCartAttributes,
  );

  const mutate = React.useCallback(
    (mutator) => {
      if (typeof mutator === "function") {
        setCartAttributes((cartAttributes) => mutator(cartAttributes));

        refresh();
      } else {
        setCartAttributes((cartAttributes) => ({
          ...cartAttributes,
          ...mutator,
        }));

        refresh(cart?.mutateKeysRaw(mutator));
      }
    },
    [refresh, cart],
  );

  React.useEffect(() => {
    if (!cart) {
      return;
    }

    setCartAttributes({
      influencerId: cart.influencerId,
      trackingCode: cart.trackingCode,
      purchaseSubscription: cart.purchaseSubscription,
      savePaymentInformation: cart.savePaymentInformation,
      pickupAtConvention: cart.pickupAtConvention,
      canContact: cart.canContact,
    });
  }, [cart, isLoading, isError]);

  return {
    cartAttributes,
    isLoading,
    isError,
    error,
    refresh: mutate,
  };
}
