import { useEffect } from "react";

const MobileViewportHeight = () => {
  const CalcProperMobileViewportHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  window.addEventListener("resize", () => {
    CalcProperMobileViewportHeight();
  });

  useEffect(() => {
    CalcProperMobileViewportHeight();
  }, []);

  return null;
};

export default MobileViewportHeight;
