import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { useMatchesSmDown } from "../../hooks";
import SoftLargeCard from "../SoftLargeCard";
import RelativeBox from "../RelativeBox";
import BadgeIcon from "./BadgeIcon";
import MobileSoftIconCard from "./MobileSoftIconCard";
import Tooltip from "./Tooltip";
import clsx from "clsx";

const SoftIconCard = React.memo(function SoftIconCard({
  badgeNumber,
  children,
  icon,
  infoContent,
  infoTitle,
  title,
  style,
  gridSpacing = 3,
  iconPadding = { x: 2, y: 1 },
  size = "medium",
}) {
  const matchesSmDown = useMatchesSmDown();

  const displayIcon = React.useMemo(() => {
    if (badgeNumber) {
      return <BadgeIcon icon={icon} badgeNumber={badgeNumber} />;
    }

    return icon;
  }, [icon, badgeNumber]);

  if (matchesSmDown) {
    return (
      <MobileSoftIconCard
        displayIcon={displayIcon}
        infoContent={infoContent}
        infoTitle={infoTitle}
        title={title}
      >
        {children}
      </MobileSoftIconCard>
    );
  }

  return (
    <SoftLargeCard className={clsx(size)} style={style}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs="auto">
          <RelativeBox py={iconPadding.x} px={iconPadding.y}>
            {displayIcon}
          </RelativeBox>
        </Grid>

        <Grid item container xs>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6">
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>

      <Tooltip title={infoTitle} content={infoContent} />
    </SoftLargeCard>
  );
});

SoftIconCard.propTypes = {
  badgeNumber: PropTypes.number,
  icon: PropTypes.node.isRequired,
  infoContent: PropTypes.string,
  infoTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  style: PropTypes.object,
  iconPadding: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  gridSpacing: PropTypes.number,
  size: PropTypes.oneOf(["small", "medium", "large"]),
};

export default SoftIconCard;
