import * as React from "react";

export default function useJsonLd(schema) {
  return React.useEffect(() => {
    if (!schema) {
      return;
    }

    const element = document.createElement("script");

    element.setAttribute("type", "application/ld+json");
    element.innerHTML = JSON.stringify(schema);

    document.head.appendChild(element);

    return () => {
      element.remove();
    };
  }, [schema]);
}
