import { styled } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const StyledInputContainer = styled(Box)(({ theme }) => ({
  background: "white",
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${theme.palette.grey[400]}`,
  height: "1.1876em",
  padding: `10.5px 14px`,
  "&:hover": {
    borderColor: theme.palette.grey.main,
  },
  "&.complete": {
    borderColor: theme.palette.green.main,
  },
}));

export default StyledInputContainer;
