import axios from "axios";
import { useAtomValue, useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import styled from "styled-components";
import useSWR from "swr";
import { fetcher } from "../../../../hooks";
import ConfirmModal from "../../../ConfirmModal";
import LoadingIndicator from "../../../LoadingIndicator";
import AddRowsModal from "./AddRowsModal";
import UserInfluencerRow from "./UserInfluencerRow";

function useUserInfluencers(influencerId) {
  const { data, error, mutate } = useSWR(
    `/api/userInfluencer?influencer_id=${influencerId}`,
    fetcher,
  );

  const userInfluencers = React.useMemo(() => {
    if (!Array.isArray(data?.user_influencers)) {
      return [];
    }

    return data.user_influencers;
  }, [data]);

  return {
    error,
    isError: Boolean(error),
    isLoading: !error && !data,
    refresh: mutate,
    userInfluencers,
  };
}

export default function UserInfluencers({
  influencerId,
  openInviteModal,
  parentScrollRef,
  setErrorText,
  TriggerAlert,
  userInfluencerAddStateAtom,
  userInviteModalAtom,
}) {
  const { userInfluencers, isLoading, isError, refresh } =
    useUserInfluencers(influencerId);

  const [shopUserUpdate, setShopUserUpdate] = React.useState({});
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [description, setDescription] = React.useState("no desc");
  const inviteModalOpen = useAtomValue(userInviteModalAtom);

  const [showCreateNewUserInfluencer, setShowCreateNewUserInfluencer] = useAtom(
    userInfluencerAddStateAtom,
  );

  const updateUserInfluencerRow = async () => {
    if (!shopUserUpdate) {
      return;
    }

    try {
      await axios.post(`/api/userInfluencer`, {
        influencer_id: influencerId,
        shopUserUpdate,
      });

      refresh();
      TriggerAlert({ message: "Saved" });
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data) {
        setErrorText(err.response.data);
      } else {
        setErrorText("Something went wrong. Please try again.");
      }
    }
  };

  React.useEffect(() => {
    refresh();
  }, [refresh, inviteModalOpen]);

  React.useEffect(() => {
    if (isError) {
      setErrorText("Something went wrong. Please try again.");
    }
  }, [setErrorText, isError]);

  React.useEffect(() => {
    if (!parentScrollRef?.current) {
      return;
    }

    requestAnimationFrame(() => {
      if (parentScrollRef.current) {
        // eslint-disable-next-line no-param-reassign
        parentScrollRef.current.scrollTop =
          parentScrollRef.current.scrollHeight;
      }
    });
  }, [parentScrollRef, userInfluencers]);

  return (
    <Container>
      {isLoading ? (
        <LoadingIndicatorContainer>
          <LoadingIndicator />
        </LoadingIndicatorContainer>
      ) : (
        <React.Fragment>
          <UserInfluencerSectionHeader>
            <HeaderText>Email</HeaderText>
            <HeaderText>Shop Owner</HeaderText>
            <HeaderText>Can edit</HeaderText>
            <HeaderText>Emails enabled</HeaderText>
            <HeaderText style={{ textAlign: "center" }}>Since</HeaderText>
          </UserInfluencerSectionHeader>

          <UserInfluencerSection>
            {userInfluencers.length > 0 ? (
              userInfluencers.map((userInfluencer) => (
                <UserInfluencerRow
                  key={userInfluencer.user_influencer_id}
                  data={userInfluencer}
                  setShopUserUpdate={setShopUserUpdate}
                  setShowConfirmModal={setShowConfirmModal}
                  setDescription={setDescription}
                />
              ))
            ) : (
              <NoUserInfluencersYet>
                No Users Have Access Yet
              </NoUserInfluencersYet>
            )}
            <ShopUserButton
              className="new-shop-user"
              onClick={() => setShowCreateNewUserInfluencer(true)}
            >
              Add User
            </ShopUserButton>
          </UserInfluencerSection>
        </React.Fragment>
      )}
      {showCreateNewUserInfluencer && (
        <AddRowsModal
          getUserInfluencerData={refresh}
          influencerId={influencerId}
          onClose={() => setShowCreateNewUserInfluencer(false)}
          openInviteModal={openInviteModal}
          TriggerAlert={TriggerAlert}
        />
      )}
      {showConfirmModal && (
        <ConfirmModal
          action={updateUserInfluencerRow}
          description={description}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
    </Container>
  );
}

UserInfluencers.propTypes = {
  influencerId: PropTypes.number.isRequired,
  openInviteModal: PropTypes.func.isRequired,
  parentScrollRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
  setErrorText: PropTypes.func.isRequired,
  TriggerAlert: PropTypes.func.isRequired,
  userInfluencerAddStateAtom: PropTypes.object,
  userInviteModalAtom: PropTypes.object,
};

const Container = styled.div`
  min-width: 400px;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const LoadingIndicatorContainer = styled.div`
  position: relative;
  height: 30px;
  width: 30px;
  margin: auto;
`;

const UserInfluencerSectionHeader = styled.div`
  border: 1px solid rgb(220, 220, 220);
  display: grid;
  grid-template-columns: 200px repeat(3, minmax(80px, 1fr)) minmax(100px, 1fr);
  padding: 0 8px;
  gap: 8px;
  text-align: center;
`;

const UserInfluencerSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(100, 100, 100);
  border: 1px solid rgb(220, 220, 220);
`;

const NoUserInfluencersYet = styled.b`
  margin-top: 20px;
  align-self: center;
  margin-bottom: 20px;
`;

const HeaderText = styled.p`
  color: rgba(0, 0, 0, 0.54);
  margin: 8px 0;
  font-size: 0.75rem;
  font-weight: bold;
  text-align: left;
  line-height: 1.2;
`;

const ShopUserButton = styled.button`
  width: 53%;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 20px;
  font-size: 15px;
  margin-bottom: 0.5rem;
  padding: 2px 20px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-weight: normal;
  background-color: rgba(100, 140, 190);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1),
      inset 0 0 20px 20px rgba(255, 255, 255, 0.2);
  }
  &:active {
    background-color: rgb(240, 240, 240);
  }
  &.new-shop-user {
    width: 32%;
    background: rgba(255, 255, 255, 0.7);
    color: rgb(80, 80, 80);
    margin-top: 0.5rem;
  }
`;
