import { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";

function useTimer(startTime) {
  const [time, setTime] = useState(startTime);
  const intervalRef = useRef(null);
  const hasTimerEndedRef = useRef(time <= 0);

  const startTimer = useCallback(() => {
    if (!hasTimerEndedRef.current && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        if (hasTimerEndedRef.current) {
          return clearInterval(intervalRef.current);
        }

        setTime((time) => {
          const newTime = Math.max(time - 1, 0);

          if (newTime <= 0) {
            hasTimerEndedRef.current = true;
          }

          return newTime;
        });
      }, 1000);
    }
  }, []);

  const stopTimer = () => {
    clearInterval(intervalRef);
  };

  useEffect(() => {
    clearInterval(intervalRef.current);
    startTimer();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [startTimer]);

  return {
    time,
    stopTimer,
  };
}

useTimer.propTypes = {
  startTime: PropTypes.number,
};

useTimer.defaultProps = {
  startTime: 600,
};

export default useTimer;
