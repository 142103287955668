import { G, S, flow } from "@mobily/ts-belt";
import useSWR from "swr";
import fetcher from "./fetcher";

const baseEndpoint = "/api/product";
const normalize = flow(S.trim, S.toLowerCase);

function composeURL({ name, influencerName, includeArchived }) {
  const searchParams = new URLSearchParams({
    include_archived: Boolean(includeArchived),
  });

  if (G.isString(name) && name.length > 0) {
    searchParams.set("name", normalize(name));
  }

  if (G.isString(influencerName) && influencerName.length > 0) {
    searchParams.set("influencer_name", normalize(influencerName));
  }

  return `${baseEndpoint}?${searchParams}`;
}

export default function useProducts(
  query = { name: "", influencerName: "", includeArchived: false },
) {
  const { data, error, mutate, isValidating } = useSWR(
    composeURL(query),
    fetcher,
    {
      keepPreviousData: true,
    },
  );

  return {
    error,
    isError: Boolean(error),
    isLoading: !data && !error,
    isValidating,
    products: data,
    refresh: mutate,
  };
}
