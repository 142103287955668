import axios from "axios";
import PropTypes from "prop-types";
import * as React from "react";
import { actionCreators, useStateValue } from "../globalState";
import { getParameter, logger } from "../util";

const actionEndpoint = "/api/trackingPageAction";
const sessionEndpoint = "/api/trackingPageSession";

const service = {
  /*
   * sendTrackingAction sends a tracking action to the backend, for a given
   * trackingUUID, action and price.
   *
   * @param {object} initialization object { trackingUUID, action, price }
   */
  async sendTrackingAction({ trackingUUID, action, price }) {
    try {
      const formData = new FormData();

      formData.set("uuid", trackingUUID);
      formData.set("action", action);

      if (price !== undefined) {
        formData.set("price", price);
      }

      await axios.post(actionEndpoint, formData);
    } catch (err) {
      logger.error(err);
    }
  },
  /*
   * getTrackingUUID returns a new trackingUUID for a session
   *
   * @returns {string | undefined} trackingUUID
   */
  async getTrackingUUID() {
    try {
      const formData = new FormData();
      const streamilyTrackingCode = getParameter("stc");

      formData.set("route", window.location.pathname);

      if (streamilyTrackingCode && streamilyTrackingCode.length < 20) {
        formData.set("tracking_code", streamilyTrackingCode.trim());
      }

      const { data } = await axios.post(sessionEndpoint, formData);

      return data;
    } catch (err) {
      logger.error(err);

      return null;
    }
  },
};

const trackingContext = React.createContext();

/*
 * useTracking allows using the trackingContext. Returns an object with the
 * shape: {
 *   track: function,
 *   refreshSession: function,
 *   setTrackingCode: function,
 * }
 */
export function useTracking() {
  return React.useContext(trackingContext);
}

/*
 * TrackingProvider maintains session information and provides functions to
 * track certain actions to children using the useTracking hook.
 */
const TrackingProvider = React.memo(function TrackingProvider({ children }) {
  const [state, dispatch] = useStateValue();

  /*
   * track allows tracking an action and a price
   *
   * @param {object} initialization object { action, price }
   */
  const track = React.useCallback(
    ({ action, price }) => {
      if (!state.trackingUUID) {
        return;
      }

      service.sendTrackingAction({
        trackingUUID: state.trackingUUID,
        action,
        price,
      });
    },
    [state.trackingUUID],
  );

  /*
   * refreshSession refreshes the trackingUUID in globalState. This is important
   * for distinguishing checkout vs regular pages.
   */
  const refreshSession = React.useCallback(async () => {
    try {
      const trackingUUID = await service.getTrackingUUID();
      dispatch(actionCreators.createSetTrackingUUID(trackingUUID));
    } catch {
      dispatch(actionCreators.createSetTrackingUUID(null));
    }
  }, [dispatch]);

  React.useEffect(() => {
    refreshSession();
  }, [refreshSession]);

  /*
   * setTrackingCode retrives the trackingCode (if any) from query params and
   * sets it in globalState
   */
  const setTrackingCode = React.useCallback(() => {
    const streamilyTrackingCode = getParameter("stc");

    if (streamilyTrackingCode && streamilyTrackingCode.length < 20) {
      dispatch(actionCreators.createSetTrackingCode(streamilyTrackingCode));
    }
  }, [dispatch]);

  React.useEffect(() => {
    setTrackingCode();
  }, [setTrackingCode]);

  const value = React.useMemo(() => {
    return {
      track,
      refreshSession,
      setTrackingCode,
    };
  }, [track, refreshSession, setTrackingCode]);

  return (
    <trackingContext.Provider value={value}>
      {children}
    </trackingContext.Provider>
  );
});

TrackingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TrackingProvider;
