import axios from "axios";
import User from "./user";
import logger from "../util/logger";

const endpoint = "/api/user";

export default class Salesperson extends User {
  #percentage;
  #talentId;
  #created;
  #rawUserSalesperson;

  /*
   * UPDATE_TYPE is the type of update to disambiguate in the usersCtl patch update
   * controller switch statement.
   */
  static get UPDATE_TYPE() {
    return "salespeople";
  }

  /*
   * The TALENT_MAX_SALESPEOPLE is a limit for the max salespeople that can be
   * associated with a talent.
   */
  static get TALENT_MAX_SALESPEOPLE() {
    return 5;
  }

  /*
   * The DEFAULT_PERCENTAGE is the initial percentage when creating a new
   * salesperson to add to a talent.
   */
  static get DEFAULT_PERCENTAGE() {
    return 100;
  }

  static get MAX_PERCENTAGE() {
    return 100;
  }

  static get MIN_PERCENTAGE() {
    return 0;
  }

  /*
   * eq determines if two salespeople are equivalent
   *
   * @param {Salesperson} salespersonA
   * @param {Salesperson} salespersonB
   *
   * @returns {boolean} result
   */
  static eq(salespersonA, salespersonB) {
    return (
      salespersonA.userId === salespersonB.userId &&
      salespersonA.talentId === salespersonB.talentId &&
      salespersonA.percentage === salespersonB.percentage
    );
  }

  /*
   * from creates a new Salesperson from a rawUser
   *
   * @param {object} rawUser
   *
   * @returns {Salesperson}
   */
  static from(rawUser) {
    return new Salesperson(rawUser);
  }

  /*
   * fromUser creates a new Salesperson from a User
   *
   * @param {User} user
   * @param {object} salespersonOptions { talentId, percentage }
   *
   * @returns {Salesperson}
   */
  static fromUser(
    user,
    salespersonOptions = {
      talentId: 0,
      percentage: Salesperson.DEFAULT_PERCENTAGE,
    },
  ) {
    const { talentId, percentage } = salespersonOptions;

    if (!salespersonOptions?.talentId) {
      throw new TypeError("salespersonOptions must include talentId");
    }

    const rawUser = { ...user.raw, percentage, talent_id: talentId };

    return new Salesperson(rawUser);
  }

  /*
   * updateUser patches a user with relevant salesperson updates
   *
   * @param {object} initialization object { user, updates }
   *
   * @returns {object} result of updates or errors
   */
  static async updateUser({ user, updates }) {
    try {
      const { data } = await axios.patch(`${endpoint}/${user.userId}`, updates);

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return { errors: [{ message: "Unable to update user" }] };
    }
  }

  /*
   * create a Salesperson from a rawUser
   *
   * @param {object} rawUser
   *
   * @returns {Salesperson} new Salesperson
   */
  constructor(rawUser) {
    const rawUserCopy = { ...rawUser };
    super(rawUser);
    this.#rawUserSalesperson = rawUserCopy;
    this.#percentage = rawUserCopy.percentage ?? 100;
    this.#talentId = rawUserCopy.talent_id;
    this.#created = rawUserCopy.salesperson_created
      ? new Date(rawUserCopy.salesperson_created)
      : new Date();
  }

  /*
   * percentage is the split of onboarding responsibility this user has for their
   * associated talent.
   */
  get percentage() {
    return this.#percentage;
  }

  /*
   * talent associated with this salesperson
   */
  get talentId() {
    return this.#talentId;
  }

  get created() {
    return this.#created;
  }

  /*
   * returns a new Salesperson with the key mutated to value
   *
   * @param {string} key
   * @param {any} value
   *
   * @returns {Salesperson} new Salesperson
   */
  mutate(key, value) {
    if (key === "percentage") {
      return this.setPercentage(value);
    }

    if (key === "talentId") {
      return Salesperson.from({
        ...this.#rawUserSalesperson,
        talent_id: value,
      });
    }

    return super.mutate(key, value);
  }

  /*
   * setPercentage sets a Salesperson's percentage to a new value
   *
   * @param {number} percentage
   */
  setPercentage(percentage) {
    const boundedPercentage = Math.min(
      Math.max(percentage, Salesperson.MIN_PERCENTAGE),
      Salesperson.MAX_PERCENTAGE,
    );

    return new Salesperson({
      ...this.#rawUserSalesperson,
      percentage: boundedPercentage,
    });
  }

  /*
   * toUserSalesperson returns a bare representation of a salesperson for user
   * update purposes.
   *
   * @returns {object} userSalesperson object
   */
  toUserSalesperson() {
    return {
      user_id: this.userId,
      talent_id: this.talentId,
      percentage: this.percentage,
    };
  }

  toJSON() {
    return {
      ...super.toJSON(),
      talentId: this.talentId,
      percentage: this.percentage,
    };
  }

  eq(salespersonB) {
    return Salesperson.eq(this, salespersonB);
  }
}
