import { G } from "@mobily/ts-belt";
import * as React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";

const baseEndpoint = "/api/product";

export default function useProductSearchAdmin(influencerId) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}?${new URLSearchParams({ influencer_id: influencerId })}`,
    fetcher,
  );

  const products = React.useMemo(() => {
    if (!data?.products) {
      return [];
    }

    const { products } = data;

    if (!G.isArray(products)) {
      return [];
    }

    return products;
  }, [data]);

  return {
    products,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
