import * as React from "react";
import PropTypes from "prop-types";
import { Avatar, Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const LiveAvatar = React.memo(function LiveAvatar({ isLive, avatarUrl }) {
  return (
    <StyledBox className={isLive ? "live" : ""}>
      <StyledAvatar src={avatarUrl} />

      {Boolean(isLive) && <StyledLiveBadge>LIVE</StyledLiveBadge>}
    </StyledBox>
  );
});

LiveAvatar.propTypes = {
  isLive: PropTypes.bool,
  avatarUrl: PropTypes.string,
};

const StyledAvatar = styled(Avatar)(() => ({
  width: 45,
  height: 45,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  border: "2px solid transparent",
  position: "relative",
  borderRadius: "50%",
  "&.live": {
    borderColor: theme.palette.red.main,
  },
}));

const StyledLiveBadge = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontWeight: theme.typography.fontWeightBold,
  position: "absolute",
  color: "white",
  background: theme.palette.red.main,
  lineHeight: 1.75,
  borderRadius: theme.spacing(0.5),
  bottom: -6,
  left: 5,
  right: 5,
  width: 35,
  textAlign: "center",
  fontSize: 8,
}));

export default LiveAvatar;
