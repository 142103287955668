import * as React from "react";
import { styled } from "@material-ui/core/styles";
import getCookie from "../util/getCookie";

const StyledBox = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  bottom: "1vw",
  maxWidth: 500,
  padding: "12px 24px",
  borderRadius: 20,
  position: "fixed",
  right: "1vw",
  width: "100%",
  zIndex: 9999,
  background: "#000",
  color: theme.palette.getContrastText("#000"),
  display: "flex",
  alignItems: "center",
  border: "1px solid rgba(255,255,255,0.2)",
  boxShadow: theme.boxShadow.big,
  gap: theme.spacing(1),
  lineHeight: 1,
  opacity: 0,
  transform: "translateY(50px)",
  transition: "all 0.2s ease-in-out",

  "& h1": {
    margin: 0,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(0.5),
  },

  "& small": {
    fontSize: theme.typography.pxToRem(12),
  },

  "& a": {
    color: "inherit",
  },

  "& button": {
    padding: "12px 14px",
    border: "none",
    background: "#fff",
    color: theme.palette.getContrastText("#fff"),
    borderRadius: 12,
    font: "inherit",
    fontWeight: 600,
  },

  "& button:hover, & button:focus": {
    filter: "brightness(0.9)",
    cursor: "pointer",
  },

  "&.show": {
    opacity: 1,
    transform: "translateY(0)",
  },
}));

export default function CookieNotice() {
  const boxRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [didAccept, setDidAccept] = React.useState(
    Boolean(getCookie("cookie-notice")),
  );
  const [boxClass, setBoxClass] = React.useState("");

  React.useEffect(() => {
    if (didAccept || !boxRef.current) return;

    setBoxClass("show");
  }, [didAccept]);

  if (didAccept) {
    return null;
  }

  const handleAccept = async () => {
    if (isLoading) return;

    setIsLoading(true);

    window
      .fetch("/api/cookie-notice", { method: "POST" })
      .then((res) => {
        if (res.ok) {
          setBoxClass("");
          setTimeout(() => {
            setDidAccept(true);
          }, 200);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <StyledBox component="header" ref={boxRef} className={boxClass}>
      <div>
        <h1>WE CARE ABOUT YOUR PRIVACY</h1>
        <small>
          We use cookies and similar technologies to provide the best experience
          on our website. <a href="/privacy-policy#cookies">Privacy Policy</a>
        </small>
      </div>

      <button onClick={handleAccept} disabled={isLoading} type="button">
        OK
      </button>
    </StyledBox>
  );
}
