import * as React from "react";
import PropTypes from "prop-types";
import StyledTooltip from "../StyledTooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import InfoFilledIcon from "@material-ui/icons/InfoRounded";
import { styled } from "@material-ui/core/styles";

const TooltipContent = React.memo(function TooltipContent({ title, content }) {
  return (
    <>
      {Boolean(title) && <StyledTooltip.Title>{title}</StyledTooltip.Title>}
      <StyledTooltip.Content variant="body2" component="p">
        {content}
      </StyledTooltip.Content>
    </>
  );
});

TooltipContent.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
};

const defaultIcon = <InfoOutlinedIcon />;
const filledIcon = <InfoFilledIcon />;

const DefaultContainer = styled("span")(() => ({
  "&.small svg": {
    width: 15,
    height: 15,
  },
  "&.medium svg": {
    width: 20,
    height: 20,
  },
  "&.large svg": {
    width: 25,
    height: 25,
  },
}));

const InfoTooltip = React.memo(function InfoTooltip({
  title,
  content,
  size = "medium",
  placement = "top-start",
  filled = false,
  icon = filled ? filledIcon : defaultIcon,
  Container = DefaultContainer,
  ContainerProps = {},
  style,
}) {
  return (
    <StyledTooltip
      style={style}
      title={<TooltipContent title={title} content={content} />}
      arrow
      interactive
      placement={placement}
      enterTouchDelay={0}
    >
      <Container className={size} {...ContainerProps}>
        {icon}
      </Container>
    </StyledTooltip>
  );
});

InfoTooltip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  placement: PropTypes.oneOf([
    "bottom-end",
    "bottom-start",
    "bottom",
    "left-end",
    "left-start",
    "left",
    "right-end",
    "right-start",
    "right",
    "top-end",
    "top-start",
    "top",
  ]),
  icon: PropTypes.node,
  Container: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
    PropTypes.object,
  ]),
  ContainerProps: PropTypes.object,
  filled: PropTypes.bool,
  style: PropTypes.object,
};

export default InfoTooltip;
