import { styled } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const StreamilyButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  "&.offline": {
    background: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[500],
  },
  "&.offline::before, &.offline::after": {
    display: "none",
  },
  "&.offline:disabled": {
    background: theme.palette.grey[200],
    color: theme.palette.grey[900],
    boxShadow: "none",
  },
  "&.largerMobile": {
    minHeight: "3.5rem",
  },
  "&.blue": {
    background: theme.gradient.lightBlue,
  },
  "&.blue:hover": {
    background: theme.gradient.lightBlueHover,
  },
}));

export default StreamilyButton;
