import * as React from "react";
import PropTypes from "prop-types";
import {
  ListItemText,
  ListItem,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import { useCurrentUser } from "../../hooks";

const UserDetails = React.forwardRef(function UserDetails(
  { dense = true, fullName = false, email = false, avatar = false },
  ref,
) {
  const currentUser = useCurrentUser();

  return (
    <ListItem dense={dense} divider ref={ref}>
      {avatar && (
        <ListItemAvatar>
          <Avatar />
        </ListItemAvatar>
      )}

      {fullName && email && currentUser.fullName && (
        <ListItemText
          primary={currentUser.fullName}
          secondary={currentUser.email}
        />
      )}

      {fullName && email && !currentUser.fullName && (
        <ListItemText primary={currentUser.email} />
      )}

      {!fullName && email && currentUser.email && (
        <ListItemText primary={currentUser.email} />
      )}
    </ListItem>
  );
});

UserDetails.propTypes = {
  dense: PropTypes.bool,
  fullName: PropTypes.bool,
  email: PropTypes.bool,
  avatar: PropTypes.bool,
};

export default UserDetails;
