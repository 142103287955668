import * as React from "react";

export default function ShopBuilderIcon({ width = 17, height = 17 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2355 4.39673C10.0909 4.54426 10.0099 4.74262 10.0099 4.94921C10.0099 5.15581 10.0909 5.35416 10.2355 5.5017L11.4983 6.76453C11.6458 6.90914 11.8442 6.99015 12.0508 6.99015C12.2574 6.99015 12.4557 6.90914 12.6033 6.76453L15.5788 3.78899C15.9757 4.66601 16.0959 5.64316 15.9233 6.59021C15.7507 7.53727 15.2937 8.40924 14.613 9.08993C13.9323 9.77062 13.0603 10.2277 12.1133 10.4003C11.1662 10.5728 10.1891 10.4526 9.31203 10.0558L3.85819 15.5096C3.5442 15.8236 3.11834 16 2.67429 16C2.23024 16 1.80438 15.8236 1.49039 15.5096C1.1764 15.1956 1 14.7698 1 14.3257C1 13.8817 1.1764 13.4558 1.49039 13.1418L6.94423 7.68797C6.54735 6.81095 6.42719 5.8338 6.59974 4.88675C6.7723 3.93969 7.22938 3.06772 7.91007 2.38703C8.59076 1.70634 9.46273 1.24926 10.4098 1.0767C11.3568 0.904149 12.334 1.02431 13.211 1.42119L10.2434 4.38883L10.2355 4.39673Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
