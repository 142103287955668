export const INFLUENCER_TYPE = "influencer";
export const PRODUCT_TYPE = "product";

export const LOOK_TALL = "tall";
export const LOOK_WIDE = "wide";
export const LOOK_PRODUCT = "product";

export const GUTTER = 32;
export const M_GUTTER = 16;

// Desktop
export const TALL_COLUMN_WIDTH = 244;
export const WIDE_COLUMN_WIDTH = 344;
export const PRODUCT_COLUMN_WIDTH = 300;
export const PRODUCT_CARD_WIDTH = 264;
export const TALL_CARD_WIDTH = 208;
export const WIDE_CARD_WIDTH = 308;
export const MIN_GRID_GAP = 16;
export const TALL_CARD_HEIGHT = 342;
export const PRODUCT_CARD_HEIGHT = 352;
export const WIDE_CARD_HEIGHT = 208;
export const TALL_IMAGE_HEIGHT = 280;
export const WIDE_IMAGE_HEIGHT = 154;
export const TALL_IMAGE_WIDTH = 208;
export const WIDE_IMAGE_WIDTH = 308;

// Mobile
export const M_TALL_COLUMN_WIDTH = 192;
export const M_WIDE_COLUMN_WIDTH = 272;
export const M_PRODUCT_COLUMN_WIDTH = 236;
export const M_PRODUCT_CARD_WIDTH = 206;
export const M_TALL_CARD_WIDTH = 162;
export const M_WIDE_CARD_WIDTH = 242;
export const M_MIN_GRID_GAP = 8;
export const M_TALL_CARD_HEIGHT = 282;
export const M_PRODUCT_CARD_HEIGHT = 310;
export const M_WIDE_CARD_HEIGHT = 182;
export const M_TALL_IMAGE_HEIGHT = 216;
export const M_WIDE_IMAGE_HEIGHT = 126;
export const M_TALL_IMAGE_WIDTH = 162;
export const M_WIDE_IMAGE_WIDTH = 242;

export function columnWidthFor({ isMobile, look }) {
  if (look === LOOK_TALL) {
    return isMobile ? M_TALL_COLUMN_WIDTH : TALL_COLUMN_WIDTH;
  }

  if (look === LOOK_PRODUCT) {
    return isMobile ? M_PRODUCT_COLUMN_WIDTH : PRODUCT_COLUMN_WIDTH;
  }

  return isMobile ? M_WIDE_COLUMN_WIDTH : WIDE_COLUMN_WIDTH;
}

export function imageHeightFor({ isMobile, look = LOOK_TALL }) {
  if (look === LOOK_TALL) {
    return isMobile ? M_TALL_IMAGE_HEIGHT : TALL_IMAGE_HEIGHT;
  }

  return isMobile ? M_WIDE_IMAGE_HEIGHT : WIDE_IMAGE_HEIGHT;
}

export function imageWidthFor({ isMobile, look = LOOK_TALL }) {
  if (look === LOOK_TALL) {
    return isMobile ? M_TALL_IMAGE_WIDTH : TALL_IMAGE_WIDTH;
  }

  return isMobile ? M_WIDE_IMAGE_WIDTH : WIDE_IMAGE_WIDTH;
}

export function cardHeightFor({ isMobile, look = LOOK_TALL }) {
  if (look === LOOK_TALL) {
    return isMobile ? M_TALL_CARD_HEIGHT : TALL_CARD_HEIGHT;
  }

  if (look === LOOK_PRODUCT) {
    return isMobile ? M_PRODUCT_CARD_HEIGHT : PRODUCT_CARD_HEIGHT;
  }

  return isMobile ? M_WIDE_CARD_HEIGHT : WIDE_CARD_HEIGHT;
}

export function cardWidthFor({ isMobile, look = LOOK_TALL }) {
  if (look === LOOK_TALL) {
    return isMobile ? M_TALL_CARD_WIDTH : TALL_CARD_WIDTH;
  }

  if (look === LOOK_PRODUCT) {
    return isMobile ? M_PRODUCT_CARD_WIDTH : PRODUCT_CARD_WIDTH;
  }

  return isMobile ? M_WIDE_CARD_WIDTH : WIDE_CARD_WIDTH;
}

export function minGridGapFor({ isMobile }) {
  return isMobile ? M_MIN_GRID_GAP : MIN_GRID_GAP;
}

export function isTall(look) {
  return look === LOOK_TALL;
}

export function isWide(look) {
  return look === LOOK_WIDE;
}

export function isProduct(look) {
  return look === LOOK_PRODUCT;
}

export function gutterFor({ isMobile }) {
  if (isMobile) {
    return M_GUTTER;
  }

  return GUTTER;
}
