import * as React from "react";
import { G } from "@mobily/ts-belt";
import { compose } from "./fp";

const splitChunk = (char, text, splitBefore) =>
  // eslint-disable-next-line max-params
  text.split(char).reduce((result, chunk, i, arr) => {
    // If there is only one chunk, don't add a <wbr />.
    if (arr.length === 1) {
      result.push(chunk);
      return result;
    }

    // If there are multiple chunks, add a <wbr /> after each chunk.
    const key = `${chunk}-${i}`;
    const jsx = <wbr key={key} />;

    if (splitBefore) {
      if (i === 0) result.push(chunk, jsx);
      else result.push(`${char}${chunk}`, jsx);
    } else {
      if (i === arr.length - 1) result.push(chunk, jsx);
      else result.push(`${chunk}${char}`, jsx);
    }

    return result;
  }, []);

const addBreaks = (char, splitBefore) => (textArray) =>
  textArray.flatMap((text) =>
    G.isString(text) ? splitChunk(char, text, splitBefore) : text,
  );

export default function addWordBreakHints(chars, opts = { splitBefore: true }) {
  return compose(...chars.map((char) => addBreaks(char, opts.splitBefore)));
}
