import { Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { Influencer } from "../../../domain";
import Header from "./Header";
import ShopBuilderProduct from "./ShopBuilderProduct";

const StyledList = styled("div")(({ theme }) => ({
  display: "grid",
  gridGap: theme.spacing(4),
  gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
  },
  "&.empty": {
    gridTemplateColumns: "1fr",
  },
}));

const List = React.memo(function List({
  action,
  emptyLabel,
  filter,
  influencer,
  onClose,
  productFilter,
  refresh,
  salesCountMap = {},
  subtitle,
  title,
}) {
  const products = React.useMemo(() => {
    if (!influencer) {
      return null;
    }

    const filteredProducts = influencer.products?.filter(filter);

    if (
      !influencer.products?.length ||
      !filteredProducts ||
      filteredProducts.length === 0
    ) {
      return (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          minHeight={240}
          width="100%"
        >
          <StyledTypography align="center" variant="h6">
            {emptyLabel}
          </StyledTypography>
        </Box>
      );
    }

    return (
      <StyledList>
        {filteredProducts.map((product, i) => (
          <ShopBuilderProduct
            influencer={influencer}
            isFirstProduct={i === 0}
            isLastProduct={i === filteredProducts.length - 1}
            key={product.productId}
            onClose={onClose}
            product={product}
            refresh={refresh}
            salesCount={salesCountMap[product.productId]}
          />
        ))}
      </StyledList>
    );
  }, [emptyLabel, filter, influencer, onClose, refresh, salesCountMap]);

  return (
    <>
      <Box pb={2} display="flex" gridGap="4px" flexDirection="column">
        <Header
          title={title}
          subtitle={subtitle}
          action={!influencer.isGroupShop ? action : null}
        />

        {productFilter}
      </Box>

      {products}
    </>
  );
});

List.propTypes = {
  action: PropTypes.node,
  emptyLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  filter: PropTypes.func,
  influencer: PropTypes.instanceOf(Influencer),
  onClickAdd: PropTypes.func,
  onClose: PropTypes.func,
  productFilter: PropTypes.node,
  refresh: PropTypes.func,
  salesCountMap: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.grey[400],
  fontWeight: theme.typography.semiBold,
}));

export default List;
