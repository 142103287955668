import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import AffiliateLinksChangeLog from "./AffiliateLinksChangeLog";
import AffiliateLinksList from "./AffiliateLinksList";
import NewAffiliateLink from "./NewAffiliateLink";
import { StyledDialog, StyledTitle } from "./styles";

export default function AffiliateLinkModal({
  open,
  onClose,
  influencerId,
  influencerRoute,
}) {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledTitle>Affiliate Links</StyledTitle>

      <Typography variant="body2" color="textSecondary">
        Any sales made through these links will have a percentage deducted from
        the Talent&apos;s eligible payout. This will not happen unless you have
        set the links in this modal.
      </Typography>

      <Box my={2}>
        <AffiliateLinksList
          influencerId={influencerId}
          influencerRoute={influencerRoute}
        />
      </Box>

      <Box display="flex" justifyContent="space-between">
        <AffiliateLinksChangeLog influencerId={influencerId} />

        <NewAffiliateLink influencerId={influencerId} />
      </Box>
    </StyledDialog>
  );
}

AffiliateLinkModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  influencerId: PropTypes.number.isRequired,
  influencerRoute: PropTypes.string.isRequired,
};
