import * as React from "react";
import { Box, Menu, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AccountActions from "../AccountActions";
import UserDetails from "../UserDetails";

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: 28,
    marginBottom: 2,
    objectFit: "cover",
  },
  button: {
    marginLeft: 8,
    padding: 0,
  },
  avatar: {
    background: "none",
    verticalAlign: "middle",
    height: 33,
    width: 33,
    marginTop: 9,
    marginBottom: 9,
    borderRadius: "50%",
    boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    marginLeft: 12,
    cursor: "pointer",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
  },
}));

const AccountDashboardMenu = React.memo(function AccountDashboardMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const buttonRef = React.useRef();
  const styles = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setAnchorEl(buttonRef.current);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        className={styles.button}
        ref={buttonRef}
        onClick={handleOpen}
        aria-haspopup="true"
        aria-controls="account-menu"
      >
        <Box mr="10px" display="flex" gridGap={4} alignItems="center">
          <span className={styles.avatar}>
            <PersonIcon />
          </span>

          {isOpen && <ArrowDropUpIcon />}
          {!isOpen && <ArrowDropDownIcon />}
        </Box>
      </Button>

      <Menu
        className={styles.menu}
        id="account-menu"
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <UserDetails fullName email />
        <AccountActions p={0} onClick={handleClose} />
      </Menu>
    </>
  );
});

export default AccountDashboardMenu;
