import * as React from "react";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import InfoTooltip from "../InfoTooltip";

const Tooltip = React.memo(function Tooltip({ title, content }) {
  if (!content) {
    return null;
  }

  return (
    <StyledBoxRight>
      <InfoTooltip title={title} content={content} />
    </StyledBoxRight>
  );
});

export default Tooltip;

const StyledBoxRight = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));
