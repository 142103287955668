import * as React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledTypography = styled(Typography)(() => ({
  userSelect: "none",
}));

const SmallCheckbox = React.forwardRef(function SmallCheckbox(
  {
    checked,
    disabled,
    onChange,
    label,
    variant = "caption",
    name,
    labelPlacement,
    style,
    value,
    ...props
  },
  ref,
) {
  return (
    <FormControlLabel
      value={checked}
      labelPlacement={labelPlacement}
      control={
        <Checkbox
          style={style}
          checked={checked}
          color="default"
          name={name}
          onChange={onChange}
          size="small"
          value={value}
        />
      }
      disabled={disabled}
      inputRef={ref}
      label={<StyledTypography variant={variant}>{label}</StyledTypography>}
      {...props}
    />
  );
});

SmallCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  labelPlacement: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
};

export default SmallCheckbox;
