import { Box, Dialog, IconButton, Typography } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { styled } from "@material-ui/core/styles";
import { CloseRounded } from "@material-ui/icons";
import { A, G } from "@mobily/ts-belt";
import * as React from "react";
import { useLocation } from "react-router-dom";
import useSWR from "swr";
import { fetcher, useActiveInfluencer, useCurrentUser } from "../hooks";

function useEducationalMessages() {
  const { data, error, mutate } = useSWR("/api/educationalMessage", fetcher);

  const educationalMessages = React.useMemo(() => {
    if (G.isArray(data?.messages) && A.isNotEmpty(data?.messages)) {
      return data.messages;
    }

    return [];
  }, [data]);

  return {
    educationalMessages,
    error,
    isError: error,
    isLoading: !error && !data,
    refresh: mutate,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDiv = styled("div")(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.8)",
  backdropFilter: "blur(10px)",
  borderRadius: theme.spacing(2),
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  padding: theme.spacing(3, 3),
  position: "relative",
  maxWidth: 522,
  width: "100%",
  ...theme.typography.body1,

  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(3, 3),
    "&.MuiDialog-paper": {
      margin: theme.spacing(1),
    },
  },

  "& .iconButton": {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

function EducationalModal() {
  const activeInfluencer = useActiveInfluencer();
  const [currentMessage, setCurrentMessage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { educationalMessages, refresh } = useEducationalMessages();
  const location = useLocation();

  const onClose = async (evt) => {
    evt.preventDefault();

    if (!currentMessage) {
      return;
    }

    const id = currentMessage.educationalMessageId;

    setIsLoading(true);
    setCurrentMessage(null);

    window
      .fetch(`/api/educationalMessage/${id}`, {
        headers: { "content-type": "application/json" },
        method: "POST",
      })
      .then(() => refresh())
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    const message = educationalMessages.find((message) =>
      message.routes.some((route) => {
        const isOnNotifyRoute = location.pathname
          .toLowerCase()
          .startsWith(route.toLowerCase());

        if (A.isNotEmpty(message.influencerIds) && activeInfluencer) {
          return (
            message.influencerIds.includes(activeInfluencer.influencerId) &&
            isOnNotifyRoute
          );
        }

        return isOnNotifyRoute;
      }),
    );

    if (educationalMessages.length && message) {
      setCurrentMessage(message);
    }
  }, [activeInfluencer, isLoading, location.pathname, educationalMessages]);

  if (!currentMessage) {
    return null;
  }

  return (
    <Dialog
      open={Boolean(currentMessage)}
      onClose={onClose}
      PaperComponent={StyledDiv}
      TransitionComponent={Transition}
      transitionDuration={300}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        mb={1}
      >
        <IconButton
          style={{ marginTop: -4, marginRight: -4 }}
          className="iconButton"
          onClick={onClose}
        >
          <CloseRounded />
        </IconButton>

        <Typography align="center" variant="h5">
          {currentMessage.title}
        </Typography>
      </Box>

      <div dangerouslySetInnerHTML={{ __html: currentMessage.message }} />
    </Dialog>
  );
}

export default function EducationalModalWrapper() {
  const currentUser = useCurrentUser();

  if (!currentUser.email) {
    return null;
  }

  return <EducationalModal />;
}
