import { D } from "@mobily/ts-belt";
import Star from "@material-ui/icons/Star";
import Videocam from "@material-ui/icons/Videocam";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import FrameDetails from "./FrameDetails";
import Money from "../money";
import FrameAddonIcon from "./FrameAddonIcon";

const registry = new Set();

export default class Addon {
  #rawAddon;

  static TYPE = "generic-addon";

  static isCOAAddon(addonJSON) {
    return addonJSON.name === "coa-addon";
  }

  static isFrameAddon(addonJSON) {
    return addonJSON.name === "frame-addon";
  }

  static isVideoAddon(addonJSON) {
    return addonJSON.name === "video-addon";
  }

  static isVideoMessageAddon(addonJSON) {
    return addonJSON.name === "video-message-addon";
  }

  static isVipAddon(addonJSON) {
    return addonJSON.name === "vip-addon";
  }

  static isPWQuoteAddon(addonJSON) {
    return addonJSON.name === "pw-quote-addon";
  }

  static isPWCharacterAddon(addonJSON) {
    return addonJSON.name === "pw-character-addon";
  }

  static isRawCOAAddon(addonJSON) {
    return addonJSON.addon_name === "coa-addon";
  }

  static isRawFrameAddon(addonJSON) {
    return addonJSON.addon_name === "frame-addon";
  }

  static isRawVideoAddon(addonJSON) {
    return addonJSON.addon_name === "video-addon";
  }

  static isRawVideoMessageAddon(addonJSON) {
    return addonJSON.addon_name === "video-message-addon";
  }

  static isRawVipAddon(addonJSON) {
    return addonJSON.addon_name === "vip-addon";
  }

  static isRawPWQuoteAddon(addonJSON) {
    return addonJSON.addon_name === "pw-quote-addon";
  }

  static isRawPWCharacterAddon(addonJSON) {
    return addonJSON.addon_name === "pw-character-addon";
  }

  /*
   * from creates a new Addon from a raw addon
   *
   * @param {object} rawAddon
   * @returns {Addon}
   */
  static from(rawAddon) {
    for (const addonClass of registry) {
      if (addonClass.canHandle(rawAddon)) {
        return new addonClass(rawAddon);
      }
    }

    return new Addon(rawAddon);
  }

  /*
   * fromAddonMap returns an addonMap of Addons from a rawAddonMap
   *
   * @param {object} rawAddonMap
   */
  static fromAddonMap(rawAddonMap) {
    return Object.fromEntries(
      Object.entries(rawAddonMap).map(([key, value]) => [
        key,
        Addon.from(value),
      ]),
    );
  }

  static fromJSON(rawAddon) {
    return Addon.from(rawAddon);
  }

  static register(addonClass) {
    registry.add(addonClass);

    Addon[addonClass.TYPE.toUpperCase().replace("-", "_")] = addonClass;

    return addonClass;
  }

  static canHandle() {
    throw new TypeError("Subclass must implement canHandle");
  }

  /*
   * creates a new Addon from a raw addon
   *
   * @param {object} rawAddon
   * @returns {Addon}
   */
  constructor(rawAddon) {
    const rawAddonCopy = { ...rawAddon };
    this.#rawAddon = rawAddonCopy;
  }

  clone(mergeMap = {}) {
    return new Addon(D.merge(this.#rawAddon, mergeMap));
  }

  get name() {
    return this.#rawAddon.addon_name;
  }

  get title() {
    return this.#rawAddon.addon_title;
  }

  get href() {
    return this.#rawAddon.href;
  }

  /*
   * price returns the Money for an addon's price
   *
   * @returns {Money}
   */
  get price() {
    return Money.for({
      value: this.#rawAddon.addon_price / 100,
      currency: "usd",
    });
  }

  get rawPrice() {
    return this.#rawAddon.addon_price ?? 0;
  }

  get priceMultiplier() {
    return this.#rawAddon.addon_price_multiplier;
  }

  get description() {
    return this.#rawAddon.addon_description;
  }

  get Icon() {
    return AddCircleIcon;
  }

  /*
   * shippingBump returns the Money for an addon's shippingBump
   *
   * @returns {Money}
   */
  get shippingBump() {
    return Money.for({
      value: this.#rawAddon.addon_shipping_bump / 100,
      currency: "usd",
    });
  }

  get isActive() {
    return Boolean(this.#rawAddon.active);
  }

  get created() {
    if (this.#rawAddon.created) {
      return new Date(this.#rawAddon.created);
    }

    return void 0;
  }

  get updated() {
    if (this.#rawAddon.updated) {
      return new Date(this.#rawAddon.updated);
    }

    return void 0;
  }

  get autographPurchaseId() {
    return this.#rawAddon.autographPurchaseId;
  }

  get tax() {
    if (this.#rawAddon.addon_tax) {
      return Money.for({ value: this.#rawAddon.addon_tax, currency: "usd" });
    }

    return void 0;
  }

  get total() {
    if (this.#rawAddon.addon_total) {
      return Money.for({ value: this.#rawAddon.addon_total, currency: "usd" });
    }

    return void 0;
  }

  get purchaseLocation() {
    return this.#rawAddon.purchase_location;
  }

  get paymentIntentId() {
    return this.#rawAddon.payment_intent_id;
  }

  get isRefunded() {
    return Boolean(this.#rawAddon.refunded);
  }

  get isNew() {
    return Boolean(this.#rawAddon.is_new);
  }

  toJSON() {
    return {
      name: this.name,
      type: this.name,
      title: this.title,
      price: this.price?.toJSON(),
      priceMultiplier: this.priceMultiplier,
      tax: this.tax?.toJSON(),
      total: this.total?.toJSON(),
      purchaseLocation: this.purchaseLocation,
      paymentIntentId: this.paymentIntentId,
      isRefunded: this.isRefunded,
      description: this.description,
      shippingBump: this.shippingBump?.toJSON(),
      isActive: this.isActive,
      created: this.created,
      updated: this.updated,
    };
  }
}

export const VipAddon = Addon.register(
  class VipAddon extends Addon {
    static canHandle(rawAddon) {
      return rawAddon?.addon_name === VipAddon.TYPE;
    }

    static TYPE = "vip-addon";

    get Icon() {
      return Star;
    }

    get isVipAddon() {
      return true;
    }
  },
);

export const VideoAddon = Addon.register(
  class VideoAddon extends Addon {
    static canHandle(rawAddon) {
      return rawAddon?.addon_name === VideoAddon.TYPE;
    }

    static TYPE = "video-addon";

    get Icon() {
      return Videocam;
    }

    get isVideoAddon() {
      return true;
    }
  },
);

export const VideoMessageAddon = Addon.register(
  class VideoMessageAddon extends Addon {
    static canHandle(rawAddon) {
      return rawAddon?.addon_name === VideoMessageAddon.TYPE;
    }

    static TYPE = "video-message-addon";

    get Icon() {
      return Videocam;
    }

    get isVideoMessageAddon() {
      return true;
    }
  },
);

export const FrameAddon = Addon.register(
  class FrameAddon extends Addon {
    static canHandle(rawAddon) {
      return Boolean(rawAddon) && rawAddon.addon_name === FrameAddon.TYPE;
    }

    static TYPE = "frame-addon";

    get Icon() {
      return FrameAddonIcon;
    }

    get isFrameAddon() {
      return true;
    }

    get AdditionalDetails() {
      return FrameDetails;
    }
  },
);

export const CoaAddon = Addon.register(
  class CoaAddon extends Addon {
    static canHandle(rawAddon) {
      return Boolean(rawAddon) && rawAddon.addon_name === CoaAddon.TYPE;
    }

    static TYPE = "coa-addon";

    get Icon() {
      return VerifiedUser;
    }

    get isCoaAddon() {
      return true;
    }
  },
);

export const PWQuoteAddon = Addon.register(
  class PWQuoteAddon extends Addon {
    static canHandle(rawAddon) {
      return Boolean(rawAddon) && rawAddon.addon_name === PWQuoteAddon.TYPE;
    }

    static TYPE = "pw-quote-addon";

    get isPWQuoteAddon() {
      return true;
    }
  },
);

export const PWCharacterAddon = Addon.register(
  class PWCharacterAddon extends Addon {
    static canHandle(rawAddon) {
      return Boolean(rawAddon) && rawAddon.addon_name === PWCharacterAddon.TYPE;
    }

    static TYPE = "pw-character-addon";

    get isPWCharacterAddon() {
      return true;
    }
  },
);
