import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import axios from "axios";
import logger from "../../util/logger";

async function postNewsletterEmail(email) {
  try {
    await axios.post(`/api/newsletterEmail`, {
      email_address: email,
    });
  } catch (err) {
    logger.error(err);
    if (err.isAxiosError) {
      return err.response.data;
    }
    return `Unable to subscribe ${email}`;
  }
}

const NewsletterEmailEntry = ({ isHomePage = false }) => {
  const statusTextTimeout = useRef(null);
  const inputRef = useRef(null);

  const [email, setEmail] = useState("");
  const [statusText, setStatusText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(
    async (evt) => {
      evt.preventDefault();
      if (isLoading) return;

      setIsLoading(true);
      clearInterval(statusTextTimeout.current);
      setStatusText("");

      if (email.length < 1) {
        return setStatusText("Please provide an email address");
      }

      inputRef.current.blur();

      const err = await postNewsletterEmail(email);

      if (err) {
        setStatusText(err);
      } else {
        setEmail("");
        setStatusText("Thank you!");
      }
      setIsLoading(false);

      statusTextTimeout.current = setTimeout(() => {
        setStatusText("");
      }, 2000);
    },
    [isLoading, email],
  );

  const handleInput = (evt) => {
    setStatusText("");
    setEmail(evt.target.value);
  };

  useEffect(() => {
    const timeout = statusTextTimeout.current;
    return () => {
      clearTimeout(timeout);
    };
  }, [statusTextTimeout]);

  return (
    <InnerContainer>
      <Title isHomePage={isHomePage}>Sign Up For Our Newsletter</Title>

      <Subtext isHomePage={isHomePage}>
        Be the first in line for Limited Editions, Promotions and New Talent
      </Subtext>

      <NewsletterForm onSubmit={handleSubmit}>
        <EmailDiv>
          <EmailInput
            id="newsletter-email-address-input"
            value={email}
            type="email"
            required
            placeholder="Email Address"
            onChange={handleInput}
            ref={inputRef}
            isHomePage={isHomePage}
          />

          <EmailSubmitButton
            className={email.length < 1 ? "disabled" : ""}
            disabled={isLoading}
            type="submit"
            isHomePage={isHomePage}
          >
            Submit
          </EmailSubmitButton>
        </EmailDiv>
      </NewsletterForm>

      {statusText && (
        <StatusText isHomePage={isHomePage}>{statusText}</StatusText>
      )}
    </InnerContainer>
  );
};

NewsletterEmailEntry.propTypes = {
  isHomePage: PropTypes.bool,
};

export default NewsletterEmailEntry;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  text-align: right;
  display: block;
  box-sizing: border-box;
  width: 100%;

  font-family: "Inter", Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  @media only screen and (max-width: 599px) {
    display: block;
  }
`;

const Title = styled.div`
  font-family: "Inter", Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #222;
  margin: 0;
  margin-bottom: 16px;
  @media only screen and (max-width: 800px) {
    font-size: 22px;
    margin-bottom: 8px;
  }
  @media only screen and (max-width: 599px) {
    margin-bottom: 0;
    font-size: 20px;
    text-align: center;
  }

  ${(props) => (props.isHomePage ? "color: #fff" : "#2b2b2b")};
`;

const Subtext = styled.div`
  text-align: right;
  display: block;
  text-decoration: none;
  font-family: "Inter", Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  @media only screen and (max-width: 599px) {
    font-size: 12px;
    text-align: center;
    word-wrap: break-word;
  }

  ${(props) => (props.isHomePage ? "color: #fff" : "#2b2b2b")};
`;

const NewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: end;

  @media only screen and (max-width: 599px) {
    display: flex;
    align-items: center;
  }
`;

const EmailInput = styled.input`
  font-size: 20px;
  border: none;
  padding: 10px;
  border: 1px solid;
  border-radius: 20px;
  text-align: left;
  max-width: 100vw;
  width: 300px;
  text-indent: 10px;
  font-family: "Inter", Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  &:focus::placeholder {
    color: transparent;
  }
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 599px) {
    font-size: 14px;
    width: 70vw;
  }

  ${(props) =>
    props.isHomePage
      ? "color: #fff; background: #1E2022; border-color: #575D63"
      : "color: #2b2b2b; background: #fff; border-color: #2b2b2b"};
`;

const EmailDiv = styled.div`
  position: relative;
  display: flex;
  width: "100%";
  margin-top: 16px;
  @media (max-width: 599px) {
    margin-top: 8px;
  }
`;

const EmailSubmitButton = styled.button`
  position: absolute;
  border: none;
  right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 4px;
  width: 70px;
  height: 80%;
  padding: 5px 20px;
  user-select: none;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
  background: linear-gradient(93.21deg, #93cff1 1.22%, #2a6fc0 88.2%),
    linear-gradient(98.52deg, #9de3e8 0%, #9886be 100%),
    linear-gradient(98.52deg, #87e2ff 0%, #feb9ff 100%), #2b2b2b;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  font-family: "Inter", Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  &:hover {
    background: rgb(148, 148, 148);
    cursor: pointer;
  }
  &.disabled {
    color: white;
  }

  @media only screen and (max-width: 599px) {
    width: 70px;
  }
`;

const StatusText = styled.div`
  color: rgb(200, 43, 102);
  font-size: 20px;
  text-align: center;
  margin: 5px 20px;
  @media only screen and (max-width: 599px) {
    font-size: 16px;
    margin-top: 0;
  }
`;
