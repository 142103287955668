import * as React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Switch, FormControlLabel } from "@material-ui/core";

function StreamilyToggle({
  checked,
  onChange,
  label,
  name,
  labelPlacement,
  color = "primary",
}) {
  return (
    <StyledFormControlLabel
      name={name}
      label={label}
      control={<Switch checked={checked} onChange={onChange} color={color} />}
      labelPlacement={labelPlacement}
    />
  );
}

StreamilyToggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelPlacement: PropTypes.oneOf(["start", "end", "bottom", "top"]),
  color: PropTypes.oneOf(["primary", "secondary"]),
};

const StyledFormControlLabel = withStyles((theme) => ({
  root: {
    gap: theme.spacing(1),
    margin: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  labelPlacementTop: {
    gap: theme.spacing(0.25),
  },
  labelPlacementBottom: {
    gap: theme.spacing(0.25),
  },
}))(FormControlLabel);

export default React.memo(StreamilyToggle);
