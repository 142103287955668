import PropTypes from "prop-types";
import * as React from "react";
import { Influencer, InfluencerEvent } from "../../../domain";
import {
  formatDateRangeReverseTZ,
  formatDateStrTimeReverseTZ,
} from "../../../util";

export default function UpcomingEvent({ influencer }) {
  if (
    influencer.nextEventTime &&
    influencer.dateType === InfluencerEvent.TYPES.DAY_TIME
  ) {
    return (
      <span className="shop-card__event-text">
        {formatDateStrTimeReverseTZ(influencer.nextEventTime)}
      </span>
    );
  }

  if (
    influencer.eventStartDate &&
    influencer.dateType === InfluencerEvent.TYPES.RANGE
  ) {
    return (
      <span className="shop-card__event-text">
        {formatDateRangeReverseTZ(
          influencer.eventStartDate,
          influencer.eventEndDate,
        )}
      </span>
    );
  }

  return null;
}

UpcomingEvent.propTypes = {
  influencer: PropTypes.oneOfType([
    PropTypes.instanceOf(Influencer),
    PropTypes.object,
  ]),
};
