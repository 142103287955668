import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import * as React from "react";

export default function EventHeader() {
  return <GreyText gutterBottom>Upcoming Events</GreyText>;
}

const GreyText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.grey.mid,
}));
