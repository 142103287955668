import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";
import {
  Card,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";

const SideScrollCard = styled(Card)(() => ({
  display: "flex",
  flexGrow: 1,
  maxWidth: "100%",
  overflowX: "auto",
  position: "relative",
  "& .fade": {
    opacity: 0.7,
  },
}));

export function SideScrollCardError({ message, ...props }) {
  return (
    <SideScrollCard elevation={0} {...props}>
      <Grid container justifyContent="center">
        <Box p={2}>
          <Typography color="error">{message}</Typography>
        </Box>
      </Grid>
    </SideScrollCard>
  );
}

SideScrollCardError.propTypes = {
  message: PropTypes.string.isRequired,
};

SideScrollCard.Error = SideScrollCardError;

export function SideScrollCardLoading({ message, ...props }) {
  return (
    <SideScrollCard elevation={0} {...props}>
      <Grid container spacing={1}>
        <Grid item container justifyContent="center" xs={12}>
          <Box p={2}>
            <CircularProgress />
          </Box>
        </Grid>

        <Grid item container justifyContent="center" xs={12}>
          {Boolean(message) && <Typography>{message}</Typography>}
        </Grid>
      </Grid>
    </SideScrollCard>
  );
}

SideScrollCardLoading.propTypes = {
  message: PropTypes.string,
};

SideScrollCard.Loading = SideScrollCardLoading;

export default SideScrollCard;
