import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import AlertIcon from "@material-ui/icons/ErrorOutline";
import PropTypes from "prop-types";
import * as React from "react";

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  alignItems: "center",
  backgroundColor: "rgb(238,232,170)",
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.text.primary,
  display: "flex",
  flexDirection: "row",
  fontWeight: theme.typography.fontWeightMedium,
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  width: "100%",
}));

export default function Hint({
  children,
  IconComponent = AlertIcon,
  IconProps = {},
  LabelComponent = "span",
  LabelProps = {},
  ...props
}) {
  return (
    <StyledTypography {...props}>
      <IconComponent {...IconProps} />
      <LabelComponent {...LabelProps}>{children}</LabelComponent>
    </StyledTypography>
  );
}

Hint.propTypes = {
  children: PropTypes.node.isRequired,
  IconComponent: PropTypes.elementType,
  IconProps: PropTypes.object,
  LabelComponent: PropTypes.elementType,
  LabelProps: PropTypes.object,
};
