import * as React from "react";
import PropTypes from "prop-types";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Button } from "@material-ui/core";

export default function ExpandButton({
  onClick,
  children,
  expanded,
  iconPosition = "start",
  ...buttonProps
}) {
  const position = {
    [iconPosition === "start" ? "startIcon" : "endIcon"]: expanded ? (
      <KeyboardArrowUpIcon />
    ) : (
      <KeyboardArrowDownIcon />
    ),
  };

  return (
    <Button {...buttonProps} onClick={onClick} {...position}>
      {children}
    </Button>
  );
}

ExpandButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  expanded: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
};
