import * as React from "react";
import useSWR from "swr";
import { Influencer } from "../domain";
import fetcher from "./fetcher";

const baseEndpoint = "/api/influencer/getGroupMembers";

export default function useGroupMembers(influencerId) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/${influencerId}`,
    fetcher,
  );

  const influencers = React.useMemo(() => {
    if (!Array.isArray(data?.influencers)) {
      return [];
    }

    return data.influencers.map(Influencer.from);
  }, [data]);

  return {
    error,
    influencers,
    isError: Boolean(error),
    isLoading: !data && !error,
    refresh: mutate,
  };
}
