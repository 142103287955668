import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
} from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
import { ProgressButton } from "../";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 12,
    maxWidth: 700,
    [theme.breakpoints.down("xs")]: {
      left: "16px", // dialog forced to left align
      marginLeft: "0",
      margingRight: "0",
      width: "100%",
      maxWidth: "100%",
    },
    "& .iconButton": {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    "& .MuiDialogTitle-root": {
      color: theme.palette.text.primary,
      fontFamily: "Inter",
      fontSize: "1.3rem",
      fontWeight: 700,
      padding: "1.5rem 2rem .5rem",
    },
    "& .MuiDialogContent-root": {
      padding: "0 2rem",
      "& .MuiDialogContentText-root": {
        margin: "0 0 0.5rem",
      },
      "& .MuiTypography-root": {
        color: theme.palette.text.primary,
      },
    },
    "& .MuiDialogActions-root": {
      padding: "0 2rem 1.5rem",
    },
  },
}));

// MessageDialog notes:
// buttonList prop: [ buttonObject, ... ]
// !buttonList: no buttons displayed
// buttonObject: { label, onClick, default, ...custom props... }
// buttonObject.default: true = the primary button and receives the gradient style
// buttonObject.onClick: func( buttonObject )
export default function MessageDialog({ title, message, onClose, buttonList }) {
  const [transitionActive, setTransitionActive] = React.useState(true);
  const [selectedButton, setSelectedButton] = React.useState(null);

  const handleClose = () => {
    setTransitionActive(false);
  };

  const buttonClick = (buttonObject) => {
    setSelectedButton(buttonObject);
    setTransitionActive(false);
  };

  const transitionEnd = React.useCallback(() => {
    if (selectedButton?.onClick) selectedButton.onClick(selectedButton);
    if (onClose) onClose();
    setTransitionActive(true);
  }, [onClose, selectedButton]);

  return (
    <StyledDialog
      open={transitionActive}
      onClose={handleClose}
      TransitionComponent={Transition}
      transitionDuration={300}
      TransitionProps={{ onExited: transitionEnd }}
    >
      <IconButton className="iconButton" onClick={handleClose}>
        <CloseRounded />
      </IconButton>
      <DialogTitle align="left" disableTypography>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonList &&
          buttonList.map((obj, idx) => {
            return obj.default ? (
              <ProgressButton
                key={`btn${idx}`}
                color="secondary"
                fullWidth={false}
                variant="contained"
                onClick={() => buttonClick(obj)}
              >
                {obj.label}
              </ProgressButton>
            ) : (
              <Button key={`btn${idx}`} onClick={() => buttonClick(obj)}>
                {obj.label}
              </Button>
            );
          })}
      </DialogActions>
    </StyledDialog>
  );
}

MessageDialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonList: PropTypes.array,
  onClose: PropTypes.func,
};
