import { execute, ACTIONS } from "../services/Captcha";

export class FetchError extends Error {
  constructor(message, status, json) {
    super(message);
    this.status = status;
    this.json = json;
    this.name = "FetchError";
  }
}

export const HTTPMethods = Object.seal(
  Object.freeze({
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",
  }),
);

export const baseHeaders = {
  Accept: "application/json;charset=utf-8",
  "Content-Type": "application/json;charset=utf-8",
};

const debug = false;

export default async function fetcher(url, init = {}) {
  const finalInit = {
    method: HTTPMethods.GET,
    ...init,
    headers: {
      ...baseHeaders,
      ...(init?.headers ?? {}),
    },
  };

  if (debug) {
    console.count(url);
  }

  const res = await window.fetch(url, finalInit);

  if (!res.ok) {
    const json = await res.json();
    const { message } = json;
    throw new FetchError(
      message ?? "An error occurred while fetching the data",
      res.status,
      json,
    );
  }

  return res.json();
}

export function createCaptchaFetcher(action = ACTIONS.ORDER_STATUS) {
  return async function captchaFetcher(url = "", init = {}) {
    const token = await execute(action);

    const [path, searchParams] = url.split("?");

    let params = new URLSearchParams();

    if (searchParams) {
      params = new URLSearchParams(searchParams);
    }

    params.set("ct", token);

    return fetcher(`${path}?${params}`, init);
  };
}
