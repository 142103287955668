import * as React from "react";

const TikTokIcon = React.memo(function TikTokIcon({
  width = 22,
  height = 22,
  fill = "#fff",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-label="TikTok"
      role="img"
      viewBox="0 0 512 512"
      width={width}
      height={height}
    >
      <path
        id="t"
        d="M219 200a117 117 0 1 0 101 115v-128a150 150 0 0 0 88 28v-63a88 88 0 0 1-88-88h-64v252a54 54 0 1 1-37-51z"
        fill={fill}
      />
    </svg>
  );
});

export default TikTokIcon;
