import * as React from "react";
import { identity } from "../util";

function useSessionStorage(key, initialValue, options) {
  if (!key) {
    throw new Error("useSessionStorage key may not be falsy");
  }

  const deserializer = React.useMemo(() => {
    if (options?.raw) {
      return identity;
    }

    if (typeof options?.deserializer === "function") {
      return options.deserializer;
    }

    return JSON.parse;
  }, [options]);

  const serializer = React.useMemo(() => {
    if (options?.raw) {
      return identity;
    }

    if (typeof options?.serializer === "function") {
      return options.serializer;
    }

    return JSON.stringify;
  }, [options]);

  const initializer = React.useRef((key) => {
    try {
      const sessionStorageValue = sessionStorage.getItem(key);
      if (sessionStorageValue !== null) {
        return deserializer(sessionStorageValue);
      }
      if (initialValue) sessionStorage.setItem(key, serializer(initialValue));
      return initialValue;
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [state, setState] = React.useState(() => initializer.current(key));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useLayoutEffect(() => setState(initializer.current(key)), [key]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const set = React.useCallback(
    (valOrFunc) => {
      if (typeof valOrFunc === "function") {
        setState((state) => {
          try {
            const newState = valOrFunc(state);

            if (typeof newState === "undefined") {
              return state;
            }

            let value;

            if (options?.raw && typeof newState === "string") {
              value = newState;
            } else if (
              options?.serializer &&
              typeof options?.serializer === "function"
            ) {
              value = options.serializer(newState);
            } else if (!options?.raw) {
              value = JSON.stringify(newState);
            }

            sessionStorage.setItem(key, value);
            return deserializer(value);
          } catch {
            // If user is in private mode or has storage restriction
            // sessionStorage can throw. Also JSON.stringify can throw.
            return state;
          }
        });
      } else {
        try {
          const newState = valOrFunc;

          if (typeof newState === "undefined") {
            return;
          }

          let value;

          if (options?.raw && typeof newState === "string") {
            value = newState;
          } else if (typeof options?.serializer === "function") {
            value = options.serializer(newState);
          } else {
            value = JSON.stringify(newState);
          }

          sessionStorage.setItem(key, value);
          setState(deserializer(value));
        } catch {
          // If user is in private mode or has storage restriction
          // sessionStorage can throw. Also JSON.stringify can throw.
        }
      }
    },
    [key, setState, deserializer, options],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const remove = React.useCallback(() => {
    try {
      sessionStorage.removeItem(key);
      setState(undefined);
    } catch {
      // If user is in private mode or has storage restriction
      // sessionStorage can throw.
    }
  }, [key, setState]);

  return [state, set, remove];
}

export default useSessionStorage;
