import * as React from "react";
import axios from "axios";

import { useStateValue, actionCreators } from "../globalState";
import { getCookie, getParameter, logger } from "../util";

const UserAuth = () => {
  const dispatch = useStateValue()[1];

  const refreshUserToken = async () => {
    try {
      const { data, status } = await axios.post(`/api/auth/refresh`);

      if (status !== 200) return;

      const { data: user } = data;

      dispatch(actionCreators.createSetUser(user));
    } catch (error) {
      logger.error(error);

      dispatch(actionCreators.createSetUser({}));
    } finally {
      const userToken = getParameter("ut");

      if (!userToken) {
        return;
      }

      if (
        window.location &&
        window.location.pathname &&
        window.location.pathname.substring(0, 13) &&
        window.location.pathname.substring(0, 13).toLowerCase() ===
          "/dashboard/s/"
      ) {
        // special logic for sales notification links. Would probably be better to have server handle this instead.
        window.location = window.location.pathname;
      } else if (
        window.location &&
        window.location.pathname &&
        window.location.pathname.substring(0, 11) &&
        window.location.pathname.substring(0, 11).toLowerCase() ===
          "/emaillogin"
      ) {
        window.location = "/account-dashboard/orders/?EmailLogin=true";
      } else {
        window.location = "/Account/ChangePassword";
      }
    }
  };

  React.useEffect(() => {
    const utCookie = getCookie("ut");

    if (utCookie) {
      refreshUserToken();
    } else {
      dispatch(actionCreators.createSetUser({}));
    }
  }, []);

  return null;
};

export default UserAuth;
