export default async function copyToClipboard(text) {
  try {
    if (window.navigator.clipboard) {
      await window.navigator.clipboard.writeText(text);
    } else {
      const tempElem = document.createElement("input");
      document.body.appendChild(tempElem);
      tempElem.value = text;
      tempElem.select();
      document.execCommand("copy", false);
      tempElem.remove();
    }

    return true;
  } catch (err) {
    console.error(err);

    return false;
  }
}
