import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";

const StreamilyPageContainer = React.memo(function StreamilyPageContainer({
  children,
  spacing,
}) {
  return <StyledBox className={`gap-${spacing ?? 0}`}>{children}</StyledBox>;
});

const StyledBox = styled("div")(({ theme }) => ({
  background: theme.palette.background.main,
  width: "100%",
  maxWidth: 1374,
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(3),
  paddingBttom: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  gap: 0,
  "&.gap-1": {
    gap: theme.spacing(1),
  },
  "&.gap-2": {
    gap: theme.spacing(2),
  },
  "&.gap-3": {
    gap: theme.spacing(3),
  },
  "&.gap-4": {
    gap: theme.spacing(4),
  },
  flex: 1,
  justifyContent: "flex-start",
}));

StreamilyPageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.number,
};

export default StreamilyPageContainer;
