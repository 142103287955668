import { Box, Typography } from "@material-ui/core";
import { A, D, G, pipe } from "@mobily/ts-belt";
import { useAtom, useAtomValue } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { SignType } from "../../../domain";
import { useActiveInfluencer, useCurrentUser } from "../../../hooks";
import { currencyFormatter } from "../../../util";
import AdvancedSettings from "../AdvancedSettings";
import * as styles from "../styles";
import { STEPS } from "../utils";

const toTag = (tagString) => ({ tagString, active: true });

function ProductSummaryStep({
  productAtom,
  productSignersAtom,
  productTagsAtom,
  stateAtom,
}) {
  const currentUser = useCurrentUser();
  const activeInfluencer = useActiveInfluencer();
  const product = useAtomValue(productAtom);
  const signers = useAtomValue(productSignersAtom);
  const [productTags, setProductTags] = useAtom(productTagsAtom);
  const [, setState] = useAtom(stateAtom);

  const setCurrentStep = React.useCallback(
    (step) => () => {
      setState(D.merge({ currentStep: step }));
    },
    [setState],
  );

  // This is a bit of a hack to ensure that the character and franchise tags
  // show up in the tag list when the user is editing the product, but don't
  // show up when they are adding new tags.
  React.useEffect(() => {
    if (!product.characterTagString && !product.franchiseTagString) {
      return;
    }

    let hasCharacterTag = false;
    let hasFranchiseTag = false;

    productTags.forEach((tag) => {
      if (product.characterTagString === tag.tagString) {
        hasCharacterTag = true;
      }

      if (product.franchiseTagString === tag.tagString) {
        hasFranchiseTag = true;
      }
    });

    const specialTags = [];

    if (!hasCharacterTag && product.characterTagString) {
      specialTags.push(product.characterTagString);
    }

    if (!hasFranchiseTag && product.franchiseTagString) {
      specialTags.push(product.franchiseTagString);
    }

    const uniqueSpecialTags = Array.from(new Set(specialTags)).map(toTag);
    const tags = [...productTags, ...uniqueSpecialTags];

    if (tags.length === productTags.length) {
      return;
    }

    setProductTags(tags);
  }, [
    productTags,
    product.characterTagString,
    product.franchiseTagString,
    setProductTags,
  ]);

  const canEdit =
    currentUser.isAdmin || currentUser.isSuperadmin || signers.length < 2;

  const canSeeOtherSigners = currentUser.isAdmin;

  return (
    <Box display="flex" flexDirection="column" gridGap="8px">
      <Box
        alignItems="center"
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        mb={1}
      >
        {Boolean(product.imageUrl) && (
          <img
            style={{ maxWidth: "100%", objectFit: "contain" }}
            height={256}
            src={product.imageUrl}
            alt={product.name}
          />
        )}

        {!product.imageUrl && (
          <Box minHeight="256">
            <Typography variant="body2" color="error">
              No image
            </Typography>
          </Box>
        )}

        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          mt={1}
          width="100%"
        >
          <styles.TextButton
            disabled={!canEdit}
            onClick={setCurrentStep(STEPS.PRODUCT_DETAILS)}
          >
            Edit
          </styles.TextButton>
        </Box>
      </Box>

      <Box
        width="100%"
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <div>
          <styles.Label>Name</styles.Label>

          <Typography
            variant="body2"
            color={!product.name ? "error" : "textSecondary"}
          >
            {product.name || "No name"}
          </Typography>
        </div>

        <styles.TextButton
          disabled={!canEdit}
          onClick={setCurrentStep(STEPS.PRODUCT_DETAILS)}
        >
          Edit
        </styles.TextButton>
      </Box>

      <Box
        width="100%"
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <div>
          <styles.Label>Description</styles.Label>

          <Typography variant="body2" color="textSecondary">
            {product.subtext || "No description"}
          </Typography>
        </div>

        <styles.TextButton
          disabled={!canEdit}
          onClick={setCurrentStep(STEPS.PRODUCT_DETAILS)}
        >
          Edit
        </styles.TextButton>
      </Box>

      <Box
        width="100%"
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column" gridGap="2px">
          <styles.Label>Type</styles.Label>

          <Typography variant="body2" color="textSecondary">
            {SignType.toLabel(product.signedType)} (
            {product.productType ?? "Other"})
          </Typography>
        </Box>

        <styles.TextButton
          disabled={!canEdit}
          onClick={setCurrentStep(STEPS.CREATE_PRODUCT)}
        >
          Edit
        </styles.TextButton>
      </Box>

      <Box
        width="100%"
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <div>
          <styles.Label>Quantity</styles.Label>

          <Typography
            variant="body2"
            color={
              product.stock !== null && product.stock < 0
                ? "error"
                : "textSecondary"
            }
          >
            {product.stock ?? "Unlimited"}
          </Typography>
        </div>

        <styles.TextButton
          disabled={!canEdit}
          onClick={setCurrentStep(STEPS.PRODUCT_DETAILS)}
        >
          Edit
        </styles.TextButton>
      </Box>

      {G.isArray(signers) && A.isEmpty(signers) && (
        <Box
          width="100%"
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <div>
            <styles.Label>Product Splits</styles.Label>
            <Typography variant="body2" color="error">
              No signers have been added to this product yet.
            </Typography>
          </div>
          <styles.TextButton onClick={setCurrentStep(STEPS.PRODUCT_DETAILS)}>
            Edit
          </styles.TextButton>
        </Box>
      )}

      {G.isArray(signers) && A.isNotEmpty(signers) && (
        <Box display="flex" flexDirection="column" gridGap="8px">
          {pipe(
            signers,
            A.filter(
              (signer) =>
                canSeeOtherSigners ||
                signer.influencerId === activeInfluencer.influencerId,
            ),
            A.map((signer) => (
              <Box
                key={signer.influencerId}
                display="flex"
                alignItems="flex-end"
                justifyContent="space-between"
                width="100%"
              >
                <div>
                  {canSeeOtherSigners && (
                    <styles.Label>
                      How much {signer.name} will make per sale
                    </styles.Label>
                  )}

                  {!canSeeOtherSigners && (
                    <styles.Span>
                      <styles.Label>Pricing</styles.Label> (How much
                      {signer.influencerId === activeInfluencer.influencerId &&
                      currentUser.email === activeInfluencer.ownerEmail
                        ? " you "
                        : ` ${signer.name} `}
                      will make per sale)
                    </styles.Span>
                  )}

                  <Typography variant="body2" color="textSecondary">
                    {currencyFormatter.format(signer.splitAmount / 100)}
                  </Typography>
                </div>

                <styles.TextButton
                  disabled={
                    !canEdit &&
                    signer.influencerId !== activeInfluencer.influencerId
                  }
                  onClick={setCurrentStep(STEPS.PRODUCT_DETAILS)}
                >
                  Edit
                </styles.TextButton>
              </Box>
            )),
          )}
        </Box>
      )}

      <Box
        width="100%"
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        mb={1}
      >
        <div>
          <styles.Label style={{ marginBottom: 2 }}>Tags</styles.Label>

          <Box display="flex" gridGap="8px" flexWrap="wrap">
            {A.isEmpty(productTags) && (
              <Typography variant="body2" color="textSecondary">
                No tags.
              </Typography>
            )}

            {A.isNotEmpty(productTags) &&
              productTags.map(({ tagString }) => (
                <styles.Chip size="small" label={tagString} key={tagString} />
              ))}
          </Box>
        </div>

        <styles.TextButton onClick={setCurrentStep(STEPS.INCREASE_EXPOSURE)}>
          Edit
        </styles.TextButton>
      </Box>

      {canEdit && <AdvancedSettings productAtom={productAtom} />}
    </Box>
  );
}

ProductSummaryStep.propTypes = {
  productAtom: PropTypes.object.isRequired,
  productTagsAtom: PropTypes.object.isRequired,
  productSignersAtom: PropTypes.object.isRequired,
  productCharactersAtom: PropTypes.object.isRequired,
  stateAtom: PropTypes.object.isRequired,
};

export default {
  Component: ProductSummaryStep,
  title: "Product Summary",
};
