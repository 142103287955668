import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { withStyles } from "@material-ui/core/styles";

const SmallAvatarGroup = withStyles((theme) => ({
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    fontSize: theme.typography.caption.fontSize,
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(3),
      width: theme.spacing(3),
      fontSize: theme.spacing(1),
    },
  },
}))(AvatarGroup);

export default SmallAvatarGroup;
