import { currencyFormatter } from "./formatCurrency";

/*
 * formatWith takes a function and a set of keys to map this function to
 * @param {function} fn
 * @param {unknown} keys
 * @returns {function} function that takes an object and maps keys with fn
 */
export default function formatWith(fn, ...keys) {
  return (object) =>
    keys.reduce(
      (result, key) => {
        result[key] = fn(result[key]);
        return result;
      },
      { ...object },
    );
}

export const formatWithBool = (...keys) =>
  formatWith((x) => Boolean(x), ...keys);

export const formatWithCurrency = (...keys) =>
  formatWith((x) => currencyFormatter.format(x / 100), ...keys);

export const formatWithLast4 = (...keys) =>
  formatWith(
    (x) =>
      typeof x === "string" && x.length > 4 ? x.substring(x.length - 4) : x,
    ...keys,
  );

export const formatWithTrim = (...keys) =>
  formatWith(
    (x) => (typeof x === "string" && x.length > 4 ? x.trim() : x),
    ...keys,
  );

export const formatWithDate = (...keys) =>
  formatWith((x) => new Date(x), ...keys);
