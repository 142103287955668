import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";

const StyledBox = styled("div")(({ theme }) => ({
  background: "#3a3a3a",
  ...theme.typography.caption,
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(10),
  color: theme.palette.common.white,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  width: "100%",
  zIndex: 10,
  padding: theme.spacing(0.75, 3),
  textAlign: "center",
  lineHeight: 1.2,
  [theme.breakpoints.down("xs")]: {},
}));

export default function SiteNotice({ text, location }) {
  const pathname = location?.pathname?.toLowerCase();

  if (
    pathname.startsWith("/dashboard") ||
    pathname.startsWith("/account-dashboard")
  ) {
    return null;
  }

  return <StyledBox>{text}</StyledBox>;
}

SiteNotice.propTypes = {
  text: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string }),
};
