import { Skeleton } from "@material-ui/lab";
import { A, D, G, pipe } from "@mobily/ts-belt";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { useActiveInfluencer, useShops } from "../hooks";
import { SearchSelect } from "./Form";

const getOptionKey = D.get("influencerId");
const getOptionLabel = D.get("name");
const getSelectLabel = ({ selected, hasSelectedAll, hasSelectedNone }) => {
  if (hasSelectedAll) {
    return "All Talents";
  }

  if (hasSelectedNone) {
    return "No Talents Selected";
  }

  const label = pipe(D.values(selected), A.map(D.get("name")), A.join(", "));

  if (label.length > 42) {
    return `${label.slice(0, 42)}...`;
  }

  return label;
};
const filterCompare = (option, value) => {
  return option?.name?.toLowerCase().includes(value.toLowerCase());
};

export default function ShopSelect({ atom, onChange }) {
  const activeInfluencer = useActiveInfluencer();

  const { shops, isLoading } = useShops({
    influencer_id: activeInfluencer?.influencerId,
  });

  const [shop, setShop] = useAtom(atom);

  React.useEffect(() => {
    if (!G.isFunction(onChange)) {
      return;
    }

    onChange(shop);
  }, [shop, onChange]);

  if (isLoading) {
    return (
      <Skeleton
        variant="rect"
        animation="pulse"
        height={33}
        width={165}
        style={{ borderRadius: 8 }}
      />
    );
  }

  return (
    <SearchSelect
      disableSorting
      filterCompare={filterCompare}
      getOptionKey={getOptionKey}
      getOptionLabel={getOptionLabel}
      getSelectLabel={getSelectLabel}
      label="Selected Talent"
      onChange={setShop}
      options={shops}
    />
  );
}

ShopSelect.propTypes = {
  atom: PropTypes.object.isRequired,
  onChange: PropTypes.func,
};
