import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const SemiBoldText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.semiBold,
  color: theme.palette.grey[900],
  "&.disabled": {
    color: theme.palette.grey[500],
  },
}));

export default SemiBoldText;
