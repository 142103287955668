import * as React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledLink = styled("button")(({ theme }) => ({
  background: "none",
  border: "none",
  padding: 0,
  margin: 0,
  textAlign: "left",
  letterSpacing: "unset",
  ...theme.typography.caption,
  color: theme.palette.primary.main,
  "&:hover": {
    textDecoration: "underline",
    color: theme.palette.primary.dark,
    cursor: "pointer",
  },
}));

const StyledBox = styled("div")(() => ({
  background: "white",
  padding: "0px 4px",
  borderRadius: 4,
  "& img": {
    verticalAlign: "middle",
    marginBottom: 1,
  },
}));

export function TextLink({ children, ...props }) {
  return <StyledLink {...props}>{children}</StyledLink>;
}

TextLink.propTypes = {
  children: PropTypes.node,
};

const sizeFor =
  ({ width, height }) =>
  (size) => ({
    width: size === "small" ? width * 0.85 : width,
    height: size === "small" ? height * 0.85 : height,
  });

export default function AffirmIcons({ size }) {
  return (
    <Box display="flex" alignItems="center">
      <StyledBox>
        <img
          style={{ objectFit: "contain" }}
          {...sizeFor({ width: 42, height: 24 })(size)}
          src="https://d3tw7mvc4pjx2c.cloudfront.net/misc/affirm.png"
          alt=""
        />
      </StyledBox>

      <img
        {...sizeFor({ width: 70, height: 48 })(size)}
        style={{ objectFit: "contain" }}
        src="https://d3tw7mvc4pjx2c.cloudfront.net/misc/klarna.png"
        alt=""
      />

      <img
        {...sizeFor({ width: 72, height: 40 })(size)}
        style={{ objectFit: "contain" }}
        src="https://d3tw7mvc4pjx2c.cloudfront.net/misc/afterpay.png"
        alt=""
      />
    </Box>
  );
}

AffirmIcons.propTypes = {
  size: PropTypes.string,
};
