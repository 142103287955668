import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { DialogActions, Grid, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { StatusMessage } from "../../../../theme";
import { useMatchesXsDown } from "../../../../hooks";

const ProductModalActions = React.memo(function ProductModalActions({
  errorMessage,
}) {
  const matchesXs = useMatchesXsDown();

  return (
    <StyledDialogActions>
      <Grid container justifyContent="flex-end" spacing={1} alignItems="center">
        {Boolean(errorMessage) && (
          <Grid item>
            <StatusMessage type="error" message={errorMessage} />
          </Grid>
        )}

        <Grid item xs={matchesXs ? 12 : undefined}>
          <Button
            fullWidth={matchesXs}
            size="large"
            variant="contained"
            color="primary"
            style={checkoutButtonStyle}
            type="submit"
            className={clsx(matchesXs && "largerMobile")}
            disabled={Boolean(errorMessage)}
          >
            Checkout
          </Button>
        </Grid>
      </Grid>
    </StyledDialogActions>
  );
});

ProductModalActions.propTypes = {
  errorMessage: PropTypes.string,
};

const checkoutButtonStyle = { minWidth: "10rem" };

const StyledDialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
}))(DialogActions);

export default ProductModalActions;
