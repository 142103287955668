import { useEffect } from "react";

export default () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "production") {
      return;
    }

    // AWIN Mastertag
    const script = document.createElement("script");
    script.src = "https://www.dwin1.com/101779.js";
    script.async = true;
    document.body.appendChild(script);

    // AWIN Source Handler

    var Data = new Date();
    var latency;
    var cookieLength = 30; // Cookie length in days;
    var origin;
    var sourceParameter = ["utm_source", "gclid"]; // The parameters used by paid medias to determine the origin of the click
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var sourceValue;
    var domain = ".streamily.com"; // UPDATE TO YOUR TOP LEVEL DOMAIN
    var organicFilter = false; //"true" will consider SEO as other channels, "false" Awin will measure organic traffic after interactions with an Awin link.

    Data.setTime(Data.getTime() + cookieLength * 24 * 60 * 60 * 1000);
    latency = Data.toUTCString();

    //Check if user is merely navigating the website's pages or if it is the first access.
    if (!document.referrer.includes(window.location.hostname)) {
      //Check if user accessed the website through parallel tracking links.
      if (window.location.href.indexOf("awaid") > -1) {
        origin = "aw";
      } else {
        //check if the user came from SEO links and de-dupe against it if organic filter is enabled.
        if (
          (organicFilter === true && document.referrer.includes("google")) ||
          document.referrer.includes("yahoo") ||
          document.referrer.includes("bing") ||
          document.referrer.includes("yandex") ||
          document.referrer.includes("duckduckgo")
        ) {
          origin = "other";
        } else {
          //Check if Awin was the last paid click referring channel
          for (var i = 0; i < sourceParameter.length; i++) {
            if (queryString.includes(sourceParameter[i])) {
              sourceValue = urlParams.get(sourceParameter[i]);
              if (
                sourceValue === "awin" ||
                sourceValue === null ||
                sourceValue === "undefined"
              ) {
                origin = "aw";
              } else {
                origin = "other";
              }

              break;
            } else {
              //No source parameter found, store "aw" as origin as a fallback to account for tracking errors.
              origin = "aw";
            }
          }
        }
      }
      //Store the origin
      document.cookie = `AwinChannelCookie=${origin}; expires=${latency};path=/; Domain=${domain}`;
    }

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};
