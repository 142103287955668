import axios from "axios";

export default async function updateGroupShopStatus({
  influencerId,
  groupShop,
}) {
  try {
    const { status } = await axios.put(`/api/influencer/${influencerId}`, {
      group_shop: groupShop,
    });
    return status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
}
