export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export function setWithExpiry(key, value, expiry = ONE_DAY_IN_MILLISECONDS) {
  localStorage.setItem(
    key,
    JSON.stringify({ value, expiry: Date.now() + expiry }),
  );
}

const localStorageUtils = {
  getWithExpiry,
  setWithExpiry,
  DEFAULT_EXPIRY: ONE_DAY_IN_MILLISECONDS,
};

export default localStorageUtils;
