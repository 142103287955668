import * as React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";
import { Addon } from "../domain";

const baseEndpoint = "/api/addons";

/*
 * useAddons returns all addons
 *
 * @returns addonHookObject { addons, isLoading, isError, error, refresh }
 */
export default function useAddons() {
  const { data, error, mutate } = useSWR(baseEndpoint, fetcher);

  const addons = React.useMemo(() => {
    if (!data) return;
    return Addon.fromAddonMap(data);
  }, [data]);

  return {
    addons,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

/*
 * useAvailableAddonsForProduct returns an addon map of available addons for
 * a given productId
 *
 * @param {number} productId
 * @returns {object} addonHookObject { availableAddons, isLoading, isError, error, refresh }
 */
export function useAvailableAddonsForProduct(productId) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/product/${productId}`,
    fetcher,
  );

  const availableAddons = React.useMemo(() => {
    if (!data) return;
    return Addon.fromAddonMap(data);
  }, [data]);

  return {
    availableAddons,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

/*
 * useAvailableAddonsForPurchase returns an addon map of unpurchased addons for
 * an autographPurchase
 *
 * @param {number} autographPurchaseId
 * @returns {object} addonHookObject { availableAddons, isLoading, isError, error, refresh }
 */
export function useAvailableAddonsForPurchase(autographPurchaseId) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/autographPurchase/${autographPurchaseId}`,
    fetcher,
  );

  const availableAddons = React.useMemo(() => {
    if (!data) return;
    return Addon.fromAddonMap(data);
  }, [data]);

  return {
    availableAddons,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

/*
 * usePurchasedAddons returns an addon map of purchased addons for an autographPurchase
 *
 * @param {number} autographPurchaseId
 * @returns {object} addonHookObject { purchasedAddons, isLoading, isError, error, refresh }
 */
export function usePurchasedAddons(autographPurchaseId) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/autographPurchase/${autographPurchaseId}/purchased`,
    fetcher,
  );

  const purchasedAddons = React.useMemo(() => {
    if (!data) return;
    return Addon.fromAddonMap(data);
  }, [data]);

  return {
    purchasedAddons,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
