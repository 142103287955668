import {
  Link,
  Box,
  Button,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import { EditRounded, SaveRounded } from "@material-ui/icons";
import { G, B } from "@mobily/ts-belt";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import useSWR from "swr";
import { fetcher } from "../../../hooks";
import { Form } from "../../../theme";
import { currencyFormatter } from "../../../util";

function useShopSplit(influencerId) {
  const { data, mutate, error } = useSWR(
    `/api/payoutCut/${influencerId}`,
    fetcher,
  );

  const shopSplit = React.useMemo(() => {
    if (!data || !data.payoutCut) {
      return null;
    }

    return data.payoutCut;
  }, [data]);

  return {
    isError: error,
    isLoading: !error && !data,
    mutate,
    shopSplit,
  };
}

function renderErrorMessage({
  errorMessage,
  setErrorMessage,
  setInviteModal,
  mutate,
}) {
  if (!errorMessage) {
    return null;
  }

  if (G.isString(errorMessage)) {
    return (
      <Typography
        onClick={() => {
          setErrorMessage("");
        }}
        variant="caption"
        color="error"
      >
        {errorMessage}
      </Typography>
    );
  }

  if (G.isObject(errorMessage)) {
    return (
      <span>
        No user with the email {errorMessage.email} found.{" "}
        <Link
          style={{ cursor: "pointer" }}
          component="a"
          color="secondary"
          onClick={() => {
            mutate();
            setInviteModal({ email: errorMessage.email });
          }}
        >
          Invite them to the platform.
        </Link>
      </span>
    );
  }

  return errorMessage;
}

export default function ShopSplit({ influencerId, userInviteModalAtom }) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isEditing, setIsEditing] = React.useState(false);
  const { isError, isLoading, shopSplit, mutate } = useShopSplit(influencerId);
  const [email, setEmail] = React.useState(shopSplit?.email || "");
  const [confirmModal, setConfirmModal] = React.useState(null);
  const [, setInviteModal] = useAtom(userInviteModalAtom);

  const onAddUser = async (
    email,
    { force = false, reassignUserIds = [] } = {},
  ) => {
    try {
      setErrorMessage("");

      if (shopSplit && shopSplit.email === email) {
        return;
      }

      const response = await window.fetch(`/api/payoutCut/${influencerId}`, {
        method: "PUT",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ force, reassignUserIds, email }),
      });

      if (response.ok) {
        setIsEditing(false);
        return mutate();
      }

      if (response.status === 450) {
        const json = await response.json();
        const { message, stats } = json;
        return setConfirmModal({ message, stats, email });
      }

      // If the user is not found
      if (response.status === 404) {
        setErrorMessage({ type: "Account Does Not Exist", email });
        return;
      }

      // Email is not valid
      if (response.status === 422) {
        setErrorMessage("Invalid email address");
        return;
      }

      const json = await response.json();
      const { message } = json;
      throw new Error(message);
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  const onSubmit = (evt) => {
    evt.preventDefault();

    if (isEditing && !email) {
      setErrorMessage("Unable to save changes, please enter an email.");
      return;
    }

    if (isEditing && shopSplit?.email !== email) {
      return onAddUser(email);
    }

    setIsEditing(B.not);
  };

  React.useEffect(() => {
    setEmail(shopSplit?.email || "");
  }, [shopSplit?.email]);

  if (isError) {
    return (
      <Typography variant="body2" color="error">
        Failed to load shop split
      </Typography>
    );
  }

  return (
    <>
      <Box
        py={2}
        display="flex"
        gridGap="8px"
        alignItems={errorMessage ? "flex-start" : "center"}
        onChange={() => {
          if (errorMessage) {
            setErrorMessage("");
          }
        }}
        maxWidth={480}
        component="form"
        onSubmit={onSubmit}
      >
        <Box flex="1">
          <Form.Input
            fullWidth
            value={email}
            onChange={(evt) => setEmail(evt.target.value.trim())}
            disabled={!isEditing || isLoading}
            label="Payout Account"
            error={Boolean(errorMessage)}
            helperText={renderErrorMessage({
              errorMessage,
              setErrorMessage,
              setInviteModal,
              mutate,
            })}
          />
        </Box>

        <IconButton type="submit" title={isEditing ? "Save" : "Edit"}>
          {isEditing ? (
            <SaveRounded fontSize="small" />
          ) : (
            <EditRounded fontSize="small" />
          )}
        </IconButton>
      </Box>

      {confirmModal && (
        <ConfirmModal
          email={confirmModal.email}
          stats={confirmModal.stats}
          open={Boolean(confirmModal)}
          onClose={() => {
            setConfirmModal(null);
          }}
          onConfirm={onAddUser}
        />
      )}
    </>
  );
}

ShopSplit.propTypes = {
  influencerId: PropTypes.number.isRequired,
  userInviteModalAtom: PropTypes.object.isRequired,
};

function ConfirmModal({ email, stats, open, onClose, onConfirm }) {
  const [reassignUserIds, setReassignUserIds] = React.useState([]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={2} display="flex" flexDirection="column" gridGap="16px">
        <Typography variant="h6">Confirm User Reassignment</Typography>

        {Object.values(stats.users).map((oldUser) => (
          <Box key={oldUser.userId}>
            <Typography gutterBottom>
              <strong>{oldUser.email}</strong> is currently owed{" "}
              <strong>{currencyFormatter.format(oldUser.amount / 100)}</strong>.
            </Typography>

            <Box display="flex" gridGap="8px" alignItems="center">
              <Form.Radio
                checked={!reassignUserIds.includes(oldUser.userId)}
                onChange={() => {
                  setReassignUserIds((prev) =>
                    prev.filter((userId) => userId !== oldUser.userId),
                  );
                }}
                id={`payout-keep-${oldUser.userId}`}
              />
              <Typography
                component="label"
                htmlFor={`payout-keep-${oldUser.userId}`}
              >
                Keep the existing payout to the current user{" "}
                <strong>{oldUser.email}</strong>
              </Typography>
            </Box>

            <Box display="flex" gridGap="8px" alignItems="center">
              <Form.Radio
                checked={reassignUserIds.includes(oldUser.userId)}
                onChange={() => {
                  setReassignUserIds((prev) => [...prev, oldUser.userId]);
                }}
                id={`payout-reassign-${oldUser.userId}`}
              />
              <Typography
                component="label"
                htmlFor={`payout-reassign-${oldUser.userId}`}
              >
                Reassign this payout to <strong>{email}</strong>
              </Typography>
            </Box>
          </Box>
        ))}

        <Box display="flex" justifyContent="flex-end" gridGap="16px" mt={2}>
          <Button onClick={onClose}>Cancel</Button>

          <Button
            style={{ minWidth: 150 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              onConfirm(email, { force: true, reassignUserIds });
              onClose();
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  stats: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
};
