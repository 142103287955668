import * as React from "react";
import PropTypes from "prop-types";
import AffiliateLinksModal from "./AffiliateLinksModal";
import { Box, Button } from "@material-ui/core";

export default function AffiliateLinks({ influencerId, influencerRoute }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Box display="flex" justifyContent="center">
      <Button
        color="secondary"
        fullWidth
        onClick={() => {
          setIsOpen(true);
        }}
        variant="contained"
      >
        Affiliate Links
      </Button>

      <AffiliateLinksModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        influencerId={influencerId}
        influencerRoute={influencerRoute}
      />
    </Box>
  );
}

AffiliateLinks.propTypes = {
  influencerId: PropTypes.number.isRequired,
  influencerRoute: PropTypes.string.isRequired,
};
