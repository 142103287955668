import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const GreySmallHeading = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.semiBold,
  fontSize: theme.typography.h6.fontSize,
  fontFamily: theme.typography.interFontStack,
  color: theme.palette.grey[900],
  marginTop: 0,
  marginBottom: 0,
}));

export default GreySmallHeading;
