import { Dialog } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import scrollbarStyles from "../scrollbarStyles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-scrollPaper": {
    alignItems: "flex-start",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  "& .MuiPaper-root": {
    border: "0.65px solid #CCC",
    overflowX: "hidden",
    backgroundColor: "linear-gradient(0deg, #FFF 0%, #FFF 100%), #F9F9F9",
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down("sm")]: { width: "100vw" },
    [theme.breakpoints.up("md")]: { width: 632, minWidth: "632px" },
  },
  "&.narrow .MuiPaper-root": {
    [theme.breakpoints.down("sm")]: { width: "100vw" },
    [theme.breakpoints.up("md")]: { width: 480, minWidth: "480px" },
  },
  "& .MuiDialog-paperScrollPaper": {
    ...scrollbarStyles(theme),
  },
  "& .MuiDialog-paperScrollPaper::-webkit-scrollbar-track": {
    borderRadius: theme.spacing(0.5),
  },
}));

export default StyledDialog;
