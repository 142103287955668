import { Box, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { D, F, G } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { SignType } from "../../../domain";
import { useInfluencer, useSessionStorage } from "../../../hooks";
import {
  ErrorUI,
  LoadingUI,
  SexierButton,
  SoftMediumCard,
} from "../../../theme";
import { SearchSelectManual } from "../../../theme/Form";
import List from "./List";

const getOptionLabel = (type) => SignType.toLabel(type);

const options = SignType.list();
const defaultOptions = D.fromPairs(options.map((t) => [t, t]));
const defaultSelected = {};

const ShopBuilderProducts = React.memo(function ShopBuilderProducts({
  route,
  setAddingProduct,
  salesCountMap,
  onClose,
}) {
  const [savedUnselected, setSavedUnselected] = useSessionStorage(
    "STREAMILY::PRODUCT::FILTER",
    defaultSelected,
  );

  const [selected, setSelected] = React.useState(defaultOptions);

  React.useEffect(() => {
    if (D.isEmpty(savedUnselected)) {
      setSelected(defaultOptions);
    } else if (F.equals(savedUnselected, defaultOptions)) {
      setSelected(defaultSelected);
    } else {
      setSelected(D.deleteKeys(defaultOptions, D.keys(savedUnselected)));
    }
  }, [savedUnselected]);

  const setSelectedAndSave = React.useCallback(
    (s) => {
      let newSelected = s;

      if (G.isFunction(s)) {
        newSelected = s(selected);
      }

      if (D.isEmpty(newSelected)) {
        setSavedUnselected(defaultOptions);
      } else if (F.equals(newSelected, defaultOptions)) {
        setSavedUnselected(defaultSelected);
      } else {
        setSavedUnselected(D.deleteKeys(defaultOptions, D.keys(newSelected)));
      }
    },
    [selected, setSavedUnselected],
  );

  const { influencer, isLoading, isError, refresh } = useInfluencer({
    route,
    forShopBuilder: true,
  });

  const onClickAdd = React.useCallback(() => {
    setAddingProduct(true);
  }, [setAddingProduct]);

  const activeProductFilter = React.useCallback(
    (product) => {
      return (
        D.keys(selected).some((key) => key === product.signedType) &&
        product.isActive &&
        !product.isArchived
      );
    },
    [selected],
  );

  const inactiveProductFilter = React.useCallback(
    (product) => {
      return (
        D.keys(selected).some((key) => key === product.signedType) &&
        (!product.isActive || product.isArchived)
      );
    },
    [selected],
  );

  const getSelectLabel = React.useCallback(
    ({ hasSelectedAll, hasSelectedNone, selected }) => {
      if (hasSelectedAll) {
        return "All Types";
      }

      if (hasSelectedNone) {
        return "No Types";
      }

      const { length } = D.keys(selected);

      return `Some Types (${length})`;
    },
    [],
  );

  const addProductButton = React.useMemo(
    () => (
      <Box mb={2} display="flex" gridGap="16px" alignItems="flex-end">
        <Box>
          <SexierButton
            onClick={onClickAdd}
            startIcon={<AddIcon />}
            size="medium"
          >
            Add Product
          </SexierButton>
        </Box>
      </Box>
    ),
    [onClickAdd],
  );

  const productFilter = React.useMemo(() => {
    return (
      <Box width={200}>
        <Typography variant="caption" gutterBottom style={{ fontWeight: 600 }}>
          Filter Shop Products
        </Typography>

        <SearchSelectManual
          disableSorting
          getSelectLabel={getSelectLabel}
          style={{ border: "1px solid #dfdfdf", width: "100%" }}
          options={options}
          getOptionLabel={getOptionLabel}
          selected={selected}
          setSelected={setSelectedAndSave}
        />
      </Box>
    );
  }, [getSelectLabel, selected, setSelectedAndSave]);

  if (isLoading) {
    return <LoadingUI />;
  }

  if (isError) {
    return <ErrorUI message="Unable to load shop products" />;
  }

  return (
    <SoftMediumCard>
      <List
        productFilter={productFilter}
        action={addProductButton}
        emptyLabel="No Products"
        filter={activeProductFilter}
        influencer={influencer}
        onClickAdd={onClickAdd}
        onClose={onClose}
        refresh={refresh}
        salesCountMap={salesCountMap}
        subtitle="These products are available on your shop"
        title="Active Products"
      />

      <Box py={4}></Box>

      <List
        emptyLabel="No Inactive Products"
        filter={inactiveProductFilter}
        influencer={influencer}
        onClose={onClose}
        refresh={refresh}
        salesCountMap={salesCountMap}
        title="Inactive Products"
      />
    </SoftMediumCard>
  );
});

ShopBuilderProducts.propTypes = {
  onClose: PropTypes.func,
  route: PropTypes.string.isRequired,
  salesCountMap: PropTypes.object,
  setAddingProduct: PropTypes.func.isRequired,
};

export default ShopBuilderProducts;
