import useSWR from "swr";
import fetcher from "./fetcher";

export default function useReferralLink() {
  const { data, error } = useSWR("/api/referralCode", fetcher);

  return {
    code: data?.code,
    error,
    isLoading: !error && !data?.code,
    isError: Boolean(error),
  };
}
