import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import { useInfluencerRoute } from "../../../hooks";
import { ErrorUI, GridPaginator, LoadingUI } from "../../../theme";
import ProductContainer from "./ProductContainer";

const ShopProducts = React.memo(function ShopProducts({
  signType,
  setProduct,
}) {
  const { influencer, isLoading, isError } = useInfluencerRoute();

  const products = React.useMemo(() => {
    if (!influencer?.products) {
      return [];
    }

    const hasNoSignedType = !signType || signType === "All Signing Types";

    return influencer.products.reduce((result, product) => {
      if (hasNoSignedType || signType === product.signedType) {
        result.push(
          <ProductContainer
            key={product.productId}
            product={product}
            onOpen={setProduct}
            influencer={influencer}
          />,
        );
      }
      return result;
    }, []);
  }, [influencer, signType, setProduct]);

  if (isLoading) {
    return <LoadingUI />;
  }

  if (isError) {
    return <ErrorUI message="Unable to load shop products" />;
  }

  return (
    <Box pb={8} pt={4}>
      {products.length > 0 && (
        <GridPaginator useObserver pageItems={products} pageSize={20} />
      )}
    </Box>
  );
});

ShopProducts.propTypes = {
  signType: PropTypes.string,
  setProduct: PropTypes.func,
};

export default ShopProducts;
