import * as React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
  Grid,
  FormControlLabel,
  FormGroup,
  Switch,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import ConfirmModal from "../../../ConfirmModal";
import { capitalizeWord } from "../../../../util";

async function setAttribute({ influencerId, attributeKey, attributeValue }) {
  try {
    const { status } = await axios.put(`/api/influencer/${influencerId}`, {
      [attributeKey]: attributeValue,
    });
    return status === 200;
  } catch (err) {
    console.error(err);
    return false;
  }
}

function readable(attributeKey) {
  const words = attributeKey.split("_");
  return words.map(capitalizeWord).join(" ");
}

const ToggleAttribute = React.memo(function ToggleAttribute({
  attributeKey,
  attributeValue,
  displayName,
  influencerId,
}) {
  const [hasAttribute, setHasAttribute] = React.useState(
    Boolean(attributeValue),
  );
  const [showModal, setShowModal] = React.useState(null);
  const [errorText, setErrorText] = React.useState("");
  const readableAttributeKey = displayName ?? readable(attributeKey);

  const onChange = React.useCallback(
    (_, newValue) => {
      setShowModal({
        action: async () => {
          const isSuccess = await setAttribute({
            influencerId,
            attributeKey,
            attributeValue: newValue,
          });
          if (isSuccess) {
            setHasAttribute(newValue);
          } else {
            setErrorText(
              `Unable to update status of attribute ${readableAttributeKey}`,
            );
          }
          setShowModal(null);
        },
        onClose: () => {
          setShowModal(null);
        },
        description: `set shop attribute ${readableAttributeKey} to ${String(
          newValue,
        )}`,
      });
    },
    [
      attributeKey,
      influencerId,
      readableAttributeKey,
      setHasAttribute,
      setShowModal,
    ],
  );

  return (
    <>
      {Boolean(showModal) && <ConfirmModal {...showModal} />}

      <Grid container justifyContent="center">
        <FormControl error={Boolean(errorText)} fullWidth>
          <FormGroup row>
            <FormControlLabel
              style={{ gap: 8 }}
              control={
                <Switch
                  color="secondary"
                  checked={hasAttribute}
                  name={attributeKey}
                  onChange={onChange}
                />
              }
              label={readableAttributeKey}
            />
          </FormGroup>
          <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
      </Grid>
    </>
  );
});

ToggleAttribute.propTypes = {
  attributeKey: PropTypes.string.isRequired,
  attributeValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    .isRequired,
  displayName: PropTypes.string,
  influencerId: PropTypes.number.isRequired,
};

export default ToggleAttribute;
