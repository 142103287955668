import * as React from "react";
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const Badge = styled(Typography)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: theme.palette.red.main,
  color: "white",
  borderRadius: 12,
  padding: "0 4px",
  boxSizing: "border-box",
  height: `18px !important`,
  lineHeight: 1,
  minWidth: 18,
  fontSize: `${theme.typography.pxToRem(12)} !important`,
  fontFamily: theme.typography.interFontStack,
  fontWeight: theme.typography.semiBold,
  width: "fit-content",
}));

const RedNumberBadge = React.forwardRef(function RedNumberBadge(
  { value },
  ref,
) {
  return <Badge ref={ref}>{value}</Badge>;
});

export default RedNumberBadge;
