import * as React from "react";

const terms = `
Terms and conditions. Do not send irreplaceable items.

- Upon purchase of Send-in, you will receive a detailed email with address and instructions
- Streamily is not responsible for personal items lost or damaged in shipping
- Items not received by signing time are signed the next session
- Streamily reserves the right to screen orders before they are delivered to the artists
- Artists may refuse to sign any items at their discretion
- Items inappropriate for all ages will not be sent to the artist, will not be refunded,
  and will be returned unsigned
- Send In items cannot contain human/animal samples, illegal items, weapons, marijuana, 
  explosives, gasoline, or air bags
- Send in items must comply with the prohibitions/restrictions of the carrier
- Please purchase insurance from your carrier for your item. Streamily is not responsible 
  for lost, stolen or damage to your item during shipping.
- International items exceeding 16ox/1lb/454g will not be returned
- Items not signed within 90 days of arrival at Streamily Facility due to artist unavailability 
  will be returned and the cost of the autograph will be refunded
- Streamily is not responsible for lost/damaged items in shipping
- All claims for lost/damaged merchandise must be filed through the carrier
`;
const termsHtml = (
  <div>
    <b>DO NOT</b> send irreplaceable items.
    <ul>
      <li>
        Upon purchase of Send-in, you will receive a detailed email with address
        and instructions
      </li>
      <li>
        Streamily is not responsible for personal items lost or damaged in
        shipping
      </li>
      <li>Items not received by signing time are signed the next session</li>
      <li>
        Streamily reserves the right to screen orders before they are delivered
        to the artists
      </li>
      <li>Artists may refuse to sign any items at their discretion</li>
      <li>
        Items inappropriate for all ages will not be sent to the artist, will
        not be refunded, and will be returned unsigned
      </li>
      <li>
        Send In items cannot contain human/animal samples, illegal items,
        weapons, marijuana, explosives, gasoline, or air bags
      </li>
      <li>
        Send in items must comply with the prohibitions/restrictions of the
        carrier
      </li>
      <li>
        Please purchase insurance from your carrier for your item. Streamily is
        not responsible for lost, stolen or damage to your item during shipping.
      </li>
      <li>International items exceeding 16ox/1lb/454g will not be returned</li>
      <li>
        Items not signed within 90 days of arrival at Streamily Facility due to
        artist unavailability will be returned and the cost of the autograph
        will be refunded
      </li>
      <li>Streamily is not responsible for lost/damaged items in shipping</li>
      <li>
        All claims for lost/damaged merchandise must be filed through the
        carrier
      </li>
    </ul>
  </div>
);

const sendinInfographic =
  "https://d3qdxs9s8vjwda.cloudfront.net/misc/streamily-send-in-process-600px-v3.jpg";

const sendinNoKitInfographic =
  "https://d3qdxs9s8vjwda.cloudfront.net/misc/streamily-send-in-process-no-kit-600px.jpg";

const sendinInternationalInfographic =
  "https://d3qdxs9s8vjwda.cloudfront.net/misc/streamily-send-in-process-international-600px.jpg";

const sendinText = `
1. Pick appropriate sized kit. A pre-paid shipping kit will be sent to the provided address.
   Securely place the items within the kit for shipment.

2. Give kit to mail carrier. Once packaged, give the shipping kit to your mail carrier for
   shipment to Streamily.

3. Watch it signed. Watch your item get signed LIVE by the artist on their livestream!
   Request custom personalization during checkout.

4. Receive it back. When we receive your item from the artist, we will process and ship
   it back to you.

${terms}
`;

const sendinNoKitText = `
1. Send item to Streamily. Package your item securely and send it to Streamily headquarters.
   Address details provided upon purchase. Postage will be emailed to the email provided with
   the order. Item must not exceed 16oz/1lb/454g.

2. Watch it signed. Watch your item get signed LIVE by the artist on their livestream!
   Request custom personalization during checkout.

3. Receive it back. When we receive your item from the artist, we will process and ship
   it back to you. Return postage included with purchase.

${terms}
`;

const sendinInternationalText = `
International orders. Outgoing kit/postage are not provided. Return postage is included with
purchase.

1. Send item to Streamily. Package your item securely and send it to Streamily headquarters.
   Address details provided upon purchase. Outgoing postage not included. Item must not
   exceed 16oz/1lb/454g.

2. Watch it signed. Watch your item get signed LIVE by the artist on their livestream!
   Request custom personalization during checkout.

3. Receive it back. When we receive your item from the artist, we will process and ship
   it back to you. Return postage included with purchase.

${terms}
`;

export default class Sendin {
  static termsHtml = termsHtml;

  static infographicFor(product) {
    if (product.isSendin) {
      return sendinInfographic;
    }

    if (product.isSendinNoKit) {
      return sendinNoKitInfographic;
    }

    if (product.isSendinInternational) {
      return sendinInternationalInfographic;
    }
  }

  static textFor(product) {
    if (product.isSendin) {
      return sendinText;
    }

    if (product.isSendinNoKit) {
      return sendinNoKitText;
    }

    if (product.isSendinInternational) {
      return sendinInternationalText;
    }
  }
}
