import { styled } from "@material-ui/core/styles";

const ProductButton = styled("button")(({ theme }) => ({
  color: theme.palette.text.primary,
  textAlign: "unset",
  margin: 0,
  width: "100%",
  border: "none",
  background: "transparent",
  font: "inherit",
  position: "relative",
  cursor: "pointer",
  fontWeight: "unset",
  padding: 0,
  "& .shop-page-product-image-container": {
    transition: "all 0.1s ease-out",
    outline: `2px solid transparent`,
  },
  "&:hover": {
    background: "transparent",
    "& .shop-page-product-image-container": {
      transition: "all 0.1s ease-out",
      boxShadow: theme.boxShadow.light,
      outline: `2px solid rgba(0, 0, 0, 0.035)`,
    },
  },
  "& .MuiButton-label": {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch",
  },
}));

export default ProductButton;
