import { Box, IconButton, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { A } from "@mobily/ts-belt";
import { useAtom, useAtomValue } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { useActiveInfluencer, useCurrentUser } from "../../../hooks";
import { Form, InfoTooltip } from "../../../theme";
import { totalsFor } from "../Step2/utils";
import * as styles from "../styles";
import { format } from "../utils";
import SignerSearch from "./SignerSearch";

const MAX_PRODUCT_PRICE = 1000000; // $10000 in cents
const MIN_PRODUCT_PRICE = 0; // $0 in cents

function ProductSigners({ productSignersAtom, productAtom }) {
  const [signers, setSigners] = useAtom(productSignersAtom);
  const product = useAtomValue(productAtom);
  const currentUser = useCurrentUser();
  const activeInfluencer = useActiveInfluencer();

  const { currentSigner, otherSigners, influencerIds } = React.useMemo(() => {
    const otherSigners = [];
    const influencerIds = [];

    let currentSigner = null;

    signers.forEach((signer) => {
      influencerIds.push(signer.influencerId);

      if (signer.influencerId === activeInfluencer.influencerId) {
        currentSigner = signer;
      } else {
        otherSigners.push(signer);
      }
    });

    return { currentSigner, otherSigners, influencerIds };
  }, [signers, activeInfluencer]);

  const handleAddSigner = React.useCallback(
    (signer) => {
      setSigners(A.append(signer));
    },
    [setSigners],
  );

  const handleRemoveSigner = React.useCallback(
    (signer) => {
      setSigners(A.filter((s) => s.influencerId !== signer.influencerId));
    },
    [setSigners],
  );

  const handleUpdateAmount = React.useCallback(
    (currentSigner, isBlur) => {
      return (evt) => {
        const { target } = evt;

        let newValue = Math.round(
          Number.parseFloat(target.value.replace(/[^0-9.-]/g, "")),
        );

        // If the value is NaN, reset the value to the current amount
        if (Number.isNaN(newValue) && isBlur) {
          target.value = format(currentSigner.splitAmount);
          return;
        }

        if (Number.isNaN(newValue)) {
          newValue = 0;
        }

        const splitAmount = Math.max(
          MIN_PRODUCT_PRICE,
          Math.min(newValue * 100, MAX_PRODUCT_PRICE),
        );

        const mapFn = (signer) =>
          signer === currentSigner ? { ...currentSigner, splitAmount } : signer;

        setSigners(A.map(mapFn));

        if (!isBlur && newValue === 0) {
          target.value = "";
        }

        target.value = format(splitAmount);
      };
    },
    [setSigners],
  );

  const handleUpdateNoFulfillment = React.useCallback(
    (currentSigner) => {
      return (evt) => {
        const newValue = evt.target.checked;
        const mapFn = (signer) =>
          signer === currentSigner
            ? { ...currentSigner, noFulfillment: newValue }
            : signer;
        setSigners(A.map(mapFn));
      };
    },
    [setSigners],
  );

  const { total, bump } = React.useMemo(
    () => totalsFor({ product, signers }),
    [product, signers],
  );

  const label = React.useMemo(() => {
    if (!currentSigner) {
      return "";
    }

    const isNotOwner =
      activeInfluencer.ownerEmail !== currentUser.email || currentUser.isAdmin;

    const phrase = isNotOwner ? ` does ${currentSigner.name}` : " do you";

    return `How much ${phrase} want to make`;
  }, [currentUser, currentSigner, activeInfluencer]);

  const canSeeOtherSigners = currentUser.isAdmin;

  return (
    <Box display="flex" flexDirection="column" mt={2}>
      {!currentSigner && A.isEmpty(otherSigners) && (
        <Box width="100%" my={2}>
          <Typography variant="body2" color="textSecondary">
            No signers have been added to this product yet.
          </Typography>
        </Box>
      )}

      {currentSigner && (
        <Box
          display="flex"
          gridGap="0px 16px"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          width="100%"
        >
          <Box>
            <styles.Label>
              {label}
              <InfoTooltip
                title={label}
                content="Any affiliate cuts will be deducted from this amount"
                size="small"
              />
            </styles.Label>

            {currentUser.isAdmin && (
              <Form.SmallCheckbox
                style={{ marginLeft: 8, marginRight: 8, padding: 0 }}
                label="No Signature Required"
                size="small"
                checked={currentSigner.noFulfillment}
                onChange={handleUpdateNoFulfillment(currentSigner)}
                title="This talent will not be required to sign the product in order to mark it as complete."
              />
            )}
          </Box>

          <Box display="flex" alignItems="center" gridGap="8px">
            <Form.Input
              className="grey-filled"
              type="number"
              inputProps={{ size: 2, max: MAX_PRODUCT_PRICE, min: 1, step: 1 }}
              InputProps={{ startAdornment: "$" }}
              required
              defaultValue={format(currentSigner?.splitAmount ?? 0)}
              size="small"
              placeholder="60"
              onChange={handleUpdateAmount(currentSigner)}
              onBlur={handleUpdateAmount(currentSigner, true)}
            />

            {currentUser.isAdmin && (
              <IconButton
                size="small"
                onClick={() => handleRemoveSigner(currentSigner)}
              >
                <Delete fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Box>
      )}

      {A.isNotEmpty(otherSigners) &&
        canSeeOtherSigners &&
        otherSigners.map((signer) => (
          <Box
            key={signer.influencerId}
            display="flex"
            gridGap="0px 16px"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            width="100%"
          >
            <Box>
              <styles.Label>
                How much does {signer.name} want to make
              </styles.Label>

              {currentUser.isAdmin && (
                <Form.SmallCheckbox
                  checked={signer.noFulfillment}
                  onChange={handleUpdateNoFulfillment(signer)}
                  style={{ marginLeft: 8, marginRight: 8, padding: 0 }}
                  label="No Signature Required"
                  size="small"
                  title="This talent will not be required to sign the product in order to mark it as complete."
                />
              )}
            </Box>

            <Box display="flex" alignItems="center" gridGap="8px">
              <Form.Input
                className="grey-filled"
                type="number"
                inputProps={{
                  size: 2,
                  max: MAX_PRODUCT_PRICE,
                  min: 1,
                  step: 1,
                }}
                InputProps={{ startAdornment: "$" }}
                defaultValue={format(signer?.splitAmount ?? 0)}
                size="small"
                placeholder="60"
                onChange={handleUpdateAmount(signer)}
                onBlur={handleUpdateAmount(signer, true)}
                disabled={!currentUser.isAdmin}
              />

              {currentUser.isAdmin && (
                <IconButton
                  size="small"
                  onClick={() => handleRemoveSigner(signer)}
                >
                  <Delete fontSize="small" />
                </IconButton>
              )}
            </Box>
          </Box>
        ))}

      <Box
        display="flex"
        gridGap="0px 16px"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        pb={1}
        style={{ borderBottom: "1px solid #ebebeb" }}
        width="100%"
      >
        <styles.Label>Platform Fee (approx)</styles.Label>

        <Form.Input
          className="white-filled"
          type="number"
          inputProps={{ size: 2 }}
          InputProps={{ startAdornment: "$" }}
          value={format(bump)}
          size="small"
          readOnly
        />
      </Box>

      <Box
        display="flex"
        gridGap="0px 16px"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <styles.Label>
          Total Product Price
          <InfoTooltip
            title="Total Product Price"
            content="This amount also includes fees for multiple signers"
            size="small"
          />
        </styles.Label>

        <Form.Input
          className="white-filled"
          type="number"
          inputProps={{ size: 2 }}
          InputProps={{ startAdornment: "$" }}
          value={format(total + bump)}
          size="small"
          readOnly
        />
      </Box>

      {currentUser.isAdmin && (
        <SignerSearch
          influencerIds={influencerIds}
          onAddSigner={handleAddSigner}
        />
      )}
    </Box>
  );
}

ProductSigners.propTypes = {
  productSignersAtom: PropTypes.object.isRequired,
  productAtom: PropTypes.object.isRequired,
};

export default React.memo(ProductSigners);
