import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useShopsSearch } from "../../../hooks";
import SearchResult from "./SearchResult";

function SearchResultsContainer({ query, onClick }) {
  const { shops } = useShopsSearch(query);

  const hasResults = shops.length > 0;

  return (
    <SearchContainer hasResults={hasResults}>
      {hasResults ? (
        <SearchResults>
          {shops.map((item) => (
            <SearchResult
              item={item}
              key={item.influencer_id}
              onClick={onClick}
            />
          ))}
        </SearchResults>
      ) : (
        <NoResults>No results.</NoResults>
      )}
    </SearchContainer>
  );
}

SearchResultsContainer.propTypes = {
  query: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default React.memo(SearchResultsContainer);

const SearchContainer = styled.div`
  width: 100%;
  z-index: 1;
  position: absolute;
  border-radius: 10px;
  margin-top: 5px;
  background: white;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
  border: 0.2px solid #d4d4d4;
`;

const SearchResults = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding: 10px 0;
`;

const NoResults = styled.div`
  padding: 10px;
  color: rgb(60, 60, 60);
  font-size: 14px;
`;
