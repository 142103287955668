import * as React from "react";
import Footer from "../../components/Footer";
import { Sections, Background } from "../../components/Home";
import { useMatchesSmDown } from "../../hooks";

export default function Home() {
  const isMobile = useMatchesSmDown();

  return (
    <>
      <Sections.Hero isMobile={isMobile} />

      <div className="home-page__sections__container">
        <Background />
        <div className="home-page__background-gradient" />

        <Sections.Main isMobile={isMobile} />

        <Footer isHomePage isMobile={isMobile} />
      </div>
    </>
  );
}
