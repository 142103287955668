import * as React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledLink = styled("button")(({ theme }) => ({
  textDecoration: "none",
  textAlign: "left",
  padding: 0,
  margin: 0,
  display: "inline",
  background: "none",
  border: "none",
  color: theme.palette.primary.main,
  "&:hover": {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const StyledLinkButton = React.memo(function StyledLinkButton({
  children,
  typographyVariant,
  ...buttonProps
}) {
  return (
    <StyledLink {...buttonProps}>
      <Typography variant={typographyVariant ?? "body1"}>{children}</Typography>
    </StyledLink>
  );
});

StyledLinkButton.propTypes = {
  children: PropTypes.node,
  typographyVariant: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
};

export default StyledLinkButton;
