import * as React from "react";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { Influencer, withIncludes } from "../domain";
import fetcher from "./fetcher";

const baseEndpoint = "/api/influencer";

export default function useInfluencerRoute() {
  const params = useParams();

  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/byRoute/${params.route}`,
    fetcher,
  );

  const influencer = React.useMemo(() => {
    if (!data) {
      return;
    }

    return withIncludes(Influencer.from(data));
  }, [data]);

  return {
    influencer,
    isLoading: !influencer && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

/*
 * prefetchInfluencer attempts to prefetch the provided influencer by Route
 *
 * @param {string} route
 */
export function prefetchInfluencer(route) {
  const endpoint = `${baseEndpoint}/byRoute/${route}`;

  mutate(endpoint, fetcher(endpoint));
}
