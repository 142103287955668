function getCookie(name) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const [cookieName, cookieValue] = cookies[i].split("=");
    if (cookieName && cookieName.trim() === name) {
      return cookieValue;
    }
  }
}

export default getCookie;
