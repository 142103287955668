import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const PageHeading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h3.fontSize,
  fontWeight: theme.typography.semiBold,
  fontFamily: theme.typography.interFontStack,
  marginBottom: 0,
  marginTop: 0,
}));

export default PageHeading;
