import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Typography } from "@material-ui/core";
import SoftLargeCard from "../SoftLargeCard";
import Tooltip from "./Tooltip";
import RelativeBox from "../RelativeBox";

const MobileSoftIconCard = React.memo(function MobileSoftIconCard({
  children,
  displayIcon,
  infoContent,
  infoTitle,
  title,
}) {
  return (
    <SoftLargeCard>
      <Grid container spacing={1}>
        <Grid item xs="auto">
          <RelativeBox>{displayIcon}</RelativeBox>
        </Grid>

        <Grid item xs>
          <Box pl={1} pt={1}>
            <Typography variant="h6">{title}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>

      <Tooltip title={infoTitle} content={infoContent} />
    </SoftLargeCard>
  );
});

MobileSoftIconCard.propTypes = {
  badgeNumber: PropTypes.number,
  displayIcon: PropTypes.node.isRequired,
  infoContent: PropTypes.string,
  infoTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default MobileSoftIconCard;
