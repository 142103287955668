import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const DarkSmallHeading = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  fontWeight: theme.typography.semiBold,
  fontSize: theme.typography.h6.fontSize,
  fontFamily: theme.typography.interFontStack,
  color: "#000",
  marginTop: 0,
  marginBottom: 0,
  lineHeight: 1.2,
  "&.disabled": {
    color: theme.palette.grey[400],
  },
}));

export default DarkSmallHeading;
