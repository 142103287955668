import { styled } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const StyledGrid = styled(Grid)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  border: `1px solid ${theme.palette.grey[300]}`,
  overflow: "hidden",
}));

export default StyledGrid;
