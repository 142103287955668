import { A, D, flow, G, pipe } from "@mobily/ts-belt";
import * as React from "react";
import useSWR from "swr";
import { Influencer } from "../domain";
import { sub, format } from "date-fns";
import fetcher from "./fetcher";

const endpoint = "/api/reports/stats";

const formatDate = (date) => format(date, "yyyy-MM-dd hh:mm:ss");

const rangeFor = (timeFilter) => {
  const range = {
    startDate: "0000-01-01 00:00:00",
    endDate: "9999-12-31 00:00:00",
  };

  switch (timeFilter) {
    case "7-day": {
      range.startDate = formatDate(sub(new Date(), { days: 7 }));
      return range;
    }

    case "1-day": {
      range.startDate = formatDate(new Date());
      return range;
    }

    case "6-month": {
      range.startDate = formatDate(sub(new Date(), { months: 6 }));
      return range;
    }

    default:
      return range;
  }
};

const endpointFor = ({ selected, timeFilter, itemized, options }) => {
  if (!G.isObject(selected) || D.isEmpty(selected)) {
    return null;
  }

  const params = new URLSearchParams({
    shops: pipe(selected, D.values, A.map(D.get("influencerId"))),
    itemized: Boolean(itemized),
    with_earnings: Boolean(options?.withEarnings),
  });

  if (timeFilter) {
    const { startDate, endDate } = rangeFor(timeFilter);

    params.set("start_date", startDate);
    params.set("end_date", endDate);
  }

  return `${endpoint}?${params}`;
};

export const defaultStats = {
  shops: [],
  summary: {
    hasTooManyShops: false,
    earnings: 0,
    sales: 0,
    incompleteOrders: 0,
    newSales: 0,
  },
};

export function useShopStats(selected, options = { withEarnings: true }) {
  const { data, error, mutate } = useSWR(
    endpointFor({ selected, options }),
    fetcher,
    { keepPreviousData: true },
  );

  const stats = React.useMemo(() => {
    if (!data || !G.isObject(selected) || D.isEmpty(selected)) {
      return defaultStats;
    }

    return data.stats;
  }, [data, selected]);

  return {
    stats,
    error,
    isError: Boolean(error),
    isLoading: !data && !error,
    mutate,
  };
}

export function useShopItemizedStats({ selected, timeFilter }) {
  const { data, error, mutate } = useSWR(
    endpointFor({ selected, timeFilter, itemized: true }),
    fetcher,
    { keepPreviousData: true },
  );

  const stats = React.useMemo(() => {
    if (!data || !G.isObject(selected) || D.isEmpty(selected)) {
      return defaultStats;
    }

    return data.stats;
  }, [data, selected]);

  return {
    stats,
    error,
    isError: Boolean(error),
    isLoading: !data && !error,
    mutate,
  };
}

const removeEmptyValues = flow(
  D.toPairs,
  A.filter(([, value]) => !!value),
  D.fromPairs,
);

export function useShops(params = {}) {
  const filteredParams = React.useMemo(
    () => removeEmptyValues(params),
    [params],
  );

  let endpoint;

  if (D.isEmpty(filteredParams)) {
    endpoint = null;
  } else {
    endpoint = `/api/userInfluencer?${new URLSearchParams(
      D.merge(params, { list: true }),
    )}`;
  }

  const { data, error, mutate } = useSWR(endpoint, fetcher);

  const shops = React.useMemo(() => {
    if (!data) return [];

    const { shops } = data;

    if (!G.isArray(shops)) return [];

    return shops.map(Influencer.from);
  }, [data]);

  return {
    shops,
    error,
    isError: Boolean(error),
    isLoading: !data && !error,
    mutate,
  };
}
