export const scrollbarStyles = (theme) => ({
  scrollbarWith: "thin",
  scrollbarColor: `${theme.palette.grey[500]} ${theme.palette.grey[300]}`,
  "&::-webkit-scrollbar": {
    width: 10,
  },
  "&::-webkit-scrollbar-track": {
    background: theme.palette.grey[300],
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.grey[500],
    borderRadius: theme.spacing(2),
    border: `2px solid ${theme.palette.grey[300]}`,
  },
});

export default scrollbarStyles;
