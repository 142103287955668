import * as React from "react";
import PropTypes from "prop-types";
import { Link, Typography } from "@material-ui/core";
import { Product, Sendin } from "../../../../domain";
import { MessageDialog } from "../../../../theme";

const ProductModalSendinInfo = React.memo(function ProductModalSendinInfo({
  product,
}) {
  const [open, setOpen] = React.useState(false);

  if (!product.isAnySendin) {
    return null;
  }

  return (
    <>
      <Link onClick={() => setOpen(true)} component="button" type="button">
        <Typography variant="body2">Send-In info</Typography>
      </Link>

      {open && (
        <MessageDialog
          title="Terms and Conditions"
          message={Sendin.termsHtml}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
});

ProductModalSendinInfo.propTypes = {
  product: PropTypes.instanceOf(Product),
};

export default ProductModalSendinInfo;
