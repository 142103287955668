import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import * as React from "react";
import { useInfluencerRoute } from "../../../../hooks";
import { ErrorUI } from "../../../../theme";
import ImageAvatar from "../../../ImageAvatar";
import VideoAvatar from "./VideoAvatar";

const loadingUI = (
  <Grid container>
    <Grid item>
      <Skeleton variant="rect" width={220} height={300} />
    </Grid>
  </Grid>
);

const ShopVideo = React.memo(function ShopVideo() {
  const { influencer, isLoading, isError } = useInfluencerRoute();

  if (isLoading) {
    return loadingUI;
  }

  if (isError) {
    return <ErrorUI message="Unable to load shop data" />;
  }

  if (influencer.hasVideoAvatar) {
    return <VideoAvatar influencer={influencer} />;
  }

  return <ImageAvatar influencer={influencer} />;
});

export default ShopVideo;
