import * as React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import clsx from "clsx";

const Input = React.forwardRef(function Input(
  { noAsterisk, className, shrink, ...props },
  ref,
) {
  return (
    <StyledTextField
      InputLabelProps={shrink ? { shrink: true } : undefined}
      ref={ref}
      variant="outlined"
      {...props}
      className={clsx(noAsterisk && "no-asterisk", className)}
    />
  );
});

Input.propTypes = {
  shrink: PropTypes.bool,
  noAsterisk: PropTypes.bool,
  className: PropTypes.string,
};

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 5,
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[500],
      },
    },
    "&:focus-within": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.grey[500],
      },
    },
  },
  "&.white-filled .MuiOutlinedInput-root, &.white-filled .MuiOutlinedInput-notchedOutline":
    {
      userSelect: "none",
      borderColor: "transparent",
      pointerEvents: "none",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
      "&:focus-within": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      },
    },
  "&.grey-filled .MuiOutlinedInput-root, &.grey-filled .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "transparent",
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.grey[300],
        },
      },
      "&:focus-within": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.grey[300],
        },
      },
    },
  "& .MuiOutlinedInput-multiline": {
    padding: 0,
  },
  "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
    padding: 0,
  },
  "& .MuiOutlinedInput-input.MuiOutlinedInput-inputMarginDense": {
    padding: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(14),
  },
  "& .MuiOutlinedInput-input": {
    padding: 15,
    fontSize: theme.typography.pxToRem(15),
    "&:auto-fill": {
      background: "white",
    },
    "&:-webkit-autofill": {
      background: "white",
    },
  },
  "&.white-filled .MuiOutlinedInput-input, &.white-filled .MuiOutlinedInput-adornedStart":
    {
      pointerEvents: "none",
      userSelect: "none",
      fontWeight: 600,
      fontSize: "0.85rem",
      background: "white",
      "&:auto-fill": {
        background: "white",
      },
      "&:-webkit-autofill": {
        background: "white",
      },
    },
  "&.grey-filled .MuiOutlinedInput-input, &.grey-filled .MuiOutlinedInput-adornedStart":
    {
      fontWeight: 600,
      fontSize: "0.85rem",
      background: theme.palette.grey[100],
      "&:auto-fill": {
        background: theme.palette.grey[100],
      },
      "&:-webkit-autofill": {
        background: theme.palette.grey[100],
      },
    },
  "& .MuiOutlinedInput-notchedOutline": {
    fontSize: theme.typography.pxToRem(15),
    borderColor: "#d9d9d9",
    transition: "border-color 0.2s ease-in-out",
  },
  "& .MuiInputLabel-outlined": {
    fontSize: theme.typography.pxToRem(15),
    transform: "translate(15px, 15px) scale(1)",
    color: theme.palette.grey[600],
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(15px, -6px) scale(0.75)",
    color: theme.palette.grey[800],
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
    fontSize: theme.typography.pxToRem(14),
    transform: "translate(12px, 12px) scale(1)",
    color: theme.palette.grey[600],
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-marginDense.MuiInputLabel-shrink": {
    transform: "translate(15px, -6px) scale(0.75)",
    color: theme.palette.grey[800],
  },
  "&.no-asterisk .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-required .MuiFormLabel-asterisk.MuiInputLabel-asterisk":
    {
      display: "none",
    },
  "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: 0,
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

export default Input;
