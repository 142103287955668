import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

function StreamilyTabs({
  currentTab,
  onChange,
  label,
  options = [],
  size = "md",
  ...props
}) {
  const handleChange = (_, newValue) => {
    onChange(newValue);
  };

  return (
    <StyledTabs
      {...props}
      value={currentTab}
      onChange={handleChange}
      aria-label={label}
    >
      {options.map((option) => (
        <StyledTab className={size} label={option} key={option} />
      ))}
    </StyledTabs>
  );
}

StreamilyTabs.propTypes = {
  currentTab: PropTypes.number,
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

const StyledTabs = withStyles((theme) => ({
  root: {
    minHeight: theme.spacing(4),
  },
  indicator: {
    background: theme.palette.grey.main,
  },
  flexContainer: {
    flexGap: theme.spacing(3),
    gap: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      flexGap: theme.spacing(2),
      gap: theme.spacing(2),
    },
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#2B2B2B",
    fontWeight: theme.typography.bold,
    fontSize: theme.typography.pxToRem(14),
    padding: 0,
    minWidth: "unset",
    minHeight: theme.spacing(4),
    "&.lg": {
      fontSize: theme.typography.pxToRem(18),
    },
    "&.sm": {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  textColorInherit: {
    opacity: 0.55,
  },
}))(Tab);

export default React.memo(StreamilyTabs);
