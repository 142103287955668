import * as React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";
import { Grid, Box, Typography, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { styled } from "@material-ui/core/styles";
import { StyledTextButton } from "../../theme";
import { useInfluencer, useResourceLocator } from "../../hooks";
import VideoAvatar from "./VideoAvatar";
import FileUploadDialog from "../FileUploadDialog";
import * as service from "./service";

const acceptList = ["video/*"];
const maxFileSize = 25;
const width = 143;
const height = 192;

const VideoAvatarEditor = React.memo(function VideoAvatarEditor({
  influencerId,
}) {
  const [open, setOpen] = React.useState(false);
  const [cancel, setCancel] = React.useState(null);

  const {
    influencer,
    isLoading: isLoadingI,
    isError: isErrorI,
    refresh,
  } = useInfluencer({
    influencerId,
  });

  const {
    resourceLocator,
    isLoading: isLoadingRL,
    isError: isErrorRL,
  } = useResourceLocator({
    type: "influencerVideoAvatar",
    payload: { influencerId },
  });

  const isLoading = isLoadingI || isLoadingRL;
  const isError = isErrorI || isErrorRL;

  const avatar = React.useMemo(() => {
    if (isLoading) {
      return <Skeleton width={width} height={height} variant="rect" />;
    }

    if (influencer?.hasVideoAvatar) {
      return (
        <VideoAvatar height={height} width={width} influencer={influencer} />
      );
    }

    return (
      <StyledBox
        width={width}
        height={height}
        p={1}
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
      >
        <Typography color="textSecondary" variant="caption" align="center">
          Add a Video Message to your fans inviting them to buy an autograph
        </Typography>
      </StyledBox>
    );
  }, [influencer, isLoading]);

  const onClose = React.useCallback(
    ({ path } = {}) => {
      if (!path && typeof cancel === "function") {
        cancel();
      }

      setOpen(false);

      if (!path) {
        return;
      }

      refresh(
        influencer.mutateRawValues({
          has_video_avatar: 1,
          video_avatar_url: path,
        }),
      );
    },
    [cancel, influencer, refresh],
  );

  const onSubmit = React.useCallback(
    async (file) => {
      if (isLoading || isError) {
        throw new Error("still loading");
      }

      const { promise, controller } = service.createVideoUpload({
        file,
        resourceLocator,
      });

      setCancel(() => () => {
        controller.abort();
      });

      return promise;
    },
    [resourceLocator, isLoading, isError],
  );

  const handleRemove = async () => {
    if (!influencer) {
      return;
    }

    refresh(
      influencer.mutateRawValues({
        has_video_avatar: 0,
        video_avatar_url: null,
      }),
    );

    await service.removeVideoAvatar({ influencerId: influencer.influencerId });

    refresh();
  };

  return (
    <StyledGrid container direction="column">
      {avatar}

      <Box pt={1}>
        <StyledTextButton
          size="small"
          color="secondary"
          onClick={() => setOpen(true)}
          variant="text"
        >
          Edit Video Message
        </StyledTextButton>
      </Box>

      {Boolean(influencer?.hasVideoAvatar) && (
        <IconButton
          style={{ position: "absolute", right: -4, bottom: -2 }}
          aria-label="Remove video message"
          onClick={handleRemove}
          size="small"
          title="Remove video message"
        >
          <DeleteIcon color="secondary" fontSize="small" />
        </IconButton>
      )}

      {open && (
        <FileUploadDialog
          cancel={cancel}
          color="secondary"
          description="Please select a new video file to upload."
          helperText="Accepted video file types include .mov and .mp4"
          maxFileSizeMB={maxFileSize}
          mimeAcceptList={acceptList}
          validateMime
          onClose={onClose}
          onSubmit={onSubmit}
          open={open}
          title="Change Video Avatar"
        />
      )}
    </StyledGrid>
  );
});

VideoAvatarEditor.propTypes = {
  influencerId: PropTypes.number.isRequired,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  maxWidth: 150,
  position: "relative",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  alignItems: "center",
  background: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 5,
  display: "flex",
  justifyContent: "center",
}));

export default VideoAvatarEditor;
