import { Button, CircularProgress, Link, TextField } from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

import { useStateValue } from "../globalState";

const Contact = () => {
  const [{ user }] = useStateValue();

  const unmounted = useRef(false);

  const OrderNumberRef = useRef(null);
  const SubjectRef = useRef(null);
  const MessageRef = useRef(null);

  const [email, setEmail] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const Submit = useCallback(async () => {
    try {
      ClearText();
      setIsLoading(true);
      await axios.post(`/api/supportTicket`, {
        email,
        subject,
        message,
        order_number: orderNumber,
      });
      if (unmounted.current) return;
      setOrderNumber("");
      setSubject("");
      setMessage("");
      setSuccessText("Thank you! We will be reaching out soon.");
    } catch (error) {
      if (unmounted.current) return;
      console.error(error);
      if (error.response && error.response.data) {
        setErrorText(error.response.data);
      } else {
        setErrorText("Something went wrong. Please try again.");
      }
    } finally {
      if (unmounted.current) return;
      setIsLoading(false);
    }
  }, [email, subject, message, orderNumber]);

  const ClearText = () => {
    setSuccessText("");
    setErrorText("");
  };

  useEffect(() => {
    if (user && user.email) setEmail(user.email);
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <Container>
      <InnerContainer>
        <Title>Contact Streamily</Title>
        <Text>
          Looking for the status of your order? Use our{" "}
          <Link component={RouterLink} to="/wheres-my-order">
            order search tool
          </Link>{" "}
          to find the most up-to-date information.
        </Text>
        <Text>
          Please provide your email address, an order number if you have one, a
          subject, and a message containing any questions you may have and one
          of our support representatives will reach out to you as soon as
          possible.
        </Text>
        <InputContainer>
          <TextField
            label="Your Email Address"
            value={email}
            inputProps={{ maxLength: 80 }}
            style={{ minWidth: "210px" }}
            fullWidth={email.length > 20}
            required
            onChange={(e) => setEmail(e.target.value)}
            onInput={ClearText}
            onKeyDown={(e) =>
              e.key === "Enter" &&
              requestAnimationFrame(() => OrderNumberRef.current.focus())
            }
            // requestAnimationFrame() is used so that the "Enter" key event doesn't send input
            // to the order number field
          />
        </InputContainer>
        <InputContainer>
          <TextField
            inputRef={OrderNumberRef}
            label="Order Number (optional)"
            style={{ minWidth: "210px" }}
            value={orderNumber}
            variant="outlined"
            inputProps={{ maxLength: 30 }}
            onChange={(e) => setOrderNumber(e.target.value)}
            onInput={ClearText}
            onKeyDown={(e) =>
              e.key === "Enter" &&
              requestAnimationFrame(() => SubjectRef.current.focus())
            }
            // requestAnimationFrame() is used so that the "Enter" key event doesn't send input
            // to the subject field
          />
        </InputContainer>
        <InputContainer>
          <TextField
            inputRef={SubjectRef}
            label="Subject"
            required
            value={subject}
            fullWidth
            variant="outlined"
            inputProps={{ maxLength: 180 }}
            onChange={(e) => setSubject(e.target.value)}
            onInput={ClearText}
            onKeyDown={(e) =>
              e.key === "Enter" &&
              requestAnimationFrame(() => MessageRef.current.focus())
            }
            // requestAnimationFrame() is used so that the "Enter" key event doesn't input a
            // newline into the textarea
          />
        </InputContainer>
        <InputContainer>
          <TextField
            inputRef={MessageRef}
            multiline
            fullWidth
            required
            inputProps={{ maxLength: 800 }}
            variant="outlined"
            rows={4}
            label="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onInput={ClearText}
          />
        </InputContainer>
        {errorText && <ErrorText>{errorText}</ErrorText>}
        {successText && <SuccessText>{successText}</SuccessText>}
        <InputContainer>
          <Button
            onClick={Submit}
            fullWidth
            color="primary"
            variant="contained"
            disabled={!(email && subject && message && !isLoading)}
          >
            {!isLoading ? "Send" : <CircularProgress size={25} />}
          </Button>
        </InputContainer>
        <Subtext>
          Alternatively, you can contact us at{" "}
          <a
            href="mailto:support@streamily.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@streamily.com
          </a>
        </Subtext>
      </InnerContainer>
    </Container>
  );
};

export default Contact;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(250, 250, 250);
`;

const InnerContainer = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 50px;
  @media only screen and (max-width: 540px) {
    margin-top: 20px;
  }
  @media only screen and (max-width: 470px) {
    margin-top: 10px;
  }
  @media only screen and (max-width: 400px) {
    margin: 0;
    border-radius: 0;
    height: 100%;
  }
`;

const Title = styled.div`
  font-size: 26px;
  color: rgb(80, 80, 80);
  font-weight: bold;
  text-align: center;
  margin: 10px;
  margin-bottom: 20px;
  align-self: center;
  &:after {
    content: "";
    display: block;
    height: 1px;
    background: linear-gradient(90deg, rgb(200, 43, 102), rgb(100, 140, 190));
    position: relative;
    top: 8px;
    margin: 0 20px;
  }
`;

const Text = styled.div`
  margin: 10px;
  color: rgb(100, 100, 100);
  max-width: 400px;
  text-align: center;
`;

const InputContainer = styled.div`
  margin: 10px;
`;

const ErrorText = styled.div`
  font-size: 16px;
  text-align: center;
  margin: 10px;
  color: rgb(200, 43, 102);
  max-width: 400px;
`;

const SuccessText = styled.div`
  font-size: 16px;
  text-align: center;
  margin: 10px;
  color: rgb(100, 140, 190);
  max-width: 400px;
`;

const Subtext = styled.div`
  text-align: center;
  color: rgb(100, 100, 100);
  width: 90%;
  max-width: 500px;
  font-size: 12px;
  margin: 10px;
  margin-bottom: 10px;
  a {
    color: rgb(64, 131, 255);
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
`;
