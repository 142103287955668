import { Box, Button, Grid } from "@material-ui/core";
import { D, flow, pipe, S } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { fetcher } from "../../../../hooks";
import { ProgressButton, StatusMessage } from "../../../../theme";
import UserSearch from "../../../UserSearch.jsx";
import { useAffiliateLinks } from "./service";
import {
  StyledDialog,
  StyledInputBasic,
  StyledLabel,
  StyledSmallTitle,
} from "./styles";
import { isValidPercentage, parsePercentage } from "./utils";

export default function NewAffiliateLink({ influencerId }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [user, setUser] = React.useState(null);
  const { refresh } = useAffiliateLinks(influencerId);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const formData = pipe(
      Object.fromEntries(new FormData(evt.target)),
      D.update("percentage", parsePercentage),
      D.update("code", flow(S.toLowerCase, S.trim)),
      D.set("influencerId", influencerId),
      D.set("userId", user?.userId ?? null),
    );

    if (!isValidPercentage(formData.percentage)) {
      setError("Invalid percentage");
      return;
    }

    setIsLoading(true);

    fetcher("/api/affiliateLink", {
      method: "POST",
      body: JSON.stringify(formData),
    })
      .then(() => {
        setIsLoading(false);
        setIsOpen(false);
        refresh();
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err?.json?.message ?? "Something went wrong");
      });
  };

  return (
    <Box minWidth={80}>
      <Button
        color="secondary"
        fullWidth
        onClick={() => setIsOpen(true)}
        variant="contained"
      >
        New Link
      </Button>

      <StyledDialog open={isOpen} onClose={() => setIsOpen(false)}>
        <StyledSmallTitle>New Link</StyledSmallTitle>

        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: 360 }}
          onChange={() => setError("")}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <StyledLabel>
                <span>Code</span>
                <StyledInputBasic
                  required
                  disabled={isLoading}
                  name="code"
                  type="text"
                />
              </StyledLabel>
            </Grid>

            <Grid item xs={4}>
              <StyledLabel>
                <span>Percentage</span>
                <StyledInputBasic
                  required
                  disabled={isLoading}
                  inputMode="numeric"
                  name="percentage"
                  type="text"
                />
              </StyledLabel>
            </Grid>

            <Grid item xs={12}>
              <UserSearch
                onAddUser={(user) => {
                  setUser(user);
                }}
                onRemoveUser={() => {
                  setUser(null);
                }}
                user={user}
              />
            </Grid>

            {Boolean(error) && (
              <Grid item xs={12}>
                <StatusMessage type="error" message={error} />
              </Grid>
            )}

            <Grid item xs={12}>
              <ProgressButton
                color="secondary"
                disabled={isLoading}
                fullWidth
                isLoading={isLoading}
                type="submit"
                variant="contained"
              >
                Save
              </ProgressButton>
            </Grid>
          </Grid>
        </form>
      </StyledDialog>
    </Box>
  );
}

NewAffiliateLink.propTypes = { influencerId: PropTypes.number.isRequired };
