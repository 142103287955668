import * as React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";

export default function ProgressButton({
  children,
  className,
  color = "default",
  disabled,
  isLoading,
  size = "small",
  variant = "outlined",
  ...props
}) {
  return (
    <Button
      {...props}
      style={{
        minHeight: size === "large" ? "3rem" : "2.75rem",
        ...props.style,
      }}
      className={className}
      color={color}
      disabled={disabled || isLoading}
      size={size}
      variant={variant}
    >
      {isLoading ? (
        <CircularProgress
          color={color === "default" ? "inherit" : color}
          size={size === "large" ? "1.5rem" : "1.25rem"}
        />
      ) : (
        children
      )}
    </Button>
  );
}

ProgressButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.string,
};
