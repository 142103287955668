import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@material-ui/core/styles";

const Logo = React.memo(function Logo({ width, height }) {
  return (
    <StyledImage
      src="https://d3qdxs9s8vjwda.cloudfront.net/misc/streamily-logo-full-without-tagline.png"
      width={width}
      height={height}
      alt="Streamily Logo"
    />
  );
});

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const StyledImage = styled("img")(({ theme }) => ({
  objectFit: "contain",
}));

export default Logo;
