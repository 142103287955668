import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams } from "react-router-dom";

const Franchise = () => {
  const unmounted = useRef(false);
  const params = useParams();

  const [franchise, setFranchise] = useState(null);
  const [characters, setCharacters] = useState([]);

  const getFranchiseData = async () => {
    try {
      const franchiseDataResp = await axios.get(
        `/api/franchise/${params.route}`,
      );
      if (unmounted.current) return;
      setFranchise(franchiseDataResp.data.data);
    } catch (error) {
      if (unmounted.current) return;
      console.error(error);
    }
  };

  const getCharacterData = async () => {
    try {
      const characterDataResp = await axios.get(
        `/api/character?franchise_route=${params.route}`,
      );
      if (unmounted.current) return;
      setCharacters(characterDataResp.data.data);
    } catch (error) {
      if (unmounted.current) return;
      console.error(error);
    }
  };

  useEffect(() => {
    getFranchiseData();
    getCharacterData();
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <Container>
      {!!franchise && (
        <TopContainer>
          <AvatarImg src={franchise.avatar_url} />
          <TopRightContainer>
            <Title>{franchise.name}</Title>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Description>
          </TopRightContainer>
        </TopContainer>
      )}
      <SubTitle>Characters</SubTitle>
      {characters.map((character) => {
        return (
          <CharacterRow key={`character_${character.influencer_id}`}>
            <CharacterRowTitle>{character.influencer_name}</CharacterRowTitle>
            <ProductsContainer>
              {character.products.map((product) => {
                return (
                  <ProductCard key={`product_${product.product_id}`}>
                    <ProductImage backgroundImg={product.image_url} />
                    <ProductName>{product.name}</ProductName>
                  </ProductCard>
                );
              })}
            </ProductsContainer>
          </CharacterRow>
        );
      })}
    </Container>
  );
};

export default Franchise;

const Container = styled.div``;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

const AvatarImg = styled.img`
  height: 200px;
  width: auto;
  margin: 10px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
`;

const TopRightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  margin: 20px;
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
`;

const Description = styled.div`
  margin: 20px;
  margin-top: 0;
`;

const SubTitle = styled.div`
  margin: 20px;
  margin-left: 40px;
  font-size: 24px;
  font-weight: bold;
`;

const CharacterRow = styled.div``;

const CharacterRowTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
  margin: 20px;
`;

const ProductsContainer = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
`;

const ProductCard = styled.div`
  margin: 10px;
  cursor: pointer;
`;

const ProductImage = styled.div`
  width: 180px;
  height: 250px;
  border-radius: 5px;
  ${(props) =>
    props.backgroundImg &&
    `
        background-image: url(${props.backgroundImg});
        background-position: center;
        background-size: cover;
    `}
`;

const ProductName = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 5px;
`;
