import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const TransparentInput = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      background: "transparent",
    },
  },
})(TextField);

export default TransparentInput;
