import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import * as React from "react";
import { useDebounce, useProductTagsSearch } from "../../../hooks";
import { Form } from "../../../theme";

function labelFor({ isCharacter, isFranchise }) {
  if (isCharacter) return "Add a character";
  if (isFranchise) return "Add a franchise";
  return "Add a new tag";
}

export default function TagSearch({
  clearOnBlur = false,
  isCharacter,
  isFranchise,
  onAddTag,
  onRemoveTag,
  tag = null,
}) {
  const inputRef = React.useRef(null);
  const [query, setQuery] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const { tags } = useProductTagsSearch(query, { isCharacter, isFranchise });

  useDebounce(
    () => {
      setQuery(searchValue.trim());
    },
    250,
    [searchValue],
  );

  const onChange = (_, newValue) => {
    if (newValue) {
      onAddTag(newValue);
    } else {
      onRemoveTag();
    }

    window.requestAnimationFrame(() => {
      inputRef.current?.blur?.();
    });
  };

  const onBlur = (evt) => {
    if (clearOnBlur) {
      return;
    }

    const newTag = evt.target.value.trim();

    if (newTag) {
      onAddTag(newTag);
    } else {
      onRemoveTag();
    }
  };

  const renderInput = (params) => (
    <Form.Input
      {...params}
      inputRef={inputRef}
      inputProps={{ maxLength: 45, ...params.inputProps }}
      fullWidth
      label={labelFor({ isCharacter, isFranchise })}
      size="small"
      onChange={(evt) => {
        setSearchValue(evt.target.value);
      }}
    />
  );

  return (
    <Form.Autocomplete
      autoComplete
      clearOnBlur={!tag && clearOnBlur}
      freeSolo
      handleHomeEndKeys
      includeInputInList
      options={tags}
      value={tag} // This is required to clear the input
      selectOnFocus
      size="small"
      onChange={onChange}
      onBlur={onBlur}
      renderInput={renderInput}
      renderOption={(tag) => <Typography variant="body2">{tag}</Typography>}
    />
  );
}

TagSearch.propTypes = {
  clearOnBlur: PropTypes.bool,
  isCharacter: PropTypes.bool,
  isFranchise: PropTypes.bool,
  onAddTag: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  tag: PropTypes.string,
};
