import React from "react";
import styled from "styled-components";

export default function UserEmailSelect({ data, handleSelectChange, value }) {
  const options = data.map((option) => (
    <option key={option.user_id} value={option.email}>
      {option.email}
    </option>
  ));
  return (
    <Select label="User" onChange={handleSelectChange} value={value}>
      <option value="">Select Email</option>
      {options}
    </Select>
  );
}

const Select = styled.select`
  border: 1px solid black;
  border-radius: 5px;
  align-self: center;
  font-size: 16px;
  padding: 5px 10px;
  outline: none;
  width: 94%;
  background-color: white;
  &.error {
    border: 1px solid red;
    background-color: #fff2a8;
  }
`;
