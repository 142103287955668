import { styled } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const StyledTextButton = styled(Button)(({ theme }) => ({
  ...theme.typography.button,
  textDecoration: "none",
  background: "none",
  border: 0,
  margin: 0,
  padding: 0,
  textAlign: "left",
  justifyContent: "flex-start",
  width: "fit-content",
  minWidth: "fit-content",
  "&:hover, &:focus": {
    background: "none",
    textShadow: "0 0 2px rgba(0, 0, 0, 0.1)",
  },
}));

export default StyledTextButton;
