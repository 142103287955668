import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { StreamilyPageTitle } from "../../../theme";
import ShareLink from "./ShareLink";

const ShopBuilderTitle = React.memo(function ShopBuilderTitle({ route }) {
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm>
        <StreamilyPageTitle
          title="Shop Builder"
          subtitle="Create and Customize the products available on your Streamily Shop"
        />
      </Grid>

      <Grid item xs={12} sm="auto">
        <ShareLink route={route} />
      </Grid>
    </Grid>
  );
});

ShopBuilderTitle.propTypes = {
  route: PropTypes.string.isRequired,
};

export default ShopBuilderTitle;
