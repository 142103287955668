import { G } from "@mobily/ts-belt";

const isValidValue = G.isNotNullable;

function looksLikeURL(maybeURL = "") {
  if (!maybeURL || !G.isString(maybeURL)) {
    return false;
  }

  try {
    new URL(maybeURL);
    return true;
  } catch {
    return maybeURL.startsWith("www.") || maybeURL.includes(".com");
  }
}

function looksLikeJSDateString(maybeDate = "") {
  return (
    G.isString(maybeDate) &&
    maybeDate.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)
  );
}

function jsDateStringToSQLDateString(jsDateString) {
  return jsDateString.replace("T", " ").replace(/\.\d{3}Z$/, "");
}

function toSQLDateString(jsDateString) {
  if (!looksLikeJSDateString(jsDateString)) {
    return jsDateString;
  }
  return jsDateStringToSQLDateString(jsDateString);
}

export {
  isValidValue,
  jsDateStringToSQLDateString,
  looksLikeJSDateString,
  looksLikeURL,
  toSQLDateString,
};
