import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const gridItemStyles = (theme) => ({
  bottom: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  top: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
});

const StyledGridItem = withStyles(gridItemStyles)(function StyledGridItem({
  classes,
  children,
  position,
  ...props
}) {
  return (
    <Grid item {...props} className={position ? classes[position] : ""}>
      {children}
    </Grid>
  );
});

export default StyledGridItem;
