import { G, A } from "@mobily/ts-belt";

const list = {
  roles: {
    Affiliate: "/account-dashboard/affiliate",
    Admin: "/Dashboard/MyDashboard",
    Superadmin: "/Dashboard/MyDashboard",
    Agent: ({ shops }) =>
      G.isArray(shops) && A.isNotEmpty(shops)
        ? "/Dashboard/AgentOverview"
        : "/account-dashboard/whoops",
    Talent: ({ shops }) =>
      G.isArray(shops) && A.isNotEmpty(shops)
        ? "/Dashboard/MyDashboard"
        : "/account-dashboard/whoops",
    TalentCoordinator: "/Dashboard/TalentCoordinatorPortal",
  },
};

export default function getLoginRoute(user) {
  if (!user) {
    return null;
  }

  const result = user.roles.find((role) => {
    return list.roles[role];
  });

  if (!result) {
    return null;
  }

  const path = list.roles[result];

  if (G.isFunction(path)) {
    return path(user);
  }

  return path;
}
