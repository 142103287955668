import * as React from "react";

export default function ManageEventsIcon({ width = 17, height = 17 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0003 2.49965H2.50004C1.67159 2.49965 1 3.17124 1 3.99969V14.5C1 15.3284 1.67159 16 2.50004 16H13.0003C13.8288 16 14.5003 15.3284 14.5003 14.5V3.99969C14.5003 3.17124 13.8288 2.49965 13.0003 2.49965Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7502 1V4.00008"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75012 1V4.00008"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7.00034H14.5003"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
