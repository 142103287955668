import * as React from "react";
import PropTypes from "prop-types";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import {
  useFavourite,
  useFavouriteMutation,
  useCurrentUser,
} from "../../hooks";
import AuthModal from "../AuthModal";

const favouriteIcon = <FavoriteIcon />;
const notFavouriteIcon = <FavoriteBorderIcon />;

export default function FavouriteButton({ influencerId }) {
  const currentUser = useCurrentUser();
  const id = currentUser.email ? influencerId : null;
  const { data, isValidating, isError } = useFavourite(id);
  const { trigger } = useFavouriteMutation(id);
  const isFavourite = data?.isFavourite;
  const [showModal, setShowModal] = React.useState(false);

  const handleClick = React.useCallback(
    async ({ isPostLogin }) => {
      if (isError || isValidating) {
        return;
      }

      if (!currentUser.isLoggedIn && !isPostLogin) {
        setShowModal(true);
        return;
      }

      trigger(!data?.isFavourite, {
        optimsticData: (current) => ({
          ...current,
          isFavourite: !data?.isFavourite,
        }),
      });
    },
    [currentUser, data, trigger, isValidating, isError],
  );

  const handleClose = React.useCallback(
    (success) => {
      if (success) {
        handleClick({ isPostLogin: true });
      }

      setShowModal(false);
    },
    [handleClick],
  );

  return (
    <>
      <StyledButton
        variant="contained"
        size="small"
        disableElevation
        disabled={isError || isValidating}
        startIcon={isFavourite ? favouriteIcon : notFavouriteIcon}
        className={isFavourite ? "favourite" : ""}
        onClick={() => handleClick({ isPostLogin: false })}
      >
        Favorite
      </StyledButton>

      {showModal && <AuthModal isLoggingIn onClose={handleClose} />}
    </>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.grey[200],
  color: theme.palette.grey[900],
  "&:hover": {
    background: theme.palette.grey[300],
  },
  "&.favourite": {
    color: "initial",
  },
}));

FavouriteButton.propTypes = {
  influencerId: PropTypes.number,
};
