import { Paper } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const SoftLargeCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
  position: "relative",
  boxShadow: theme.boxShadow.big,
  borderRadius: theme.spacing(2),
  "&.outlined": {
    backgroundColor: "#fff",
    position: "relative",
    zIndex: 0,
  },
  "&.outlined::after": {
    content: "''",
    inset: 0,
    zIndex: -1,
    position: "absolute",
    boxShadow: theme.boxShadow.big,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    background: "#fff",
  },
  "&.outlined::before": {
    content: "''",
    inset: -2,
    zIndex: -1,
    position: "absolute",
    background:
      "linear-gradient(90.5deg, #2A6FC0 0%, #2A6FC0 0%, #E15499 100%)",
    borderRadius: theme.spacing(1) + 2,
  },
  "&.faded": {
    opacity: 0.3,
  },
  "&.large": {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
  },
  "&.small": {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5),
    },
  },
}));

export default SoftLargeCard;
