import PropTypes from "prop-types";
import * as React from "react";
import { useAvailableAddonsForProduct } from "../../../../hooks";
import Addons from "../../../Addons";
import ProductModalAddonButton from "./ProductModalAddonButton";

const ProductModalAddons = React.memo(function ProductModalAddons({
  productId,
}) {
  const { availableAddons } = useAvailableAddonsForProduct(productId);

  return (
    <Addons
      addons={availableAddons}
      ActionComponent={({ addon }) => (
        <ProductModalAddonButton addon={addon} productId={productId} />
      )}
    />
  );
});

ProductModalAddons.propTypes = {
  productId: PropTypes.number.isRequired,
};

export default ProductModalAddons;
