import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Influencer } from "../../domain";
import { useUser } from "../../globalState";
import {
  useInfluencerRoute,
  useMatchesMdDown,
  useMatchesXsDown,
} from "../../hooks";
import { validateEmail } from "../../util";
import UpcomingSignings from "../UpcomingSignings";
import ShopVideo from "./Header/ShopVideo";

const useStyles = makeStyles((theme) => ({
  notifyButton: {
    height: "56px",
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
    },
  },
  notifyMessage: {
    padding: "15px 80px",
    border: "1px solid rgba(42, 111, 192, 1)",
    borderRadius: "7px",
    textAlign: "center",
    color: "rgba(42, 111, 192, 1)",
    fontSize: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "15px 20px",
    },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  textBox: {
    marginTop: "24px",
    height: "80px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
}));

const ShopHeaderWithNoProduct = React.memo(function ShopHeader() {
  const matchesXs = useMatchesXsDown();
  const matchesMd = useMatchesMdDown();
  const classes = useStyles();
  const { influencer, isLoading } = useInfluencerRoute();
  const currentUser = useUser();
  const [email, setEmail] = React.useState(currentUser?.email || "");
  const [submited, setSubmitted] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
    if (validateEmail(email)) {
      setError(false);
    }
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setError(true);
    } else {
      setError(false);
      await Influencer.subscribeNewsLetter({
        email,
        influencerId: influencer.influencerId,
      });
      setSubmitted(true);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <Box
      pt={matchesXs ? 2 : 8}
      pb={matchesXs ? 2 : 4}
      px={matchesXs ? 0 : matchesMd ? 8 : 12}
    >
      <Grid container spacing={5} alignItems="flex-start">
        <Grid item xs={12} sm="auto">
          <ShopVideo />
        </Grid>

        <Grid item sm container spacing={matchesXs ? 2 : 4}>
          <Grid item xs>
            <Box pt={matchesMd ? 2 : 5} pb={1}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.title}>
                    {influencer.name} Autographed Prints & Memorabilia
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    Hmm, looks like no items are available right now.
                  </Typography>

                  <Typography variant="body1">
                    Be the first to know when {influencer.name} posts
                    autographed products and schedules an event!
                  </Typography>
                </Grid>

                <Grid item spacing={1} container alignItems="center">
                  {!currentUser.userId && !submited && (
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        error={error}
                        className={classes.textBox}
                        fullWidth={true}
                        variant="outlined"
                      >
                        <InputLabel htmlFor="component-outlined">
                          Email Address
                        </InputLabel>

                        <OutlinedInput
                          fullWidth={true}
                          type="email"
                          id="component-outlined"
                          value={email}
                          onChange={handleChange}
                          label="Email Address"
                        />

                        {error && (
                          <FormHelperText id="component-error-text">
                            Invalid email address
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    {!submited && (
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={handleSubmit}
                        color="primary"
                        className={classes.notifyButton}
                      >
                        Notify Me
                      </Button>
                    )}

                    {submited && (
                      <Typography
                        variant="body2"
                        className={classes.notifyMessage}
                      >
                        You&apos;ll be the first to know!
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {influencer.showEvents && (
            <Grid item xs={12} lg={5} xl={4}>
              <Box pt={matchesMd ? 0 : 5} pb={matchesXs ? 0 : 1}>
                <UpcomingSignings
                  influencerId={influencer.influencerId}
                  isGroupShop={influencer.isGroupShop}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
});

export default ShopHeaderWithNoProduct;
