const types = Object.freeze(
  Object.seal({
    LIVESTREAM_SIGNING: "Livestream Signing",
    SIGN_ANYTIME: "Sign Anytime",
    SIGNED_OFF_CAMERA: "Signed Off Camera",
    PRESIGNED: "Presigned",
    DIGITAL_SIGNATURE: "Digital Signature",
    NO_AUTOGRAPH: "No Autograph",
    SHOUTOUT: "Shoutout",
    IN_PERSON_SIGNING: "In Person Signing",
  }),
);

const typesArray = [
  // { label: "Audio Shoutout", signedType: "Audio Shoutout" },
  // note: hint may not be used, see usage of ProductInfoSection.FEATURE_FLAG_SIGN_CLARITY
  // note: param = url param: https:.../live
  {
    label: "Signed on Livestream", // UI value
    signedType: types.LIVESTREAM_SIGNING, // product.signed_type value
    param: "live", // default url param
    alt: ["livestream", "livestreamsigning"], // alternative url params
    backgroundColor: "", // uses default chip color
    hint: "VIP Orders will be signed on camera",
    descrShort:
      "Sign VIP orders of this product on a livestream signing event. Non-Vip orders may be signed off-camera.",
    descr: `Our "traditional" offering. Sign VIP orders on Livestream
      at a scheduled time and date. Non-VIP orders may be signed on- or
      off-camera.`,
  },
  {
    label: "Signed with Video",
    signedType: types.SIGN_ANYTIME,
    param: "signanytime",
    alt: [
      "recordedsigning",
      "recordedsigningplusvideo",
      "recordedsigningandvideo",
    ],
    backgroundColor: "#561A57",
    hint: "Orders will be signed on camera",
    descrShort: "Send fans a video of you signing their order.",
    descr: `Sign Orders when you have time using our Streamily Talent App to
      record the video. Videos are automatically sent to customers upon
      completion, and we'll provide the kits to return signed Items to
      Streamily or Customers directly. Note: Choose your own guaranteed
      timeframe in Shop Builder, after which orders may be cancelled by the
      customer.`,
  },
  {
    label: "Pre-Signed (Ships Fast)",
    signedType: types.PRESIGNED,
    param: "presigned",
    alt: ["presign", "quickship"],
    backgroundColor: "#1A4857",
    hint: "Items will be presigned and sent to Streamily",
    descrShort:
      "Send Streamily pre-signed versions of this product to sell them 24/7/365 and make money all year round.",
    descr: `Sign a batch of non-personalized Items and send them to our warehouse.
      We'll sell them and ship them to your fans 24/7, 365 with nothing
      more for you to do! Note: Pre-signed products will go Live once
      Streamily has received them.`,
  },
  {
    label: "Digital Signature",
    signedType: types.DIGITAL_SIGNATURE,
    param: "digital",
    alt: ["digitalsignature"],
    backgroundColor: "#1A1A57",
    hint: "Orders will be signed digitally",
    descrShort: "Sign orders digitally without having to put pen to paper.",
    descr: `Sign a batch of non-personalized Items and send them to our warehouse.
      We'll sell them and ship them to your fans 24/7, 365 with nothing
      more for you to do! Note: Digitally-signed products will go Live once
      Streamily has received them.`,
  },
  {
    label: "Signed Off Camera",
    signedType: types.SIGNED_OFF_CAMERA,
    param: "offcamera",
    alt: ["signedoffcamera, signedoffline, offline"],
    backgroundColor: "#5b5b5b",
    hint: "Orders will be signed off camera",
    descrShort:
      "Camera shy? Sign personalized orders off camera without having to create an event.",
    descr: `These Items may be personalized, but won't be signed on-camera,
      regardless of VIP status. This is often used for collaborative
      products with other Talent.`,
  },
  {
    label: "Unsigned (Ships Fast)",
    signedType: types.NO_AUTOGRAPH,
    param: "unsigned",
    alt: ["noautograph", "unsign", "notsigned"],
    backgroundColor: "#1A572F",
    hint: "Item will have no personalization, sent to Streamily",
    descrShort: "Sell unsigned merchandise and collectibles with no signature.",
    descr: `This could be artwork, clothing, Pokemon cards, items that you
      don't want to sign, or may not benefit from having a signature.`,
  },
  {
    label: "Shoutout",
    signedType: types.SHOUTOUT,
    param: "shoutout",
    alt: [],
    backgroundColor: "#571A1A",
    hint: "",
    descrShort:
      "Sell video and audio shoutouts to give your fans an even more personalized experience!",
    descr: `This can be used for Video and Audio Shoutouts to give your fans an even more personalized experience.`,
  },
  {
    label: "Signed In Person",
    signedType: types.IN_PERSON_SIGNING,
    param: "inperson",
    alt: ["inpersonsigning"],
    backgroundColor: "#98b8b8",
    hint: "",
    descrShort:
      "Sell items in person at conventions using the Streamily Talent App!",
    descr: `Use this for any items that you want to sell in person at Conventions with the Streamily Talent App`,
  },
];

const ifPersonalized = (a, b) => (product) => product.isPersonalized ? a : b;

const signedTypeDescriptions = {
  [types.LIVESTREAM_SIGNING]: ifPersonalized(
    {
      text: "VIP and Priority orders will be signed and personalized on a livestream signing! Please note, your order may be signed on a personalized video rather than a livestream at the Talent's discretion.",
    },
    {
      text: "VIP and Priority orders will be signed on a livestream signing! Please note, your order may be signed on a personalized video rather than a livestream at the Talent's discretion.",
    },
  ),
  [types.SIGN_ANYTIME]: () => ({
    text: "Watch your order be signed on a personalized video that's sent directly to your email!",
  }),
  [types.PRESIGNED]: () => ({
    text: `Get an autograph shipped to you within 3 business days!`,
  }),
  [types.DIGITAL_SIGNATURE]: () => ({
    text: `Get a digitally signed autograph shipped to you within 3 business days!`,
  }),
  [types.SIGNED_OFF_CAMERA]: ifPersonalized(
    { text: `This order will be signed and personalized for you off camera!` },
    { text: `This order will be signed for you off camera!` },
  ),
  [types.NO_AUTOGRAPH]: () => ({
    text: "This item is not autographed, we'll ship it out to you ASAP!",
  }),
  [types.SHOUTOUT]: () => ({ text: `Receive a personalized shoutout!` }),
  [types.IN_PERSON_SIGNING]: () => ({
    text: `Receive your autograph in person at a convention by showing your confirmation email.`,
    note: "Please note that Signed In Person orders must be picked up in person, they will not be shipped.",
  }),
};

export default class SignType {
  static livestream = types.LIVESTREAM_SIGNING;
  static anytime = types.SIGN_ANYTIME;
  static offCamera = types.SIGNED_OFF_CAMERA;
  static presigned = types.PRESIGNED;
  static noAutograph = types.NO_AUTOGRAPH;
  static shoutout = types.SHOUTOUT;
  static inPerson = types.IN_PERSON_SIGNING;
  static digital = types.DIGITAL_SIGNATURE;

  static types = types;

  static enum = new Set([
    SignType.livestream,
    SignType.anytime,
    SignType.offCamera,
    SignType.presigned,
    SignType.noAutograph,
    SignType.shoutout,
    SignType.inPerson,
    SignType.digital,
  ]);

  static isLivestreamSigning(signedType) {
    return signedType === SignType.livestream;
  }

  static isSignAnytime(signedType) {
    return signedType === SignType.anytime;
  }

  static isSignedOffCamera(signedType) {
    return signedType === SignType.offCamera;
  }

  static isPresigned(signedType) {
    return signedType === SignType.presigned;
  }

  static isNoAutograph(signedType) {
    return signedType === SignType.noAutograph;
  }

  static isShoutout(signedType) {
    return signedType === SignType.shoutout;
  }

  static isInPerson(signedType) {
    return signedType === SignType.inPerson;
  }

  static isDigital(signedType) {
    return signedType === SignType.digital;
  }

  static backgroundColor(signedType) {
    const result = typesArray.find((obj) => obj.signedType === signedType);
    return result?.backgroundColor ?? "";
  }

  static labelToParam(label) {
    if (label === "All Signing Types") return "";
    const result = typesArray.find((obj) => obj.label === label);
    return result?.param ?? "";
  }

  static list() {
    return typesArray.map((type) => type.signedType);
  }

  static toHint(signedType) {
    const result = SignType.type(signedType);
    return result?.hint ?? "";
  }

  static toLabel(signedType) {
    const result = SignType.type(signedType);
    return result?.label ?? "";
  }

  static type(signedType) {
    if (!signedType) return null;
    return typesArray.find((obj) => obj.signedType === signedType);
  }

  static signedTypeFor({ presigned, signed_type, no_signature } = {}) {
    if (presigned && !signed_type) {
      return SignType.presigned;
    }

    if (no_signature) {
      return SignType.noAutograph;
    }

    if (!signed_type) {
      return SignType.livestream;
    }

    return signed_type;
  }

  static getSignedTypeDescription(product) {
    return (
      signedTypeDescriptions[product.signedType]?.(product) ??
      product.signedType
    );
  }

  static find(fn = () => {}) {
    return typesArray.find(fn);
  }

  static map(fn = () => {}) {
    return typesArray.map(fn);
  }

  static filter(fn = () => {}) {
    return typesArray.filter(fn);
  }

  static contains(fn = () => true) {
    return Boolean(typesArray.find(fn));
  }

  static includes(value = {}) {
    return typesArray.includes(value);
  }
}
