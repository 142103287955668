import * as React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import StyledGrid from "../StyledGrid";
import ShopCard from "../../Home/Section/ShopCard";
import { useGroupMembers, useMatchesXsDown } from "../../../hooks";
import { Influencer } from "../../../domain";
import { ErrorUI, LoadingUI } from "../../../theme";

export default function GroupShopInfluencer({ influencer }) {
  const matchesXsDown = useMatchesXsDown();
  const { influencers, isLoading, isError } = useGroupMembers(
    influencer.influencerId,
  );

  if (isLoading) {
    return (
      <Box pb={8} pt={4} width="100%" display="flex" justifyContent="center">
        <LoadingUI />;
      </Box>
    );
  }

  if (isError) {
    return (
      <Box pb={8} pt={4}>
        <ErrorUI message="Unable to load group members" />;
      </Box>
    );
  }

  if (!influencers?.length) {
    return (
      <Box pb={8} pt={4}>
        <Typography variant="body2">No talents.</Typography>
      </Box>
    );
  }

  return (
    <Box pb={8} pt={4}>
      <StyledGrid>
        {influencers.map((influencer) => (
          <Grid key={influencer.influencerId} item>
            <ShopCard
              isMobile={matchesXsDown}
              isDark={false}
              data={influencer}
              position="normal"
            />
          </Grid>
        ))}
      </StyledGrid>
    </Box>
  );
}

GroupShopInfluencer.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
};
