import * as React from "react";

export default function GetPaidIcon({ width = 17, height = 17 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.54541 1V16"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.95459 3.72725H3.84095C3.20805 3.72725 2.60107 3.97867 2.15354 4.4262C1.70601 4.87373 1.45459 5.48071 1.45459 6.11361C1.45459 6.74652 1.70601 7.3535 2.15354 7.80103C2.60107 8.24856 3.20805 8.49998 3.84095 8.49998H7.25004C7.88295 8.49998 8.48993 8.7514 8.93746 9.19893C9.38499 9.64646 9.63641 10.2534 9.63641 10.8863C9.63641 11.5192 9.38499 12.1262 8.93746 12.5738C8.48993 13.0213 7.88295 13.2727 7.25004 13.2727H1.45459"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
