import * as React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { useMatchesSmDown } from "../../hooks";

const StreamilyPageTitle = React.memo(function StreamilyPageTitle({
  title,
  subtitle,
}) {
  const matchesSmDown = useMatchesSmDown();

  return (
    <Box pb={1}>
      <Typography variant={matchesSmDown ? "h4" : "h5"} component="h1">
        {title}
      </Typography>

      {Boolean(subtitle) && (
        <Typography
          variant="subtitle2"
          component="p"
          style={{ maxWidth: "70ch", lineHeight: 1.5 }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
});

StreamilyPageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default StreamilyPageTitle;
