import { Dialog, DialogContent, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import SignType from "../../../domain/signType";
import { DialogTitle } from "../../../theme";

export default function SignTypeDescribeModal({ open, onClose }) {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle
        gutterBottom={0}
        align="left"
        onClose={onClose}
        className="title"
      >
        Sign Types
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" className="blurb">
          You are busy! That&apos;s why we&apos;ve created different signing
          options so that you can make money year-round, not just during
          Livestream events! For each Item you are selling, choose the one that
          works for you:
        </Typography>

        {SignType.map((type, idx) => (
          <div key={`div${idx}`} className="divDescribe">
            <Typography key={`label${idx}`} variant="h6">
              {type.label}
            </Typography>

            <Typography key={`descr${idx}`} variant="caption">
              {type.descr}
            </Typography>
          </div>
        ))}
      </DialogContent>
    </StyledDialog>
  );
}

SignTypeDescribeModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const StyledDialog = withStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    "& .title": {
      margin: 0,
      paddingBottom: 0,
    },
    "& .blurb": {
      marginBottom: "1rem",
    },
    "& .divDescribe": {
      marginBottom: "0.5rem",
      "& .MuiTypography-root:first-child": {
        marginBottom: "-2px !important",
      },
    },
  },
}))(Dialog);
