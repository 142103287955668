import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { D } from "@mobily/ts-belt";
import { useAtom } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import ProductContainer from "../Shop/Products/ProductContainer";
import ProductModal from "../Shop/Products/ProductModal";
import { useProducts } from "./utils";

function resultsTextFor({ filter, pagination }) {
  if (filter.name) {
    const resultText = pagination.totalResults === 1 ? "result" : "results";

    return pagination.hasQueryMatches
      ? `Showing ${pagination.totalResults} ${resultText} for "${filter.name}"`
      : `No matches for "${filter.name}". Showing all products instead.`;
  }

  const productText = pagination.totalResults === 1 ? "product" : "products";

  return `${pagination.totalResults} ${productText}`;
}

export default function Products({ filterAtom }) {
  const [product, setProduct] = React.useState(null);
  const [filter, setFilter] = useAtom(filterAtom);
  const { products, pagination, isValidating } = useProducts(filter);

  React.useEffect(() => {
    setProduct(null);
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  }, [filter.rank]);

  return (
    <>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          gridGap="8px"
          mb={4}
        >
          <Box display="flex" alignItems="center" gridGap="8px">
            <Typography variant="h6">Products</Typography>

            {isValidating && <CircularProgress size={20} color="inherit" />}
          </Box>

          <Typography variant="caption" color="textSecondary">
            {resultsTextFor({ filter, pagination })}
          </Typography>
        </Box>

        <Grid container spacing={4} style={{ opacity: isValidating ? 0.7 : 1 }}>
          {products.map((product) => (
            <Grid xs={12} sm={6} md={4} item key={product.productId}>
              <ProductContainer
                product={product}
                influencer={{ isGroupShop: false }}
                onOpen={() => {
                  setProduct(product);
                }}
              />
            </Grid>
          ))}
        </Grid>

        {products.length === 0 && (
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary">
              No products found
            </Typography>
          </Box>
        )}

        {products.length > 0 && (
          <Box
            alignItems="center"
            display="flex"
            flex="1"
            gridGap="8px"
            justifyContent="center"
            mt={4}
          >
            <Button
              disabled={!pagination?.hasPrevious}
              onClick={() => {
                setFilter(D.merge(filter, { rank: pagination.previousPageId }));
              }}
            >
              Previous Page
            </Button>

            <Button
              disabled={!pagination?.hasNext}
              onClick={() => {
                setFilter(D.merge(filter, { rank: pagination.nextPageId }));
              }}
            >
              Next Page
            </Button>
          </Box>
        )}
      </Box>

      {Boolean(product) && (
        <ProductModal
          product={product}
          influencer={null}
          onClose={(done) => {
            setProduct(null);
            done?.();
          }}
          open={Boolean(product)}
          pageName="Quick Ship"
          pageRoute="quickship"
        />
      )}
    </>
  );
}

Products.propTypes = {
  matchesSmDown: PropTypes.bool.isRequired,
  filterAtom: PropTypes.object.isRequired,
};
