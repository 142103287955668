import React from "react";
import LoadingIndicator from "../../components/LoadingIndicator";
import { styled } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const LoadingIndicatorContainer = styled(Box)(({ theme }) => ({
  width: "25px",
  height: "25px",
  position: "absolute",
  left: "calc( 45% - 12px)",
  top: "calc( 30% - 12px)",
}));

export default function LoadingSVG({
  containerStyle = null,
  iconStyle = null,
  color = null,
}) {
  return (
    <LoadingIndicatorContainer style={containerStyle}>
      <LoadingIndicator color={color} style={iconStyle}></LoadingIndicator>
    </LoadingIndicatorContainer>
  );
}
