import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

export const validBreakpoints = Object.seal(
  Object.freeze({
    xs: "xs",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
  }),
);

export const validDirections = Object.seal(
  Object.freeze({
    up: "up",
    down: "down",
  }),
);

/*
 * useBreakpoint simplifies using Media queries for MUI breakpoints
 *
 * @param {string} breakpoint "xs", "sm", "md", "lg", "xl"
 * @param {direction} direction "up", "down"
 *
 * @returns {boolean} matchesMedia
 */
export default function useBreakpoint(breakpoint = "sm", direction = "up") {
  if (!(breakpoint in validBreakpoints)) {
    throw new TypeError(
      `Breakpoint ${breakpoint} is not a valid breakpoint. Expected one of: ${validBreakpoints.values()}`,
    );
  }

  if (!(direction in validDirections)) {
    throw new TypeError(
      `Direction ${direction} is not a valid direction. Expected one of : ${validDirections}`,
    );
  }

  const theme = useTheme();
  const matchesMedia = useMediaQuery(theme.breakpoints[direction](breakpoint));

  return matchesMedia;
}

useBreakpoint.breakpoints = validBreakpoints;
useBreakpoint.directions = validDirections;

export function useMatchesXsDown() {
  return useBreakpoint("xs", "down");
}

export function useMatchesSmDown() {
  return useBreakpoint("sm", "down");
}

export function useMatchesMdDown() {
  return useBreakpoint("md", "down");
}

export function useMatchesLgDown() {
  return useBreakpoint("lg", "down");
}

export function useMatchesXlDown() {
  return useBreakpoint("xl", "down");
}

export function useMatchesXsUp() {
  return useBreakpoint("xs", "up");
}

export function useMatchesSmUp() {
  return useBreakpoint("sm", "up");
}

export function useMatchesMdUp() {
  return useBreakpoint("md", "up");
}

export function useMatchesLgUp() {
  return useBreakpoint("lg", "up");
}

export function useMatchesXlUp() {
  return useBreakpoint("xl", "up");
}
