/* eslint-disable no-param-reassign */
import logger from "../util/logger";

export default async function track(
  action = { userId: 0, actionType: "", actionMetaData: {} },
) {
  try {
    if (!action) {
      throw new TypeError("action not provided");
    }

    if (!action.actionType) {
      throw new TypeError(`'${action.actionType}' is not a valid actionType`);
    }

    if (!action.actionMetaData) {
      // eslint-disable-next-line no-param-reassign
      action.actionMetaData = {};
    }

    if (!Number.isInteger(action.userId)) {
      const parsedId = Number.parseInt(action.userId, 10);

      if (Number.isNaN(parsedId)) {
        // eslint-disable-next-line no-param-reassign
        action.userId = null;
      } else {
        // eslint-disable-next-line no-param-reassign
        action.userId = parsedId;
      }
    }

    if (action.userId === 0) {
      // eslint-disable-next-line no-param-reassign
      action.userId = null;
    }

    const response = await window.fetch("/api/userTrackingActions", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(action),
    });

    if (!response.ok) {
      throw new Error("response was not ok");
    }

    const text = await response.text();

    return text === "OK";
  } catch (err) {
    logger.error(err);
    return false;
  }
}

/**
 * @function: trackReplace
 * @desc: allow userTrackingActions row to be inserted and updated
 * @desc: insert requires userTrackingActionsId === 0 or null
 * @desc: update requires userTrackingActionsId
 * @returns: userTrackingActionsId
 */
export async function trackReplace(
  action = {
    userTrackingActionsId: 0,
    userId: 0,
    actionType: "",
    actionMetaData: {},
    cartToken: "",
  },
) {
  try {
    if (!action) {
      throw new TypeError("action not provided");
    }

    if (!action.actionType) {
      throw new TypeError(`'${action.actionType}' is not a valid actionType`);
    }

    if (!action.actionMetaData) {
      action.actionMetaData = {};
    }

    if (!Number.isInteger(action.userId)) {
      const parsedId = Number.parseInt(action.userId, 10);

      if (Number.isNaN(parsedId)) {
        action.userId = null;
      } else {
        action.userId = parsedId;
      }
    }

    if (!Number.isInteger(action.userTrackingActionsId)) {
      const parsedId = Number.parseInt(action.userTrackingActionsId, 10);

      if (Number.isNaN(parsedId)) {
        action.userTrackingActionsId = null;
      } else {
        action.userTrackingActionsId = parsedId;
      }
    }

    if (action.userId === 0) {
      action.userId = null;
    }

    const response = await window.fetch("/api/userTrackingActions/replace", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(action),
    });

    if (!response.ok) {
      throw new Error("trackReplace: command failed.");
    }

    const json = await response.json();
    return json.userTrackingActionsId;
  } catch (err) {
    logger.error(err);
    return false;
  }
}
