import { Box, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import * as React from "react";
import useSWR from "swr";
import { Influencer } from "../../../domain";
import { fetcher, useMatchesXsDown } from "../../../hooks";
import { ErrorUI, LoadingUI } from "../../../theme";
import ShopCard from "../../Home/Section/ShopCard";
import StyledGrid from "../StyledGrid";

function urlFor(productIds = []) {
  if (!Array.isArray(productIds) || !productIds.length) {
    return null;
  }

  const params = new URLSearchParams({
    product_ids: productIds.join(","),
  });

  return `/api/influencer/artists?${params.toString()}`;
}

function useGroupArtists(productIds = []) {
  const { data, error, mutate } = useSWR(urlFor(productIds), fetcher);

  const influencers = React.useMemo(() => {
    if (!Array.isArray(data?.artists)) {
      return [];
    }

    return data.artists.map(Influencer.from);
  }, [data]);

  return {
    influencers,
    isLoading: !error && !data,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export default function GroupShopArtists({ influencer }) {
  const matchesXsDown = useMatchesXsDown();
  const { influencers, isLoading, isError } = useGroupArtists(
    influencer?.products?.map((product) => product.productId) ?? [],
  );

  if (isLoading) {
    return (
      <Box pb={8} pt={4} width="100%" display="flex" justifyContent="center">
        <LoadingUI />;
      </Box>
    );
  }

  if (isError) {
    return (
      <Box pb={8} pt={4}>
        <ErrorUI message="Unable to load group members" />;
      </Box>
    );
  }

  if (!influencers?.length) {
    return null;
  }

  return (
    <Box pb={8} pt={4}>
      <StyledGrid>
        {influencers.map((influencer) => (
          <Grid key={influencer.influencerId} item>
            <ShopCard
              isMobile={matchesXsDown}
              isDark={false}
              data={influencer}
              position="normal"
            />
          </Grid>
        ))}
      </StyledGrid>
    </Box>
  );
}

GroupShopArtists.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
};
