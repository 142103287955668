import * as React from "react";
import PropTypes from "prop-types";
import { User } from "./domain";
import globalStateReducer, { actions, actionCreators } from "./reducer";
import { localStorageUtils } from "./util";

export { actions, actionCreators };

export const StateContext = React.createContext();

const initialState = (function init() {
  let user;

  try {
    user = JSON.parse(localStorage.getItem("uo")) ?? {};
  } catch {
    user = {};
  }

  const trackingCode = localStorageUtils.getWithExpiry("stc");

  return {
    user,
    trackingUUID: null,
    trackingCode,
    alerts: [],
    triggerAuthModal: null,
    numberOfBanners: 0,
    browserWidth: 0,
  };
})();

export function StateProvider({ children }) {
  return (
    <StateContext.Provider
      value={React.useReducer(globalStateReducer, initialState)}
    >
      {children}
    </StateContext.Provider>
  );
}

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useStateValue() {
  return React.useContext(StateContext);
}

export function useDispatch() {
  return React.useContext(StateContext)[1];
}

export function useUser() {
  const [{ user }] = React.useContext(StateContext);

  return React.useMemo(() => {
    return User.from(user);
  }, [user]);
}

export const useCurrentUser = useUser;

export function useBrowserWidth() {
  const [{ browserWidth }] = React.useContext(StateContext);

  return React.useMemo(() => browserWidth, [browserWidth]);
}
