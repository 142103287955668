import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import fetcher from "./fetcher";

const baseEndpoint = "/api/productType";

export default function useProductTypes() {
  const { data, error } = useSWR(baseEndpoint, fetcher);

  return {
    productTypes: data ?? [],
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
  };
}

export function useProductTypesForUser() {
  const { data, error } = useSWRImmutable(`${baseEndpoint}/list`, fetcher);

  return {
    productTypes: data?.productTypes ?? [],
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
  };
}

export function useSignedTypes() {
  const { data, error } = useSWRImmutable(
    `${baseEndpoint}/signedTypes`,
    fetcher,
  );

  return {
    signedTypes: data?.signedTypes ?? [],
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
  };
}
