import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import fetcher from "./fetcher";
import { Influencer } from "../domain";

const baseEndpoint = "/api/favourites";

export default function useFavourite(influencerId) {
  const { data, error, isValidating } = useSWR(
    influencerId ? `${baseEndpoint}/${influencerId}` : null,
    fetcher,
  );

  return {
    data,
    isLoading: !data && !error,
    isError: Boolean(error),
    isValidating,
    error,
  };
}

async function toggleFavourite(url) {
  return fetcher(url, { method: "PUT" });
}

export function useFavouriteMutation(influencerId) {
  const { trigger } = useSWRMutation(
    `${baseEndpoint}/${influencerId}`,
    toggleFavourite,
  );

  return { trigger };
}

export function useMyFavourites() {
  const { data, error, isValidating, mutate } = useSWR(
    `${baseEndpoint}/myFavourites/`,
    fetcher,
  );

  const influencers = {
    collections: [],
    talent: [],
  };

  if (Array.isArray(data)) {
    data.forEach((row) => {
      const influencer = Influencer.from(row);
      if (row.group_shop === 1) {
        influencers.collections.push(influencer);
      } else {
        influencers.talent.push(influencer);
      }
    });
  }

  return {
    influencers,
    refresh: mutate,
    isLoading: !data && !error,
    isError: Boolean(error),
    isValidating,
    error,
  };
}
