// optimized ordering
export const urlAlphabet =
  "ModuleSymbhasOwnPr-0123456789ABCDEFGHNRVfgctiUvz_KqYTJkLxpZXIjQW";

export const simpleAsciiAlphabet = "ABCEFGHJKLMNPQRSTWXY23456789";

/**
 * Non-cryptographically secure id function
 */
export function generateId(size = 21, alphabet = urlAlphabet) {
  let id = "";
  // eslint-disable-next-line no-param-reassign
  while (size--) id += alphabet[(Math.random() * alphabet.length) | 0];
  return id;
}

export default generateId;
