import * as React from "react";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { IconButton } from "@material-ui/core";
import SearchResults from "./SearchResults";
import { useAutoScroll, useDebounce } from "../../../hooks";

function Search() {
  const searchBoxRef = React.useRef(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [query, setQuery] = React.useState("");
  const { scroll } = useAutoScroll();

  const onChange = (evt) => {
    const searchValue = evt.target.value;

    if (searchValue.trim() === "") {
      return setSearchValue("");
    }

    return setSearchValue(searchValue);
  };

  const clear = React.useCallback(() => {
    setSearchValue("");
    setQuery("");
  }, []);

  const onClick = React.useCallback(() => {
    scroll();
    requestAnimationFrame(clear);
  }, [scroll, clear]);

  useDebounce(() => setQuery(searchValue), 200, [searchValue]);

  return (
    <Container>
      <SearchBoxContainer>
        <SearchBox
          type="text"
          placeholder="Search talents, characters, actors and more..."
          spellCheck="false"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          name="search"
          value={searchValue}
          onChange={onChange}
          ref={searchBoxRef}
          onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
        />
        <ButtonContainer>
          {query.length > 0 ? (
            <IconButton size="small" onClick={clear}>
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={() => searchBoxRef.current.focus()}
            >
              <SearchIcon />
            </IconButton>
          )}
        </ButtonContainer>
      </SearchBoxContainer>
      {query.length > 0 && <SearchResults query={query} onClick={onClick} />}
    </Container>
  );
}

export default Search;

const Container = styled.div`
  position: relative;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const SearchBoxContainer = styled.div`
  position: relative;
  border: 0.2px solid #d4d4d4;
  border-radius: 10px;
  z-index: 10;

  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
  }
`;

const SearchBox = styled.input`
  background: #f7f7f7;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  color: rgb(60, 60, 60);
  font-size: 15px;
  padding: 8px 55px 8px 20px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  &:hover {
    -webkit-tap-highlight-color: transparent;
  }
  &:focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
