import * as React from "react";
import { styled } from "@material-ui/core/styles";

const HiddenRadio = React.memo(function HiddenRadio({
  children,
  ...inputProps
}) {
  return <StyledRadio type="radio" {...inputProps} />;
});

const StyledRadio = styled("input")(({ theme }) => ({
  height: 0,
  width: 0,
  position: "absolute",
  visibility: "hidden",
}));

export default HiddenRadio;
