import * as React from "react";
import useSWR from "swr";
import { fetcher } from "../../../../hooks";

export function useAffiliateLinks(influencerId) {
  const { data, error, mutate } = useSWR(
    `/api/affiliateLink/byInfluencerId/${influencerId}`,
    fetcher,
  );

  const affiliateLinks = React.useMemo(() => {
    if (!data || !Array.isArray(data?.affiliateLinks)) {
      return [];
    }

    return data.affiliateLinks;
  }, [data]);

  return {
    affiliateLinks,
    isLoading: !error && !data,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useChanges(influencerId) {
  const { data, error, mutate } = useSWR(
    `/api/affiliateLink/changeLog/${influencerId}`,
    fetcher,
  );

  const changes = React.useMemo(() => {
    if (!data || !Array.isArray(data?.changes)) {
      return [];
    }

    return data.changes;
  }, [data]);

  return {
    changes,
    isLoading: !error && !data,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
