import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";

const editInputProps = {
  min: 0,
  step: 0.01,
  "data-testid": "service-bump-edit-input",
  startAdornment: <InputAdornment position="start">$</InputAdornment>,
};

const useStyles = makeStyles(() => ({
  root: {
    "& input::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "none",
    },
  },
}));

const ServiceBumpInput = React.memo(function ServiceBumpInput({
  value,
  setValue,
  isEditing,
}) {
  const styles = useStyles();
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (isEditing) inputRef.current.focus();
  }, [inputRef, isEditing]);

  return (
    <TextField
      className={styles.root}
      inputRef={inputRef}
      disabled={!isEditing}
      name="service_fee_bump"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      size="small"
      fullWidth
      type="number"
      InputProps={editInputProps}
    />
  );
});

ServiceBumpInput.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default ServiceBumpInput;
