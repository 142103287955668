import axios from "axios";
import { joinEnglishPick, logger } from "../util";
import Money from "./money";
import SignType from "./signType";

const endpoint = "/api/product";
const favouriteEndpoint = "/api/favourites";

export default class Product {
  #rawProduct;
  #fulfillers;
  #tags;

  /*
   * update performs an update operation on product with updates object
   *
   * @param {object} initialization object { product, updates }
   * @returns {object} result
   */
  static async update({ product, updates }) {
    try {
      const { data } = await axios.patch(
        `${endpoint}/${product.productId}`,
        updates,
      );

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return { errors: [{ message: "Unable to update product" }] };
    }
  }

  /*
   * updatePosition updates a product's position for shopInfluencerId shop (or the
   * product's owner by default) to new position
   *
   * @param {object} initialization object { product, shopInfluencerId, position }
   * @returns {object} result
   */
  static async updatePosition({ product, shopInfluencerId, position }) {
    try {
      const { data } = await axios.put(`${endpoint}/${product.productId}`, {
        shop_influencer_id: shopInfluencerId ?? product.influencerId,
        position,
      });

      return data;
    } catch (err) {
      logger.error(err);

      if (err.isAxiosError) {
        return err.response.data;
      }

      return { errors: [{ message: "Unable to update product position" }] };
    }
  }

  /*
   * updateFavouriteStatus performs an update on a product with isFavourite status
   *
   * @param {object} initialization object { product, isFavourite }
   * @returns {object | null} result
   */
  static async updateFavouriteStatus({ product, isFavourite }) {
    try {
      const { data } = await axios.post(favouriteEndpoint, {
        product_id: product.productId,
        is_favourite: isFavourite,
      });

      return data;
    } catch (err) {
      logger.error(err);
      return null;
    }
  }

  static async favourite({ product }) {
    return Product.updateFavouriteStatus({ product, isFavourite: true });
  }

  static async unfavourite({ product }) {
    return Product.updateFavouriteStatus({ product, isFavourite: false });
  }

  /*
   * from creates a new Product from a rawProduct
   *
   * @param {object} rawProduct
   * @returns {Product}
   */
  static from(rawProduct) {
    return new Product(rawProduct);
  }

  static customerSignedTypeForRaw({ signed_type } = {}) {
    switch (signed_type) {
      case "Sign Anytime":
        return "Recorded Signing + Video";

      case "Presigned":
        return "Quick Ship";

      default:
        return signed_type;
    }
  }

  /*
   * creates a new Product from a rawProduct
   *
   * @param {object} rawProduct
   * @returns {Product}
   */
  constructor(rawProduct) {
    const rawProductCopy = { ...rawProduct };
    this.#rawProduct = rawProductCopy;
  }

  get productId() {
    return this.#rawProduct.product_id;
  }

  get name() {
    return this.#rawProduct.name;
  }

  get isActive() {
    return Boolean(this.#rawProduct.active);
  }

  get stock() {
    return this.#rawProduct.stock;
  }

  /*
   * price returns the Money value for the product price { value, currency }
   *
   * @returns {Money}
   */
  get price() {
    return Money.for({ value: this.#rawProduct.price / 100, currency: "usd" });
  }

  priceFor(fulfillerId) {
    if (!fulfillerId) {
      return this.price;
    }

    if (!this.fulfillers.length) {
      return this.price;
    }

    const fulfiller = this.fulfillers.find(
      (fulfiller) => fulfiller.influencerId === fulfillerId,
    );

    if (!fulfiller) {
      return this.price;
    }

    return fulfiller.splitAmount;
  }

  get subtotal() {
    return Money.add(this.price, this.productBump);
  }

  get productBump() {
    return Money.for({
      value: this.#rawProduct.product_bump / 100,
      currency: "usd",
    });
  }

  get imageUrl() {
    return this.#rawProduct.image_url;
  }

  get originalImageUrl() {
    return this.#rawProduct.original_image_url;
  }

  get croppedOriginalImageUrl() {
    return this.#rawProduct.cropped_original_image_url;
  }

  get canUploadImage() {
    return this.#rawProduct.can_upload_image;
  }

  get subtext() {
    return this.#rawProduct.subtext;
  }

  get description() {
    return this.#rawProduct.subtext;
  }

  get position() {
    return this.#rawProduct.position;
  }

  get marketingTag() {
    return this.#rawProduct.marketing_tag;
  }

  get productType() {
    return this.#rawProduct.product_type;
  }

  get productTypeId() {
    return this.#rawProduct.product_type_id;
  }

  get shouldContainImage() {
    return Boolean(this.#rawProduct.is_print);
  }

  get linkedStoreRoute() {
    return this.#rawProduct.linked_store_route;
  }

  get route() {
    return this.#rawProduct.route;
  }

  get isDomesticOnly() {
    return Boolean(this.#rawProduct.domestic_only);
  }

  get hasFreeShipping() {
    return Boolean(this.#rawProduct.free_shipping);
  }

  get hasNoServiceFee() {
    return Boolean(this.#rawProduct.no_service_fee);
  }

  get hasNoTax() {
    return Boolean(this.#rawProduct.no_tax);
  }

  get hasLongImage() {
    return Boolean(this.#rawProduct.long_image);
  }

  get hasTallImage() {
    return Boolean(this.#rawProduct.tall_image);
  }

  get isMembersOnly() {
    return Boolean(this.#rawProduct.members_only);
  }

  get signedType() {
    return this.#rawProduct.signed_type;
  }

  get customerSignedType() {
    return Product.customerSignedTypeForRaw(this.#rawProduct);
  }

  get itemType() {
    return this.#rawProduct.item_type;
  }

  get created() {
    if (this.#rawProduct.created) {
      return new Date(this.#rawProduct.created);
    }

    return void 0;
  }

  get updated() {
    if (this.#rawProduct.updated) {
      return new Date(this.#rawProduct.updated);
    }

    return void 0;
  }

  get isArchived() {
    return Boolean(this.#rawProduct.archived);
  }

  get isHidden() {
    return Boolean(this.#rawProduct.show_product);
  }

  get shouldAllowShipmentChoice() {
    return Boolean(this.#rawProduct.allow_shipment_choice);
  }

  get allowPersonalization() {
    return Boolean(this.#rawProduct.allow_personalization);
  }

  get allowNotes() {
    return Boolean(this.#rawProduct.allow_notes);
  }

  get isPersonalized() {
    return this.allowPersonalization || this.allowNotes;
  }

  /*
   * additionalShippingCost returns the Money for a product's additionalShippingCost
   *
   * @returns {Money}
   */
  get additionalShippingCost() {
    return Money.for({
      value: this.#rawProduct.additional_shipping_cost / 100,
      currency: "usd",
    });
  }

  /*
   * additionalInternationalShippingCost returns the Money for a product's additionalInternationalShippingCost
   *
   * @returns {Money}
   */
  get additionalInternationalShippingCost() {
    return Money.for({
      value: this.#rawProduct.additional_international_shipping_cost / 100,
      currency: "usd",
    });
  }

  get allowCOAAddon() {
    return Boolean(this.#rawProduct.allow_cert_of_auth);
  }

  get allowFrameAddon() {
    return Boolean(this.#rawProduct.allow_frame_addon);
  }

  get showSizeSelection() {
    return Boolean(this.#rawProduct.show_size_selection);
  }

  get isSendin() {
    return Boolean(this.#rawProduct.sendin);
  }

  get isSendinNoKit() {
    return Boolean(this.#rawProduct.sendin_no_kit);
  }

  get isSendinInternational() {
    return Boolean(this.#rawProduct.sendin_international);
  }

  get isAnySendin() {
    return this.isSendin || this.isSendinNoKit || this.isSendinInternational;
  }

  get isSoldOut() {
    if (this.#rawProduct.stock === null) {
      return false;
    }

    return (
      Number.isInteger(this.#rawProduct.stock) && this.#rawProduct.stock <= 0
    );
  }

  /*
   * additionalServiceFee returns the Money for a product's additional service fee
   *
   * @returns {Money}
   */
  get additionalServiceFee() {
    return Money.for({
      value: this.#rawProduct.additional_service_fee / 100,
      currency: "usd",
    });
  }

  get hasNoShipping() {
    return Boolean(this.#rawProduct.no_shipping);
  }

  get hasNoSignature() {
    return (
      Boolean(this.#rawProduct.no_signature) ||
      this.signedType === SignType.noAutograph
    );
  }

  get isPresigned() {
    return (
      Boolean(this.#rawProduct.presigned) ||
      this.signedType === SignType.presigned
    );
  }

  get isUnsigned() {
    return this.signedType === SignType.noAutograph;
  }

  get isDigitalSignature() {
    return this.signedType === SignType.digital;
  }

  get influencerName() {
    return this.#rawProduct.influencer_name;
  }

  get influencerHidden() {
    return Boolean(this.#rawProduct.influencer_hidden);
  }

  get influencerIsLive() {
    return Boolean(this.#rawProduct.influencer_is_live);
  }

  get influencerRoute() {
    return this.#rawProduct.influencer_route;
  }

  get influencerId() {
    return this.#rawProduct.influencer_id;
  }

  get fulfillers() {
    return this.#fulfillers ?? [];
  }

  get hasFulfillers() {
    return (
      Array.isArray(this.#rawProduct.fulfillers) &&
      this.#rawProduct.fulfillers.length > 0
    );
  }

  get fulfillerNames() {
    if (!this.#fulfillers) {
      return this.influencerName;
    }

    return joinEnglishPick((x) => x.name, this.#fulfillers);
  }

  get tags() {
    return this.#tags ?? [];
  }

  get hasTags() {
    return (
      Array.isArray(this.#rawProduct.tags) && this.#rawProduct.tags.length > 0
    );
  }

  /*
   * setFulfillers sets a product's fulfillers with the provided class constructor
   *
   * @param {Fulfiller}
   * @returns {Product}
   */
  setFulfillers(Fulfiller) {
    this.#fulfillers = this.#rawProduct.fulfillers.map(Fulfiller.from);
    return this;
  }

  /*
   * setTags sets a product's tags with the provided class constructor
   *
   * @param {Tag}
   * @returns {Product}
   */
  setTags(Tag) {
    this.#tags = this.#rawProduct.tags.map(Tag.from);
    return this;
  }

  /*
   * mutate returns a new Product with key set to value
   *
   * @param {string} key
   * @param {any} value
   *
   * @returns {Product}
   */
  mutate(key, value) {
    const updatedRawProduct = { ...this.#rawProduct, [key]: value };
    return new Product(updatedRawProduct);
  }

  raw() {
    return { ...this.#rawProduct };
  }

  favourite() {
    return Product.favourite({ product: this });
  }

  unfavourite() {
    return Product.unfavourite({ product: this });
  }

  toJSON() {
    return this.#rawProduct;
  }

  toRawCartProduct() {
    return {
      productId: this.productId,
      name: this.name,
      price: this.price.toJSON(),
      productBump: this.productBump.toJSON(),
      stock: this.stock,
      imageUrl: this.imageUrl,
      influencerId: this.influencerId,
      influencerName: this.influencerName,
      influencerRoute: this.influencerRoute,
      fulfillers: this.fulfillers.map((fulfiller) => fulfiller.toJSON()),
      additionalShippingCost: this.additionalShippingCost.toJSON(),
      additionalInternationalShippingCost:
        this.additionalInternationalShippingCost.toJSON(),
      additionalServiceFee: this.additionalServiceFee.toJSON(),
      productType: this.productType,
      subtext: this.subtext,
      marketingTag: this.marketingTag,
      isDomesticOnly: this.isDomesticOnly,
      hasFreeShipping: this.hasFreeShipping,
      hasNoServiceFee: this.hasNoServiceFee,
      hasNoTax: this.hasNoTax,
      hasNoShipping: this.hasNoShipping,
      isSendin: this.isSendin,
      isSendinNoKit: this.isSendinNoKit,
      isSendinInternational: this.isSendinInternational,
      isMembersOnly: this.isMembersOnly,
      isPresigned: this.isPresigned,
      showSizeSelection: this.showSizeSelection,
      tags: this.tags.map((tag) => tag.toJSON()),
    };
  }
}
