import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { AutoSizer, Grid } from "react-virtualized";
import { Product } from "../../../domain";
import * as constants from "../constants";
import ProductCard from "./ProductCard";
import ShopCard from "./ShopCard";
import "./styles.css";

export default function ShopsSlider({
  products = [],
  influencers = [],
  updateFavouriteStatus,
  look = constants.LOOK_TALL,
  isMobile,
  isDark = true,
  numberPerRow,
  updateWidth,
  count = false,
  recommendedShops = false,
}) {
  const [scrollLeft, setScrollLeft] = React.useState(0);

  const Component = React.useMemo(() => {
    if (constants.isProduct(look) || products?.length) {
      return ProductCard;
    }

    return ShopCard;
  }, [look, products?.length]);

  const data = React.useMemo(() => {
    if (products?.length) {
      return products;
    }

    return influencers;
  }, [products, influencers]);

  const rowHeight = constants.cardHeightFor({ isMobile, look });

  const cellRenderer = React.useCallback(
    ({ columnIndex, key, style, ...props }) => {
      let componentStyle = style;
      let componentClass;

      if (columnIndex === 0) {
        componentStyle = {
          ...componentStyle,
          marginLeft: constants.gutterFor({ isMobile }),
        };
        componentClass = "first";
      }

      return (
        <Component
          {...props}
          style={componentStyle}
          data={data[columnIndex]}
          key={key}
          updateFavouriteStatus={updateFavouriteStatus}
          look={look}
          isMobile={isMobile}
          isDark={isDark}
          className={componentClass}
          count={count}
          recommendedShops={recommendedShops}
        />
      );
    },
    [
      count,
      data,
      isDark,
      isMobile,
      look,
      recommendedShops,
      updateFavouriteStatus,
    ],
  );

  const getColumnWidth = React.useCallback(
    ({ index }) => {
      if (index === 0) {
        return (
          constants.columnWidthFor({ isMobile, look }) +
          constants.gutterFor({ isMobile })
        );
      }

      return constants.columnWidthFor({ isMobile, look });
    },
    [isMobile, look],
  );

  const columnWidth = constants.columnWidthFor({ isMobile, look });

  const onScroll = React.useCallback(({ scrollLeft }) => {
    setScrollLeft(scrollLeft);
  }, []);

  React.useEffect(() => {
    updateWidth();
  }, [updateWidth]);

  return (
    <div className={clsx("shops-slider__grid", look)}>
      {scrollLeft > 0 && (
        <div
          className="shops-slider__container"
          style={{
            left: constants.gutterFor({ isMobile }) / 2,
            height: constants.imageHeightFor({ isMobile, look }),
          }}
        >
          <button
            className="shops-slider__icon-button"
            onClick={() =>
              setScrollLeft((c) => Math.max(0, c - columnWidth * numberPerRow))
            }
          >
            <ChevronLeftIcon />
          </button>
        </div>
      )}

      <AutoSizer>
        {({ width }) => (
          <Grid
            cellRenderer={cellRenderer}
            className="hidden-scrollbar"
            columnWidth={getColumnWidth}
            columnCount={data.length}
            rowHeight={rowHeight}
            rowCount={1}
            overscanColumnCount={1}
            height={rowHeight}
            width={width}
            scrollLeft={scrollLeft}
            scrollToAlignment="start"
            onScroll={onScroll}
          />
        )}
      </AutoSizer>

      {scrollLeft <=
        data.length * columnWidth -
          numberPerRow * columnWidth -
          columnWidth && (
        <div
          className="shops-slider__container"
          style={{
            right: constants.gutterFor({ isMobile }) / 2,
            height: constants.imageHeightFor({ isMobile, look }),
          }}
        >
          <button
            className="shops-slider__icon-button"
            onClick={() =>
              setScrollLeft((c) =>
                Math.min(
                  data.length * columnWidth - numberPerRow * columnWidth,
                  c + columnWidth * numberPerRow,
                ),
              )
            }
          >
            <ChevronRightIcon />
          </button>
        </div>
      )}
    </div>
  );
}

ShopsSlider.propTypes = {
  products: PropTypes.arrayOf(PropTypes.instanceOf(Product)),
  influencers: PropTypes.array,
  updateFavouriteStatus: PropTypes.func,
  look: PropTypes.oneOf([
    constants.LOOK_TALL,
    constants.LOOK_WIDE,
    constants.LOOK_PRODUCT,
  ]),
  isMobile: PropTypes.bool,
  isDark: PropTypes.bool,
  numberPerRow: PropTypes.number,
  updateWidth: PropTypes.func,
  shop: PropTypes.bool,
  count: PropTypes.bool,
  recommendedShops: PropTypes.bool,
};
