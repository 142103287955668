import * as React from "react";
import axios from "axios";

const baseEndpoint = "/api/purchases/create";

async function createPurchase(init) {
  try {
    const { data } = await axios.post(baseEndpoint, init);
    return data;
  } catch (err) {
    if (err.isAxiosError) {
      return err.response.data;
    }
    return { errors: [{ message: "Unable to create purchase" }] };
  }
}

export default function usePurchase(init) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [purchaseDetails, setPurchaseDetails] = React.useState(null);

  React.useEffect(() => {
    setIsLoading(true);
    createPurchase(init).then((res) => {
      if (res.errors) {
        setIsError(true);
      } else {
        setPurchaseDetails(res);
      }
      setIsLoading(false);
    });
  }, [init]);

  return React.useMemo(() => {
    return {
      isLoading,
      isError,
      purchaseDetails,
    };
  }, [isLoading, isError, purchaseDetails]);
}
