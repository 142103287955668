import * as React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { Dialog, Link, Typography, Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { InfluencerEvent } from "../../domain";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
}));

function EventDate({ influencerEvent }) {
  if (influencerEvent.startDate) {
    return (
      <Typography variant="body2" color="textSecondary">
        {format(influencerEvent.startDate, "MMMM do, yyyy")}
        {" - "}
        {format(influencerEvent.endDate, "MMMM do, yyyy")}
        {influencerEvent.platform && ` on ${influencerEvent.platform}`}
        {influencerEvent.hasInfluencer &&
          ` with ${influencerEvent.influencerName}`}
      </Typography>
    );
  }

  return (
    <Typography variant="body2" color="textSecondary">
      {format(influencerEvent.value, "MMMM do, yyyy")} at{" "}
      {format(influencerEvent.value, "h:mm a")}{" "}
      {influencerEvent.browserTimezone}
      {influencerEvent.platform && ` on ${influencerEvent.platform}`}
      {influencerEvent.hasInfluencer &&
        ` with ${influencerEvent.influencerName}`}
    </Typography>
  );
}

EventDate.propTypes = {
  influencerEvent: PropTypes.instanceOf(InfluencerEvent),
};

export default function AllEventsModal({ influencerEvents, open, onClose }) {
  const [isOpen, setIsOpen] = React.useState(!!open);

  return (
    <StyledDialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      TransitionProps={{
        onExited: () => {
          if (!isOpen) {
            onClose();
          }
        },
      }}
    >
      <Typography variant="h4">All Events</Typography>

      {influencerEvents.map((influencerEvent) => (
        <Box key={influencerEvent.influencerEventId}>
          <Typography variant="h6">
            {influencerEvent.eventTitle ?? "Event"}
          </Typography>

          <EventDate influencerEvent={influencerEvent} />

          {Boolean(influencerEvent.eventDescription) && (
            <Typography>{influencerEvent.eventDescription}</Typography>
          )}

          {Boolean(influencerEvent.url) && (
            <Link
              href={influencerEvent.url}
              target="_blank"
              rel="noopener noreferrer"
              title={influencerEvent.eventUrl}
            >
              Go to Event
            </Link>
          )}
        </Box>
      ))}
    </StyledDialog>
  );
}

AllEventsModal.propTypes = {
  influencerEvents: PropTypes.arrayOf(PropTypes.instanceOf(InfluencerEvent)),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
