import { useMemo } from "react";
import useSWR from "swr";
import fetcher from "./fetcher";

function queryParamsFor({ route, includeCancelledOrders } = {}) {
  const searchParams = new URLSearchParams();

  if (route) {
    searchParams.set("route", route);
  }

  if (includeCancelledOrders) {
    searchParams.set("cancelled_orders", true);
  }

  return searchParams.toString();
}

export function useSigningAssistant(opts = {}) {
  const { refreshInterval, route } = opts;

  const endpoint = route
    ? `/api/autographPurchase/getWithNewSales?${queryParamsFor(opts)}`
    : null;

  const { data, error, mutate } = useSWR(endpoint, fetcher, {
    keepPreviousData: true,
    refreshInterval: refreshInterval ?? undefined,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}

export function useArchivedOrders(influencerId) {
  const { data, error, mutate } = useSWR(
    `/api/autographPurchase/getArchivedOrders/${influencerId}`,
    fetcher,
  );

  return {
    archivedOrders: data,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}

export function useInfluencerStatus(id) {
  const { data, error, mutate } = useSWR(
    `/api/influencer?dashboard=true&route=${id}`,
    fetcher,
  );

  const status = useMemo(
    () => ({
      is_live: Boolean(data?.is_live),
      is_paused: Boolean(data?.is_paused),
      is_stopped: Boolean(data?.is_stopped),
      is_started: Boolean(data?.is_started),
      influencer_id: data?.influencer_id ?? null,
    }),
    [data],
  );

  return {
    status,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
