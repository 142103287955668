import { Chip, Typography } from "@material-ui/core";
import { styled, withStyles } from "@material-ui/core/styles";
import { D } from "@mobily/ts-belt";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import SignType from "../../domain/signType";

const SignTypeBadge = React.memo(function SignTypeBadge({
  active = true,
  DeleteIcon,
  disabled,
  label,
  onClick,
  onDelete,
  signedType,
  style = {},
}) {
  const hasNewBadge = !!SignType.backgroundColor(signedType);

  if (hasNewBadge) {
    return (
      <ConditionalStyledChip
        className={clsx(active && "active")}
        disabled={disabled}
        style={D.merge(style, {
          backgroundColor:
            disabled || active === false
              ? undefined
              : SignType.backgroundColor(signedType),
        })}
        label={
          disabled || active === false ? (
            <DisabledTypography>{label}</DisabledTypography>
          ) : (
            <ConditionalStyledTypography>{label}</ConditionalStyledTypography>
          )
        }
        onClick={onClick}
        onDelete={onDelete}
        deleteIcon={
          DeleteIcon ? <DeleteIcon style={{ color: "white" }} /> : undefined
        }
      />
    );
  }

  return (
    <StyledChip
      style={style}
      className={clsx(active && "active")}
      disabled={disabled}
      label={
        disabled || active === false ? (
          <DisabledTypography>{label}</DisabledTypography>
        ) : (
          <StyledTypography>{label}</StyledTypography>
        )
      }
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={
        DeleteIcon ? <DeleteIcon style={{ color: "white" }} /> : undefined
      }
    />
  );
});

SignTypeBadge.propTypes = {
  active: PropTypes.bool,
  DeleteIcon: PropTypes.elementType,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  signedType: PropTypes.string.isRequired,
  style: PropTypes.object,
};

const StyledChip = withStyles((theme) => ({
  root: {
    "&.Mui-disabled, &:not(.active)": {
      background: theme.palette.grey[300],
    },
    background: theme.gradient.grey,
    height: "unset",
  },
  label: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
  },
}))(Chip);

const DisabledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(11),
  fontWeight: theme.typography.semiBold,
  lineHeight: theme.typography.pxToRem(13),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(11),
  fontWeight: theme.typography.semiBold,
  lineHeight: theme.typography.pxToRem(13),
  background:
    "linear-gradient(90deg, #FFE68E 0%, #C2A049 100%), linear-gradient(90deg, #FFC48E 0%, #B491FF 100%), #FFFFFF;",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
}));

const ConditionalStyledChip = withStyles((theme) => ({
  root: {
    height: "unset",
  },
  label: {
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1.5)}px`,
  },
}))(Chip);

const ConditionalStyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.pxToRem(11),
  fontWeight: theme.typography.semiBold,
  lineHeight: theme.typography.pxToRem(13),
  color: "#FFFFFF",
}));

export const TopLeft = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(0.5), // postion modified for error icon
  left: theme.spacing(0.75),
}));

export const TopRight = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(0.75),
  right: theme.spacing(0.75),
}));

export default SignTypeBadge;
