import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, InputAdornment, TextField, Typography } from "@material-ui/core";
import ConfirmModal from "../../../ConfirmModal";
import ToggleSwitch from "./ToggleSwitch";
import updateGroupShopStatus from "./service";

export default function GroupShopSettings({
  influencerId,
  isGroupShop,
  shopHashTag,
  setShopHashTag,
  onInput,
  onKeyDown,
}) {
  const [errorText, setErrorText] = React.useState("");
  const [showModal, setShowModal] = React.useState(null);
  const [groupShop, setGroupShop] = React.useState(Boolean(isGroupShop));

  const handleToggle = (_, newValue) => {
    setShowModal({
      action: async () => {
        const isSuccess = await updateGroupShopStatus({
          influencerId,
          groupShop: newValue,
        });

        if (isSuccess) {
          setGroupShop(newValue);
          setErrorText("");
        } else {
          setErrorText("unable to update shop");
        }
      },
      onClose: () => {
        setShowModal(null);
      },
      description: newValue
        ? "set this shop to a Collection"
        : "remove this shop's Collection status (including removing the shop's hashtag)",
    });
  };

  return (
    <Box>
      {Boolean(showModal) && <ConfirmModal {...showModal} />}
      <StyledBox p={1}>
        {groupShop && (
          <Typography variant="subtitle2">Collection Settings</Typography>
        )}
        {groupShop && (
          <Box py={2}>
            <TextField
              title="Shop Hashtag"
              aria-label="Shop Hashtag"
              label="Shop Hashtag"
              fullWidth
              value={shopHashTag}
              onChange={(e) => setShopHashTag(e.target.value)}
              InputProps={InputProps}
              onInput={onInput}
              onKeyDown={onKeyDown}
            />
          </Box>
        )}
        {!isGroupShop && (
          <ToggleSwitch
            checked={groupShop}
            onChange={handleToggle}
            error={Boolean(errorText)}
            helperText={errorText}
          />
        )}
      </StyledBox>
    </Box>
  );
}

GroupShopSettings.propTypes = {
  influencerId: PropTypes.number.isRequired,
  isGroupShop: PropTypes.bool.isRequired,
  shopHashTag: PropTypes.string,
  setShopHashTag: PropTypes.func,
  onInput: PropTypes.func,
  onKeyDown: PropTypes.func,
};

const StyledBox = styled(Box)`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  display: "flex";
  flex-direction: "column";
`;

const InputProps = {
  startAdornment: <InputAdornment position="start">#</InputAdornment>,
};
