import * as React from "react";
import useSWRImmutable from "swr/immutable";
import fetcher from "./fetcher";
import { logger } from "../util";

const baseEndpoint = "/api/vertical";
let verticalData = [];

export const nullVertical = { id: 0, name: "" };

const genVerticalList = (verticalData) => {
  if (!verticalData.length) return [nullVertical];

  let current = "";
  const list = [];
  verticalData.forEach((row) => {
    if (current === row.vertical_name) return;
    current = row.vertical_name;
    list.push({ id: row.vertical_id, name: row.vertical_name });
  });

  return list;
};

export const getSubverticalList = (vertical) => {
  if (verticalData.length === 0 || !vertical) return [nullVertical];

  let found = false;
  const list = [];
  for (const row of verticalData) {
    if (row.vertical_name !== vertical.name) {
      if (found === true) break;
      continue;
    }
    found = true;
    list.push({ id: row.subvertical_id, name: row.subvertical_name });
  }

  return !list.length ? [nullVertical] : list;
};

export const getVerticalRow = (vid) => {
  if (!vid) return nullVertical;
  for (const row of verticalData) {
    if (row.vertical_id === vid) {
      return { id: row.vertical_id, name: row.vertical_name };
    }
  }
};

export const getSubverticalRow = (vid, sid) => {
  if (verticalData.length === 0 || !vid || !sid) return nullVertical;
  for (const row of verticalData) {
    if (row.vertical_id === vid && row.subvertical_id === sid) {
      return { id: row.subvertical_id, name: row.subvertical_name };
    }
  }
};

export function useVerticals() {
  const { data, error } = useSWRImmutable(`${baseEndpoint}/all`, fetcher);

  if (error) logger.log("useVerticals() error:", error);

  const verticalList = React.useMemo(() => {
    if (!data) return [nullVertical];
    verticalData = data.rows;
    return genVerticalList(verticalData);
  }, [data]);

  return {
    verticalList,
    getSubverticalList,
    isVerticalsError: Boolean(error),
  };
}
