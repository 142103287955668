const siteId = process.env.REACT_APP_RECAPTCHA_SITE_ID;

if (!siteId) {
  throw new Error("REACT_APP_RECAPTCHA_SITE_ID not found in environment");
}

const dummy = Promise.resolve();

let captchaPromise = dummy;

export async function loadCaptcha() {
  if (document.getElementById("recaptcha") || captchaPromise !== dummy) {
    return captchaPromise;
  }

  captchaPromise = new Promise((resolve, reject) => {
    const scriptEl = document.createElement("script");

    scriptEl.id = "recaptcha";
    scriptEl.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteId}`;

    scriptEl.onload = resolve;
    scriptEl.onerror = reject;

    document.head.appendChild(scriptEl);
  });

  return captchaPromise;
}

export const ACTIONS = {
  LOGIN: "login",
  SIGNUP: "signup",
  CONTACT_FORM: "contact_form",
  ORDER_STATUS: "order_status",
};

export async function execute(action = ACTIONS.ORDER_STATUS) {
  await loadCaptcha();

  if (!window.grecaptcha?.enterprise) {
    throw new Error("reCAPTCHA not loaded");
  }

  return new Promise((resolve, reject) => {
    window.grecaptcha.enterprise.ready(async () => {
      const token = await window.grecaptcha.enterprise.execute(siteId, {
        action,
      });

      const response = await window.fetch("/api/captcha/verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        reject(new Error("Failed to verify captcha"));
        return;
      }

      const data = await response.json();

      if (!data.success || !data.token) {
        reject(new Error("Captcha verification failed"));
        return;
      }

      resolve(data.token);
    });
  });
}
