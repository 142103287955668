export default class PaymentMethod {
  static async updatePaymentMethod({
    paymentMethodId,
    paymentIntentId,
    clientSecret,
  }) {
    try {
      const init = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          clientSecret,
          updates: { payment_method: paymentMethodId },
        }),
      };

      const response = await window.fetch(
        `/api/purchases/paymentIntents/${paymentIntentId}`,
        init,
      );

      if (!response.ok) {
        console.error("response not ok");
      }

      return response.json();
    } catch (err) {
      console.error(err);
      return {};
    }
  }
}
