import * as React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DialogTitle } from "../theme";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiPaper-root": {
      background: "#fff",
      borderRadius: 10,
      margin: 0,
      padding: 0,
      width: "50rem",
      position: "relative",
      top: "-10vh",
      "& .MuiDialogContent-root": {
        padding: "0 20px 20px 20px",
      },
    },
    "& .title": {
      paddingBottom: "7px",
      "& .MuiTypography-root": {
        fontSize: "20px",
        fontWeight: "700",
        left: "-4px",
        maxWidth: "fit-content",
        position: "relative",
      },
    },
    "& .subtitle": {
      paddingTop: "0px",
      paddingBottom: "10px",
      "& .MuiTypography-root": {
        fontSize: "12px",
        fontWeight: "500",
        left: "-4px",
        maxWidth: "fit-content",
        position: "relative",
      },
    },
    "& .blurb": {
      color: "#5B5B5B",
      fontSize: "15px",
      fontWeight: "400",
      paddingBottom: "10px",
    },
    "& .divSignType": {
      border: "1px solid #ccc",
      borderRadius: "10px",
      cursor: "pointer",
      marginBottom: "0.5rem",
      marginTop: "2px",
      padding: "15px",
    },
    "& .divSignType:hover": {
      border: "1px outset #ccc",
      outline: "1px solid #0009",
    },
  },
}));

export default function NotifySelectModal({
  title,
  subtitle,
  open,
  onClose,
  onSave, // onSave( selectedActionIndex, actionText )
  actions = [], // [ actionText1, ... ]
  customActions = false,
}) {
  const classes = useStyles();

  const actionButtons = React.useMemo(() => {
    if (customActions && typeof customActions === "function") {
      return customActions({ onClose, onSave });
    }

    return actions.map((key, idx) => (
      <div
        key={`action${idx}`}
        className="divSignType"
        onClick={() => {
          onSave(idx, key);
          onClose();
        }}
      >
        {key}
      </div>
    ));
  }, [actions, onClose, onSave, customActions]);

  return (
    <Dialog open={open} onClose={onClose} className={classes.root}>
      <DialogTitle
        align="left"
        className="title"
        disableTypography={true}
        onClose={onClose}
        gutterBottom={0}
      >
        {title}
      </DialogTitle>
      {subtitle && (
        <DialogTitle
          align="left"
          className="subtitle"
          disableTypography={true}
          onClose={onClose}
          gutterBottom={0}
        >
          {subtitle}
        </DialogTitle>
      )}
      <DialogContent>{actionButtons}</DialogContent>
    </Dialog>
  );
}

NotifySelectModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
  actions: PropTypes.array,
  customActions: PropTypes.func,
};
