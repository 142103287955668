import * as React from "react";
import useSWR, { mutate } from "swr";
import fetcher from "./fetcher";
import { flow, S } from "@mobily/ts-belt";

const baseEndpoint = "/api/influencer/searchDashboard";

function createQuery(
  query,
  opts = { excludeCollections: false, excludedIds: [] },
) {
  const params = new URLSearchParams();

  if (query) {
    params.set("q", query);
  }

  if (opts.excludeCollections) {
    params.set("exclude_collections", true);
  }

  if (opts.excludedIds && opts.excludedIds.length > 0) {
    params.set(
      "excluded_ids",
      opts.excludedIds.map(flow(String, S.trim)).join(","),
    );
  }

  return params;
}

export default function useShopsSearch(
  query,
  opts = { excludeCollections: false, excludedIds: [] },
) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}?${createQuery(query, opts)}`,
    fetcher,
    { keepPreviousData: true },
  );

  const shops = React.useMemo(() => {
    if (!data) {
      return [];
    }

    const { shops } = data;

    if (!shops || !Array.isArray(shops)) {
      return [];
    }

    return shops;
  }, [data]);

  return {
    shops,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function prefetchShops() {
  mutate(baseEndpoint, fetcher(baseEndpoint));
}
