import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import AddonBadge from "./AddonBadge";
import { useMatchesXsDown } from "../../hooks";

const Addons = React.memo(function Addons({ addons = {}, ActionComponent }) {
  const matchesXsDown = useMatchesXsDown();

  return (
    <Grid container item spacing={matchesXsDown ? 1 : 2}>
      {Object.values(addons).map((addon) => (
        <Grid key={addon.name} item xs={12} sm={6}>
          <AddonBadge
            name={addon.name}
            price={addon.price}
            title={addon.title}
            isNew={addon.isNew}
            description={addon.description}
            icon={<addon.Icon />}
            action={<ActionComponent addon={addon} />}
            additionalDetails={
              Boolean(addon.AdditionalDetails) && <addon.AdditionalDetails />
            }
          />
        </Grid>
      ))}
    </Grid>
  );
});

Addons.propTypes = {
  addons: PropTypes.object,
  ActionComponent: PropTypes.func.isRequired,
};

export default Addons;
