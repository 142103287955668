import PropTypes from "prop-types";
import * as React from "react";
import { Influencer, Product } from "../../../domain";
import * as constants from "../constants";
import ProductCard from "./ProductCard";
import ShopCard from "./ShopCard";
import "./styles.css";

const DEFAULT_PAGE_SIZE = 16;

export default function SectionExpanded({
  products = [],
  influencers = [],
  updateFavouriteStatus,
  look = constants.LOOK_TALL,
  numberPerRow,
  columnGap,
  isMobile,
}) {
  const [numberToShow, setNumberToShow] = React.useState(DEFAULT_PAGE_SIZE);

  const Component = React.useMemo(() => {
    if (constants.isProduct(look) || products?.length) {
      return ProductCard;
    }

    return ShopCard;
  }, [look, products?.length]);

  const data = React.useMemo(() => {
    if (products?.length) {
      return products;
    }

    return influencers;
  }, [products, influencers]);

  const dataLength = data.length - 1;

  return (
    <>
      <div
        className="shops-slider__expanded"
        style={{
          columnGap,
          rowGap: Math.min(8, columnGap),
          gridTemplateColumns: `repeat(${numberPerRow}, minmax(1px, 1fr))`,
        }}
      >
        {data.slice(0, numberToShow).map((datum) => (
          <Component
            style={{
              height: constants.cardHeightFor({ isMobile, look }),
              width: constants.cardWidthFor({ isMobile, look }),
              position: "relative",
            }}
            isMobile={isMobile}
            data={datum}
            updateFavouriteStatus={updateFavouriteStatus}
            look={look}
            key={datum.route}
            isExpanded
          />
        ))}
      </div>

      {numberToShow < dataLength && (
        <div className="flex-center">
          <button
            className="text-button"
            style={{ color: "white" }}
            onClick={() =>
              setNumberToShow((ns) =>
                Math.min(ns + DEFAULT_PAGE_SIZE, dataLength),
              )
            }
          >
            View More
          </button>
        </div>
      )}
    </>
  );
}

SectionExpanded.propTypes = {
  products: PropTypes.arrayOf(PropTypes.instanceOf(Product)),
  influencers: PropTypes.arrayOf(PropTypes.instanceOf(Influencer)),
  updateFavouriteStatus: PropTypes.func,
  look: PropTypes.oneOf(["tall", "wide"]),
  numberPerRow: PropTypes.number,
  columnGap: PropTypes.number,
  isMobile: PropTypes.bool,
};
