import * as React from "react";
import fetcher from "./fetcher";
import useSWR from "swr";

export default function useInviteStatus(influencerId) {
  const { data, error, mutate } = useSWR(
    influencerId ? `/api/influencer/inviteStatus/${influencerId}` : null,
    fetcher,
  );

  const inviteStatus = React.useMemo(() => {
    if (!data) {
      return null;
    }

    const { status } = data;
    return status;
  }, [data]);

  return {
    error,
    inviteStatus,
    isError: error,
    isLoading: !error && !data,
    mutate,
  };
}
