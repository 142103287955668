import * as React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(6),
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.h4.fontSize,
  fontWeight: theme.typography.h5.fontWeight,
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.typography.h6.fontSize,
    marginBottom: theme.spacing(2),
  },
}));

export default function AccountPageTitle({ children, align, style = {} }) {
  return (
    <StyledTitle component="h1" variant="h4" align={align} style={style}>
      {children}
    </StyledTitle>
  );
}

AccountPageTitle.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(["left", "center", "right"]),
  style: PropTypes.object,
};
