import useSWR from "swr";
import fetcher from "./fetcher";

const baseEndpoint = "/api/shippingAddress";

export function useShippingAddress(shippingAddressId) {
  const { data, error, mutate } = useSWR(
    shippingAddressId ? `${baseEndpoint}/${shippingAddressId}` : null,
    fetcher,
  );
  return {
    shippingAddress: data?.shippingAddress,
    isShippingAddressLoading: !data && !error,
    isShippingAddressError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useShippingAddressInfluencerId(influencerId) {
  const { data, error, mutate } = useSWR(
    influencerId ? `${baseEndpoint}/influencer/${influencerId}` : null,
    fetcher,
  );

  return {
    shippingAddress: data?.shippingAddress,
    isShippingAddressLoading: !data && !error,
    isShippingAddressError: Boolean(error),
    error,
    refresh: mutate,
  };
}
