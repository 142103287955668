import * as React from "react";

let isLoaded = false;

const clientId = "fac65073-76cf-4598-82e8-809e6df4d376";
const endpoint = "https://beacon-v2.helpscout.net";

function loadHelpScout() {
  if (isLoaded) {
    return;
  }

  window.Beacon = function Beacon(t, n, a) {
    window.Beacon.readyQueue.push({ method: t, options: n, data: a });
  };

  window.Beacon.readyQueue = [];

  window.Beacon("init", clientId);

  const firstScript = document.getElementsByTagName("script")[0];
  const newScript = document.createElement("script");

  newScript.type = "text/javascript";
  newScript.async = true;
  newScript.src = endpoint;

  firstScript.parentNode.insertBefore(newScript, firstScript);
  isLoaded = true;
}

export default function HelpScout() {
  React.useEffect(() => {
    setTimeout(loadHelpScout, 1750);
  }, []);

  return null;
}
