import { Box, IconButton, Fade } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import PropTypes from "prop-types";
import * as React from "react";

const StyledMessage = styled("div")(({ theme }) => ({
  background: "#FFFBDA",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  "& h6": {
    color: "#9A7515",
    fontFamily: theme.typography.interFontStack,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 800,
    lineHeight: "17px",
    margin: 0,
    marginBottom: theme.spacing(1),
    padding: 0,
  },
  "& p": {
    color: "#9A7515",
    fontFamily: theme.typography.interFontStack,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    lineHeight: "14.5px",
    margin: 0,
  },
}));

export default function EducationalMessage({ open, onClose }) {
  const [isOpen, setIsOpen] = React.useState(open);

  if (!open) {
    return null;
  }

  return (
    <Fade
      in={isOpen}
      onExited={() => {
        onClose();
      }}
    >
      <StyledMessage>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <h6>What you set is what you get</h6>
          <IconButton
            size="small"
            style={{ marginTop: -8, marginRight: -8, color: "#9A7515" }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <p>
          We&apos;ve updated the way that you set your prices. Set how much you
          want to make - you keep 100% of it, and we&apos;ll automatically set
          the price that customers will pay.
        </p>
      </StyledMessage>
    </Fade>
  );
}

EducationalMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
