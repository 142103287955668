import * as React from "react";

export default function Background() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
      className="home-page__background"
      preserveAspectRatio="none"
    >
      <path
        fill="#32363e"
        fillOpacity="1"
        d="M0,160L60,144C120,128,240,96,360,80C480,64,600,64,720,90.7C840,117,960,171,1080,170.7C1200,171,1320,117,1380,90.7L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
      ></path>
    </svg>
  );
}
