import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Box, Button } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import LoadingUI from "../LoadingUI";

const defaultPageSize = 50;

export default function GridPaginator({
  ItemComponent = null,
  pageItems = [],
  pageSize = defaultPageSize,
  renderItem = null,
  renderShowMore = null,
  ShowMoreComponent = null,
  useObserver = false,
}) {
  const showMoreRef = React.useRef(null);
  const observerRef = React.useRef(null);
  const [pages, setPages] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);

  const hasShowMoreRenderFunction =
    Boolean(renderShowMore) && typeof renderShowMore === "function";

  const hasMorePages = pages * pageSize < pageItems.length;

  React.useEffect(() => {
    if (useObserver && hasMorePages) {
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setIsLoading(true);
        }
      });

      observerRef.current.observe(showMoreRef.current);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [useObserver, hasMorePages, pages, pageSize, pageItems]);

  React.useEffect(() => {
    if (isLoading) {
      setPages((pages) => pages + 1);
      setIsLoading(false);
    }
  }, [isLoading]);

  const showMore = React.useMemo(() => {
    if (!hasMorePages) {
      return null;
    }

    if (hasShowMoreRenderFunction) {
      return renderShowMore({
        ref: showMoreRef,
        pages,
        setPages,
        pageSize,
        pageItems,
      });
    }

    if (ShowMoreComponent) {
      return (
        <ShowMoreComponent
          pages={pages}
          setPages={setPages}
          pageSize={pageSize}
          pageItems={pageItems}
          ref={showMoreRef}
        />
      );
    }

    return (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        py={2}
      >
        <Button ref={showMoreRef} onClick={() => setPages(pages + 1)}>
          Show more
        </Button>
      </Box>
    );
  }, [
    ShowMoreComponent,
    hasMorePages,
    hasShowMoreRenderFunction,
    renderShowMore,
    pages,
    pageSize,
    pageItems,
  ]);

  const items = React.useMemo(() => {
    return pageItems.slice(0, pages * pageSize).map((item, index) => {
      if (renderItem && typeof renderItem === "function") {
        return renderItem({ item, pages, index, pageSize });
      }

      if (ItemComponent) {
        return <ItemComponent key={index} {...item} />;
      }

      return item;
    });
  }, [renderItem, ItemComponent, pages, pageSize, pageItems]);

  return (
    <>
      <StyledGrid>{items}</StyledGrid>

      {isLoading && (
        <Box mt={2}>
          <LoadingUI disableShrink />
        </Box>
      )}

      {!isLoading && showMore}
    </>
  );
}

GridPaginator.propTypes = {
  ItemComponent: PropTypes.elementType,
  pageItems: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  renderItem: PropTypes.func,
  renderShowMore: PropTypes.func,
  ShowMoreComponent: PropTypes.elementType,
  useObserver: PropTypes.bool,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, minmax(100px, 1fr))",
  gridTemplateRows: "auto",
  gap: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
  "@media (min-width: 600px)": {
    gap: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
  },
  "@media (min-width: 980px)": {
    gridTemplateColumns: "repeat(3, minmax(200px, 1fr))",
    gap: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
  },
  "@media (min-width: 1000px)": {
    gridTemplateColumns: "repeat(4, minmax(200px, 1fr))",
  },
  "@media (min-width: 1280px)": {
    gridTemplateColumns: "repeat(5, minmax(200px, 1fr))",
  },
}));
