export const noop = () => {};
// array functions
export const head = (arr) => arr && arr[0];
export const last = (arr) => arr & arr[arr.length - 1];

export const add = curry((x, y) => x + y);
export const increment = add(1);

// Concert a method to a standalone function
export const demethodize = Function.prototype.bind.bind(
  Function.prototype.call,
);

// arity functions
export const urnary = (fn) => (x) => fn(x);
export const binary = (fn) => (x, y) => fn(x, y);

// simply debounce function
export function debounce(delay, fn) {
  let pending = false;

  return function wrapper(...args) {
    if (pending) clearTimeout(pending);
    // eslint-disable-next-line prefer-reflect
    pending = setTimeout(() => fn.apply(this, args), delay);
  };
}

function compose2(f, g) {
  // eslint-disable-next-line prefer-reflect
  return (x) => f.call(this, g.call(this, x));
}

// compose functions together, right to left
export function compose(...fns) {
  return fns.reduce(compose2);
}

// pipe functions together, left to right
export function pipe(...fns) {
  return fns.reduceRight(compose2);
}

// autocurry
export function curry(f) {
  return function innerCurry(...args1) {
    return args1.length >= f.length
      ? // eslint-disable-next-line prefer-reflect
        f.apply(this, args1)
      : (...args2) =>
          args1.length + args2.length >= f.length
            ? // eslint-disable-next-line prefer-reflect
              f.call(this, ...args1, ...args2)
            : curry(f)(...args1, ...args2);
  };
}

/*
 * mapObject takes a functionMap { key: (targetKey) => neValue } and applies it
 * to a target object, returning a new object with the keys mapped with the
 * functionMap.
 *
 * @param {object} functionMap
 * @param {object} targetObject
 * @returns {object} result
 */
export function mapObject(functionMap, targetObject) {
  return Object.entries(functionMap).reduce(
    (result, [key, mapper]) => {
      result[key] = mapper(targetObject[key]);
      return result;
    },
    { ...targetObject },
  );
}

/*
 * mapObjectAll maps all properties in an Object with mapper function
 *
 * @param {function} mapper
 * @param {object} targetObject
 * @returns {object} result
 */
export function mapObjectAll(mapper, targetObject) {
  return Object.fromEntries(
    Object.entries(targetObject).map(([key, value]) => [key, mapper(value)]),
  );
}

export function diffObject(original, changed) {
  return Object.entries(changed).reduce((result, [key, value]) => {
    if (original[key] !== value && value !== void 0) {
      result[key] = value;
    }
    return result;
  }, {});
}

/*
 * deepTrim trims all strings in an object NOTE: MUTATES
 * @param {object} target object
 * @returns {object} target object with keys trimmed
 */
export function deepTrim(target) {
  // eslint-disable-next-line guard-for-in
  for (const key in target) {
    const value = target[key];

    if (typeof value === "string") {
      // eslint-disable-next-line no-param-reassign
      target[key] = value.trim();
    } else if (Array.isArray(value)) {
      // eslint-disable-next-line no-param-reassign
      target[key] = value.map(deepTrim);
    } else if (typeof value === "object") {
      // eslint-disable-next-line no-param-reassign
      target[key] = deepTrim(value);
    }
  }

  return target;
}

// returns first non-null / undefined argument
export function coalesce(...args) {
  for (let i = 0; i < args.length; i++) {
    if (args[i] === null || args[i] === void 0) continue;
    return args[i];
  }
}

export function firstTruthy(...args) {
  return args.find((arg) => Boolean(arg));
}

/*
 * applies a map/filter depending on if an item is in arrayA but not in arrayB
 *
 * @param {array} arrayA
 * @param {array} arrayB
 * @param {object} options { mapper, comparator }
 *
 * @returns {array} array result
 */
export function reduceIfNotIn(
  arrayA,
  arrayB,
  options = { mapper: (x) => x, comparator: (x, y) => x === y },
) {
  const { mapper, comparator } = options;

  if (!arrayB.length) {
    return arrayA.map(mapper);
  }

  if (!arrayA.length) {
    return [];
  }

  return arrayA.reduce((result, valueA) => {
    if (!arrayB.find((valueB) => comparator(valueA, valueB))) {
      result.push(mapper(valueA));
    }
    return result;
  }, []);
}

/*
 * zipMap zips two array together with mapper function
 *
 * @param {function} mapper
 * @param {array} arrayA
 * @param {array} arrayB
 *
 * @returns {array} array result
 */
export function zipMap(mapper, arrayA, arrayB) {
  const result = [];
  const length = Math.min(arrayA.length, arrayB.length);

  for (let i = 0; i < length; i++) {
    result.push(mapper([arrayA[i], arrayB[i]], i, arrayA, arrayB));
  }

  return result;
}

/*
 * createSorter creates a sorter function
 *
 * @param {function} selector function
 * @returns {function} sorter function
 */
export function createSorter(fn) {
  return (a, b) => {
    const resultA = fn(a);
    const resultB = fn(b);

    return resultA === resultB ? 0 : resultA < resultB ? -1 : 1;
  };
}

export function sum(...args) {
  return args.reduce((result, num) => {
    if (Array.isArray(num)) {
      return result + sum(...num);
    }

    return result + num;
  }, 0);
}

export function ifEmpty(b) {
  return (a) => {
    if (Array.isArray(a) && !a.length) {
      return b;
    }

    if (typeof a === "string" && !a.length) {
      return b;
    }

    if (typeof a === "object" && a !== null && !Object.keys(a).length) {
      return b;
    }

    return a || b;
  };
}

export function not(fn) {
  return function wrapper() {
    // eslint-disable-next-line prefer-reflect, prefer-rest-params
    return !fn.apply(this, arguments);
  };
}

export function identity(x) {
  return x;
}

export function diffLengths(a, b) {
  const lengthA = a?.length ?? 0;
  const lengthB = b?.length ?? 0;
  return Math.abs(lengthA - lengthB);
}

export function toBoolean(something) {
  if (typeof something === "boolean") {
    return something;
  }

  if (typeof something === "string") {
    return something.toLowerCase().trim() === "true";
  }

  if (Array.isArray(something)) {
    return something.length > 0;
  }

  if (something === null) {
    return false;
  }

  if (something instanceof Set || something instanceof Map) {
    return something.size > 0;
  }

  if (typeof something === "object") {
    return Reflect.ownKeys(something).length > 0;
  }

  return Boolean(something);
}

export const pick = curry((keys, obj) => {
  if (!obj) {
    return {};
  }

  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => keys.includes(key)),
  );
});

export const omit = curry((keys, obj) => {
  if (!obj) {
    return {};
  }

  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key)),
  );
});

export function isEmpty(x) {
  if (typeof x === "string") {
    return x.trim().length === 0;
  }

  if (Array.isArray(x)) {
    return x.length === 0;
  }

  if (typeof x === "object" && x !== null) {
    return Object.keys(x).length === 0;
  }

  return !x;
}

export function values(obj) {
  if (typeof obj !== "object") {
    return [];
  }

  if (obj === null) {
    return [];
  }

  if (Array.isArray(obj)) {
    return obj;
  }

  if (obj instanceof Map || obj instanceof Set) {
    return [...obj.values()];
  }

  return Object.values(obj);
}

export function entries(obj) {
  if (typeof obj !== "object") {
    return [];
  }

  if (obj === null) {
    return [];
  }

  if (Array.isArray(obj)) {
    return obj;
  }

  if (obj instanceof Map || obj instanceof Set) {
    return [...obj.entries()];
  }

  return Object.entries(obj);
}

export const every = curry((fn, arr) => arr.every(fn));

export const some = curry((fn, arr) => arr.some(fn));

export const map = curry((fn, arr) => arr.map(fn));

export const find = curry((fn, arr) => arr.find(fn));

export const filter = curry((fn, arr) => arr.filter(fn));

export const reduce = curry((fn, initialValue, arr) =>
  arr.reduce(fn, initialValue),
);

export const or = curry((f, g, x) => f(x) || g(x));

export const and = curry((f, g, x) => f(x) && g(x));

export const renameKeys = curry((keyMap, obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = keyMap[key] || key;
    acc[newKey] = obj[key];
    return acc;
  }, {});
});
