import { Paper } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const Card = styled(Paper)(({ theme }) => ({
  boxShadow: theme.boxShadow.offsetDark,
  padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(
    2,
  )}px ${theme.spacing(1.5)}px`,
  borderRadius: theme.spacing(2),
  "& span": {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.3,
    color: theme.palette.grey[800],
  },
}));

export default Card;
