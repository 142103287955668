import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const OrderBadge = React.forwardRef(function OrderBadge(
  {
    label,
    top,
    left,
    right,
    bottom,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    style = {},
  },
  ref,
) {
  const isAbsolute = Boolean(top || left || right || bottom);

  const finalStyles = {
    top,
    left,
    right,
    bottom,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius,
    position: isAbsolute ? "absolute" : undefined,
    ...style,
  };

  return (
    <StyledBox style={finalStyles} ref={ref}>
      <StyledTypography>{label}</StyledTypography>
    </StyledBox>
  );
});

const StyledBox = styled(Box)(({ theme }) => ({
  background: theme.palette.green.limeLight,
  width: "fit-content",
  height: "fit-content",
  padding: `4px ${theme.spacing(1)}px`,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: theme.typography.semiBold,
  fontSize: "0.8rem",
  color: theme.palette.green.limeDark,
}));

export default OrderBadge;
