import * as React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { StyledTextButton, ErrorUI, RelativeBox } from "../../../theme";
import { Influencer } from "../../../domain";
import { Skeleton } from "@material-ui/lab";

const defaultDescription =
  "Add information about yourself, your events, and any message you want to relay to your fans to keep them updated and excited";

const Description = React.memo(function Description({
  influencer,
  isLoading,
  isError,
  refresh,
}) {
  const descriptionRef = React.useRef(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [description, setDescription] = React.useState(
    influencer?.caption ?? "",
  );

  const onClick = () => {
    if (isEditing) {
      setIsEditing(false);

      handleSave();
    } else {
      setIsEditing(true);

      window.requestAnimationFrame(() => {
        descriptionRef.current?.focus();
      });
    }
  };

  const handleSave = () => {
    const hasChanges =
      description !== defaultDescription && description !== influencer.caption;

    if (!hasChanges) {
      return;
    }

    const caption = description.trim();
    const updates = { caption: !caption ? null : caption };

    Influencer.update({ influencer, updates }).then(() => refresh());
  };

  const onCancel = () => {
    setDescription(influencer?.caption ?? "");
    setIsEditing(false);
  };

  React.useEffect(() => {
    if (influencer) {
      setDescription(influencer.caption ?? "");
    }
  }, [influencer]);

  if (isError) {
    return <ErrorUI message="Unable to load shop description" />;
  }

  const rows = Math.max(
    Math.round(defaultDescription.length / 38),
    Math.round(description.length / 38),
    3,
  );

  return (
    <Grid item>
      <Box pt={2}>
        <Typography component="p">
          <Typography variant="h6" component="span">
            Shop Description
          </Typography>

          {!isEditing && (
            <StyledTextButton
              size="medium"
              color="secondary"
              style={{ marginLeft: 16, marginBottom: 1 }}
              onClick={onClick}
            >
              Edit
            </StyledTextButton>
          )}
        </Typography>
      </Box>

      <RelativeBox maxWidth={391}>
        {isLoading && <Skeleton variant="rect" width="100%" height="9rem" />}

        {isEditing && (
          <StyledTypography variant="caption" color="textSecondary">
            {255 - description.length} / 255
          </StyledTypography>
        )}

        {!isLoading && (
          <StyledTextArea
            ref={descriptionRef}
            value={description}
            onChange={(evt) => setDescription(evt.target.value)}
            disabled={!isEditing}
            placeholder={defaultDescription}
            rows={rows}
            maxLength={255}
          />
        )}

        {isEditing && (
          <StyledGrid container spacing={1}>
            <Grid item>
              <StyledTextButton
                onClick={onCancel}
                color="default"
                size="medium"
              >
                Cancel
              </StyledTextButton>
            </Grid>

            <Grid item>
              <StyledTextButton
                onClick={onClick}
                color="secondary"
                size="medium"
              >
                Save
              </StyledTextButton>
            </Grid>
          </StyledGrid>
        )}
      </RelativeBox>
    </Grid>
  );
});

const StyledTextArea = styled("textarea")(({ theme }) => ({
  ...theme.typography.body2,
  width: "100%",
  resize: "none",
  color: theme.palette.grey.light,
  borderRadius: theme.spacing(0.4),
  outline: "1px solid transparent",
  outlineOffset: 2,
  outlineColor: theme.palette.grey[200],
  border: "none",
  background: "none",
  padding: theme.spacing(1),
  margin: 0,
  "&:disabled": {
    outline: "none",
    padding: `${theme.spacing(1)}px 0`,
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: -20,
  right: 0,
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  bottom: 0,
  right: theme.spacing(0.5),
  width: "fit-content",
  position: "absolute",
}));

export default Description;
