import * as React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledPageHeader = styled(Box)(({ gradient }) => ({
  backgroundColor: gradient === "true" ? "#fff" : "transparent",
  background:
    gradient === "true"
      ? `linear-gradient(180deg, #fff 94%, rgba(210,210,210,1) 100%)`
      : "transparent",
  gap: "1rem",
  margin: "0",
  padding: "2rem 1rem 1rem 2rem",
  position: "relative",
  alignItems: "flex-start",
}));

const PageTitle = styled(Typography)(({ theme }) => ({
  display: "block",
  fontFamily: theme.typography.interFontStack,
  fontSize: theme.typography.h3.fontSize,
  lineHeight: "38px",
  fontWeight: 700,
  minWidth: "fit-content",
  color: "#02324E",
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.typography.h6.fontSize,
    marginBottom: theme.spacing(1),
  },
}));

export default function DashboardPageHeader({
  gradient,
  pageTitle,
  children,
  ...args
}) {
  const title = pageTitle ? pageTitle : "DashboardPageHeader";
  return (
    <StyledPageHeader
      className="DashboardPageHeader"
      gradient={gradient}
      {...args}
    >
      <PageTitle>{title}</PageTitle>
      {children}
    </StyledPageHeader>
  );
}

DashboardPageHeader.propTypes = {
  gradient: PropTypes.oneOf(["true", "false"]),
  pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
};
