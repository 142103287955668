import { Box, Dialog, Link, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { DialogTitle, Form, ProgressButton } from "../theme";

import { actionCreators, useStateValue } from "../globalState";
import { useEmailModal } from "../services/EmailModal";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: theme.spacing(2),
    minWidth: "24rem",
  },
}));

const AuthModal = React.memo(function AuthModal({ isLoggingIn, onClose }) {
  const emailModal = useEmailModal();
  const dispatch = useStateValue()[1];
  const unmounted = useRef(false);
  const onCloseProps = useRef();
  const [isOpen, setIsOpen] = React.useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(Boolean(isLoggingIn) || false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  React.useEffect(() => {
    emailModal.block();

    return () => {
      emailModal.unblock();
    };
  }, [emailModal]);

  const closeModal = () => {
    onClose?.(onCloseProps.current);
  };

  const resetModal = () => {
    setEmail("");
    setPassword("");
    setErrorText("");
  };

  const submitAuth = async (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    if (isLoading) {
      return;
    }

    if (!email || !password) {
      return setErrorText("Please enter email and password");
    }

    setIsLoading(true);

    try {
      const { data, status } = await axios.post(
        isLogin ? `/api/auth/login` : `/api/auth/signup`,
        { email, password },
      );

      if (unmounted.current) return;
      if (status !== 200) return;

      const { data: user } = data;

      dispatch(actionCreators.createSetUser(user));
      setIsLoading(false);

      if (typeof onClose === "function") {
        onCloseProps.current = user;
        setIsOpen(false);
      }
    } catch (error) {
      if (unmounted.current) return;

      console.error(error);

      setPassword("");
      setIsLoading(false);

      if (error.response && error.response.data) {
        setErrorText(error.response.data);
      } else {
        setErrorText("Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {
    resetModal();
  }, [isLogin]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const authMethodText = isLogin ? "Log In" : "Sign Up";

  return (
    <StyledDialog
      onClose={() => setIsOpen(false)}
      open={isOpen}
      TransitionProps={{ onExited: closeModal }}
    >
      <DialogTitle onClose={() => setIsOpen(false)}>
        <Typography component="span" variant="h5">
          {authMethodText}
        </Typography>
      </DialogTitle>

      <Box
        component="form"
        display="flex"
        flexDirection="column"
        gridGap="8px"
        onSubmit={submitAuth}
        mb={1}
        pl={3}
        pr={3}
      >
        <Form.Input
          autoFocus
          maxLength={80}
          onChange={(e) => setEmail(e.target.value)}
          onInput={() => setErrorText("")}
          placeholder="Email"
          required
          value={email}
        />

        <Form.Input
          maxLength={40}
          onChange={(e) => setPassword(e.target.value)}
          onInput={() => setErrorText("")}
          placeholder="Password"
          required
          type="password"
          value={password}
        />

        {errorText && (
          <Typography variant="body2" color="error">
            {errorText}
          </Typography>
        )}

        <ProgressButton
          color="primary"
          disabled={isLoading}
          isLoading={isLoading}
          size="large"
          type="submit"
          variant="contained"
        >
          {authMethodText}
        </ProgressButton>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pb={3}
        pl={3}
        pr={3}
        pt={1}
      >
        <Typography variant="caption" color="textSecondary" align="center">
          By {isLogin ? "logging in" : "signing up"} you agree to our{" "}
          <Link href="/Policy" target="_blank">
            policies
          </Link>
          .
        </Typography>

        <Box display="flex" gridGap="16px">
          <Typography variant="caption">
            <Link href="/PasswordReset"> I Forgot My Password</Link>
          </Typography>

          {isLogin && (
            <Typography variant="caption" color="textSecondary" align="center">
              <span>Don&apos;t have an account? </span>

              <Link
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsLogin(false);
                }}
              >
                Signup
              </Link>
            </Typography>
          )}

          {!isLogin && (
            <Typography variant="caption" color="textSecondary" align="center">
              <span>Already have an account? </span>

              <Link
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsLogin(true);
                }}
              >
                Login
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
    </StyledDialog>
  );
});

AuthModal.propTypes = {
  isLoggingIn: PropTypes.bool,
  noReload: PropTypes.bool,
  showSignupBtn: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AuthModal;
