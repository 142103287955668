import * as React from "react";
import styled from "styled-components";
import ServiceBumpHeader from "./ServiceBumpHeader";
import ServiceBumpInput from "./ServiceBumpInput";
import ConfirmModal from "../../../ConfirmModal";
import { setServiceBump } from "./service";
import { currencyFormatter } from "../../../../util/formatCurrency";

const ServiceBump = React.memo(function ServiceBump({
  influencer,
  setIsEditingServiceFeeBump,
}) {
  const [showModal, setShowModal] = React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);

  const [value, setValue] = React.useState(
    influencer.service_fee_bump / 100 ?? 0,
  );

  const handleUpdate = React.useCallback(() => {
    if (!isEditing) {
      setIsEditingServiceFeeBump(true);
      return setIsEditing(true);
    }

    const newValue = value * 100;
    if (newValue === influencer.service_fee_bump) {
      setIsEditingServiceFeeBump(false);
      return setIsEditing(false);
    }

    setShowModal({
      action: async () => {
        const isSuccess = await setServiceBump({
          influencer_id: influencer.influencer_id,
          service_fee_bump: newValue,
        });
        if (isSuccess) setValue(value);
      },
      onClose: () => {
        setIsEditing(false);
        setShowModal(null);
        setIsEditingServiceFeeBump(false);
      },
      description: `set service fee bump for ${
        influencer.name
      } to ${currencyFormatter.format(value)}`,
    });
  }, [value, influencer, isEditing, setIsEditing, setIsEditingServiceFeeBump]);

  return (
    <ServiceFeeBumpForm>
      {showModal && <ConfirmModal {...showModal} />}
      <ServiceBumpHeader isEditing={isEditing} setIsEditing={handleUpdate} />
      <ServiceBumpInput
        isEditing={isEditing}
        value={value}
        setValue={setValue}
      />
    </ServiceFeeBumpForm>
  );
});

export default ServiceBump;

const ServiceFeeBumpForm = styled.div`
  margin: 1rem 0;
  padding: 0 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  display: flex;
  flex-wrap: wrap;
`;
