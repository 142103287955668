import useSWR from "swr";
import fetcher from "./fetcher";

const baseEndpoint = "/api/keyRoles";

export function useKeyRoles(keyRolesId) {
  const { data, error, mutate } = useSWR(
    keyRolesId ? `${baseEndpoint}/${keyRolesId}` : null,
    fetcher,
  );
  return {
    keyRoles: data?.keyRoles,
    isKeyRolesLoading: !data && !error,
    isKeyRolesError: Boolean(error),
    error,
    refresh: mutate,
  };
}
