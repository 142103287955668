import * as React from "react";

import { useStateValue, actionCreators } from "../globalState";

const BrowserWidth = () => {
  const dispatch = useStateValue()[1];

  const setBrowserWidth = React.useCallback(() => {
    dispatch(actionCreators.createSetBrowserWidth(window.innerWidth));
  }, [dispatch]);

  React.useEffect(() => {
    setBrowserWidth();

    window.addEventListener("resize", setBrowserWidth);

    return () => {
      window.removeEventListener("resize", setBrowserWidth);
    };
  }, [setBrowserWidth]);

  return null;
};

export default BrowserWidth;
