import * as React from "react";
import PropTypes from "prop-types";
import { StyledTextButton } from "../../../theme";
import Dialog from "./Dialog";

const SocialHandleModal = React.memo(function SocialHandleModal({ route }) {
  const [open, setOpen] = React.useState(false);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  if (!route) {
    return null;
  }

  return (
    <>
      <StyledTextButton
        size="small"
        onClick={() => setOpen(true)}
        color="secondary"
      >
        Edit Social Handles
      </StyledTextButton>

      {open && <Dialog open={open} onClose={onClose} route={route} />}
    </>
  );
});

SocialHandleModal.propTypes = {
  route: PropTypes.string.isRequired,
};

export default SocialHandleModal;
