import * as React from "react";
import { atom, useAtom, useAtomValue } from "jotai";
import { useCurrentUser } from "../globalState";
import { withIncludes, Influencer } from "../domain";
import useUserInfluencers from "../hooks/useUserInfluencers";
import useSessionStorage from "../hooks/useSessionStorage";

const activeInfluencerAtom = atom(null);
const enrichInfluencer = (influencer) =>
  withIncludes(Influencer.from(influencer));

export function useActiveInfluencerWithSetter() {
  const [influencer, setInfluencer] = useAtom(activeInfluencerAtom);

  const setActiveInfluencer = React.useCallback(
    (influencer) => {
      setInfluencer(enrichInfluencer(influencer));
    },
    [setInfluencer],
  );

  return React.useMemo(
    () => ({
      activeInfluencer: influencer,
      setActiveInfluencer,
    }),
    [influencer, setActiveInfluencer],
  );
}

export function useActiveInfluencer() {
  return useAtomValue(activeInfluencerAtom);
}

export default function ActiveInfluencerWrapper() {
  const currentUser = useCurrentUser();
  const shouldRequest =
    currentUser.email &&
    (currentUser.isAdmin ||
      currentUser.isSuperadmin ||
      (currentUser.isNotJustCustomer && currentUser.shops.length > 0));

  if (!shouldRequest) {
    return null;
  }

  return <ActiveInfluencerRestoreService />;
}

function ActiveInfluencerRestoreService() {
  const [influencer, setInfluencer] = useAtom(activeInfluencerAtom);

  const [savedActiveInfluencer, setSavedActiveInfluencer] = useSessionStorage(
    "STREAMILY::ACTIVE::INFLUENCER",
  );

  const { activeInfluencer: fetchedActiveInfluencer, userInfluencers } =
    useUserInfluencers(savedActiveInfluencer?.route);

  React.useEffect(() => {
    if (
      influencer?.influencerId === savedActiveInfluencer?.influencer_id &&
      savedActiveInfluencer?.influencer_id ===
        fetchedActiveInfluencer?.influencer_id
    ) {
      return;
    }

    // If there is no active influencer in state, or in saved session storage,
    // but fetch returns an influencer, we set the influencer to the fetched influencer.
    if (!influencer && !savedActiveInfluencer && fetchedActiveInfluencer) {
      setInfluencer(enrichInfluencer(fetchedActiveInfluencer));
      setSavedActiveInfluencer(fetchedActiveInfluencer);
      return;
    }

    // If there is no active influencer in state, but there is an active influencer
    // in saved session storage, and the fetched influencer is different from the saved influencer,
    // we set the influencer to the fetched influencer.
    if (
      !influencer &&
      savedActiveInfluencer &&
      fetchedActiveInfluencer &&
      savedActiveInfluencer?.influencer_id !==
        fetchedActiveInfluencer?.influencer_id
    ) {
      setInfluencer(enrichInfluencer(fetchedActiveInfluencer));
      setSavedActiveInfluencer(fetchedActiveInfluencer);
      return;
    }

    // If there is no active influencer in state, but there is an active
    // influencer in saved session storage, we set the influencer to the saved influencer.
    if (!influencer && !fetchedActiveInfluencer && savedActiveInfluencer) {
      setInfluencer(enrichInfluencer(savedActiveInfluencer));
      return;
    }

    // If there is an active influencer in state, and there is an active influencer
    // in session storage, and the saved influencer is different from the fetched influencer,
    // and the saved influencer is not in the user's influencers, we set the influencer to the
    // fetched influencer.
    if (
      influencer &&
      savedActiveInfluencer &&
      savedActiveInfluencer?.influencer_id !==
        fetchedActiveInfluencer?.influencer_id &&
      Array.isArray(userInfluencers) &&
      !userInfluencers.find(
        (i) => i.influencer_id === savedActiveInfluencer.influencer_id,
      )
    ) {
      setInfluencer(enrichInfluencer(fetchedActiveInfluencer));
      setSavedActiveInfluencer(fetchedActiveInfluencer);
      return;
    }

    // If there is active influencer in state, and there is an active
    // influencer in saved session storage, and the saved influencer is different
    // from the fetched influencer, we set the influencer to the saved influencer.
    if (
      influencer &&
      savedActiveInfluencer?.influencer_id !== influencer?.influencerId
    ) {
      setSavedActiveInfluencer(influencer.raw());
    }
  }, [
    fetchedActiveInfluencer,
    influencer,
    savedActiveInfluencer,
    setInfluencer,
    setSavedActiveInfluencer,
    userInfluencers,
  ]);

  return null;
}
