import {
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { ExpandMoreRounded } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { generateId, identity } from "../../util";

function IconComponent({ className, ...props }) {
  return <ExpandMoreRounded {...props} className={clsx(className)} />;
}

IconComponent.propTypes = {
  className: PropTypes.string,
};

const SmallInput = withStyles((theme) => ({
  root: {
    background: "#fff",
    borderRadius: 5,
    minWidth: 165,
  },
  input: {
    borderColor: theme.palette.grey[500],
    borderRadius: 5,
    color: "#5B5B5B",
    fontFamily: theme.typography.interFontStack,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    padding: theme.spacing(1.25, 1.5),
    "&:auto-fill": {
      background: "white",
    },
    "&:-webkit-autofill": {
      background: "white",
    },
  },
}))(InputBase);

const StyledSelect = withStyles((theme) => ({
  root: {
    outline: `1px solid ${theme.palette.grey[300]}`,
    transition: "outline 0.2s ease-in-out",
    borderRadius: 5,
    "&:hover:not(.Mui-disabled)": {
      outline: `1px solid ${theme.palette.grey[500]}`,
    },
    "&:focus, &:focus-within": {
      borderRadius: 5,
      background: "#fff",
      outline: `2px solid ${theme.palette.grey[500]}`,
      outlineOffset: -2,
    },
  },
  icon: {
    right: theme.spacing(1),
  },
}))(MuiSelect);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    background: "#fff",
    color: theme.typography.body1.color,
    fontFamily: theme.typography.interFontStack,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    padding: theme.spacing(1.25, 1.5),
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 2,
    borderRadius: 5,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: "0px 2px 13.3775px rgba(0, 0, 0, 0.125)",
  },
  list: {
    display: "flex",
    flexFlow: "column nowrap",
    background: "#ebebeb",
    gap: 1,
    minWidth: 140,
    padding: 0,
    "& .Mui-selected": {
      background: theme.palette.grey[100],
    },
    "& .MuiListItem-root:hover": {
      background: theme.palette.grey[100],
    },
  },
}));

const Select = React.memo(function Select({
  disabled,
  error,
  formControlProps,
  getOptionLabel = identity,
  getOptionValue = identity,
  helperText,
  inputProps,
  InputProps = {},
  onChange,
  options = [],
  value,
  ...props
}) {
  const classes = useStyles();

  const id = React.useMemo(() => generateId(), []);

  return (
    <FormControl error={Boolean(error)} {...formControlProps}>
      <StyledSelect
        {...props}
        id={id}
        input={<SmallInput {...InputProps} />}
        inputProps={inputProps}
        value={value}
        onChange={onChange}
        disabled={disabled}
        IconComponent={IconComponent}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          classes,
        }}
      >
        {options.map((option) => (
          <StyledMenuItem
            key={getOptionValue(option)}
            value={getOptionValue(option)}
          >
            {getOptionLabel(option)}
          </StyledMenuItem>
        ))}
      </StyledSelect>

      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});

Select.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  formControlProps: PropTypes.object,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  helperText: PropTypes.string,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  menuClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  shrink: PropTypes.bool,
};

export default Select;
