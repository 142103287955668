import * as React from "react";

export default function RequestGraphicsIcon({
  width = 17,
  height = 17,
  fill = "none",
  stroke = "currentColor",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 21"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.54 17.7396C24.54 18.2945 24.3145 18.8267 23.9132 19.2191C23.5119 19.6115 22.9676 19.832 22.4 19.832H3.14C2.57244 19.832 2.02812 19.6115 1.62679 19.2191C1.22546 18.8267 1 18.2945 1 17.7396V6.23111C1 5.67616 1.22546 5.14394 1.62679 4.75153C2.02812 4.35912 2.57244 4.13867 3.14 4.13867H7.42L9.56 1H15.98L18.12 4.13867H22.4C22.9676 4.13867 23.5119 4.35912 23.9132 4.75153C24.3145 5.14394 24.54 5.67616 24.54 6.23111V17.7396Z"
        stroke={stroke}
        strokeWidth="1.55633"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.77 15.9086C14.9368 15.9086 16.6933 13.9764 16.6933 11.5929C16.6933 9.20947 14.9368 7.27728 12.77 7.27728C10.6032 7.27728 8.84668 9.20947 8.84668 11.5929C8.84668 13.9764 10.6032 15.9086 12.77 15.9086Z"
        stroke={stroke}
        strokeWidth="1.55633"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
