import * as React from "react";

export default function ShopBuilderCopyIcon({ width = 28, height = 28 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7054 6.99219H8.5764C7.70146 6.99219 6.99219 7.70146 6.99219 8.5764V15.7054C6.99219 16.5803 7.70146 17.2896 8.5764 17.2896H15.7054C16.5803 17.2896 17.2896 16.5803 17.2896 15.7054V8.5764C17.2896 7.70146 16.5803 6.99219 15.7054 6.99219Z"
        stroke="currentColor"
        strokeWidth="1.13158"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.82359 11.7448H3.03148C2.61132 11.7448 2.20837 11.5779 1.91127 11.2808C1.61417 10.9837 1.44727 10.5807 1.44727 10.1606V3.0316C1.44727 2.61144 1.61417 2.20849 1.91127 1.91139C2.20837 1.6143 2.61132 1.44739 3.03148 1.44739H10.1604C10.5806 1.44739 10.9835 1.6143 11.2806 1.91139C11.5777 2.20849 11.7447 2.61144 11.7447 3.0316V3.82371"
        stroke="currentColor"
        strokeWidth="1.13158"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
