import * as React from "react";
import { useCurrentUser } from "../globalState";
import { useLocation } from "react-router-dom";
import { useMatchesSmDown } from "./useBreakpoint";

export default function useShrinkBuffer() {
  const currentUser = useCurrentUser();
  const location = useLocation();
  const matchesSmDown = useMatchesSmDown();

  const shrinkBuffer = React.useMemo(() => {
    return (
      matchesSmDown &&
      currentUser &&
      (currentUser.roles.isAdmin ||
        currentUser.roles.isSuperadmin ||
        currentUser.roles.isTalent ||
        currentUser.roles.isArtist ||
        currentUser.roles.isAgency ||
        currentUser.roles.isTalentCoordinator)
    );
  }, [matchesSmDown, currentUser]);

  const isDashboard = React.useMemo(
    () => location.pathname.toLowerCase().startsWith("/dashboard"),
    [location.pathname],
  );

  return React.useMemo(
    () => ({
      shrinkBuffer,
      isDashboard,
      matchesSmDown,
      shouldShrink: shrinkBuffer && isDashboard,
    }),
    [shrinkBuffer, matchesSmDown, isDashboard],
  );
}
