import * as React from "react";
import useSWR from "swr";
import { InfluencerEvent } from "../domain";
import fetcher from "./fetcher";

const baseEndpoint = "/api/influencerEvents";

export function useAgentShopEvents(userId) {
  const { data, error, mutate } = useSWR(
    userId ? `${baseEndpoint}/agentShop/${userId}` : null,
    fetcher,
  );

  const agentShopEvents = React.useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data;
  }, [data]);

  return {
    agentShopEvents,
    isLoading: !agentShopEvents && !error,
    isError: Boolean(error),
    error,
    refreshEvents: mutate,
  };
}

export default function useInfluencerEvents(influencerId) {
  const { data, error, mutate } = useSWR(
    influencerId ? `${baseEndpoint}/${influencerId}` : null,
    fetcher,
  );

  const influencerEvents = React.useMemo(() => {
    if (!Array.isArray(data)) {
      return;
    }

    return data.map(InfluencerEvent.from);
  }, [data]);

  return {
    influencerEvents,
    isLoading: !influencerEvents && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
