import * as React from "react";
import useSWR from "swr";
import { User, withIncludes } from "../domain";
import fetcher from "./fetcher";

const baseEndpoint = "/api/user";

export default function useUser(userId) {
  const { data, error, mutate } = useSWR(`${baseEndpoint}/${userId}`, fetcher);

  const user = React.useMemo(() => {
    if (!data) {
      return;
    }

    return withIncludes(User.from(data));
  }, [data]);

  return {
    user,
    isLoading: !user && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
