import * as React from "react";
import PropTypes from "prop-types";
import { withStyles, styled } from "@material-ui/core/styles";
import { Switch, FormGroup } from "@material-ui/core";
import generateId from "../../util/generateId";

export default function HideUnpersonalizedToggle({
  isChecked,
  onChange,
  label,
  labelPlacement = "start",
  color = "primary",
}) {
  const id = React.useMemo(() => generateId(), []);
  return (
    <StyledFormGroup
      className={
        labelPlacement === "start" || labelPlacement === "end"
          ? `row-${labelPlacement}`
          : `column-${labelPlacement}`
      }
    >
      <ToggleSwitch
        className={color}
        checked={isChecked}
        onChange={onChange}
        name="unpersonalized"
        id={id}
      />
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
    </StyledFormGroup>
  );
}

HideUnpersonalizedToggle.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  labelPlacement: PropTypes.oneOf(["start", "end", "top", "bottom"]),
};

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  display: "flex",
  flexGap: theme.spacing(1),
  gap: theme.spacing(1),
  "&.row-start": {
    flexDirection: "row-reverse",
  },
  "&.row-end": {
    flexDirection: "row",
  },
  "&.column-top": {
    flexDirection: "column-reverse",
  },
  "&.column-bottom": {
    flexDirection: "column",
  },
}));

const StyledLabel = styled("label")(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  fontFamily: theme.typography.interFontStack,
  cursor: "pointer",
}));

export const ToggleSwitch = withStyles((theme) => ({
  root: {
    width: theme.spacing(4) + 2,
    height: theme.spacing(2) + 2,
    padding: 0,
    zIndex: 5,
    overflow: "initial",
    boxSizing: "border-box",
  },
  switchBase: {
    boxSizing: "border-box",
    padding: 1,
    "&$checked": {
      transform: `translateX(${theme.spacing(2)}px)`,
      color: theme.palette.common.white,
      "& + $track": {
        background: theme.gradient.primary,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "3px solid #fff",
    },
  },
  switchBaseTalent: {
    boxSizing: "border-box",
    padding: 1,
    "&$checked": {
      transform: `translateX(${theme.spacing(2)}px)`,
      color: theme.palette.common.white,
      "& + $track": {
        background: theme.gradient.secondary,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "3px solid #fff",
    },
  },
  thumb: {
    boxSizing: "border-box",
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  track: {
    boxSizing: "border-box",
    borderRadius: theme.spacing(2),
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[500],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase:
          props.color === "primary"
            ? classes.switchBase
            : classes.switchBaseTalent,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
