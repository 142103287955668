import useSWRimmutable from "swr/immutable";
import fetcher from "./fetcher";
import getCookie from "../util/getCookie";

const baseEndpoint = "/api/purchases/savedPurchaseResponse";

export default function usePurchaseResponse() {
  const { data, error } = useSWRimmutable(baseEndpoint, fetcher);

  const hasPurchaseResponse =
    typeof data === "object" && data !== null && Object.values(data).length > 0;

  return {
    purchaseResponse: hasPurchaseResponse ? data : null,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
  };
}

export function hasPurchaseResponseCookie() {
  return getCookie("scs") !== undefined;
}
