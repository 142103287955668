import * as React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { useActiveInfluencer } from "../../hooks";

import MobileSideBarNav from "./MobileSideBarNav";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MobileSidebarModal = React.memo(function MobileSidebarModal({
  onClose = null,
}) {
  const activeInfluencer = useActiveInfluencer();
  const [internalOpen, setInternalOpen] = React.useState(true);

  const linkClick = React.useCallback(() => {
    setInternalOpen(false);
  }, []);

  const wrappedOnClose = React.useCallback(() => {
    setInternalOpen(false);
  }, []);

  const transitionEnd = React.useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  return (
    <>
      <StyledDialog
        open={internalOpen}
        onClose={wrappedOnClose}
        TransitionComponent={Transition}
        transitionDuration={200}
        TransitionProps={{ onExited: transitionEnd }}
        aria-labelledby="mobile-sidebar-dialog-title"
        aria-describedby="mobile-sidebar-dialog-description"
      >
        <DialogContent>
          <MobileSideBarNav
            activeInfluencer={activeInfluencer}
            UnsignedCountRef={null}
            unsignedCount={null}
            linkClick={linkClick}
          />
        </DialogContent>
      </StyledDialog>
    </>
  );
});

MobileSidebarModal.propTypes = {
  onClose: PropTypes.func,
};

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paperScrollPaper": {
    maxHeight: "100%",
  },
  "& .MuiPaper-root": {
    bottom: "0px",
    right: "0px",
    borderRadius: 0,
    height: "100%",
    margin: "0",
    position: "fixed",
  },
  "& .MuiDialogContent-root": {
    padding: "0 1em",
  },
  "& .MuiDialogContentText-root": {
    padding: 0,
    margin: 0,
  },
}));

export default MobileSidebarModal;
