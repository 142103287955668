import { Button } from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";
import PropTypes from "prop-types";
import * as React from "react";
import { Icons } from ".";
import { copyToClipboard } from "../util";

export default function CopyButton({
  className = "",
  text = "Copy Link",
  payload = "",
  ButtonComponent = Button,
}) {
  const unmounted = React.useRef(false);
  const [didCopy, setDidCopy] = React.useState(false);

  const onClick = React.useCallback(async () => {
    try {
      await copyToClipboard(payload);

      setDidCopy(true);

      setTimeout(() => {
        setDidCopy(false);
      }, 2000);
    } catch (err) {
      console.error(err);
    }
  }, [payload]);

  React.useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <ButtonComponent className={className} onClick={onClick}>
      {didCopy && <CheckRounded style={{ width: 22, height: 22 }} />}
      {!didCopy && <Icons.ShopBuilderCopyIcon width={22} height={22} />}
      <span>{didCopy ? "Copied!" : text}</span>
    </ButtonComponent>
  );
}

CopyButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  payload: PropTypes.string.isRequired,
  ButtonComponent: PropTypes.elementType,
};
