import * as React from "react";
import { Grid, Box } from "@material-ui/core";
import ShopDetails from "./ShopDetails";
import ShopVideo from "./ShopVideo";
import ShopSideDetails from "./ShopSideDetails";
import ShopTags from "./ShopTags";
import { useMatchesMdDown, useMatchesXsDown } from "../../../hooks";

const ShopHeader = React.memo(function ShopHeader() {
  const matchesXs = useMatchesXsDown();
  const matchesMd = useMatchesMdDown();

  return (
    <Box
      pt={matchesXs ? 2 : 8}
      pb={matchesXs ? 2 : 4}
      px={matchesXs ? 0 : matchesMd ? 8 : 12}
    >
      <Grid container spacing={5} alignItems="flex-start">
        <Grid item xs={12} sm="auto">
          <ShopVideo />
        </Grid>

        <Grid item sm container spacing={matchesXs ? 2 : 4}>
          <Grid item xs>
            <ShopDetails />
          </Grid>

          <Grid item xs={12} lg={5} xl={4}>
            <ShopSideDetails />
          </Grid>

          <Grid item xs={12}>
            <ShopTags />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default ShopHeader;
