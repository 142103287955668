import fetcher, { HTTPMethods } from "../../hooks/fetcher";
import { isProduction, isDev, isStaging } from "../../util";

function createResourceURL(resource) {
  if (!isProduction && !isDev && !isStaging) {
    return `http://${resource}`;
  }

  return `https://${resource}`;
}

export function createVideoUpload({ file, resourceLocator }) {
  const { resource, token } = resourceLocator;

  const controller = new AbortController();
  const { signal } = controller;

  return {
    controller,
    promise: new Promise((resolve, reject) => {
      window
        .fetch(createResourceURL(resource), {
          method: HTTPMethods.POST,
          body: file,
          signal,
          headers: {
            Authorization: `Bearer ${token}`,
            Filename: file.name,
            "Content-Length": file.size,
          },
        })
        .then(handleSuccess)
        .then(resolve)
        .catch(reject);
    }),
  };

  function handleSuccess(res) {
    if (!res.ok) {
      return Promise.reject(
        new Error(`response not ok, status: ${res.status}`),
      );
    }

    const isJSON =
      res.headers.has("content-type") &&
      res.headers
        .get("content-type")
        .toLowerCase()
        .includes("application/json");

    if (isJSON) {
      return res.json();
    }

    return res.text();
  }
}

export function removeVideoAvatar({ influencerId }) {
  try {
    return fetcher(`/api/influencer/${influencerId}`, {
      method: HTTPMethods.PUT,
      body: JSON.stringify({ video_avatar_url: null }),
    });
  } catch (err) {
    console.error(err);
    return null;
  }
}
