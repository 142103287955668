import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { createCaptchaFetcher } from "../../hooks";
import { ACTIONS, loadCaptcha } from "../../services/Captcha";
import { Form, StreamilyPageTitle } from "../../theme";
import Order from "./Order";

const StyledBox = styled(Box)(({ theme }) => ({
  background: "white",
  border: `1px solid ${theme.palette.grey[300]}`,
}));

function isValid(orderNumber = "") {
  return (
    orderNumber.length > 8 &&
    orderNumber.length <= 16 &&
    /^[PH][0-9]+$/.test(orderNumber)
  );
}

const captchaFetcher = createCaptchaFetcher(ACTIONS.ORDER_STATUS);

async function getOrder(params) {
  const json = await captchaFetcher(
    `/api/orders?${new URLSearchParams(params)}`,
  );

  if (!json.order) {
    throw new Error(json.message);
  }

  return json.order;
}

export default function WheresMyOrder() {
  const history = useHistory();
  const checkedParamsRef = React.useRef(false);
  const orderNumberInputRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [orderNumberError, setOrderNumberError] = React.useState("");
  const [orderNumberValue, setOrderNumberValue] = React.useState("");
  const [order, setOrder] = React.useState(null);

  React.useEffect(() => {
    loadCaptcha();
  }, []);

  const onSubmit = React.useCallback(
    async (evt, orderNumber) => {
      evt?.preventDefault?.();

      if (isLoading) {
        return;
      }

      if (order) {
        setOrder(null);
        setOrderNumberError("");
        setOrderNumberValue("");

        return setTimeout(() => {
          orderNumberInputRef.current.focus();
        });
      }

      try {
        if (orderNumber.length && !isValid(orderNumber)) {
          setOrderNumberError(
            "Order number should start with P or H and be followed by numbers.",
          );
          return;
        }

        setOrderNumberError("");
        setIsLoading(true);

        const order = await getOrder({ order_number: orderNumber });

        setIsLoading(false);
        setOrder(order);
      } catch (err) {
        setIsLoading(false);
        setOrderNumberError(err.message);
      }
    },
    [isLoading, order],
  );

  React.useEffect(() => {
    if (order || checkedParamsRef.current) {
      return;
    }

    checkedParamsRef.current = true;

    const params = new URLSearchParams(window.location.search);

    if (params.has("order_number")) {
      const orderNumber = params.get("order_number");

      onSubmit(null, orderNumber);

      history.replace(window.location.pathname);
    }
  }, [order, onSubmit, history]);

  return (
    <Container maxWidth="md">
      <Box mt={6}>
        <StreamilyPageTitle
          title="Where’s my order?"
          subtitle="Use the form below to verify your order status."
        />
      </Box>

      <Box mt={4}>
        <Box
          display="grid"
          gridGap="16px"
          gridTemplateColumns="250px 150px"
          alignItems="flex-start"
          component="form"
          onSubmit={(evt) => {
            onSubmit(evt, orderNumberValue);
          }}
        >
          <Form.Input
            autoFocus
            disabled={Boolean(order)}
            error={Boolean(orderNumberError)}
            helperText={orderNumberError}
            inputProps={{
              maxLength: 16,
              "data-testid": "wheres-my-order__order-number-input",
            }}
            inputRef={orderNumberInputRef}
            label="Order Number"
            onBlur={() => setOrderNumberValue(orderNumberValue.trim())}
            onChange={(evt) => setOrderNumberValue(evt.target.value)}
            required
            size="small"
            value={orderNumberValue}
            variant="outlined"
          />

          <Button
            data-testid="wheres-my-order__search-button"
            disabled={
              !orderNumberValue.trim().length ||
              !isValid(orderNumberValue.trim())
            }
            variant="contained"
            color={order ? "default" : "primary"}
            type="submit"
          >
            {order ? "Search Again" : "Search"}
          </Button>
        </Box>

        <StyledBox
          data-testid="wheres-my-order__results"
          borderRadius={16}
          minHeight="25vh"
          mt={4}
          mb={8}
          p={4}
        >
          {!order && !isLoading && (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              minHeight="33vh"
            >
              <Typography
                color="textSecondary"
                style={{ opacity: 0.5 }}
                variant="body2"
              >
                Enter an Order Number to search.
              </Typography>
            </Box>
          )}

          {isLoading && (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              minHeight="25vh"
              width="100%"
            >
              <CircularProgress size={32} />
            </Box>
          )}

          {order && !isLoading && <Order order={order} />}
        </StyledBox>
      </Box>
    </Container>
  );
}
