import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { styled } from "@material-ui/core/styles";

import ShopBuilderCopyIcon from "../Icons/ShopBuilderCopyIcon";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Typography, Paper, Grid } from "@material-ui/core";

export default function PaperCard({ message, text, type, onClick }) {
  return (
    <StyledPaper elevation={3} onClick={() => onClick(text)}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
      >
        <Grid item xs="auto">
          {type === "link" && <ShopBuilderCopyIcon width={28} height={28} />}

          {type === "event" && <DateRangeIcon width={28} height={28} />}
        </Grid>

        <Grid item xs container>
          <Grid item xs>
            <StyledTypography
              style={{ width: 128 }}
              align="right"
              className="title"
              aria-roledescription="Title"
            >
              {message}
            </StyledTypography>

            <StyledTypography
              align="right"
              className={clsx(type)}
              aria-roledescription="Text"
            >
              {text}
            </StyledTypography>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

PaperCard.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(["link", "event"]),
  text: PropTypes.string,
  onClick: PropTypes.func,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  cursor: "pointer",
  boxShadow: theme.boxShadow.soft,
  borderRadius: "15px",
  padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  width: "100%",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.interFontStack,
  "&.link": {
    color: theme.palette.grey.main,
    fontSize: theme.typography.pxToRem(9.5),
  },
  "&.event": {
    color: theme.palette.grey.main,
    fontSize: theme.typography.pxToRem(9.5),
  },
  "&.title": {
    color: theme.typography.h1,
    fontWeight: "600",
    fontSize: theme.typography.pxToRem(12),
  },
}));
