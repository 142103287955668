import * as React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import { SITE_VIDEO } from "../../../../config";

export default function HeroVideo({ isMobile }) {
  const videoRef = React.useRef(null);
  const [muted, setMuted] = React.useState(true);
  const [hidden, setHidden] = React.useState(!isMobile);

  React.useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    // open bug in React, cannot set video muted attribute
    videoRef.current.defaultMuted = true;
    videoRef.current.muted = true;
  }, []);

  const toggleMute = () => {
    if (!videoRef.current) {
      return;
    }

    setMuted((muted) => {
      videoRef.current.muted = !muted;
      return !muted;
    });
  };

  return (
    <div
      className="home-page__hero__right"
      onMouseEnter={() => setHidden(false)}
      onMouseLeave={() => setHidden(true)}
    >
      {(isMobile || !hidden) && (
        <IconButton
          style={{ position: "absolute", bottom: 4, right: 4 }}
          onClick={toggleMute}
        >
          {muted ? (
            <VolumeOffIcon fontSize="small" />
          ) : (
            <VolumeUpIcon fontSize="small" />
          )}
        </IconButton>
      )}

      <video
        ref={videoRef}
        autoPlay
        height="100%"
        width="100%"
        preload="auto"
        loop
        muted
        playsInline
        style={{
          width: isMobile ? "100vw" : "100%",
          maxHeight: isMobile ? "60vh" : "400px",
          borderRadius: isMobile ? 0 : 10,
          objectFit: "contain",
        }}
      >
        <source src={SITE_VIDEO} type="video/mp4" />
      </video>
    </div>
  );
}

HeroVideo.propTypes = {
  isMobile: PropTypes.bool,
};
