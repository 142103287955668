import * as React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Product, SignType } from "../../../../domain";
import SignTypeBadge, { TopRight } from "../../../SignTypeBadge";

const ProductModalImage = React.memo(function ProductModalImage({ product }) {
  return (
    <StyledBox>
      {product.signedType && (
        <TopRight>
          <SignTypeBadge
            signedType={product.signedType}
            label={SignType.toLabel(product.signedType)}
          />
        </TopRight>
      )}

      <StyledImage src={product.imageUrl} alt={product.productname} />
    </StyledBox>
  );
});

ProductModalImage.propTypes = {
  product: PropTypes.instanceOf(Product),
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  isPresigned: PropTypes.bool,
};

const StyledBox = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  aspectRatio: "1 / 1",
  width: "100%",
  height: 300,
  [theme.breakpoints.down("xs")]: {
    marginBottom: theme.spacing(2),
  },
}));

const StyledImage = styled("img")(({ theme }) => ({
  objectFit: "contain",
  verticalAlign: "center",
  maxWidth: "100%",
  maxHeight: "100%",
  boxShadow: theme.boxShadow.sharp,
}));

export default ProductModalImage;
