import * as React from "react";
import PropTypes from "prop-types";
import { Fab, Box } from "@material-ui/core";
import { withStyles, styled } from "@material-ui/core/styles";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { debounce } from "../../util";
import clsx from "clsx";

const MIN_SCROLL_DISTANCE = 25;

const BackToTopButton = React.memo(function BackToTopButton({
  color,
  top,
  left,
  right,
  bottom,
}) {
  const [isHidden, setIsHidden] = React.useState(true);

  const handleClick = React.useCallback(() => {
    setIsHidden(true);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleScroll = React.useCallback(
    debounce(100, () => {
      const height = window.scrollY;

      setIsHidden((isHidden) => {
        if (isHidden && height > MIN_SCROLL_DISTANCE) {
          return false;
        }

        if (!isHidden && height <= MIN_SCROLL_DISTANCE) {
          return true;
        }

        return isHidden;
      });
    }),
    [],
  );

  React.useEffect(() => {
    const cb = handleScroll;

    window.addEventListener("scroll", cb);

    return () => {
      window.removeEventListener("scroll", cb);
    };
  }, [handleScroll]);

  return (
    <StyledBox
      position="fixed"
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      className={clsx(isHidden && "hidden")}
    >
      <StyledFab
        size="small"
        onClick={handleClick}
        title="Back to top"
        aria-label="Back to top"
        color={color}
      >
        <ArrowUpwardIcon />
      </StyledFab>
    </StyledBox>
  );
});

BackToTopButton.propTypes = {
  color: PropTypes.oneOf(["default", "primary", "secondary"]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

function backgroundForColor(theme) {
  return ({ color }) => {
    if (color === "primary") {
      return theme.gradient.primary;
    }

    if (color === "secondary") {
      return theme.gradient.secondary;
    }

    return theme.palette.grey.main;
  };
}

function hoverBackgroundForColor(theme) {
  return ({ color }) => {
    if (color === "primary") {
      return theme.gradient.primaryDark;
    }

    if (color === "secondary") {
      return theme.gradient.secondaryDark;
    }

    return theme.palette.grey.dark;
  };
}

const StyledFab = withStyles((theme) => ({
  root: {
    background: backgroundForColor(theme),
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    fontWeight: theme.typography.semiBold,
    "&:hover": {
      background: hoverBackgroundForColor(theme),
    },
  },
}))(Fab);

const StyledBox = styled(Box)(({ theme }) => ({
  transform: "translateY(0)",
  opacity: 1,
  transition: "all 0.1s ease-out",
  "&.hidden": {
    transform: "translateY(8px)",
    opacity: 0,
  },
}));

export default BackToTopButton;
