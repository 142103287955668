import { styled } from "@material-ui/core/styles";

export default styled("h6")(({ theme }) => ({
  ...theme.typography.h6,
  fontSize: "1rem",
  margin: 0,
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },
}));
