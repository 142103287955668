import { Button, Box, Grid, Typography } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { G, A } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { Form } from "../../theme";
import { useUsers } from "../../hooks";
import * as utils from "./utils";

const params = { is_salesperson: true };

const nameFor = (user) => {
  const parts = [user.firstName, user.lastName].filter(Boolean);

  if (parts.length === 0) {
    return "No Name";
  }

  return parts.join(" ");
};

const SalespersonSearch = React.memo(function SalespersonSearch({
  isError,
  isLoading,
  salespersons,
  setSalespersons,
  setUser,
  user,
}) {
  const inputRef = React.useRef(null);
  const { users } = useUsers(params);
  /*
   * onClick handles adding a new Salesperson to the user's list of
   * workingSalespeople. This fails if the user is already at their max amount of
   * salespeople.
   */
  const onClick = () => {
    if (salespersons.length === utils.MAX_SALESPEOPLE) {
      return;
    }

    const newSalesperson = {
      userId: user.userId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      fullName: user.fullName,
      created: user.created,
      percentage: 100,
    };

    setUser(null);
    setSalespersons(A.append(newSalesperson));
  };

  const onChange = (_, newValue) => {
    if (newValue) {
      setUser(newValue);
    } else {
      setUser(null);
    }

    window.requestAnimationFrame(() => {
      inputRef.current?.blur?.();
    });
  };

  const renderInput = (params) => (
    <Form.Input
      {...params}
      inputRef={inputRef}
      inputProps={{ maxLength: 45, ...params.inputProps }}
      fullWidth
      label="Salesperson"
      size="small"
    />
  );

  const shouldDisable =
    user === null ||
    isLoading ||
    isError ||
    salespersons.length === utils.MAX_SALESPEOPLE;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Add Salesperson</Typography>
      </Grid>

      <Grid item xs>
        <Form.Autocomplete
          autoComplete
          clearOnBlur={!user?.email}
          freeSolo
          handleHomeEndKeys
          includeInputInList
          options={users}
          value={user?.email ?? ""} // This is required to clear the input
          selectOnFocus
          size="small"
          onChange={onChange}
          getOptionLabel={(user) => {
            if (G.isString(user)) {
              return user;
            }
            return user.email;
          }}
          renderInput={renderInput}
          renderOption={(user) => (
            <Box display="flex" flexDirection="column">
              <Typography variant="body2">{nameFor(user)}</Typography>

              <Typography variant="caption" color="textSecondary">
                {user.email}
              </Typography>
            </Box>
          )}
          onClick={onClick}
        />
      </Grid>

      <Grid item xs={2}>
        <Button
          onClick={onClick}
          startIcon={<SaveIcon />}
          disabled={shouldDisable}
          fullWidth
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
});

SalespersonSearch.propTypes = {
  customParams: PropTypes.object.isRequired,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  salespersons: PropTypes.arrayOf(
    PropTypes.shape({ userId: PropTypes.number, percentage: PropTypes.number }),
  ).isRequired,
  setSalespersons: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    created: PropTypes.instanceOf(Date),
    email: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    lastName: PropTypes.string,
    userId: PropTypes.number,
  }),
};

export default SalespersonSearch;
