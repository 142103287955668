import * as React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";

const baseEndpoint = "/api/user";
const tcBaseEndpoint = "/api/talentcoordinator";

export function useInfluencerTalentCoordinator(influencerId) {
  const { data, error, mutate } = useSWR(
    `/api/influencer/tc/${influencerId}`,
    fetcher,
  );

  return {
    error,
    isError: Boolean(error),
    isLoading: !data && !error,
    refresh: mutate,
    talentCoordinator: data?.talentCoordinator,
  };
}

export function useTalentCoordinator(userId) {
  const { data, error, mutate } = useSWR(
    `${baseEndpoint}/${userId}/tc`,
    fetcher,
  );

  const talentCoordinator = React.useMemo(() => {
    if (!data?.talentCoordinator) {
      return null;
    }

    const [talentCoordinator] = data.talentCoordinator;

    return talentCoordinator;
  }, [data]);

  return {
    talentCoordinator,
    isTalentCoordinatorLoading: !data && !error,
    isTalentCoordinatorError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useTalentCoordinators() {
  const { data, error, mutate } = useSWR(`${baseEndpoint}/tcs`, fetcher);

  const talentCoordinators = React.useMemo(() => {
    if (!Array.isArray(data?.talentCoordinators)) {
      return [];
    }

    return data?.talentCoordinators;
  }, [data]);

  return {
    talentCoordinators,
    isTalentCoordinatorsLoading: !data && !error,
    isTalentCoordinatorsError: Boolean(error),
    error,
    refresh: mutate,
  };
}

export function useTcTalents(userId) {
  const { data, error, mutate } = useSWR(
    `${tcBaseEndpoint}/getTalent/${userId}`,
    fetcher,
  );

  const talents = React.useMemo(() => {
    if (!Array.isArray(data)) {
      return [];
    }

    return data;
  }, [data]);

  return {
    error,
    talents,
    isError: Boolean(error),
    isLoading: !data && !error,
    refreshTalent: mutate,
  };
}
