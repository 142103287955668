import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link, Box, TextField, Button, Typography } from "@material-ui/core";
import UserEmailSelect from "../CommonComponents/UserEmailSelect";
import LoadingIndicator from "../../../LoadingIndicator";

export default function AddRowsModal({
  onClose,
  getUserInfluencerData,
  influencerId,
  TriggerAlert,
  openInviteModal,
}) {
  const activeQueryId = useRef(0);
  const unmounted = useRef(false);

  const [userEmail, setUserEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [newShopUserObject, setNewShopUserObject] = useState({});

  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [errorText, setErrorText] = useState("");
  const searchBufferInterval = useRef(null);
  const userEmailInputRef = useRef(null);

  const getUsers = async (queryId, userEmail) => {
    try {
      if (!userEmail || userEmail.trim().length < 4) return;
      setIsUsersLoading(true);
      setUsers([]);
      const data = await axios.get(
        `/api/user?email=${encodeURIComponent(userEmail.trim())}`,
      );
      if (unmounted.current) return;
      if (queryId !== activeQueryId.current) return;
      setUsers(data.data);
      setIsUsersLoading(false);
    } catch (error) {
      if (unmounted.current) return;
      if (queryId !== activeQueryId.current) return;
      setIsUsersLoading(false);
      console.error(error);
    }
  };

  const submitNewShopUserObject = async (newShopUserObject) => {
    try {
      setSendButtonDisabled(true);
      await axios.post(`/api/userInfluencer`, {
        influencer_id: influencerId,
        newShopUserObject,
      });
      getUserInfluencerData();
      TriggerAlert({ message: "Saved" });
      setSendButtonDisabled(false);
      onClose();
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        setErrorText(error.response.data);
      } else {
        setErrorText("Something went wrong. Please try again.");
      }
      setSendButtonDisabled(false);
    }
  };

  useEffect(() => {
    clearInterval(searchBufferInterval.current);
    activeQueryId.current++;
    const queryId = activeQueryId.current;
    setIsUsersLoading(false);
    setUsers([]);
    searchBufferInterval.current = setTimeout(() => {
      if (unmounted.current) return;
      if (userEmail && userEmail.trim()) {
        getUsers(queryId, userEmail.trim());
      }
    }, 500);
  }, [userEmail]);

  useEffect(() => {
    if (userEmailInputRef.current) userEmailInputRef.current.focus();
    return () => {
      unmounted.current = true;
      clearInterval(searchBufferInterval.current);
    };
  }, []);

  const clearErrorText = () => {
    setErrorText("");
  };

  const handleSelectChange = (e) => {
    clearErrorText();
    setSelectedUserEmail(e.target.value);
  };

  const createNewShopUserObject = (user_id) => {
    if (!user_id) return setSendButtonDisabled(true);
    setNewShopUserObject({ user_id });
  };

  useEffect(() => {
    if (!selectedUserEmail) return setSendButtonDisabled(true);
    if (selectedUserEmail.length > 0) {
      const [result] = users.filter((user) => user.email === selectedUserEmail);
      setSendButtonDisabled(false);
      if (!result) return setSendButtonDisabled(true);
      createNewShopUserObject(result.user_id);
    }
  }, [users, selectedUserEmail]);

  const handleSaveButton = () => {
    submitNewShopUserObject(newShopUserObject);
  };

  return (
    <BackgroundOverlay onMouseDown={onClose}>
      <Container onMouseDown={(e) => e.stopPropagation()}>
        <TitleContainer>
          <Title>New User Access</Title>
          <ExitButton onClick={onClose}>X</ExitButton>
        </TitleContainer>

        <Box p={2}>
          <Typography variant="caption" color="textSecondary">
            If the user does not exist you can{" "}
            <Link
              component="a"
              href="#"
              onClick={() => {
                openInviteModal();
              }}
            >
              invite them to the platform
            </Link>
            .
          </Typography>
        </Box>

        <Inputs>
          <EmailSearchContainer>
            <TextField
              inputRef={userEmailInputRef}
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
              variant="outlined"
              label="Search For User Email"
              InputLabelProps={{ shrink: true }}
              style={{ margin: 10 }}
              onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
              inputProps={{ maxLength: 80 }}
            />
            {isUsersLoading ? (
              <LoadingIndicatorContainer>
                <LoadingIndicator />
              </LoadingIndicatorContainer>
            ) : (
              <UserEamilSelectContainer>
                {users.length > 0 && (
                  <UserEmailSelect
                    data={users}
                    handleSelectChange={handleSelectChange}
                    value={selectedUserEmail}
                  />
                )}
              </UserEamilSelectContainer>
            )}
          </EmailSearchContainer>
        </Inputs>

        <Footer>
          {errorText ? (
            <ErrorText>{errorText}</ErrorText>
          ) : (
            <Button
              disabled={sendButtonDisabled}
              onClick={handleSaveButton}
              color="primary"
              variant="contained"
              style={{
                marginTop: "10px",
                height: "40px",
                flexShrink: 0,
                width: "95%",
                maxWidth: "300px",
                alignSelf: "center",
                marginBottom: "20px",
              }}
            >
              <React.Fragment>Save</React.Fragment>
            </Button>
          )}
        </Footer>
      </Container>
    </BackgroundOverlay>
  );
}

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  background: white;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 420px;
  width: 400px;
  box-sizing: border-box;
  max-height: 90%;
  overflow: hidden;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
`;

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
  background: rgb(100, 140, 190);
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 40px;
  font-weight: bold;
  text-align: center;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
`;

const EmailSearchContainer = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid rgb(215, 215, 215);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  flex-direction: column;
`;

const ExitButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  font-size: 15px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  line-height: 1;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 95%;
  flex-shrink: 0;
`;

const LoadingIndicatorContainer = styled.div`
  position: relative;
  height: 30px;
`;

const UserEamilSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

const ErrorText = styled.div`
  margin: 0.5rem;
  line-height: 22px;
  font-size: 12px;
  color: rgb(200, 43, 102);
`;
