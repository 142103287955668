import * as React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormHelperText,
  InputBase,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { identity } from "../../util";
import clsx from "clsx";

function ifFancy(a, b) {
  return ({ fancy }) => (fancy ? a : b);
}

const SmallInput = withStyles((theme) => ({
  root: {
    background: "#fff",
    boxShadow: ifFancy(theme.boxShadow.tight, "none"),
    borderRadius: 6.25,
    minWidth: 116,
  },
  input: {
    "&.MuiSelect-selectMenu": {
      height: ifFancy(undefined, "25px"),
      alignItems: ifFancy(undefined, "center"),
      display: ifFancy(undefined, "flex"),
    },
    fontSize: ifFancy(
      theme.typography.pxToRem(13),
      theme.typography.body2.fontSize,
    ),
    fontWeight: ifFancy(theme.typography.medium, theme.typography.regular),
    padding: ifFancy("3px 8px", "5px 8px"),
    "&.notDefault": {
      background: theme.gradient.text,
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textFillColor: "transparent",
      fontWeight: theme.typography.regular,
    },
  },
}))(InputBase);

const StyledSelect = withStyles((theme) => ({
  root: {
    border: ifFancy("2px solid transparent", "1px solid rgb(197 197 197)"),
    borderRadius: 6.25,
    "&:focus": {
      borderRadius: 6.25,
      borderColor: theme.palette.grey[800],
    },
  },
}))(Select);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.interFontStack,
    fontWeight: theme.typography.medium,
    fontSize: theme.typography.body2.fontSize,
    background: "#fff",
    padding: "10px 13px",
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 4,
    borderRadius: 8.3,
    border: `1px solid ${theme.palette.grey[300]}`,
    background: "#fff",
    boxShadow: "1.66167px 1.66167px 8.30836px rgba(0, 0, 0, 0.1)",
  },
  list: {
    display: "flex",
    flexFlow: "column nowrap",
    background: theme.palette.grey[200],
    gap: 2,
    minWidth: 140,
    padding: 0,
    "& .Mui-selected": {
      background: theme.palette.grey[100],
    },
    "& .MuiListItem-root:hover": {
      background: theme.palette.grey[100],
    },
  },
}));

const FancySelect = React.memo(function FancySelect({
  options = [],
  onChange,
  disabled,
  value,
  getOptionLabel = identity,
  getOptionValue = identity,
  fancy,
  helperText,
  error,
  formControlProps,
  menuClassName = "",
  ...props
}) {
  const classes = useStyles();
  const [firstOption] = options;

  return (
    <FormControl error={Boolean(error)} {...formControlProps}>
      <StyledSelect
        {...props}
        fancy={fancy ? String(fancy) : undefined}
        input={<SmallInput />}
        inputProps={{
          className: clsx(
            fancy &&
              !disabled &&
              value !== getOptionValue(firstOption) &&
              "notDefault",
          ),
        }}
        value={value}
        onChange={onChange}
        disabled={disabled}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          classes,
        }}
      >
        {options.map((option) => (
          <StyledMenuItem
            key={getOptionValue(option)}
            value={getOptionValue(option)}
            className={menuClassName}
          >
            {getOptionLabel(option)}
          </StyledMenuItem>
        ))}
      </StyledSelect>

      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
});

FancySelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  menuClassName: PropTypes.string,
};

export default FancySelect;
