import useSWR from "swr";
import fetcher, { HTTPMethods } from "./fetcher";

const endpoint = "/api/tasks/token";

function createFetcher(body) {
  return (url) =>
    fetcher(url, {
      method: HTTPMethods.POST,
      body: JSON.stringify(body),
    });
}

export default function useResourceLocator(body) {
  const { data, error, mutate } = useSWR(endpoint, createFetcher(body));

  return {
    resourceLocator: data,
    isLoading: !data && !error,
    isError: Boolean(error),
    error,
    refresh: mutate,
  };
}
