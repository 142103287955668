import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { A } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { InfluencerEvent } from "../../domain";
import {
  useCurrentUser,
  useInfluencerEvents,
  useNotification,
} from "../../hooks";
import { ErrorUI, SemiBoldText, StyledTextButton } from "../../theme";
import { noop } from "../../util";
import AllEventsModal from "./AllEventsModal";
import EventHeader from "./EventHeader";
import InfluencerEventElement from "./InfluencerEventElement";
const EventDialog = React.lazy(() => import("../TalentDashboard/EventDialog"));

export const loadingUI = (
  <Grid container>
    <Grid item>
      <Skeleton width={300} height={16} />
    </Grid>

    <Grid item>
      <Skeleton width={300} height={16} />
    </Grid>

    <Grid item>
      <Skeleton width={300} height={16} />
    </Grid>
  </Grid>
);

function splitUpEvents(events) {
  const firstEvents = [];
  const allEvents = [];

  for (let i = 0; i < events.length; i++) {
    if (i < 5) {
      firstEvents.push(events[i]);
    }

    allEvents.push(events[i]);
  }

  return { firstEvents, allEvents };
}

const UpcomingSignings = React.memo(function UpcomingSignings({
  influencerId,
  alignItems = "flex-start",
  allowEdit = false,
  isGroupShop = false,
}) {
  const currentUser = useCurrentUser();
  const history = useHistory();
  const [eventToEdit, setEventToEdit] = React.useState(null);
  const [showAllEventsModal, setShowAllEventsModal] = React.useState(false);
  const notify = useNotification();

  const { influencerEvents, isLoading, isError, refresh } =
    useInfluencerEvents(influencerId);

  const handleClose = React.useCallback(() => {
    setEventToEdit(null);
    refresh();
  }, [refresh]);

  const onClose = React.useCallback(
    (message) => {
      if (message) {
        notify({ message, duration: 5000 });
      }
    },
    [notify],
  );

  const { firstEvents, allEvents } = React.useMemo(() => {
    if (!Array.isArray(influencerEvents)) {
      return { firstEvents: [], allEvents: [] };
    }

    return splitUpEvents(influencerEvents);
  }, [influencerEvents]);

  if (isLoading) {
    return loadingUI;
  }

  if (isError) {
    return <ErrorUI message="Unable to load upcoming events" />;
  }

  const align = alignItems === "flex-end" ? "right" : "left";

  const makeOnClick = (evt) => {
    if (isGroupShop) {
      return () => {
        history.push(`/${evt.influencerRoute}`);
      };
    }

    if (
      allowEdit &&
      !currentUser.isAdmin &&
      InfluencerEvent.isNonLiveSigning(evt)
    ) {
      return () => {
        notify({
          message:
            "You do not have permission to edit this non-live event. Please contact your Talent Coordinator for assistance.",
          duration: 5000,
        });
      };
    }

    if (allowEdit) {
      return () => {
        setEventToEdit(evt.raw());
      };
    }
  };

  return (
    <Grid container direction="column" alignItems={alignItems}>
      <Grid item>
        <EventHeader />
      </Grid>

      {!firstEvents.length && (
        <Grid item>
          <SemiBoldText variant="body2" align={align}>
            No upcoming events.
          </SemiBoldText>
        </Grid>
      )}

      {A.isNotEmpty(firstEvents) &&
        firstEvents.map((influencerEvent) => (
          <Grid key={influencerEvent.influencerEventId} item>
            <InfluencerEventElement
              align={align}
              influencerEvent={influencerEvent}
              onClick={makeOnClick(influencerEvent)}
            />
          </Grid>
        ))}

      {firstEvents.length < allEvents.length && (
        <StyledTextButton
          onClick={() => {
            setShowAllEventsModal(true);
          }}
        >
          See More ({allEvents.length - firstEvents.length})
        </StyledTextButton>
      )}

      {showAllEventsModal && (
        <AllEventsModal
          influencerEvents={allEvents}
          open={showAllEventsModal}
          onClose={() => {
            setShowAllEventsModal(false);
          }}
        />
      )}

      {allowEdit && Boolean(eventToEdit) && !isGroupShop && (
        <React.Suspense fallback={null}>
          <EventDialog
            defaultInfluencerId={influencerId}
            onClose={onClose}
            open={Boolean(eventToEdit)}
            setOpen={handleClose}
            updateDetails={eventToEdit}
            setAddedItemToList={noop}
          />
        </React.Suspense>
      )}
    </Grid>
  );
});

UpcomingSignings.propTypes = {
  influencerId: PropTypes.number.isRequired,
  alignItems: PropTypes.oneOf(["flex-start", "flex-end"]),
  allowEdit: PropTypes.bool,
  isGroupShop: PropTypes.bool,
};

export default UpcomingSignings;
