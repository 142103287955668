import * as React from "react";

export default function NoScroll() {
  React.useEffect(() => {
    const handler = (evt) => {
      if (!(evt.target instanceof HTMLInputElement)) {
        return;
      }

      if (evt.target.type === "number") {
        evt.preventDefault();
      }
    };

    document.addEventListener("touchmove", handler, { passive: false });
    document.addEventListener("wheel", handler, { passive: false });

    return () => {
      document.removeEventListener("touchmove", handler);
      document.removeEventListener("wheel", handler);
    };
  }, []);

  return null;
}
