import * as React from "react";

export default function SquiggleIcon({ height = 50, width = 50 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.7165 10.7819C44.6823 11.0521 38.8917 15.0477 35.9771 16.3602C34.1386 17.3813 32.1197 18.0368 30.032 18.2904C28.2562 18.2904 27.986 17.0358 28.3527 14.237C28.4492 13.6193 29.2792 8.04099 25.0135 8.29192C23.0833 8.40773 19.9949 10.2221 11.7722 18.136L15.015 9.99051C15.5464 8.64223 15.6301 7.15881 15.2539 5.75928C14.8776 4.35976 14.0613 3.11832 12.9254 2.21828C11.7896 1.31825 10.3944 0.807327 8.94591 0.760967C7.49743 0.714606 6.07243 1.13526 4.88134 1.96082L0.57697 4.83684C0.298644 5.01875 0.10247 5.30227 0.0303404 5.62686C-0.0417893 5.95144 0.0158422 6.29136 0.190928 6.57403L1.54208 8.67796C1.72399 8.95629 2.00751 9.15246 2.33209 9.22459C2.65668 9.29672 2.9966 9.23909 3.27927 9.06401L7.81527 6.13008C8.1278 5.9427 8.49086 5.85715 8.85417 5.8853C9.21748 5.91345 9.56304 6.05389 9.84298 6.28717C10.1229 6.52045 10.3234 6.83502 10.4166 7.1873C10.5098 7.53958 10.4911 7.91212 10.3631 8.25332L2.6423 27.5554C2.43549 28.0896 2.41767 28.6785 2.59181 29.2242C2.76595 29.7699 3.12156 30.2396 3.59956 30.5553C4.07756 30.871 4.64918 31.0136 5.21944 30.9594C5.7897 30.9053 6.32427 30.6577 6.73435 30.2577C10.035 26.9571 18.8368 18.4641 23.2377 14.9512C23.0265 16.0944 23.0619 17.2696 23.3415 18.398C23.6211 19.5265 24.1385 20.5822 24.859 21.4946C25.5235 22.164 26.3242 22.6829 27.2067 23.0159C28.0892 23.3489 29.033 23.4884 29.9741 23.4248C32.7734 23.2224 35.4975 22.4263 37.9652 21.0892C40.5517 19.9311 45.686 16.1865 48.7937 15.9356C49.113 15.9208 49.4146 15.7848 49.637 15.5552C49.8595 15.3256 49.9858 15.0198 49.9904 14.7002V12.1909C50.0127 12.0121 49.9961 11.8305 49.9418 11.6586C49.8876 11.4867 49.7969 11.3285 49.676 11.1948C49.5551 11.0611 49.4068 10.955 49.2413 10.8837C49.0757 10.8125 48.8967 10.7777 48.7165 10.7819Z"
        fill="currentColor"
      />
    </svg>
  );
}
