import * as React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { withStyles, styled } from "@material-ui/core/styles";
import clsx from "clsx";

const StyledButton = withStyles((theme) => ({
  root: {
    background: "#fff",
    boxShadow: "1.30515px 1.30515px 13.0515px rgba(0, 0, 0, 0.05);",
    borderRadius: 16,
    position: "relative",
    padding: "0 12px",
    minWidth: "unset",
    height: 32,
    transition: "all 0.1s ease-out",
    "&:hover": {
      background: "#fff",
      boxShadow: "1.30515px 3px 13.0515px rgba(0, 0, 0, 0.125);",
    },
    "&.selected": {
      background: theme.palette.grey.main,
      boxShadow: "none",
    },
  },
  endIcon: {
    position: "absolute",
    top: -7,
    right: -4,
  },
}))(Button);

const SoftButton = React.memo(function SoftButton({
  children,
  selected = false,
  ...buttonProps
}) {
  return (
    <StyledButton {...buttonProps} className={clsx(selected && "selected")}>
      <StyledTypography
        component="div"
        className={clsx(selected && "selected")}
      >
        {children}
      </StyledTypography>
    </StyledButton>
  );
});

SoftButton.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.medium,
  fontSize: theme.typography.pxToRem(13),
  "&.selected": {
    color: theme.palette.getContrastText(theme.palette.grey.main),
  },
}));

export default SoftButton;
