import * as React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { SocialHandle } from "../../domain";

const SocialHandleElement = React.memo(function SocialHandleElement({
  handle,
}) {
  const attributes = SocialHandle.attributesFor(handle);

  if (!attributes) {
    return null;
  }

  const label = `Visit ${handle.value} on ${attributes.type}`;

  return (
    <StyledIconButton
      style={{ backgroundColor: attributes.color }}
      component="a"
      href={handle.link}
      aria-label={label}
      title={label}
      rel="noopener noreferer"
      target="_blank"
      size="small"
    >
      <attributes.Icon width={22} height={22} />
    </StyledIconButton>
  );
});

SocialHandleElement.propTypes = {
  handle: PropTypes.instanceOf(SocialHandle),
};

const StyledIconButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    color: "white",
    transition: "all 0.1s ease-out",
    "&:hover": {
      transition: "all 0.1s ease-out",
      transform: "translateY(-2px)",
      boxShadow: "0 4px 0 rgba(85, 85, 85, 1)",
    },
  },
}))(IconButton);

export default SocialHandleElement;
