import * as React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";
import { CircularProgress, Typography } from "@material-ui/core";

const StyledButton = styled(Button)(({ theme }) => ({
  background:
    "linear-gradient(90deg,  rgb(75,152,204), rgb(214, 163, 191), rgb(75,152,204));",
  color: "white",
  backgroundPosition: "-50% 0%",
  backgroundSize: "200% 200%",
  animation: "6s ease 0s infinite normal none running gradientMove",
  textTransform: "none",
  width: "100%",
  height: "2.5rem",
  padding: theme.spacing(1),
  "&.green:disabled": {
    color: theme.palette.green.main,
    background: "none",
    animation: "unset",
  },

  "&:disabled": {
    background: theme.palette.grey[300],
    color: theme.palette.grey[600],
    animation: "unset",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
    lineHeight: 1.2,
  },
  "&.large": {
    padding: `0 ${theme.spacing(5)}px`,
    height: theme.spacing(7),
    minWidth: theme.spacing(24),
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  "&.small": {
    padding: `0 ${theme.spacing(1)}px`,
    height: "2rem",
    width: "fit-content",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontStyle: "italic",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: 11,
    lineHeight: 1.2,
  },
}));

export function DisabledButton() {
  return (
    <StyledTypography align="center" variant="caption">
      Purchase option coming soon!
    </StyledTypography>
  );
}

export default function SexyButton({ isLoading, children, ...props }) {
  return (
    <StyledButton
      {...props}
      disabled={Boolean(isLoading) || Boolean(props.disabled)}
    >
      {isLoading ? <CircularProgress size="2rem" /> : children}
    </StyledButton>
  );
}

SexyButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};
