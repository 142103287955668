import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";

export default function Loading() {
  return (
    <Box
      alignItems="center"
      display="flex"
      height="100vh"
      justifyContent="center"
      width="100vw"
    >
      <CircularProgress color="primary" />
    </Box>
  );
}
