import { useLocation } from "react-router-dom";

export default function useQuery() {
  const location = useLocation();
  const { search } = location;

  if (!search) {
    return new URLSearchParams();
  }

  return new URLSearchParams(search.substring(1));
}
