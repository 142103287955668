import * as React from "react";
import { styled } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  marginTop: 0,
  marginBottom: 0,
}));

function SectionTitle({ title, subtitle }) {
  return (
    <Box pt={3} pb={2}>
      <StyledTitle component="h1">{title}</StyledTitle>

      {subtitle && (
        <Typography gutterBottom variant="caption">
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}

export default SectionTitle;
