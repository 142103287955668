// This util is for formating phone number.

export function formatPhoneNumber(rawPhoneNumber) {
  if (!rawPhoneNumber) {
    return rawPhoneNumber;
  }

  const phoneNumber = rawPhoneNumber.replace(/[^\d]/g, "");

  if (phoneNumber.length < 4) {
    return phoneNumber;
  }

  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  if (phoneNumber.length <= 10 && phoneNumber.length >= 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`;
  }

  if (phoneNumber.length === 11 && phoneNumber[0] === "1") {
    return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
      1,
      4,
    )}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
  }

  return phoneNumber;
}
