import { Container } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.xl,
  width: "100%",
  boxSizing: "border-box",
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default StyledContainer;
