import { Dialog, InputBase } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const gridStyle =
  "50px 70px 70px minmax(70px, 1fr) minmax(150px, 0.5fr) minmax(150px, 200px)";

export const StyledTitle = styled("h1")(({ theme }) => ({
  ...theme.typography.h4,
  fontSize: theme.typography.pxToRem(32),
  fontWeight: 800,
  margin: 0,
}));

export const StyledSmallTitle = styled("h1")(({ theme }) => ({
  ...theme.typography.h5,
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 800,
  margin: 0,
  marginBottom: theme.spacing(1),
}));

export const StyledHeader = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  display: "grid",
  fontWeight: 600,
  gap: theme.spacing(1),
  gridTemplateColumns: gridStyle,
  gridTemplateRows: "auto",
  alignItems: "center",
  padding: theme.spacing(1, 2),
}));

export const StyledRow = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  display: "grid",
  fontWeight: 500,
  gap: theme.spacing(1),
  gridTemplateColumns: gridStyle,
  gridTemplateRows: "auto",
  alignItems: "center",
  justifyItems: "start",
  padding: theme.spacing(0, 2),
  border: "1px solid #e0e0e0",
  borderRadius: theme.spacing(1),
  "&.editing": {
    borderColor: theme.palette.secondary.main,
    boxShadow: theme.boxShadow.buttonDefault,
  },
  "&.inactive": {
    border: "1px solid #d0d0d0",
    background: "#f0f0f0",
    color: theme.palette.grey[500],
  },
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 500,
  lineHeight: 1,
  margin: 0,
  minWidth: theme.spacing(1),
  padding: theme.spacing(0.5, 0),
  "& .MuiInputBase-input": {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  "& .MuiInputBase-input[readonly]": {
    borderColor: "transparent",
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    maxWidth: `calc(100vw - ${theme.spacing(2)}px)`,
  },
}));

export const StyledLabel = styled("label")(({ theme }) => ({
  ...theme.typography.body1,
  fontWeight: 500,
  display: "flex",
  flexDirection: "column",
}));

export const StyledInputBasic = styled("input")(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 4,
  padding: theme.spacing(1),
}));
