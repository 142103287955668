import * as React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { SocialHandle } from "../../domain";
import SocialHandleElement from "./SocialHandleElement";

/*
 * SocialHandles displays an Influencer's social handles
 */
const SocialHandles = React.memo(function SocialHandles({ handles = [] }) {
  return (
    <Grid container spacing={2}>
      {handles.map((handle, i) => (
        <Grid item key={i}>
          <SocialHandleElement handle={handle} />
        </Grid>
      ))}
    </Grid>
  );
});

SocialHandles.propTypes = {
  handles: PropTypes.arrayOf(PropTypes.instanceOf(SocialHandle)),
};

export default SocialHandles;
