import * as React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SignType } from "../../../domain";
import { DialogTitle } from "../../../theme";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiPaper-root": {
      background: "#fff",
      borderRadius: 10,
      margin: 0,
      padding: 0,
      width: "50rem",
      position: "relative",
      top: "-10vh",
      "& .MuiDialogContent-root": {
        padding: "0 20px 20px 20px",
      },
    },
    "& .title": {
      paddingBottom: "10px",
      "& .MuiTypography-root": {
        fontSize: "20px",
        fontWeight: "700",
      },
    },
    "& .blurb": {
      color: "#5B5B5B",
      fontSize: "15px",
      fontWeight: "400",
      paddingBottom: "10px",
    },
    "& .divSignType": {
      border: "1px solid #ccc",
      borderRadius: "10px",
      cursor: "pointer",
      marginBottom: "0.5rem",
      marginTop: "2px",
      padding: "15px",
      background: "none",
      font: "inherit",
      width: "100%",
      textAlign: "inherit",
    },
    "& .divSignType:hover": {
      border: "1px outset #ccc",
      outline: "1px solid #0009",
    },
  },
}));

export default function SignTypeChangeConfirm({
  product,
  newSignType,
  open,
  onClose,
  onSave,
}) {
  const classes = useStyles();
  const label = SignType.toLabel(newSignType);

  return (
    <Dialog open={open} onClose={onClose} className={classes.root}>
      <DialogTitle
        align="left"
        className="title"
        disableTypography={true}
        onClose={onClose}
        gutterBottom={0}
      >
        Create a {label} Version of this Product?
      </DialogTitle>

      <DialogContent>
        {product?.signers?.length > 1 && (
          <p style={{ color: "red", textAlign: "left", margin: "-2 0 12px 0" }}>
            This product is signed by other Talent as well. Please confirm with
            them that they&apos;d like to make a {label} version of this product
            before proceeding.
          </p>
        )}

        <button
          className="divSignType"
          onClick={() => {
            onSave(newSignType, false);
          }}
        >
          Create a {label} version
        </button>

        <button
          className="divSignType"
          onClick={() => {
            onSave(newSignType, true);
          }}
        >
          Create a {label} version and deactivate current product
        </button>
      </DialogContent>
    </Dialog>
  );
}

SignTypeChangeConfirm.propTypes = {
  product: PropTypes.object,
  newSignType: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
};
