import * as React from "react";
import { Box, Chip } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { PaperCard } from "../../../theme";
import { copyToClipboard } from "../../../util";
import { Skeleton } from "@material-ui/lab";

const ShareLink = React.memo(function ShareLink({ route }) {
  const copiedIndicatorTimeout = React.useRef(null);
  const [notifyCopied, setNotifyCopied] = React.useState(false);

  const copyText = async (content) => {
    const isSuccess = await copyToClipboard(content);

    if (isSuccess) {
      setNotifyCopied(true);

      clearTimeout(copiedIndicatorTimeout.current);

      copiedIndicatorTimeout.current = setTimeout(() => {
        setNotifyCopied(false);
      }, 1000);
    }
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(copiedIndicatorTimeout.current);
    };
  }, []);

  if (!route) {
    return <Skeleton variant="rect" width={200} height={50} />;
  }

  return (
    <Box position="relative" width={"100%"} height={50}>
      <PaperCard
        message="Share Your Shop Link"
        text={`streamily.com/${route}`}
        type="link"
        onClick={copyText}
      />
      <CopiedIndicator
        className={notifyCopied ? "show" : undefined}
        label="Copied"
      />
    </Box>
  );
});

const CopiedIndicator = styled(Chip)(({ theme }) => ({
  opacity: 0,
  pointerEvents: "none",
  transition: "opacity 0.2s ease-out",
  position: "absolute",
  bottom: 0,
  left: 0,
  color: theme.palette.getContrastText(theme.palette.grey.dark),
  background: theme.palette.grey.dark,
  "&.show": {
    opacity: 1,
  },
}));

export default ShareLink;
