import { D } from "@mobily/ts-belt";
import { SignType } from "../../domain";

export const DEFAULT_BUMP_PERCENTAGE = 0.22;

export const STEPS = Object.freeze(
  Object.seal({
    CREATE_PRODUCT: 0,
    PRODUCT_DETAILS: 1,
    INCREASE_EXPOSURE: 2,
    PRODUCT_SUMMARY: 3,
  }),
);

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const format = (n) =>
  currencyFormatter
    .format(n / 100)
    .replace("$", "")
    .replace(",", "");

export function createWorkingSigners({ product, activeInfluencer }) {
  if (!product) {
    return [
      {
        active: true,
        avatarUrl: activeInfluencer.avatarUrl,
        influencerId: activeInfluencer.influencerId,
        name: activeInfluencer.name,
        noFulfillment: false,
        position: 1,
        route: activeInfluencer.route,
        splitAmount: 6000,
      },
    ];
  }

  return product.signers;
}

export function createInitialState(product) {
  return {
    currentStep: product?.productId
      ? STEPS.PRODUCT_SUMMARY
      : STEPS.CREATE_PRODUCT,
    didConfirmTypeWarning: false,
    didShowEducationalMessage: false,
    errorMessage: "",
    hasError: false,
    isEditing: Boolean(product?.productId),
    isLoading: false,
    isOpen: true,
    showTypeWarning: false,
  };
}

export function createWorkingProduct({
  product,
  defaultProductType,
  activeInfluencer,
}) {
  if (!product) {
    return {
      active: true,
      additionalBumpAmount: defaultProductType.additionalBumpAmount,
      allowNotes: true,
      allowPersonalization: true,
      bumpPercentage: defaultProductType.bumpPercentage,
      characterTagString: null,
      convention: false,
      disableVip: false,
      blockCertOfAuth: false,
      fixedBump: false,
      franchiseTagString: null,
      imageUrl: "",
      influencerId: activeInfluencer.influencerId,
      name: "",
      noShipping: false,
      originalImageUrl: "",
      productBump: 0,
      productId: null,
      productTypeAspectRatio: defaultProductType.aspectRatio,
      productType: defaultProductType.name,
      productTypeId: defaultProductType.productTypeId,
      signedType: SignType.livestream,
      stock: null,
      subtext: "",
    };
  }

  return {
    active: product.active,
    additionalBumpAmount: product.additionalBumpAmount,
    allowNotes: product.allowNotes,
    allowPersonalization: product.allowPersonalization,
    bumpPercentage: product.bumpPercentage,
    characterTagString: product.characterTagString || null,
    convention: Boolean(product.convention),
    disableVip: Boolean(product.disableVip),
    blockCertOfAuth: Boolean(product.blockCertOfAuth),
    fixedBump: Boolean(product.fixedBump),
    franchiseTagString: product.franchiseTagString || null,
    imageUrl: product.imageUrl,
    influencerId: product.influencerId,
    name: product.name,
    noShipping: Boolean(product.noShipping),
    originalImageUrl: product.originalImageUrl,
    productBump: product.productBump,
    productId: product.productId,
    productTypeAspectRatio: product.productTypeAspectRatio,
    productTypeId: product.productTypeId,
    productType: product.productType,
    signedType: product.signedType,
    stock: product.stock,
    subtext: product.subtext ?? "",
  };
}

export function getImageFromDropEvent({ dataTransfer }) {
  let file;

  if (dataTransfer.items) {
    const foundItem = [...dataTransfer.items].find(
      (item) => item.kind === "file" && item.type.startsWith("image/"),
    );

    file = foundItem?.getAsFile?.();
  } else {
    file = [...dataTransfer.files].find((file) =>
      file.type.startsWith("image/"),
    );
  }

  return file;
}

export function getImageFromFileInputEvent({ target }) {
  const { files } = target;
  return [...files].find((file) => file.type.startsWith("image/"));
}

export function getDefaultProductTypes({ productTypes, activeInfluencer }) {
  if (!productTypes || !productTypes.length) {
    return {};
  }

  let defaultProductType;
  let defaultShoutout;
  let defaultDomestic;
  let defaultInternational;

  productTypes.forEach((productType) => {
    if (productType.isDefaultDomestic) {
      defaultDomestic = productType;
    } else if (productType.isDefaultInternational) {
      defaultInternational = productType;
    }

    if (activeInfluencer.isDomestic && productType.isDefaultDomestic) {
      defaultProductType = productType;
    } else if (
      !activeInfluencer.isDomestic &&
      productType.isDefaultInternational
    ) {
      defaultProductType = productType;
    } else if (productType.isDefaultShoutout) {
      defaultShoutout = productType;
    }
  });

  return {
    defaultProductType,
    defaultShoutout,
    defaultDomestic,
    defaultInternational,
  };
}

export function nextTextFor({ currentStep = 0, isEditing }) {
  if (currentStep === STEPS.PRODUCT_SUMMARY) {
    return isEditing ? "Save Product" : "Create Product";
  }

  if (isEditing || currentStep === STEPS.INCREASE_EXPOSURE) {
    return "Review Product";
  }

  return "Next";
}

export async function createOrUpdateProduct({
  characters,
  product,
  productTags,
  signers,
  state,
}) {
  const isEditingOrDuplicate = state.isEditing || state.duplicate;

  const method = isEditingOrDuplicate ? "PUT" : "POST";

  const endpoint = `/api/product/shopBuilder${
    isEditingOrDuplicate ? `/${product.productId}` : ""
  }`;

  const body = {
    duplicate: Boolean(state.duplicate),
    deactivateParent: Boolean(state.deactivateParent),
    product: D.merge(product, {
      characters,
      signers,
      tags: productTags,
    }),
  };

  const response = await window.fetch(endpoint, {
    method, // POST or PUT
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  });

  const json = await response.json();

  if (!response.ok || json.errors) {
    const { errors, message } = json;
    const errorMessage =
      message ?? errors?.[0]?.message ?? "Something went wrong";
    throw new Error(errorMessage);
  }

  return json.product;
}
