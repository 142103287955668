import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const GradientText = styled(Typography)(({ theme }) => ({
  background: theme.gradient.text,
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textFillColor: "transparent",
  fontWeight: theme.typography.regular,
}));

export default GradientText;
