import * as React from "react";
import { Typography } from "@material-ui/core";
import { useCartProducts, useTimer } from "../../../../hooks";
import { formatSecToMinSec } from "../../../../util/formatSecond";

function ProductModalHeaderUrgency() {
  const { cartProducts } = useCartProducts();
  const { time } = useTimer(600);

  // hide this component for product_id 32823 in prod. by custom request
  if (
    !cartProducts ||
    cartProducts.length < 1 ||
    cartProducts[0].productId === 32823
  ) {
    return null;
  }

  return (
    <Typography variant="caption" style={styles}>
      {Number.isFinite(cartProducts[0]?.stock)
        ? cartProducts[0]?.stock > 50
          ? "Limited quantity remain"
          : `Just ${cartProducts[0]?.stock}% remaining`
        : `Reserved for ${formatSecToMinSec(time)}`}
    </Typography>
  );
}

const styles = {
  color: "#B90000",
  fontWeight: "600",
};

export default ProductModalHeaderUrgency;
