import * as React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

const scrollContext = React.createContext();

const scroll = () => {
  window.scrollTo(0, 0);
};

export function useAutoScroll() {
  return React.useContext(scrollContext);
}

/*
 * Enables the automatic restoration of scroll position to top when navigating
 * to new pages. Context exposes functions for enabling/disabling this behaviour
 * in the event that the page location changes but scroll position should not be
 * altered.
 *
 */
export default function ScrollProvider({ children }) {
  const [shouldAutoScroll, setShouldAutoScroll] = React.useState(true);

  const value = React.useMemo(
    () => ({
      enableAutoScroll: () => setShouldAutoScroll(true),
      disableAutoScroll: () => setShouldAutoScroll(false),
      scroll,
    }),
    [],
  );

  return (
    <scrollContext.Provider value={value}>
      <Scroll shouldAutoScroll={shouldAutoScroll} />
      {children}
    </scrollContext.Provider>
  );
}

ScrollProvider.propTypes = {
  children: PropTypes.node,
};

function Scroll({ shouldAutoScroll }) {
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (!shouldAutoScroll) {
      return;
    }

    scroll();
  }, [pathname, shouldAutoScroll]);

  return null;
}

Scroll.propTypes = {
  shouldAutoScroll: PropTypes.bool,
};
