import { flow, S } from "@mobily/ts-belt";

export const parsePercentage = flow(
  S.trim,
  S.replaceAll(/%/g, ""),
  Number.parseFloat,
  (n) => (n > 1 ? n / 100 : n),
  (n) => Math.round(n * 100) / 100,
);

export const isValidPercentage = (num) =>
  !Number.isNaN(num) && num >= 0 && num <= 1;
