import * as React from "react";
import {
  IconButton,
  Link,
  Dialog,
  Typography /* , CircularProgress */,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useMatchesSmDown } from "../../hooks";

// keep ImageStepper for future use:
// const ImageStepper = React.lazy(() => import("../../theme/ImageStepper"));
// const imageSteps = [
//   {
//     label:
//       "Frame suitable for horizontal or vertical display. Sample prints shown.",
//     imageUrl: "/public/frame-modal-pic-1-scaled.jpg",
//     alt: "Horizontal frame display",
//   },
//   {
//     label:
//       "Frame suitable for horizontal or vertical display. Sample prints shown.",
//     imageUrl: "/public/frame-modal-pic-2-scaled.jpg",
//     alt: "Vertical frame display",
//   },
// ];

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(0.5),
  top: theme.spacing(0.5),
  zIndex: 10,
}));
const StyledDialog = styled(Dialog)(({ theme }) => ({
  width: "auto",
  "& .MuiDialog-paperWidthSm": {
    maxWidth: "fit-content",
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "90vw",
      margin: "0",
    },
  },
}));

const FrameDetails = React.memo(function FrameDetails() {
  const [open, setOpen] = React.useState(false);
  const isMobile = useMatchesSmDown();
  const size = isMobile ? "100%" : "600px";

  return (
    <>
      <Link component="button" type="button" onClick={() => setOpen(true)}>
        <Typography
          variant="caption"
          style={{ color: "#2A6FC0", textDecoration: "underline" }}
        >
          See more
        </Typography>
      </Link>

      <StyledDialog open={open} onClose={() => setOpen(false)}>
        {/* 
        // keep ImageStepper for future use:
        <React.Suspense fallback={<CircularProgress />}>
          <ImageStepper steps={imageSteps} />
        </React.Suspense> */}
        <StyledIconButton
          onClick={() => setOpen(false)}
          aria-label="Close"
          style={{ right: "4px", left: "auto" }}
        >
          <CloseIcon style={{ color: "silver" }} />
        </StyledIconButton>
        <img
          src="https://d3tw7mvc4pjx2c.cloudfront.net/misc/frame-sm.png"
          style={{ width: size, height: size }}
        />
      </StyledDialog>
    </>
  );
});

export default FrameDetails;
