import deleteCookie from "./util/deleteCookie";
import { localStorageUtils } from "./util";

export const actions = Object.seal(
  Object.freeze({
    SET_USER: "setUser",
    SET_TRACKING_UUID: "setTrackingUuid",
    SET_TRACKING_CODE: "setTrackingCode",
    TRIGGER_ALERT: "triggerAlert",
    TRIGGER_AUTH_MODAL: "triggerAuthModal",
    SET_NUMBER_OF_BANNERS: "setNumberOfBanners",
    SET_BROWSER_WIDTH: "setBrowserWidth",
  }),
);

export const actionCreators = {
  createSetUser(payload) {
    return { type: actions.SET_USER, payload };
  },
  createSetTrackingUUID(payload) {
    return { type: actions.SET_TRACKING_UUID, payload };
  },
  createSetTrackingCode(payload) {
    return { type: actions.SET_TRACKING_CODE, payload };
  },
  createTriggerAlert(payload) {
    return { type: actions.TRIGGER_ALERT, payload };
  },
  createTriggerAuthModal(payload) {
    return { type: actions.TRIGGER_AUTH_MODAL, payload };
  },
  createSetNumberOfBanners(payload) {
    return { type: actions.SET_NUMBER_OF_BANNERS, payload };
  },
  createSetBrowserWidth(payload) {
    return { type: actions.SET_BROWSER_WIDTH, payload };
  },
};

function globalStateReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.SET_USER: {
      const user = payload;

      localStorage.setItem("uo", JSON.stringify(user));

      if (!user?.user_id) deleteCookie("ut", "/");

      return { ...state, user };
    }

    case actions.SET_TRACKING_UUID: {
      const trackingUUID = payload;

      return { ...state, trackingUUID };
    }

    case actions.SET_TRACKING_CODE: {
      const trackingCode = payload;

      localStorageUtils.setWithExpiry("stc", trackingCode);

      return { ...state, trackingCode };
    }

    case actions.TRIGGER_ALERT: {
      const alert = payload;
      const alerts = state.alerts.concat(alert);

      return { ...state, alerts };
    }

    case actions.TRIGGER_AUTH_MODAL: {
      const triggerAuthModal = { type: payload ?? "login" };

      return { ...state, triggerAuthModal };
    }

    case actions.SET_NUMBER_OF_BANNERS: {
      const numberOfBanners = payload;

      return { ...state, numberOfBanners };
    }

    case actions.SET_BROWSER_WIDTH: {
      const browserWidth = payload;

      return { ...state, browserWidth };
    }

    default:
      return state;
  }
}

export default globalStateReducer;
