/*
 * sizeInMb returns a rounded to one-decimal place size in MB
 *
 * @param {number} size in bytes
 * @returns {number} size in MB
 */
export function sizeInMb(size) {
  return Math.round((size / 1048576) * 10) / 10;
}

/*
 * validateType returns true if file type is one of mimeType
 *
 * @param {object} initialization object { file, mimeTypes }
 * @returns {boolean} isValidType
 */
export function validateType({ file, mimeTypes = [] }) {
  if (!file || !mimeTypes) {
    return false;
  }

  const { type } = file;

  const types = mimeTypes.map((mimeType) => {
    const [first] = mimeType.split("/");

    return first;
  });

  return (
    types.length > 0 && types.some((mimeType) => type.startsWith(mimeType))
  );
}
