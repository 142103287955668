import momentTimezone from "moment-timezone";

export function formatDateRangeTZ(start_date, end_date, timezone) {
  const startDateData = new Date(Date.parse(start_date));
  const endDateData = new Date(Date.parse(end_date));
  const startDateStr = momentTimezone(start_date).tz(timezone).format("MMM D");
  const endDateStr = momentTimezone(end_date).tz(timezone).format("MMM D");
  return `${startDateStr} - ${endDateStr}`;
}

export function formatDateRangeReverseTZ(start_date, end_date) {
  const startDateStr = momentTimezone(start_date)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format("MMM D");
  const endDateStr = momentTimezone(end_date)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format("MMM D");
  return `${startDateStr} - ${endDateStr}`;
}
