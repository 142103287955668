import { styled } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const StyledDarkBackground = styled(Box)(({ theme }) => ({
  background: theme.palette.grey[200],
  height: "100%",
  width: "100%",
}));

export default StyledDarkBackground;
