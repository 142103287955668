import * as React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";

export default function OverflowText({
  text,
  maxLength,
  component,
  typographyProps = {},
}) {
  const Text = React.useMemo(() => component ?? Typography, [component]);

  if (!text) return null;
  if (text.length <= maxLength) {
    return (
      <Text title={text} {...typographyProps}>
        {text}
      </Text>
    );
  }

  const trimmedText = `${text.slice(0, maxLength)}…`;

  return (
    <Box title={text} aria-label={text}>
      <Text {...typographyProps}>{trimmedText}</Text>
    </Box>
  );
}

OverflowText.propTypes = {
  text: PropTypes.string,
  maxLength: PropTypes.number,
  component: PropTypes.object,
  typographyProps: PropTypes.object,
};
