import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import FilePreview from "./FilePreview";
import * as utils from "./utils";

/*
 * FileUploadDialog presents a file upload dialog to upload a single file using
 * the onSubmit function.
 *
 * The title is the dialog title, the description is the short dialog content
 * text. The helperText provides information about accepted file types.
 *
 * In addition, it takes an mimeAcceptList string[] of mime types such as "image/*"
 * or "video/quicktime" that will filter file selection candidates.
 *
 * The maxFileSizeMB prop allows preventing the user from uploading a file that is
 * too large. This is a number representing the max size of a file in MB.
 * Default is 25 MB. For unlimited file size, pass Infinity.
 */
const FileUploadDialog = React.memo(function FileUploadDialog({
  cancel,
  color = "primary",
  description,
  helperText,
  maxFileSizeMB = 25,
  mimeAcceptList,
  onClose,
  onSubmit,
  open,
  title,
  validateMime,
}) {
  const [loading, setLoading] = React.useState(false);
  const [errorText, setErrorText] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleChange = (evt) => {
    const [file] = Array.from(evt.target.files);

    const shouldVerifyFileSize = Number.isFinite(maxFileSizeMB);
    const fileSizeInMB = utils.sizeInMb(file.size);

    if (shouldVerifyFileSize && fileSizeInMB > maxFileSizeMB) {
      setErrorText(
        `🤯 Your file is too powerful! (${fileSizeInMB}MB, max is ${maxFileSizeMB}MB)`,
      );
      return;
    }

    if (validateMime) {
      const isValidType = utils.validateType({
        file,
        mimeTypes: mimeAcceptList,
      });

      if (!isValidType) {
        setErrorText(`🤔 Hmm.. I don't think that file is the right type!`);
        return;
      }
    }

    if (errorText) {
      setErrorText(null);
    }

    setSelectedFile({ file, url: URL.createObjectURL(file) });
  };

  const handleSuccess = (result) => {
    setLoading(false);
    onClose(result);
  };

  const handleFailure = (err) => {
    console.error(err);
    setLoading(false);
    setErrorText(`Unable to submit file: ${err.message}`);
  };

  const wrappedOnSubmit = (evt) => {
    evt.preventDefault();

    if (!selectedFile) {
      setErrorText(`🤔 Hmm.. I think you forgot to pick a file!`);
      return;
    }

    setErrorText(null);
    setLoading(true);

    const { file } = selectedFile;

    if (typeof onSubmit === "function") {
      try {
        const result = onSubmit(file);

        if (result instanceof Promise) {
          result.then(handleSuccess).catch(handleFailure);
        } else {
          handleSuccess(result);
        }
      } catch (err) {
        handleFailure(err);
      }
    }
  };

  const preview = React.useMemo(() => {
    if (!selectedFile || !selectedFile?.file) {
      return null;
    }

    return <FilePreview file={selectedFile.file} url={selectedFile.url} />;
  }, [selectedFile]);

  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>

      <form onSubmit={wrappedOnSubmit}>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>

          <Box py={2}>{preview}</Box>

          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Button
                component="label"
                disabled={loading}
                startIcon={<AddIcon />}
                type="button"
                variant="contained"
              >
                <span>{selectedFile ? "Change file" : "Select file"}</span>

                <input
                  accept={mimeAcceptList}
                  disabled={loading}
                  hidden
                  onChange={handleChange}
                  type="file"
                />
              </Button>
            </Grid>
          </Grid>

          <DialogContentText>
            <Typography variant="caption" color="textSecondary">
              {helperText}
            </Typography>

            <Box py={2} height={8} width="100%">
              {loading && <LinearProgress color={color} />}
            </Box>
          </DialogContentText>

          {Boolean(errorText) && (
            <DialogContentText color="error">{errorText}</DialogContentText>
          )}
        </DialogContent>

        <DialogActions>
          {Boolean(cancel) && (
            <Button type="button" variant="text" onClick={cancel}>
              Cancel
            </Button>
          )}

          <Button
            color={color}
            disabled={loading}
            type="submit"
            variant="contained"
          >
            Upload
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
});

FileUploadDialog.propTypes = {
  cancel: PropTypes.func,
  color: PropTypes.oneOf(["primary", "secondary"]),
  description: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  maxFileSizeMB: PropTypes.number,
  mimeAcceptList: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  validateMime: PropTypes.bool,
};

const StyledDialog = withStyles(() => ({
  paper: {
    minWidth: "32rem",
    minHeight: "12rem",
  },
}))(Dialog);

export default FileUploadDialog;
