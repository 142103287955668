import PropTypes from "prop-types";
import * as React from "react";
import { StreamilyTabs } from "../../../../theme";
import FanReviews from "./FanReviews";
import HowStreamilyWorks from "./HowStreamilyWorks";

export default function PromoTabs() {
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleChange = React.useCallback((newValue) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <div className="home-page__sections__promo-tabs">
      <StreamilyTabs
        size="lg"
        currentTab={currentTab}
        options={["Fan Reviews", "How Streamily Works"]}
        onChange={handleChange}
      />

      <div className="home-page__sections__promo-tabs__tab-box">
        <div className="home-page__sections__promo-tabs__inner-box">
          <FanReviews isCurrentTab={currentTab === 0} />
          <HowStreamilyWorks isCurrentTab={currentTab === 1} />
        </div>
      </div>
    </div>
  );
}

PromoTabs.propTypes = {
  isMobile: PropTypes.bool,
};
