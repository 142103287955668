import React from "react";
import useSWR from "swr";
import fetcher from "./fetcher";

const defaultStats = {
  totalPaid: 0,
  totalEligible: 0,
};

export default function useStats(
  opts = { influencerId: 0, refreshInterval: 5000 },
) {
  const { refreshInterval, influencerId } = opts;

  const hasRefreshInterval =
    Number.isInteger(refreshInterval) && refreshInterval > 0;

  const { data, error, mutate } = useSWR(
    `/api/stats/${influencerId}`,
    fetcher,
    hasRefreshInterval ? { refreshInterval } : undefined,
  );

  const stats = React.useMemo(
    () => (data?.stats ? data.stats : defaultStats),
    [data],
  );

  return {
    stats,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
