import { Box } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { D } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import formatDate from "../../../../util/formatDate";

export default function UserInfluencerRow({
  data,
  setShowConfirmModal,
  setShopUserUpdate,
  setDescription,
}) {
  const shopAccessSince = formatDate(data.created);

  const HandleDeactivateButton = () => {
    if (data.user_influencer_id) {
      setShopUserUpdate(D.merge(data, { active: false }));
      setDescription("revoke this user's access");
      setShowConfirmModal(true);
    }
  };

  const handleOwnerCheckbox = (event) => {
    setShopUserUpdate(D.merge(data, { is_owner: event.target.checked }));
    setDescription(
      event.target.checked === true
        ? "set this user as shop owner"
        : "revoke this user as shop owner",
    );
    setShowConfirmModal(true);
  };

  const handleNotificationsEnabled = (event) => {
    setShopUserUpdate(
      D.merge(data, { notifications_enabled: event.target.checked }),
    );
    setDescription(
      event.target.checked === true
        ? "enable email notifications for this user"
        : "disable email notifications for this user",
    );
    setShowConfirmModal(true);
  };

  const handleNoShopEditorCheckbox = (event) => {
    setShopUserUpdate(D.merge(data, { no_shop_editor: !event.target.checked }));
    setDescription(
      event.target.checked === true
        ? "grant access to this user to shop editor"
        : "revoke this user's access to shop editor",
    );

    setShowConfirmModal(true);
  };

  return (
    <PayoutCut>
      <span>{data.email}</span>

      <CheckboxContainer>
        <Checkbox
          checked={Boolean(data.is_owner)}
          onChange={handleOwnerCheckbox}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </CheckboxContainer>

      <CheckboxContainer>
        <Checkbox
          checked={Boolean(!data.no_shop_editor)}
          onChange={handleNoShopEditorCheckbox}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </CheckboxContainer>

      <CheckboxContainer>
        <Checkbox
          checked={Boolean(data.notifications_enabled)}
          onChange={handleNotificationsEnabled}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </CheckboxContainer>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        py={1}
      >
        <span>
          {shopAccessSince.date}
          <br />
          {shopAccessSince.time}
        </span>
        <IconButton
          size="small"
          aria-label="delete"
          onClick={HandleDeactivateButton}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
    </PayoutCut>
  );
}

const PayoutCut = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 200px repeat(3, minmax(80px, 1fr)) minmax(100px, 1fr);
  font-size: 0.75rem;
  border: 1px solid rgb(215, 215, 215);
  padding: 0 8px;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  color: rgb(60, 60, 60);
  width: 100%;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

UserInfluencerRow.propTypes = {
  data: PropTypes.object,
  setShowConfirmModal: PropTypes.func,
  setShopUserUpdate: PropTypes.func,
  setDescription: PropTypes.func,
};
