import * as React from "react";
import PropTypes from "prop-types";
import { Typography, Grid } from "@material-ui/core";
import * as utils from "./utils";
import { dateTimeFormatter } from "../../util";

const FileDetails = React.memo(function FileDetails({ file }) {
  return (
    <Grid item xs={12} md>
      <Typography variant="body2">File details</Typography>

      <Typography component="p" color="textSecondary" variant="caption">
        Name: {file.name}
      </Typography>

      <Typography component="p" color="textSecondary" variant="caption">
        Size: {utils.sizeInMb(file.size)}MB
      </Typography>

      <Typography component="p" color="textSecondary" variant="caption">
        Last Modified: {dateTimeFormatter.format(file.lastModified)}
      </Typography>
    </Grid>
  );
});

FileDetails.propTypes = {
  file: PropTypes.instanceOf(File),
};

export default FileDetails;
