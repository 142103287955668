import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StyledBackground = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.background,
  flex: "1",
  display: "flex",
  flexDirection: "column",
  minHeight: "90vh",
  [theme.breakpoints.down("sm")]: {
    minHeight: "32rem",
  },
}));

export default StyledBackground;
