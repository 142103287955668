import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const GoldText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  fontWeight: theme.typography.semiBold,
  fontSize: "0.8rem",
  background: theme.gradient.gold,
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textFillColor: "transparent",
}));

export default GoldText;
