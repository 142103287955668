import { Box, IconButton, ListItemText, TextField } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { A } from "@mobily/ts-belt";
import PropTypes from "prop-types";
import * as React from "react";
import { dateTimeFormatter } from "../../util";

const inputProps = {
  min: 0,
  max: 100,
  size: 6,
};

export function SalespersonRow({ isError, salesperson, setSalespersons }) {
  const [textPercentage, setTextPercentage] = React.useState(
    String(salesperson.percentage),
  );
  /*
   * onChange handles updating the current salesperson's percentage in the user's
   * workingSalespeople list.
   */
  const onChange = (evt) => {
    const { value } = evt.target;

    if (value === textPercentage) {
      return;
    }

    setTextPercentage(value);

    const newValue = Number.parseInt(value, 10);

    if (Number.isNaN(newValue)) {
      return;
    }

    const boundedValue = Math.max(Math.min(newValue, 100), 0);

    setSalespersons(
      A.map((sp) =>
        sp === salesperson ? { ...salesperson, percentage: boundedValue } : sp,
      ),
    );
  };

  /*
   * onBlur sets the percentage field back to default if it has been cleared and
   * then loses focus.
   */
  const onBlur = (evt) => {
    const { value } = evt.target;

    if (!value) {
      setTextPercentage(String(salesperson.percentage));
    }
  };

  /*
   * onDelete removes the current salesperson from the user's list of
   * workingSalespeople.
   */
  const onDelete = () => {
    setSalespersons((wsp) => wsp.filter((sp) => sp !== salesperson));
  };

  return (
    <StyledBox display="flex" alignItems="center" gridGap="8px">
      <ListItemText
        primary={`${salesperson.fullName ?? "No name"} (${salesperson.email})`}
        secondary={dateTimeFormatter.format(salesperson.created)}
      />

      <TextField
        disabled
        error={isError}
        inputProps={inputProps}
        label="Percentage"
        onBlur={onBlur}
        onChange={onChange}
        size="small"
        value={textPercentage}
        variant="outlined"
      />

      <IconButton aria-label="delete" onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </StyledBox>
  );
}

SalespersonRow.propTypes = {
  salesperson: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string.isRequired,
    created: PropTypes.instanceOf(Date).isRequired,
    percentage: PropTypes.number.isRequired,
    setPercentage: PropTypes.func.isRequired,
  }),
  setSalespersons: PropTypes.func.isRequired,
  isError: PropTypes.bool,
};

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  background: theme.palette.grey[100],
  flexGrow: 1,
  padding: theme.spacing(1, 2),
}));

export default SalespersonRow;
