import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Influencer } from "../../../domain";
import { PreloadLink } from "../../../theme";

function SearchResultWrapper({ influencer, onClick, children }) {
  if (influencer.isOpen) {
    return (
      <StyledPreloadLink
        onClick={onClick}
        isReactLink={influencer.isOpen}
        to={`/${influencer.route}`}
        endpoints={[
          influencer.endpoints.influencerByRoute,
          influencer.endpoints.influencerEvents,
        ]}
      >
        {children}
      </StyledPreloadLink>
    );
  }

  return (
    <SearchResultLink onClick={onClick} href={influencer.route}>
      {children}
    </SearchResultLink>
  );
}

SearchResultWrapper.propTypes = {
  influencer: PropTypes.instanceOf(Influencer),
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default React.memo(SearchResultWrapper);

const StyledPreloadLink = styled(PreloadLink)`
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  align-items: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    background: rgb(240, 240, 240);
  }
  &:active {
    background: rgb(230, 230, 230);
  }
`;

const SearchResultLink = styled.a`
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  align-items: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    background: rgb(240, 240, 240);
  }
  &:active {
    background: rgb(230, 230, 230);
  }
`;
